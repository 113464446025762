import {EventEmitter, Injectable} from '@angular/core';
import {Subscription} from 'rxjs';
import {ViewTypeEnum} from '../../core/enums/view-type.enum';
import {UserViewModel} from '../../features/user/settings/user.view-model';

@Injectable({
  providedIn: 'root'
})
export class ActionsService {
  get permissions(): string[] {
    return this._permissions;
  }

  set permissions(value: string[]) {
    console.log(value);
    this._permissions = value;
  }
  get viewSubject(): EventEmitter<any> {
    return this._viewSubject;
  }

  get addSubject(): EventEmitter<any> {
    return this._addSubject;
  }

  public viewChangeEnabled: boolean = false;
  public viewType: ViewTypeEnum = ViewTypeEnum.GRID;
  public contextUser: UserViewModel;
  private _permissions: string[];
  private subscriptions: Subscription[] = [];
  private _addSubject: EventEmitter<any> = new EventEmitter<any>();
  private _viewSubject: EventEmitter<any> = new EventEmitter<ViewTypeEnum>();

  constructor() {
  }

  onAdd() {
    this.addSubject.emit();
  }

  onViewChange(viewType: ViewTypeEnum) {
    this.viewType = viewType;
    this.viewSubject.emit(viewType);
  }

  public unsubscribeActions(): void {
    this.viewChangeEnabled = false;
    this.viewType = ViewTypeEnum.GRID;
    this.contextUser = undefined;
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public subscribeToAddAction(callable: CallableFunction): Subscription {
    const subscription = this.addSubject.subscribe(() => {
      callable();
    });

    this.subscriptions.push(subscription);
    return subscription;
  }

  public subscribeToViewChangeAction(callable: CallableFunction): Subscription {
    this.viewChangeEnabled = true;
    const subscription = this.viewSubject.subscribe((viewType: ViewTypeEnum) => {
      callable(viewType);
    });

    this.subscriptions.push(subscription);
    return subscription;
  }
}
