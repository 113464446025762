import {Exclude, Expose} from "class-transformer";
import {AbstractApiModel} from "./abstract.api.model";

@Exclude()
export class RatingApiModel extends AbstractApiModel {
  @Expose() public id: number;
  @Expose() public rated: number;
  @Expose() public ratedBy: string;
  @Expose() public note: number;
  @Expose() public type: string;
}
