import {Injectable} from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import {Observable} from "rxjs";
import {TokenDataProviderService} from "./token-data-provider.service";
import {CurrentUserViewModel} from "../models/current-user.view-model";
import {take} from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class CurrentUserResolverService  {
  constructor(private ts: TokenDataProviderService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CurrentUserViewModel> {
    return this.ts.currentUser.pipe(take(1));
  }
}
