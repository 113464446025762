import {Expose, Type} from 'class-transformer';
import {UserApiModel} from './user.api-model';
import {AbstractApiModel} from './abstract.api.model';
import {ReactionApiModel} from './reaction.api.model';

export class CommentApiModel extends AbstractApiModel {
  @Expose() public id: number;
  @Expose() public type: string;
  @Expose() public content: string;
  @Expose({groups: ['post']}) public targetObject: string;
  @Expose()
  @Type(() => CommentApiModel)
  public responses: CommentApiModel[];
  @Type(() => ReactionApiModel)
  public reactions: ReactionApiModel[];
  public currentUserReaction: ReactionApiModel;
  @Expose() public thread?: string;
  @Expose() public post: string;
  @Expose()
  @Type(() => UserApiModel)
  public author: UserApiModel;
  @Expose() public updatedAt: Date;
  @Expose() public createdAt: Date;
}
