import {Component, Input, OnInit} from '@angular/core';
import {ReactionApiModel} from '../../../api/models/reaction.api.model';
import {ArrayHelper} from '../../../core/helpers/array.helper';
import {KeyValue} from '@angular/common';

@Component({
  selector: 'app-social-reactions-bar',
  templateUrl: './social-reactions-bar.component.html',
  styleUrls: ['./social-reactions-bar.component.scss']
})
export class SocialReactionsBarComponent implements OnInit {
  get reactions(): ReactionApiModel[] {
    return this._reactions;
  }

  @Input() set reactions(value: ReactionApiModel[]) {
    this._reactions = value;
    if (value) {
      this.groupReactionTypes();
    }
  }
  public uniqueReactionTypes: {[key: string]: ReactionApiModel[]};
  constructor() { }

  private _reactions: ReactionApiModel[];

  valueLengthDescOrder = (a: KeyValue<string, ReactionApiModel[]>, b: KeyValue<string, ReactionApiModel[]>): number => {
    return a.value.length > b.value.length ? -1 : (b.value.length > a.value.length ? 1 : 0);
  }
  ngOnInit(): void {
    this.groupReactionTypes();
  }

  public groupReactionTypes(): void {
    this.uniqueReactionTypes =  ArrayHelper.groupBy<ReactionApiModel>(this.reactions, (reactionAM: ReactionApiModel) => reactionAM.reactedWith);
  }
}
