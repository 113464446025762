import { Injectable } from '@angular/core';
import {PostRestService} from '../../api/services/post-rest.service';
import {PostApiModel} from '../../api/models/post-api.model';
import {PostViewModel} from './post.view-model';
import {map, mergeMap} from 'rxjs/operators';
import {forkJoin, Observable, of} from 'rxjs';
import {UserViewModel} from '../../features/user/settings/user.view-model';
import {ResultListApiModel} from '../../api/models/result-list.api.model';
import {MediaObjectViewModel} from '../../core/models/media-object.view-model';
import {QueryParamsApiModel} from '../../api/models/query-params-api.model';
import {FilterParameterApiModel} from '../../api/models/filter-parameter.api.model';
import {MediaObjectApiModel} from '../../api/models/media-object.api-model';
import {MediaObjectRestService} from '../../api/services/media-object.rest.service';
import {PostTypeEnum} from '../../core/enums/post-type.enum';
import {CommentRestService} from '../../api/services/comment.rest.service';
import {plainToClass} from 'class-transformer';
import {CommentApiModel} from '../../api/models/comment.api.model';
import {ProjectViewModel} from '../../features/user/garage/project.view-model';
import {ProjectApiModel} from '../../api/models/project.api-model';
import {EventApiModel} from '../../api/models/event.api.model';
import {EventRestService} from '../../api/services/event.rest.service';
import {ProjectRestService} from '../../api/services/project.rest.service';
import {VehicleViewModel} from '../../features/user/garage/vehicle.view-model';
import {VehicleApiModel} from '../../api/models/vehicle.api-model';
import {VehicleTypeViewModel} from '../../core/models/vehicle-type.view-model';
import {VehicleRestService} from '../../api/services/vehicle.rest.service';
import {ReactionApiModel} from '../../api/models/reaction.api.model';
import {ReactionRestService} from '../../api/services/reaction.rest.service';
import {SubjectTypeToReactionTypeMapper} from '../../core/helpers/subject-type-to-reaction-type.mapper';
import {ReactionTypeEnum} from "../../core/enums/reaction-type.enum";
import {ReactionSubjectInterface} from "./timeline-tile/reaction-subject.interface";

@Injectable({
  providedIn: 'root'
})
export class TimelineDataProviderService {
  public totalPostItems: number;
  public items: PostViewModel[];
  public page: number;
  public endOfResults: boolean;
  constructor(
    private postRS: PostRestService,
    private mediaObjectRS: MediaObjectRestService,
    private eventRS: EventRestService,
    private projectRS: ProjectRestService,
    private vehicleRS: VehicleRestService,
    private commentRS: CommentRestService,
    private reactionRS: ReactionRestService,
  ) { }

  public createPost(postVM: PostViewModel): Observable<PostViewModel> {
    const postInput = postVM.toApiModel();
    return this.postRS.create(postInput).pipe(map((postAM: PostApiModel) => {
      return new PostViewModel(postAM);
    }));
  }

  public updatePost(postVM: PostViewModel): Observable<PostViewModel> {
    const postAM = postVM.toApiModel();
    return this.postRS.put(postAM).pipe(map((postAM: PostApiModel) => {
      return postVM;
    }));
  }

  public removePost(postVM: PostViewModel): Observable<any> {
    const postAM = postVM.toApiModel();
    return this.postRS.delete(postAM);
  }

  public getPostCollection(project: ProjectViewModel, event: EventApiModel, page: number, postTypes: string[]): Observable<PostViewModel[]> {
    const queryParams = new QueryParamsApiModel();
    queryParams.filters.push(new FilterParameterApiModel('limit', 9, 'single'));
    queryParams.page = page;

    postTypes.forEach((postType: string) => {
      queryParams.filters.push(new FilterParameterApiModel('discriminator', postType, 'array'));

    });

    if (project) {
      queryParams.filters.push(new FilterParameterApiModel('project', project.id, 'single'));

    }

    if (event) {
      queryParams.filters.push(new FilterParameterApiModel('event', event.id, 'single'));

    }

    try {
      return this.postRS.getCollection(queryParams).pipe(mergeMap((resultListAM: ResultListApiModel<PostApiModel>) => {
        this.totalPostItems = resultListAM.total;
        const deps = forkJoin([
          project ? of([project]) : this.getProjectsCollection(resultListAM.records),
          event ? of([event]) : this.getEventCollection(resultListAM.records)
        ]);
        return deps.pipe(map((
          [projectVMs, eventAMs]: [ProjectViewModel[], EventApiModel[]]) => {
          return resultListAM.records.map((postAM: PostApiModel) => {
            const postVM = new PostViewModel(postAM);
            const userVM = new UserViewModel(postAM.postedBy);
            userVM.avatar = postAM.postedBy.avatar ? new MediaObjectViewModel(postAM.postedBy.avatar as MediaObjectApiModel) : null;
            postVM.postedBy = userVM;
            if (postAM.project) {
              postVM.project = projectVMs.find((projectVM: ProjectViewModel) => projectVM['@id'] === postAM.project['@id']);
            }
            if (postAM.event) {
              postVM.event = eventAMs.find((eventAM: EventApiModel) => eventAM['@id'] === postAM.event['@id']);
            }
            return postVM;
          });
        }));
      }));
    } catch (e) {
      console.log(e);
    }
  }

  public getMediaObjectCollection(postAMs: PostApiModel[]): Observable<MediaObjectViewModel[]> {
    const params = new QueryParamsApiModel();
    params.filters = postAMs
      .filter((e: PostApiModel) => typeof e.postedBy.avatar === 'string')
      .map(e => new FilterParameterApiModel('id', e.postedBy.avatar));
    if (params.filters.length) {
      return this.mediaObjectRS.getMediaObjectCollection(params)
        .pipe(map((resultListAM: ResultListApiModel<MediaObjectApiModel>) => {
          return resultListAM.records.map((mediaObjectAM: MediaObjectApiModel) => new MediaObjectViewModel(mediaObjectAM));
        }));
    }

    return of<MediaObjectViewModel[]>([]);
  }

  public getProjectsCollection(postAMs: PostApiModel[]): Observable<ProjectViewModel[]> {
    const params = new QueryParamsApiModel();
    params.filters = postAMs
      .filter((e: PostApiModel) => e.project !== null)
      .map(e => {
        // const iriArray = e.project.split('/');
        // const slug = iriArray[iriArray.length - 1];
        return new FilterParameterApiModel('slug', (e.project as ProjectApiModel).slug);
      });
    if (params.filters.length) {
      return this.projectRS.getCollection(params)
        .pipe(mergeMap((resultListAM: ResultListApiModel<ProjectApiModel>) => {
          return this.getVehicleCollection(resultListAM.records).pipe(map((vehicleVMs: VehicleViewModel[]) => {
            return resultListAM.records.map((projectAM: ProjectApiModel) => {
              const projectVM = new ProjectViewModel(projectAM);
              projectVM.vehicle = vehicleVMs.find((vehicleVM: VehicleViewModel) => projectAM.vehicle === vehicleVM['@id']);
              return projectVM;
            });
          }));
        }));
    }

    return of<ProjectViewModel[]>([]);
  }

  public getVehicleCollection(projectAMs: ProjectApiModel[]): Observable<VehicleViewModel[]> {
    const params = new QueryParamsApiModel();
    params.filters = projectAMs.map(e => new FilterParameterApiModel('id', e.vehicle));
    return this.vehicleRS.getVehicleCollection(params)
      .pipe(map((resultListAM: ResultListApiModel<VehicleApiModel>) => {
          return resultListAM.records.map((vehicleAM: VehicleApiModel) => {
            return new VehicleViewModel(vehicleAM);
          });
      }));
  }

  public getEventCollection(postAMs: PostApiModel[]): Observable<EventApiModel[]> {
    const params = new QueryParamsApiModel();
    params.filters = postAMs
      .filter((e: PostApiModel) => e.event !== null)
      .map(e => new FilterParameterApiModel('slug', e.event['slug']));

    if (params.filters.length) {
      return this.eventRS.getCollection(params).pipe(map((resultListAM: ResultListApiModel<EventApiModel>) => {
        return resultListAM.records;
      }));
    }

    return of<EventApiModel[]>([]);
  }

  public createComment(content: string, post: PostViewModel, thread?: CommentApiModel): Observable<CommentApiModel> {
    return this.commentRS
      .create(plainToClass(CommentApiModel, {content, type: 'POST_COMMENT', targetObject: post['@id'], thread: thread ? thread['@id'] : null}, {groups: ['post']}));
  }

  public updateComment(commentPartialAM: {'@id': string, content: string}): Observable<CommentApiModel> {
    return this.commentRS.put(commentPartialAM).pipe(map((commentAM: CommentApiModel) => {
      return commentAM;
    }));
  }

  public removeComment(commentAM: CommentApiModel): Observable<any> {
    return this.commentRS.delete(commentAM);
  }

  public getCommentCollection(post: PostViewModel): Observable<any> {
    const params = new QueryParamsApiModel();
    params.filters.push(new FilterParameterApiModel('post', post['@id'], 'single'));
    params.filters.push(new FilterParameterApiModel('discriminator', 'POST_COMMENT', 'array'));

    return this.commentRS.getCollection(params);
  }

  public postMediaObject(file): Observable<MediaObjectApiModel> {
    return this.mediaObjectRS.uploadImage(file);
  }

  public deleteMediaObject(mediaObjectAM: MediaObjectApiModel): Observable<any> {
    return this.mediaObjectRS.deleteMediaObject(mediaObjectAM['@id']);
  }

  public createReaction(reactedWith: string, subject: ReactionSubjectInterface, type: ReactionTypeEnum): Observable<ReactionApiModel> {
    return this.reactionRS
      .create(plainToClass(ReactionApiModel, {reactedWith, subject: subject['@id'], type}));
  }

  public removeReaction(reactionAM: ReactionApiModel): Observable<any> {
    return this.reactionRS.delete(reactionAM);
  }
}
