import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ResponseNotificationService} from "../../../core/services/response-notification.service";
import {RegistrationRestService} from "../../../api/services/registration.rest.service";
import {LoaderService} from "../../../core/services/loader.service";

@Component({
  selector: 'app-registration-confirmation',
  templateUrl: './registration-confirmation.component.html',
  styleUrls: ['./registration-confirmation.component.scss']
})
export class RegistrationConfirmationComponent implements OnInit {

  public currentStyles = {
    'background': 'url(http://localhost:8081/uploads/backgrounds/test1.jpg) no-repeat fixed center',
    'background-size': 'cover',
  };

  public message: string;
  public success: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private rs: RegistrationRestService,
    private rns: ResponseNotificationService,
    private ls: LoaderService
  ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.ls.show();
      this.rs.registrationConfirm(params.token).subscribe(
        successResponse => {
          this.success = true;
          this.message = successResponse.message
        },
        errorResponse => {
          this.message = 'Something went wrong during confirmation process. Please contact with the support';
          this.rns.emitError(errorResponse.error['hydra:description']);
        }
      ).add(() => {
        this.ls.hide();
      });
    });
  }
}
