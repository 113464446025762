<p-virtualScroller *ngIf="parentModalRendered" [value]="followings | async" [scrollHeight]="viewScrollerHeight + 'px'" [itemSize]="107"
                   [lazy]="true">
  <ng-template let-following pTemplate="item">
    <div class="grid grid-nogutter flex justify-content-start align-items-center width-100" style="gap: 10px">
      <div class="col-fixed">

      <app-thumbnail
        (click)="navigateTo(following, 'profile')"
        [imgSrc]="following.follower.avatar | mediaObject"
        [circle]="true"
        size="sm"
      >
      </app-thumbnail>
      </div>
      <div class="col-6" style="min-width: 200px">
        <div>
          <a (click)="navigateTo(following, 'profile')">{{following.follower.username}}</a>
          <br>
          <span *ngIf="following|projectUserCount: currentUser as projectUserCount">
            <ng-container *ngIf="projectUserCount.hasUser || projectUserCount.projectCount">
              {{ 'common.follows.user_projects'|translate: {
                user: projectUserCount.hasUser ? (projectUserCount.userTranslateParam|lowercase) : '',
                project: projectUserCount.projectTranslateParam|translateSelector: projectUserCount.projectCount,
                and: projectUserCount.hasUser && projectUserCount.projectCount ? ('common.and'|translate) : ''
              }
              }}</ng-container>
          </span>
        </div>
        <div
          class="width-100">
          <swiper
            [config]="config"
          >
            <ng-template swiperSlide *ngFor="let followed of following.followed|filterCollectionByType: 'Project'; let last = last; let i = index">
              <div>
                <app-thumbnail
                  [imgSrc]="followed.avatar | mediaObject"
                  [circle]="true"
                  size="xs"
                >
                </app-thumbnail>
              </div>
            </ng-template>
          </swiper>
        </div>
      </div>
      <div class="col-fixed flex ml-auto gap-2 relative w-3rem h-3rem">
        <app-item-action-dial
          [isFollowedByCurrentUser]="socialDPS.isFollowedByCurrentUser(following.follower)"
          [isReactedByCurrentUser]="socialDPS.isLikedByCurrentUser(following.follower)"
          [subjectUserVM]="following.follower"
          [currentUserVM]="currentUser"
        ></app-item-action-dial>
      </div>

    </div>
  </ng-template>
  <ng-template pTemplate="loadingItem">
          <div class="flex">
            <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
            <div style="flex: 1">
              <p-skeleton width="100%" height="1.5rem" borderRadius="16px" styleClass="mb-2 mt-1"></p-skeleton>
              <p-skeleton width="75%" height="1.5rem" borderRadius="16px"></p-skeleton>
            </div>
          </div>
  </ng-template>
</p-virtualScroller>
