import {Exclude, Expose} from "class-transformer";
import {AbstractApiModel} from "./abstract.api.model";

@Exclude()
export class PersonalInformationApiModel extends AbstractApiModel {
  @Expose() public firstName: string;
  @Expose() public lastName: string;
  @Expose() public phone: string;
  @Expose() public address: string;
  @Expose() public streetNr: string;
  @Expose() public houseNr: string;
  @Expose() public town: string;
  @Expose() public postcode: string;
  @Expose() public province: string;
  @Expose() public country: string;
}
