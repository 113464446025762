import {ReactedWithEnum} from '../enums/reacted-with.enum';

export class ReactedWithToEmojiMapper {
  public static map(type: string): {id: string, skin: number} {
    switch (type) {
      case ReactedWithEnum.FIRE: return { id: 'fire', skin: 3 };
      case ReactedWithEnum.HEART: return { id: 'heart', skin: 3 };
      case ReactedWithEnum.THUMBS_UP: return { id: 'thumbsup', skin: 3 };
      case ReactedWithEnum.FACE_SURPRISE: return { id: 'open_mouth', skin: 3 };
    }
  }
}
