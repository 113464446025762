import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {State} from '../interfaces/state';

@Injectable()
export class LoaderService {
    private loaderSubject = new Subject<State>();
    loaderState = this.loaderSubject.asObservable();
    constructor() { }

    show() {
        this.loaderSubject.next({show: true});
    }
    hide() {
        this.loaderSubject.next({show: false});
    }
}
