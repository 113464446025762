import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from "rxjs";
import { HttpClient } from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {plainToClassFromExist} from "class-transformer";
import {ResultListApiModel} from "../models/result-list.api.model";
import {QueryParamsService} from "../../core/services/query-params.service";
import {QueryParamsApiModel} from "../models/query-params-api.model";
import {NotificationInterface} from "../../shared/interfaces/notification.interface";
import {NotificationApiModel} from "../models/notification.api-model";


@Injectable()
export class NotificationRestService {
  private readonly resource: string = '/notifications';

  constructor(
    public http: HttpClient,
    public params: QueryParamsService
  ) {
  }

  public getCollection(queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<ResultListApiModel<NotificationInterface>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<NotificationInterface>>(environment.apiUrl + this.resource, {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<NotificationInterface>(NotificationApiModel), result as object)));
  }

  public massUpdateReadAt(data: {notifications: number[], readAt: boolean}): Observable<ResultListApiModel<NotificationInterface>> {
    return this.http.post<ResultListApiModel<NotificationInterface>>(environment.apiUrl + this.resource, data)
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<NotificationInterface>(NotificationApiModel), result as object)))
  }
}
