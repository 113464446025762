<div class="app-grid-fluid mb-2 md:mt-3 md:mb-3">
  <div class="grid grid-nogutter">
    <div class="col-12 justify-content-center">
      <mat-tab-group  matTabGroupGesture dynamicHeight mat-align-tabs="center" [(selectedIndex)]="selectedIndex" (selectedIndexChange)="selectChange()">
        <ng-container *ngFor="let tab of tabs">
          <mat-tab [label]="tab.label">
          </mat-tab>
        </ng-container>
      </mat-tab-group>
    </div>
  </div>

  <div class="grid grid-nogutter flex justify-content-center width-100 mb-2">
    <div class="col-12 p-0 my-2" style="max-width: 900px">
      <swiper
        #slaveSwiper
        [config]="childConfig"
        (activeIndexChange)="setActiveSlide($event)"
        [updateOnImagesReady]="true"
        [updateOnWindowResize]="true"
        [observeSlideChildren]="true"
        [lazy]="true"
        [preloadImages]="true"
        [autoHeight]="true"
        [watchOverflow]="true"
      >
        <ng-template swiperSlide *ngFor="let tab of tabs; let last = last; let i = index">
          <div class="min-h-screen">
            <app-search-infinite-list *ngIf="selectedTab.type === tab.type"
                                      [selectedTab]="selectedTab"
                                      [noResultsMsg]="'common.no_results'|translate"
            ></app-search-infinite-list>
          </div>

        </ng-template>
      </swiper>
    </div>
  </div>
</div>


