<form [formGroup]="loginForm" novalidate>
  <h1 class="text-center m-1">
    <img src="assets/layout/images/wtg-logo-transparent7-white.webp" alt="watch-this-garage-logo" class="app-logo">
    <small class="-mt-3" style="color: #4285F4">{{'auth.login.title' | translate}}</small>
  </h1>
  <div class="col-12 p-fluid">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-user"></i>
              <input type="text" pInputText id="username" formControlName="username">
              <label for="username">{{'auth.login.username_email' | translate }}</label>
            </span>
  </div>
  <div class="col-12 p-fluid">
              <span class="p-float-label p-input-icon-left">
                <i class="pi pi-key"></i>
                <input pInputText type="password" id="password" formControlName="password">
                <label for="password">{{'auth.login.password' | translate}}</label>
              </span>
    <p *ngIf="error" class="mat-error app-form-error">{{error}}</p>
  </div>
  <div class="col-12 p-fluid">
    <p-checkbox checkboxIcon="pi pi-thumbs-up" [binary]="true" [formControlName]="'remember'"
                [label]="'auth.login.remember' | translate">
    </p-checkbox>
  </div>
  <div class="col-12">
    <p style="position: relative"><a [routerLink]="['/reset-password']" class="center">{{'auth.login.forgot' | translate}}</a></p>
  </div>
  <div class="col-12 p-fluid">
    <button pButton pRipple
            icon="pi pi-sign-in"
            iconPos="left"
            type="submit"
            class="p-button-primary p-button-outlined p-button-rounded"
            (click)="login()"
            [disabled]="loginForm.invalid"
            [label]="'auth.login.button.sign_in' | translate"
    >
    </button>
  </div>
  <div class="col-12 p-fluid">
    <button pButton pRipple
            icon="pi pi-facebook"
            iconPos="left"
            type="button"
            class="p-button-primary p-button-outlined p-button-rounded"
            (click)="fbLogin()"
            [label]="'auth.login.button.sign_in_with_facebook' | translate"
    >
    </button>
  </div>
  <div class="col-12 p-fluid">
    <button pButton pRipple
            icon="pi pi-plus"
            iconPos="left"
            type="button"
            class="p-button-danger p-button-outlined p-button-rounded"
            [routerLink]="['/register']"
            [label]="'auth.login.button.sign_up' | translate">
    </button>
  </div>
</form>
