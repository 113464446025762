import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalInterface} from "../../modal.interface";
import {Observable, Subscription} from "rxjs";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ModalState} from "../../modal-state.enum";
import {ProjectRestService} from "../../../../api/services/project.rest.service";
import {StatusApiModel} from "../../../../api/models/status.api.model";
import {ModalStateEvent} from "../../modal-state-event";

@Component({
  selector: 'app-change-status',
  templateUrl: './change-status.component.html',
  styleUrls: ['./change-status.component.scss']
})
export class ChangeStatusComponent implements OnInit, ModalInterface {

  @Input() data: any;
  @Input() state: Observable<ModalStateEvent>;
  @Output() subject = new EventEmitter<ModalStateEvent>();
  statusForm: UntypedFormGroup;
  statuses: Array<StatusApiModel>;
  chosenStatus: number;
  private modalSubscription: Subscription;

  constructor(private fb: UntypedFormBuilder, private ps: ProjectRestService) { }

  ngOnInit(): void {
    this.createChangeStatusForm();
    this.getProjectStatusCollection();
  }


   createChangeStatusForm(): void {
    this.statusForm = this.fb.group({
      status: new UntypedFormControl({value: ''}, [Validators.required]),
    });
  }

   getProjectStatusCollection() {
    this.ps.getStatusCollection().subscribe(() => {
      this.statuses = this.ps.statuses
    });

  }
  onChooseStatus(status: number): void {
    this.chosenStatus = status;
    this.emitValidState();
    this.subscribeParentModalState();
  }
  private emitValidState(): void {
      this.subject.emit(new ModalStateEvent(ModalState.VALID));
  }

  private emitSuccededState(): void {
    this.subject.emit(new ModalStateEvent(ModalState.SUCCESS));
  }

  private emitPendingState(): void {
    this.subject.emit(new ModalStateEvent(ModalState.PENDING));
  }

  private subscribeParentModalState(): void {
    this.modalSubscription = this.state.subscribe(modalState => {
      if (modalState.status === ModalState.PENDING) {
        this.saveStatus()
      }
    });
  }
  private saveStatus(): void {
    this.emitPendingState();
    // const sub = this.ps.updateProjectStatus(item, this.chosenStatus).subscribe((project: Project) => {
    //   this.emitSuccededState();
    //   sub.unsubscribe();
    // })
  }
}
