<div class="layout-breadcrumb-container flex justify-content-between align-items-center shadow-4">
  <div class="layout-breadcrumb-buttons flex align-items-center pl-3">
    <a (click)="goBack()" title="Garage" pButton pRipple type="button" icon="pi pi-arrow-left"
       class="hoverable p-button-text p-button-plain rounded-circle"></a>
    <a class="p-button-text p-button-plain rounded-circle w-2rem">
      <p-scrollTop styleClass="app-scrolltop" icon="pi pi-arrow-up" [threshold]="200" target="window"></p-scrollTop>
    </a>
  </div>
  <p-breadcrumb *ngIf="!breadcrumbService.searchInputVisible" [model]="items" [home]="home" styleClass="layout-breadcrumb pl-4 py-2"></p-breadcrumb>

  <div *ngIf="breadcrumbService.searchInputVisible" class="topbar-search-input widget-chat layout-search-item mx-2">
    <div class="p-inputgroup visible" style="flex-wrap: wrap" [ngClass]="{'focused': isFocused}">
      <a type="button" pButton pRipple class="p-inputgroup-addon hoverable p-button-xs p-button-text p-button-plain rounded-circle" icon="pi pi-search"
      (click)="appendQuery()"
      ></a>
      <input
        #searchInput
        type="text"
        class="px-0"
        pInputText
        [(ngModel)]="breadcrumbService.query"
        [placeholder]="'shared.top_bar.placeholder.search' | translate"
        (keydown.enter)="appendQuery()"
        (focus)="onFocus()"
        (blur)="onBlur()"
      >
      <a *ngIf="breadcrumbService.filtersVisible" (click)="breadcrumbService.emitFiltersClicked()" pButton pRipple type="button" icon="pi pi-sliders-h" class="p-button-xs p-button-rounded p-button-text p-button-plain"></a>
      <a *ngIf="breadcrumbService.searchInputVisible" (click)="resetQuery()" pButton pRipple type="button" icon="pi pi-times" class="p-button-xs p-button-rounded p-button-text p-button-plain"></a>
    </div>
  </div>

  <div class="layout-breadcrumb-buttons flex align-items-center pr-3">
    <div *ngIf="actionsService.viewChangeEnabled">
      <button *ngIf="actionsService.viewType === ViewTypeEnum.LIST" icon="pi pi-th-large" pButton class="hoverable p-button-text p-button-plain rounded-circle"
              (click)="actionsService.onViewChange(ViewTypeEnum.GRID)"
      >
      </button>
      <button *ngIf="actionsService.viewType === ViewTypeEnum.GRID" pButton icon="pi pi-list" class="hoverable p-button-text p-button-plain rounded-circle"
              (click)="actionsService.onViewChange(ViewTypeEnum.LIST)"
      >
      </button>
    </div>
    <!--        <button pButton pRipple type="button" icon="pi pi-cloud-upload" class="p-button-rounded p-button-text p-button-plain mr-1"></button>-->
    <!--        <button pButton pRipple type="button" icon="pi pi-bookmark" class="p-button-rounded p-button-text p-button-plain mr-1"></button>-->
    <a [routerLink]="['/', currentUser.slug, 'profile']" title="Garage" pButton pRipple type="button" icon="pi pi-user"
       class="hoverable p-button-text p-button-plain mr-1 rounded-circle"></a>
    <a [routerLink]="['/', currentUser.slug, 'garage']" title="Garage" pButton pRipple type="button"
       icon="fas fa-warehouse" class="hoverable p-button-text p-button-plain mr-1 rounded-circle"></a>
    <!--        <a role="button" [routerLink]="['/login']" pButton pRipple type="button" icon="pi pi-power-off" class="p-button-rounded p-button-text p-button-plain mr-1"-->
    <!--        (click)="logout()">-->
    <!--        </a>-->
  </div>
</div>
