import {AbstractControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
export class HashtagValidator {

  constructor() {}

  public static maxHashtags(max: number, translator: TranslateService): any {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const tags = control.value || [];
      return !tags.length || tags.length <= max ? null : { maxHashtags: {message: translator.instant('app.validator.hashtags.max_amount', {max})} };
    };
  }

  public static uniqueHashtags(translator: TranslateService): any {
  return (control: AbstractControl): { [key: string]: any } | null => {
      const tags = control.value || [];
      const uniqueTags = Array.from(new Set(tags));
      return tags.length === uniqueTags.length ? null : { uniqueHashtags: {message: translator.instant('app.validator.hashtags.non_unique') }};
    };
  }
}
