<div
  pRipple
  class="thumbnail thumbnail-{{size}} {{classList}}"
  style="{{stylesList}}"
  [ngClass]="{
      'rounded-circle ': circle,
      'img-thumbnail': bordered
    }"
>
  <img *ngIf="imgSrc" alt="thumbnail" [src]="imgSrc" loading="lazy" [ngClass]="{
        'rounded-circle ': circle,
  }">
  <p-skeleton *ngIf="!imgSrc" width="100%" height="100%" shape="circle"></p-skeleton>
</div>
