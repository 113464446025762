import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import {HtmlEditorComponent} from "./html-editor.component";
// import {EditorModule} from "primeng/editor";
import {FormsModule} from "@angular/forms";



@NgModule({
  declarations: [
    // HtmlEditorComponent
  ],
  exports: [
    // HtmlEditorComponent
  ],
  imports: [
    CommonModule,
    // EditorModule,
    FormsModule
  ]
})
export class HtmlEditorModule { }
