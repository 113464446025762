import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationRestService} from '../../../core/services';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ResponseNotificationService} from "../../../core/services/response-notification.service";
import {ResponseErrorsHandlerService} from "../../../core/services/response-errors-handler.service";
import {LoaderService} from "../../../core/services/loader.service";
import {MessageApiModel} from "../../../api/models/message.api.model";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public resetForm: UntypedFormGroup;
  public error: string;
  public currentStyles = {
    'background': 'url(http://localhost:8081/uploads/backgrounds/test1.jpg) no-repeat fixed center',
    'background-size': 'cover',
  };

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private authenticationService: AuthenticationRestService,
    private reh: ResponseErrorsHandlerService,
    private ls: LoaderService,
    private rns: ResponseNotificationService
  ) {
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    this.resetForm = this.fb.group(
      {
        usernameOrEmail: ['', [Validators.required, Validators.email]],
      }
    );
  }

  reset() {
    this.ls.show();
    this.authenticationService.resetPassword(this.resetForm.get('usernameOrEmail').value)
      .subscribe((successResponse: MessageApiModel) => {
        this.rns.emitMessage(successResponse.message)
      }, errorResponse => {
        this.reh.handleFormErrors(this.resetForm, errorResponse)
      })
      .add(() => this.ls.hide());
  }
}
