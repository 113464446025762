import {Injectable} from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {finalize, tap} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {ResponseErrorsHandlerService} from "../services/response-errors-handler.service";

@Injectable()
export class ResponseHttpInterceptor implements HttpInterceptor {

  constructor(
    private reh: ResponseErrorsHandlerService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (environment.production === false) {
      const started = Date.now();
      let ok: string;
      // extend server response observable with logging
      return next.handle(req)
          .pipe(
              tap(
                  event => ok = event instanceof HttpResponse ? 'succeeded' : '',
                  error => {
                    this.reh.handleServerErrors(error);
                    ok = 'failed';
                  }
              ),
              // Log when response observable either completes or errors
              finalize(() => {
                const elapsed = Date.now() - started;
                const msg = `${req.method} "${req.urlWithParams}" ${ok} in ${elapsed} ms.`;
                console.log(msg);
              })
          );
    }

    return next.handle(req).pipe(tap(() => {
    }, errorResponse => {
      this.reh.handleServerErrors(errorResponse);
    }));
  }
}
