import {Injectable} from '@angular/core';
import {map, mergeMap} from "rxjs/operators";
import {forkJoin, Observable, of} from "rxjs";
import {PostRestService} from "../../../../api/services/post-rest.service";
import {ResultListApiModel} from "../../../../api/models/result-list.api.model";
import {PostApiModel} from "../../../../api/models/post-api.model";
import {MediaObjectRestService} from "../../../../api/services/media-object.rest.service";
import {MediaObjectViewModel} from "../../../../core/models/media-object.view-model";
import {QueryParamsApiModel} from "../../../../api/models/query-params-api.model";
import {MediaObjectApiModel} from "../../../../api/models/media-object.api-model";
import {FilterParameterApiModel} from "../../../../api/models/filter-parameter.api.model";
import {HistoryPostViewModel} from "./history-post.view-model";
import {PostTypeEnum} from "../../../../core/enums/post-type.enum";
import { HttpResponse } from "@angular/common/http";
import {ProjectViewModel} from "../../garage/project.view-model";
import {DescriptionPostViewModel} from "./details/description-post.view-model";
import {GoalRestService} from "../../../../api/services/goal.rest.service";
import {GoalViewModel} from "../goals/goal.view-model";
import {GoalApiModel} from "../../../../api/models/goal.api.model";


@Injectable({
  providedIn: 'root'
})
export class AboutDataProviderService {

  constructor(
    private postRS: PostRestService,
    private mediaObjectRS: MediaObjectRestService,
    private goalRS: GoalRestService
  ) {
  }

  createDescriptionPost(postVM: DescriptionPostViewModel): Observable<DescriptionPostViewModel> {
    return this.postRS.create(AboutDataProviderService.toDescriptionApiModel(postVM)).pipe(map((postAM: PostApiModel) => {
      return AboutDataProviderService.fromDescriptionApiModel(postAM);
    }));
  }

  updateDescriptionPost(postVM: DescriptionPostViewModel): Observable<DescriptionPostViewModel> {
    return this.postRS.put(AboutDataProviderService.toDescriptionApiModel(postVM)).pipe(map((postAM: PostApiModel) => {
      return AboutDataProviderService.fromDescriptionApiModel(postAM);
    }));
  }

  createHistoryPost(postVM: HistoryPostViewModel): Observable<HistoryPostViewModel> {
    return this.postRS.create(AboutDataProviderService.toHistoryApiModel(postVM)).pipe(map((postAM: PostApiModel) => {
      return AboutDataProviderService.fromHistoryApiModel(postAM);
    }));
  }

  updateHistoryPost(postVM: HistoryPostViewModel): Observable<HistoryPostViewModel> {
    return this.postRS.put(AboutDataProviderService.toHistoryApiModel(postVM)).pipe(map((postAM: PostApiModel) => {
      return AboutDataProviderService.fromHistoryApiModel(postAM);
    }));
  }

  getDescriptionPostCollection(projectVM: ProjectViewModel): Observable<DescriptionPostViewModel[]> {
    const queryParams = new QueryParamsApiModel();
    queryParams.filters.push(new FilterParameterApiModel('discriminator', PostTypeEnum.DESCRIPTION_POST, 'array'));
    queryParams.filters.push(new FilterParameterApiModel('project', projectVM.id, 'single'));
    return this.postRS.getCollection(queryParams).pipe(mergeMap((resultListAM: ResultListApiModel<PostApiModel>) => {
      const deps = forkJoin([
        this.getMediaObjectCollection(resultListAM.records)
      ]);

      return deps.pipe(map((
        [mediaObjectMVs]: [MediaObjectViewModel[]]) => {
        return resultListAM.records.map((postAM: PostApiModel) => {
          return AboutDataProviderService.fromDescriptionApiModel(postAM);
        });
      }));
    }));
  }

  getHistoryPostCollection(projectVM: ProjectViewModel) {
    const queryParams = new QueryParamsApiModel();
    queryParams.filters.push(new FilterParameterApiModel('discriminator', PostTypeEnum.HISTORY_POST, 'array'));
    queryParams.filters.push(new FilterParameterApiModel('project', projectVM.id, 'single'));
    queryParams.filters.push(new FilterParameterApiModel('order[position]', 'ASC', 'single'));
    return this.postRS.getCollection(queryParams).pipe(mergeMap((resultListAM: ResultListApiModel<PostApiModel>) => {
      const deps = forkJoin([
        this.getMediaObjectCollection(resultListAM.records)
      ]);

      return deps.pipe(map((
        [mediaObjectMVs]: [MediaObjectViewModel[]]) => {
        return resultListAM.records.map((postAM: PostApiModel) => {
          return AboutDataProviderService.fromHistoryApiModel(postAM);
        });
      }));
    }));
  }

  deleteHistoryPost(postVM: HistoryPostViewModel): Observable<HttpResponse<any>> {
    return this.postRS.delete(AboutDataProviderService.toHistoryApiModel(postVM))
  }

  deleteGoal(goalVM: GoalViewModel): Observable<HttpResponse<any>> {
    return this.goalRS.delete(GoalApiModel.fromGoalVM(goalVM))
  }

  public getMediaObjectCollection(postAMs: PostApiModel[]): Observable<MediaObjectViewModel[]> {
    // const params = new QueryParamsApiModel()
    // params.filters = postAMs
    //   .filter((e: PostApiModel) => typeof e.postedBy.avatar === 'string')
    //   .map(e => new FilterParameterApiModel('id', e.postedBy.avatar))
    // if (params.filters.length) {
    //   return this.mediaObjectRS.getMediaObjectCollection(params)
    //     .pipe(map((resultListAM: ResultListApiModel<MediaObjectApiModel>) => {
    //       return resultListAM.records.map((mediaObjectAM: MediaObjectApiModel) => new MediaObjectViewModel(mediaObjectAM))
    //     }))
    // }

    return of<MediaObjectViewModel[]>([]);
  }

  getGoalsCollection(projectVM: ProjectViewModel): Observable<GoalViewModel[]> {
    const queryParams = new QueryParamsApiModel();
    queryParams.filters.push(new FilterParameterApiModel('project', projectVM.id, 'single'));
    return this.goalRS.getCollection().pipe(map((resultListAM: ResultListApiModel<GoalApiModel>) => {
      return resultListAM.records.map((goalAM: GoalApiModel) => GoalApiModel.toGoalVM(goalAM));
    }));
  }

  public static fromHistoryApiModel(apiModel: PostApiModel): HistoryPostViewModel {
    return {
      apiModel: apiModel,
      id: apiModel.id,
      type: apiModel.type,
      heading: apiModel.heading,
      content: apiModel.content,
      project: apiModel.project as string,
      createdAt: apiModel.createdAt,
      updatedAt: apiModel.updatedAt,
      startDate: apiModel.startDate,
      endDate: apiModel.endDate,
    };
  }

  public static toHistoryApiModel(viewModel: HistoryPostViewModel): PostApiModel {
    const apiModel = viewModel.apiModel;
    apiModel.postedBy = null;
    apiModel.content = viewModel.content;
    apiModel.type = viewModel.type;
    apiModel.startDate = viewModel.startDate;
    apiModel.endDate = viewModel.endDate;
    apiModel.type = viewModel.type;
    apiModel.heading = viewModel.heading;
    apiModel.project = viewModel.project;
    return apiModel;
  }

  public static fromDescriptionApiModel(apiModel: PostApiModel): DescriptionPostViewModel {
    return {
      apiModel: apiModel,
      id: apiModel.id,
      type: apiModel.type,
      content: apiModel.content,
      project: apiModel.project as string,
      createdAt: apiModel.createdAt,
      updatedAt: apiModel.updatedAt,
    };
  }

  public static toDescriptionApiModel(viewModel: DescriptionPostViewModel): PostApiModel {
    const apiModel = viewModel.apiModel;
    apiModel.postedBy = null;
    apiModel.content = viewModel.content;
    apiModel.type = viewModel.type;
    apiModel.project = viewModel.project;
    return apiModel;
  }
}
