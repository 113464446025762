import {Exclude, Expose} from "class-transformer";
import {AbstractApiModel} from "./abstract.api.model";

@Exclude()
export class AbstractDictionaryApiModel extends AbstractApiModel {

  @Expose() public id: number;
  @Expose() public name: string;
  @Expose() public description: string;

}
