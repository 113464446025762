import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {FollowApiModel} from "../../api/models/follow.api-model";
import * as FollowActions from "./following.actions";
import {FollowActionTypes} from "./following.actions";

const followAdapter = createEntityAdapter<FollowApiModel>({
  selectId: selectFollowId,
  sortComparer: sortById,
});

export function selectFollowId(a: FollowApiModel): string {
  return a['@id'];
}

export function sortById(a: FollowApiModel, b: FollowApiModel): number {
  return a.id - b.id;
}

export interface FollowState extends EntityState<FollowApiModel> { }

export const initialState: FollowState = followAdapter.getInitialState();

export function followReducer(state: FollowState = initialState, action: FollowActions.Actions): FollowState {
  switch (action.type) {
    case FollowActionTypes.GET_ALL:
      return followAdapter.setAll(action.follows, state);
    case FollowActionTypes.GET_ONE:
      return followAdapter.setOne(action.follow, state);
    case FollowActionTypes.ADD_ONE:
      return followAdapter.addOne(action.follow, state);
    case FollowActionTypes.ADD_MANY:
      return followAdapter.addMany(action.follows, state);
    case FollowActionTypes.UPDATE_ONE:
      return followAdapter.updateOne({
        id: action.id,
        changes: action.changes
      }, state);
    case FollowActionTypes.DELETE_ONE:
      return followAdapter.removeOne(action.id, state);
    default:
      return state;
  }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = followAdapter.getSelectors();
