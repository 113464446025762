import {FilterParameterApiModel} from "./filter-parameter.api.model";

export class QueryParamsApiModel {
  public filters: FilterParameterApiModel[] = [];
  public limit: number;
  public page: number;
  public orderDirection: any;
  public pagination: boolean;

  public constructor(filters: FilterParameterApiModel[] = [], page: number = null, orderDirection: any = null, limit = null) {
    this.filters = filters;
    this.limit = limit;
    this.page = page;
    this.orderDirection = orderDirection;
  }
}
