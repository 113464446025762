import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {plainToClass, plainToClassFromExist} from "class-transformer";
import {ResultListApiModel} from "../models/result-list.api.model";
import { HttpClient, HttpResponse } from "@angular/common/http";
import {QueryParamsApiModel} from "../models/query-params-api.model";
import {QueryParamsService} from "../../core/services/query-params.service";
import {CommentApiModel} from "../models/comment.api.model";

@Injectable({
  providedIn: 'root'
})
export class CommentRestService {
  private readonly resource: string = '/comments';

  constructor(
    private http: HttpClient,
    public params: QueryParamsService
  ) {
  }

  public create(commentAM: CommentApiModel): Observable<CommentApiModel> {
    return this.http.post<CommentApiModel>(environment.apiUrl + this.resource, commentAM)
      .pipe(map((result: CommentApiModel) => plainToClass(CommentApiModel, result)));
  }

  public put(commentAM: object): Observable<CommentApiModel> {
    return this.http.put<CommentApiModel>(environment.apiUrl + commentAM['@id'], commentAM)
      .pipe(map((result: CommentApiModel) => plainToClass(CommentApiModel, result)));
  }

  public get(commentAM: CommentApiModel): Observable<CommentApiModel> {
    return this.http.get<CommentApiModel>(environment.apiUrl+ commentAM['@id'])
      .pipe(map((result: CommentApiModel) => plainToClass(CommentApiModel, result)));
  }

  public delete(commentAM: CommentApiModel): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(environment.apiUrl + commentAM['@id']);
  }

  public getCollection(queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<ResultListApiModel<CommentApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<CommentApiModel>>(environment.apiUrl + this.resource, {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<CommentApiModel>(CommentApiModel), result as object)));
  }

  public getUserSubResourceCollection(userId: string): Observable<ResultListApiModel<CommentApiModel>> {
    return this.http.get<ResultListApiModel<CommentApiModel>>(environment.apiUrl+ userId + this.resource)
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<CommentApiModel>(CommentApiModel), result as object)));
  }
}
