<div class="grid grid-nogutter m-0 p-0 relative" style="height: 100vh; width: 100vw">
  <div style="background-color: black"
       class="col-12 md:col-fixed swiper-fixed-col height-100 m-0 p-0 flex justify-content-center align-items-center relative">
    <swiper
      #swiperComponent
      [config]="config"
      (swiper)="onSwiper($event)"
      (slideChange)="onSlideChange($event)"
      (navigationNext)="onNavigationNext($event)"
      (activeIndexChange)="onActiveIndexChange($event)"
      [initialSlide]="activeIndex"
    >
      <ng-template swiperSlide *ngFor="let item of data.post.media; let last = last; let i = index">
        <app-media-view [mediaObject]="item" [prefix]="'video-preview'" [id]="item.id" (click)="toggleVideoPlay()"
                        [(currentTime)]="currentTime">

        </app-media-view>
        <!--                  <img style="height: 100%; width: 100%; object-fit: cover" [src]="item | mediaObject">-->
      </ng-template>
    </swiper>
  </div>

  <div class="col-12 z-1 bottom-0 absolute social-fixed-col top-0 right-0 md:col-fixed m-0 p-0">
    <div class="grid grid-nogutter width-100">
      <div class="col p-2 flex column-gap-2">
        <a [routerLink]="['/' + post.postedBy.slug, 'garage']" (click)="close()">
          <app-thumbnail
            classList="thumbnail-inline-block"
            size="xs"
            [circle]="true"
            [imgSrc]="post.postedBy.avatar | mediaObject"
          ></app-thumbnail>
        </a>
        <div>
          <a (click)="close()" [routerLink]="['/' + post.postedBy.slug, 'garage']">{{post.postedBy.username}}</a>
          <span class="date block">{{post.createdAt | dateAgo}}</span>
        </div>
        <div>
          <ng-container [ngSwitch]="post.type">
            <ng-container *ngSwitchCase="PostTypeEnum.PROJECT_POST">
              <a (click)="close()" [routerLink]="['/' + post.postedBy.slug, 'garage', post.project.slug]">
                <i class="pi pi-caret-right pr-1"></i>
                <i class="pi pi-car pr-1"></i>
                <ng-container *ngIf="post.project.alias">{{post.project.alias}} | </ng-container>
                {{post.project.vehicle.fullVehicleIdentity|truncateOnWord: 50: '...'}}</a>
            </ng-container>
            <ng-container *ngSwitchCase="PostTypeEnum.EVENT_DISCUSSION_POST">
              <a (click)="close()" [routerLink]="['/events', post.event.slug, EventShowTabsEnum.DISCUSSION]">
                <i class="pi pi-caret-right pr-1"></i>
                <i class="pi pi-calendar-plus pr-1"></i>
                {{post.event.name}}</a>
            </ng-container>
            <ng-container *ngSwitchCase="PostTypeEnum.SIMPLE_POST">
              <a (click)="close()" [routerLink]="['/' + post.postedBy.slug, 'profile']">
                <i class="pi pi-caret-right pr-1"></i>
                <i class="pi pi-user pr-1"></i>
                {{post.postedBy.username}}</a>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="col-fixed flex justify-content-end">
        <button
          (click)="close()"
          pButton
          icon="pi pi-times"
          class="p-button-rounded p-button-plain p-button-text"
        ></button>
        <!--        <app-end-cap-->
        <!--          *ngxPermissionsOnly="[ post.postedBy | ownerRole]"-->
        <!--          icon="pi pi-ellipsis-h"-->
        <!--          [pointerClass]="'p-button-rounded p-button-text p-button-icon-only p-button-primary'"-->
        <!--          (onClickTrigger)="emitPostContextMenuOpen(post)"-->
        <!--        >-->
        <!--        </app-end-cap>-->
      </div>
    </div>
  </div>
</div>
