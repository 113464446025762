import {Exclude, Expose} from "class-transformer";
import {AbstractDictionaryApiModel} from "./abstract-dictionary.api-model";
import {EngineInterface} from "../../features/user/project/parameters/engine-interface";

@Exclude()
export class EngineApiModel extends AbstractDictionaryApiModel implements EngineInterface {
  @Expose() boostType: string;
  @Expose() capacityCm3: number;
  @Expose() cylinderBore: number;
  @Expose() cylinderLayout: string;
  @Expose() cylinders: number;
  @Expose() enginePowerBHP: number;
  @Expose() engineType: string;
  @Expose() injectionType: string;
  @Expose() intercooler: boolean;
  @Expose() maxPowerAtRPM: string;
  @Expose() maxTorqueNm: number;
  @Expose() strokeCycle: number;
  @Expose() turnoverOfMaxTorqueRPM: number;
  @Expose() valvesPerCylinder: number;
}




