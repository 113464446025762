import {Expose, Transform, Type} from "class-transformer";
import {UserApiModel} from "./user.api-model";
import {AbstractApiModel} from "./abstract.api.model";

export class TaskApiModel extends AbstractApiModel {
  @Expose() public id: string;
  @Expose() public name: string;
  @Expose() public finishedAt: string;
  @Transform(({value}) => value ? value.toString() : value, { toClassOnly: true })
  @Expose() public summaryCost: string;
  @Expose() public state: string;
  @Expose() public goal: string;
}
