<p-card *ngIf="post.isPlaceHolder" [style]="{width: '100%'}" class="timeline-tile" styleClass="mb-2">
  <ng-template pTemplate="header">
    <div class="grid grid-nogutter">
      <div class="pt-2 col flex column-gap-2">
        <p-skeleton shape="circle" size="36px"></p-skeleton>
        <div>
          <p-skeleton width="15rem" styleClass="mb-2"></p-skeleton>
          <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="grid grid-nogutter">
    <div class="col-12 ">
      <p-skeleton width="100%" styleClass="mb-1"></p-skeleton>
      <p-skeleton width="95%" styleClass="mb-1"></p-skeleton>
      <p-skeleton width="75%" styleClass="mb-2"></p-skeleton>
      <p-skeleton width="4rem" styleClass="mb-3"></p-skeleton>
      <div class="grid post-media"
           [ngClass]="{'one-item': skeletonRandomNumber === 1, 'three-items': skeletonRandomNumber === 3}">
        <ng-container [ngSwitch]="skeletonRandomNumber">
          <ng-container *ngSwitchCase="1">
            <div class="col-12 p-0">
              <p-skeleton width="100%" height="200px"></p-skeleton>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="2">
            <div class="col-6 p-0">
              <p-skeleton width="100%" height="150px"></p-skeleton>
            </div>
            <div class="col-6 p-0">
              <p-skeleton width="100%" height="150px"></p-skeleton>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="3">
            <div class="col-12 p-0">
              <p-skeleton width="100%" height="200px"></p-skeleton>
            </div>
            <div *ngFor="let item of [1,2]" class="col-6 p-0">
              <p-skeleton width="100%" height="150px"></p-skeleton>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="4">
            <div *ngFor="let item of [1,2,3,4]; let i = index" class="col-6 p-0">
              <p-skeleton width="100%" height="150px"></p-skeleton>
            </div>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <div class="col-6 p-0">
              <p-skeleton width="100%" height="150px"></p-skeleton>
            </div>
            <div class="col-6 p-0">
              <p-skeleton width="100%" height="150px"></p-skeleton>
            </div>
            <div *ngFor="let item of [1,2,3]" class="col-4 p-0">
              <p-skeleton width="100%" height="150px"></p-skeleton>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="grid">
      <div class="col-12 inline-flex justify-content-end" style="gap: 20px">
        <span class="link">
          <p-skeleton width="4rem" styleClass="mb-2"></p-skeleton>
        </span>
        <span class="link">
          <p-skeleton width="4rem" styleClass="mb-2"></p-skeleton>
        </span>
        <span class="link">
          <p-skeleton width="4rem" styleClass="mb-2"></p-skeleton>
        </span>
      </div>
    </div>
    <p-divider styleClass="p-0 m-0 my-2" type="solid"></p-divider>
    <div class="grid mx-0">
      <div class="col-12 flex justify-content-center p-0 m-0 mb-1 mt-1 pt-1">
        <div class="p-buttonset" style="width: 100%">
          <button pButton type="button" [label]="'common.like_it' | translate"
                  class="p-button-text p-button-rounded p-button-plain text-center justify-content-center flex-auto"
                  style="width: 33.33%; font-size: 0.8em"
                  icon="pi pi-thumbs-up" [disabled]="true"></button>
          <button pButton type="button" [label]="'common.comment' | translate | ucfirst"
                  class="p-button-text p-button-rounded p-button-plain text-center justify-content-center"
                  style="width: 33.33%; font-size: 0.8em"
                  icon="pi pi-comments" [disabled]="true"></button>
          <button pButton type="button" [label]="'common.share' | translate"
                  class="p-button-text p-button-rounded p-button-plain text-center justify-content-center"
                  style="width: 33.33%; font-size: 0.8em"
                  icon="fas fa-share" [disabled]="true"></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-card>


<p-card *ngIf="!post.isPlaceHolder" [style]="{width: '100%'}" class="timeline-tile" styleClass="mb-2">
  <ng-template pTemplate="header">
    <div class="grid grid-nogutter">
      <div class="pt-2 col flex column-gap-2">
        <a [routerLink]="['/' + post.postedBy.slug, 'garage']">
          <app-thumbnail
            classList="thumbnail-inline-block"
            size="xs"
            [circle]="true"
            [imgSrc]="post.postedBy.avatar | mediaObject"
          ></app-thumbnail>
        </a>
        <div>
          <a [routerLink]="['/' + post.postedBy.slug, 'garage']">{{ post.postedBy.username }}</a>
          <span class="date block">{{ post.createdAt | dateAgo }}</span>
        </div>
        <div>
          <ng-container [ngSwitch]="post.type">
            <ng-container *ngSwitchCase="PostTypeEnum.PROJECT_POST">
              <a [routerLink]="['/' + post.postedBy.slug, 'garage', post.project.slug]">
                <i class="pi pi-caret-right pr-1"></i>
                <i class="pi pi-car pr-1"></i>
                <ng-container *ngIf="post.project.alias">{{ post.project.alias }} |</ng-container>
                {{ post.project.vehicle.fullVehicleIdentity|truncateOnWord: 50: '...' }}</a>
            </ng-container>
            <ng-container *ngSwitchCase="PostTypeEnum.EVENT_DISCUSSION_POST">
              <a [routerLink]="['/events', post.event.slug, EventShowTabsEnum.DISCUSSION]">
                <i class="pi pi-caret-right pr-1"></i>
                <i class="pi pi-calendar-plus pr-1"></i>
                {{ post.event.name }}</a>
            </ng-container>
            <ng-container *ngSwitchCase="PostTypeEnum.SIMPLE_POST">
              <a [routerLink]="['/' + post.postedBy.slug, 'profile']">
                <i class="pi pi-caret-right pr-1"></i>
                <i class="pi pi-user pr-1"></i>
                {{ post.postedBy.username }}</a>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="col-fixed flex justify-content-end">
        <app-end-cap
          *ngxPermissionsOnly="[ post.postedBy | ownerRole]"
          icon="pi pi-ellipsis-h"
          [pointerClass]="'p-button-rounded p-button-text p-button-icon-only p-button-primary'"
          (onClickTrigger)="emitPostContextMenuOpen(post)"
        >
        </app-end-cap>
      </div>
    </div>
  </ng-template>
  <div class="grid grid-nogutter">
    <div class="col-12 ">
      <p *ngIf="!isTextExpanded">
        {{ post.content | truncateOnWord: 500: post.content.length > 500 ? '...' : '' }}
        <a *ngIf="post.content.length > 500" class="pl-2" style="cursor: pointer"
           (click)="isTextExpanded = true">{{ 'common.more' | translate }}</a>
      </p>
      <p *ngIf="isTextExpanded">
        {{ post.content }}
        <a class="pl-2" style="cursor: pointer" (click)="isTextExpanded = false">{{ 'common.less' | translate }}</a>
      </p>
      <div *ngIf="post.media.length" class="grid post-media"
           [ngClass]="{'one-item': post.media.length ===1, 'three-items': post.media.length === 3}">
        <ng-container [ngSwitch]="post.media.length">
          <ng-container *ngSwitchCase="1">
            <div class="col-12 p-0" [ngStyle]="imageContainerStyles(post.media[0], post.media.length)"
                 (click)="openGalleria($event,0, object)">
              <app-media-view #object [mediaObject]="post.media[0]"></app-media-view>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="2">
            <div class="col-6 p-0" [ngStyle]="imageContainerStyles(post.media[0], post.media.length)"
                 (click)="openGalleria($event, 0, object)">
              <app-media-view [mediaObject]="post.media[0]"></app-media-view>
            </div>
            <div class="col-6 p-0" [ngStyle]="imageContainerStyles(post.media[1], post.media.length)"
                 (click)="openGalleria($event, 1, object)">
              <app-media-view #object [mediaObject]="post.media[1]"></app-media-view>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="3">
            <div class="col-12 p-0" [ngStyle]="imageContainerStyles(post.media[0], post.media.length)"
                 (click)="openGalleria($event, 0, object)">
              <app-media-view #object [mediaObject]="post.media[0]"></app-media-view>
            </div>
            <div *ngFor="let item of post.media.slice(1, post.media.length); let i = index" class="col-6 p-0"
                 [ngStyle]="imageContainerStyles(item, post.media.length)"
                 (click)="openGalleria($event, i + 1, object)">
              <app-media-view #object [mediaObject]="item"></app-media-view>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="4">
            <div *ngFor="let item of post.media; let i = index" class="col-6 p-0"
                 [ngStyle]="imageContainerStyles(item, post.media.length)" (click)="openGalleria($event, i, object)">
              <app-media-view #object [mediaObject]="item"></app-media-view>
            </div>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <div class="col-6 p-0" [ngStyle]="imageContainerStyles(post.media[0], post.media.length)"
                 (click)="openGalleria($event, 0, object)">
              <app-media-view #object [mediaObject]="post.media[0]"></app-media-view>
            </div>
            <div class="col-6 p-0" [ngStyle]="imageContainerStyles(post.media[1], post.media.length)"
                 (click)="openGalleria($event, 1, object)">
              <app-media-view #object [mediaObject]="post.media[1]"></app-media-view>
            </div>
            <div *ngFor="let item of post.media.slice(2, 5); let last = last; let i = index" class="col-4 p-0"
                 [ngStyle]="imageContainerStyles(item, post.media.length)"
                 (click)="openGalleria($event, i + 2, object)">
              <div class="flex justify-content-center align-items-center"
                   style="background-color: rgba(0, 0, 0, 0.5); height: 100%; width: 100%; position: absolute"
                   *ngIf="last && post.media.slice(6, post.media.length).length"><span
                style="font-size: 3rem;">+{{ post.media.slice(6, post.media.length).length }}</span></div>
              <app-media-view #object [mediaObject]="item"></app-media-view>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="grid flex justify-content-end">
      <div class="col-fixed inline-flex justify-content-end align-items-center" style="gap: 20px">
          <span *ngIf="post.reactions.length" class="link inline-flex align-items-center" style="gap: 5px">
            <a (click)="openSocialModal($event, post)">
              <app-social-reactions-bar [reactions]="post.reactions"></app-social-reactions-bar>
              {{ ('common.likes' | translate) + ': ' + post.reactions.length }}
            </a>
          </span>
        <span *ngIf="post.comments.length" class="link">
                    <a (click)="onCommentBoxOpen(post)"
                    >{{ ('common.comments' | translate) + ': ' + post.comments.length }}</a>
          </span>
        <span *ngIf="post.comments.length" class="link">
                    <a>{{ ('common.shares' | translate) + ': ' + 0 }}</a>
          </span>
      </div>
    </div>
    <p-divider styleClass="p-0 m-0 my-2" type="solid"></p-divider>
    <div class="grid mx-0">
      <div class="col-12 flex justify-content-center p-0 m-0 mb-1 mt-1 pt-1">
        <div class="p-button-group" style="width: 100%; position: relative">
          <app-speed-dial #tileSpeedDial [model]="items" direction="right"></app-speed-dial>
          <button *ngIf="post.currentUserReaction && post.currentUserReaction.reactedWith !== ReactedWithEnum.THUMBS_UP"
                  pButton type="button"
                  class="p-button-text p-button-rounded text-center justify-content-center align-items-center flex-auto"
                  [ngClass]="{'p-button-plain': !post.currentUserReaction, 'p-button-primary': post.currentUserReaction}"
                  style="width: 33.33%; font-size: 0.8em; max-height: 32px"
                  ngxLongPress2
                  (onLongPress)="toggleReactionBtn($event, post, ReactionTypeEnum.POST_REACTION, tileSpeedDial)"
                  (click)="toggleThumbsUp($event, post, ReactionTypeEnum.POST_REACTION, tileSpeedDial)"
                  (contextmenu)="false"
          >
            <ngx-emoji [emoji]="post.currentUserReaction.reactedWith|reactionClassMap" size="16"></ngx-emoji><span class="ml-2">{{('common.reaction.' + post.currentUserReaction.reactedWith.toLowerCase())|translate}}</span>
          </button>
          <button *ngIf="!post.currentUserReaction || post.currentUserReaction.reactedWith === ReactedWithEnum.THUMBS_UP"
                  pButton type="button" [label]="(post.currentUserReaction ? 'common.reaction.thumbs_up' : 'common.like_it') | translate|translateSelector:  'c'"
                  class="p-button-text p-button-rounded text-center justify-content-center flex-auto"
                  [ngClass]="{'p-button-plain': !post.currentUserReaction, 'p-button-primary': post.currentUserReaction}"
                  style="width: 33.33%; font-size: 0.8em"
                  icon="pi pi-thumbs-up"
                  ngxLongPress2
                  (onLongPress)="toggleReactionBtn($event, post, ReactionTypeEnum.POST_REACTION, tileSpeedDial)"
                  (click)="toggleThumbsUp($event, post, ReactionTypeEnum.POST_REACTION, tileSpeedDial)"
                  (contextmenu)="false"
          ></button>
          <button pButton type="button" [label]="'common.comment' | translate | ucfirst"
                  (click)="onCommentBoxOpen(post)"
                  class="p-button-text p-button-rounded p-button-plain text-center justify-content-center"
                  style="width: 33.33%; font-size: 0.8em"
                  icon="pi pi-comments"></button>
          <button pButton type="button" [label]="'common.share' | translate"
                  class="p-button-text p-button-rounded p-button-plain text-center justify-content-center"
                  style="width: 33.33%; font-size: 0.8em"
                  icon="fas fa-share"></button>
        </div>
      </div>
    </div>
    <ng-container *ngIf="isCommentBoxOpened">
      <p-divider styleClass="p-0 m-0 my-2" type="solid"></p-divider>
      <div class="grid mx-0">
        <div class="col-12 py-3 px-0 m-0 mt-1 pt-1">
          <app-social-input (onSubmit)="onCommentSubmit($event, post)"
                            [label]="'common.write_something' | translate"></app-social-input>
        </div>
        <div class="col-12 p-0 m-0">
          <ng-container *ngIf="post.comments.length && !commentBoxLoaded">
            <p-skeleton styleClass="mb-2" borderRadius="16px"></p-skeleton>
            <p-skeleton width="10rem" styleClass="mb-2" borderRadius="16px"></p-skeleton>
            <p-skeleton width="5rem" styleClass="mb-2" borderRadius="16px"></p-skeleton>
            <p-skeleton height="2rem" styleClass="mb-2" borderRadius="16px"></p-skeleton>
            <p-skeleton width="10rem" styleClass="mb-3" height="4rem" borderRadius="16px"></p-skeleton>
          </ng-container>
          <ng-container *ngIf="comments.length && commentBoxLoaded">
            <div *ngIf="comments.length > 1" class="inline-flex justify-content-start" style="width: 100%; gap: 20px;">
              <a *ngIf="!isThreadListOpened" (click)="toggleThreadList()">
                {{ 'common.show_all_comments' | translate }} ({{ comments.length }})</a>
              <a *ngIf="isThreadListOpened" (click)="toggleThreadList()">{{ 'common.hide_comments' | translate }}</a>
            </div>
            <ul class="comment thread mb-0">
              <li *ngFor="let comment of comments.slice(0, isThreadListOpened ? comments.length : 1)" class="mb-2">
                <div class="grid grid-nogutter">
                  <div class="col-12 inline-flex">
                    <a [routerLink]="['/' + comment.author.slug, 'garage']" style="height: 36px">
                      <app-thumbnail
                        classList="thumbnail-inline-block mr-2"
                        size="xs"
                        [circle]="true"
                        [imgSrc]="comment.author.avatar | mediaObject"
                      ></app-thumbnail>
                    </a>
                    <div class="grid grid-nogutter" style="width: 100%">
                      <div class="col-11">
                        <a [routerLink]="['/' + comment.author.slug, 'garage']">{{ comment.author.username }}</a>
                        <span class="block">{{ comment.content }}</span>
                      </div>
                      <div class="col-1 flex justify-content-end">
                        <app-end-cap
                          *ngxPermissionsOnly="[comment.author | ownerRole]"
                          icon="pi pi-ellipsis-h"
                          [pointerClass]="'p-button-rounded p-button-text p-button-icon-only p-button-primary'"
                          (onClickTrigger)="emitCommentContextMenuOpen(comment)"
                        >
                        </app-end-cap>
                      </div>
                      <div *ngIf="comment.responses.length > 1" class="col-12 inline-flex justify-content-start mb-2"
                           style="width: 100%; gap: 20px;">
                        <a *ngIf="!isItemOpened(isThreadCommentListOpened, comment)"
                           (click)="toggleThreadCommentsMap(comment)">{{ 'common.show_all_comments' | translate }}
                          ({{ comment.responses.length }})</a>
                        <a *ngIf="isItemOpened(isThreadCommentListOpened, comment)"
                           (click)="toggleThreadCommentsMap(comment)">{{ 'common.hide_comments' | translate }}</a>
                      </div>
                      <div class="col-12 inline-flex justify-content-start  mb-2" style="width: 100%; gap: 20px;">
                        <a>{{ comment.createdAt | dateAgo }}</a>
                        <app-speed-dial #commentSpeedDial [model]="items" direction="right"></app-speed-dial>
                        <a
                          ngxLongPress2
                          (onLongPress)="toggleReactionBtn($event, comment, ReactionTypeEnum.COMMENT_REACTION, commentSpeedDial)"
                          (click)="toggleThumbsUp($event, comment, ReactionTypeEnum.COMMENT_REACTION, commentSpeedDial)"
                          (contextmenu)="false"
                        >
                          <span *ngIf="comment.currentUserReaction" class="inline-flex align-items-center">
                            <span (click)="openSocialModal($event, comment)" class="mr-2">{{comment.reactions.length}}</span>
                            <ngx-emoji [emoji]="comment.currentUserReaction.reactedWith|reactionClassMap" size="12.8"></ngx-emoji>
                          </span>
                          <span *ngIf="!comment.currentUserReaction">{{ 'common.like' | translate }}</span>
                        </a>
                        <a (click)="toggleReplyBooleanMap(comment)">
                          {{ 'common.reply' | translate }}</a>
                      </div>
                      <ul *ngIf="comment.responses.length" class="col-12 comment">
                        <li
                          *ngFor="let reply of comment.responses.slice(isItemOpened(isThreadCommentListOpened, comment) ? 0 : comment.responses.length -1, comment.responses.length)">
                          <div class="grid grid-nogutter">
                            <div class="col-12 inline-flex">
                              <a [routerLink]="['/' + reply.author.slug, 'garage']" style="height: 36px">
                                <app-thumbnail
                                  classList="thumbnail-inline-block mr-2"
                                  size="xs"
                                  [circle]="true"
                                  [imgSrc]="reply.author.avatar | mediaObject"
                                ></app-thumbnail>
                              </a>
                              <div class="grid grid-nogutter" style="width: 100%">
                                <div class="col-11">
                                  <a [routerLink]="['/' + reply.author.slug, 'garage']">{{ reply.author.username }}</a>
                                  <span class="block">{{ reply.content }}</span>
                                </div>
                                <div class="col-1 flex justify-content-end">
                                  <app-end-cap
                                    *ngxPermissionsOnly="[reply.author | ownerRole]"
                                    icon="pi pi-ellipsis-h"
                                    [pointerClass]="'p-button-rounded p-button-text p-button-icon-only p-button-primary'"
                                    (onClickTrigger)="emitCommentContextMenuOpen(reply)"
                                  >
                                  </app-end-cap>
                                </div>
                                <div class="col-12 inline-flex justify-content-start mb-2"
                                     style="width: 100%; gap: 20px;">
                                  <a>{{ reply.createdAt | dateAgo }}</a>
                                  <app-speed-dial #replySpeedDial [model]="items" direction="right"></app-speed-dial>
                                  <a
                                    ngxLongPress2
                                    (onLongPress)="toggleReactionBtn($event, reply, ReactionTypeEnum.COMMENT_REACTION, replySpeedDial)"
                                    (click)="toggleThumbsUp($event, reply, ReactionTypeEnum.COMMENT_REACTION, replySpeedDial)"
                                    (contextmenu)="false"
                                  >
                                    <span *ngIf="reply.currentUserReaction" class="inline-flex align-items-center">
                                      <span (click)="openSocialModal($event, reply)" class="mr-2">{{reply.reactions.length}}</span>
                                      <ngx-emoji [emoji]="reply.currentUserReaction.reactedWith|reactionClassMap" size="12.8"></ngx-emoji>
                                    </span>
                                    <span *ngIf="!reply.currentUserReaction">{{ 'common.like' | translate }}</span>
                                  </a>
                                  <a (click)="toggleReplyBooleanMap(comment)">{{ 'common.reply' | translate }}</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <div *ngIf="isItemOpened(isThreadCommentInputOpened, comment)" class="col-12">
                        <app-social-input style="width: 100%"
                                          (onSubmit)="onReplySubmit($event, post, comment)"
                                          [label]="'common.write_something' | translate"
                        ></app-social-input>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-template>
</p-card>
