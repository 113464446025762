import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {DropdownModule} from "../dropdown/dropdown.module";
import {SharedModule} from "../shared.module";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {InputTextModule} from "primeng/inputtext";
import {ButtonModule} from "primeng/button";
import {TabMenuModule} from "primeng/tabmenu";
import {AppMenuComponent} from "./app.menu.component";
import {AppTopBarComponent} from "./app.topbar.component";
import {AppRightMenuComponent} from "./app.rightmenu.component";
import {AppMenuitemComponent} from "./app.menuitem.component";
import {AppInlineMenuComponent} from "./app.inlinemenu.component";
import {AppCodeModule} from "./app.code.component";
import {AppConfigComponent} from "./app.config.component";
import {BreadcrumbModule} from "primeng/breadcrumb";
import {AppBreadcrumbComponent} from "./app.breadcrumb.component";
import {RippleModule} from "primeng/ripple";
import {TooltipModule} from "primeng/tooltip";
import {MegaMenuModule} from "primeng/megamenu";
import {RadioButtonModule} from "primeng/radiobutton";
import {InputSwitchModule} from "primeng/inputswitch";
import {SidebarModule} from "primeng/sidebar";
import {AppBreadcrumbService} from "./app.breadcrumb.service";
import {FormsModule} from "@angular/forms";
import { SearchListComponent } from './search-list.component';
import {VirtualScrollerModule} from "primeng/virtualscroller";
import {SpinnerModule} from "../spinner/spinner.module";
import {ScrollTopModule} from "primeng/scrolltop";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DropdownModule,
    ScrollPanelModule,
    InputTextModule,
    ButtonModule,
    TabMenuModule,
    SharedModule,
    AppCodeModule,
    BreadcrumbModule,
    RippleModule,
    TooltipModule,
    MegaMenuModule,
    RadioButtonModule,
    InputSwitchModule,
    SidebarModule,
    FormsModule,
    VirtualScrollerModule,
    SpinnerModule,
    ScrollTopModule,
  ],
  declarations: [AppRightMenuComponent, AppMenuComponent, AppMenuitemComponent, AppInlineMenuComponent, AppBreadcrumbComponent, AppTopBarComponent, AppConfigComponent, SearchListComponent],
  exports: [AppRightMenuComponent, AppMenuComponent, AppMenuitemComponent, AppInlineMenuComponent, AppBreadcrumbComponent, AppTopBarComponent, AppConfigComponent],
  providers: [
    AppBreadcrumbService
  ]
})
export class NavbarModule {
}
