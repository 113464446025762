import {Injectable} from '@angular/core';
import {EventRestService} from '../../../../api/services/event.rest.service';
import {EventViewModel} from './event.view-model';
import {EventApiModel} from '../../../../api/models/event.api.model';
import {forkJoin, Observable, of, Subject} from 'rxjs';
import {ProjectViewModel} from '../../../../features/user/garage/project.view-model';
import {UserViewModel} from '../../../../features/user/settings/user.view-model';
import {debounceTime, distinctUntilChanged, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {QueryParamsApiModel} from '../../../../api/models/query-params-api.model';
import {FilterParameterApiModel} from '../../../../api/models/filter-parameter.api.model';
import {ResultListApiModel} from '../../../../api/models/result-list.api.model';
import {UserApiModel} from '../../../../api/models/user.api-model';
import {MediaObjectViewModel} from '../../../../core/models/media-object.view-model';
import {
  PersonalInformationViewModel
} from '../../../../features/user/settings/personal-information/personal-information-view-model';
import {UserRestService} from '../../../../api/services/user.rest.service';
import {PersonalInformationApiModel} from '../../../../api/models/personal-information.api.model';
import {MediaObjectApiModel} from '../../../../api/models/media-object.api-model';
import {QueryService} from '../../../../core/services/query.service';
import {PersonalInformationRestService} from '../../../../api/services/personal-information.rest.service';
import {MediaObjectRestService} from '../../../../api/services/media-object.rest.service';
import {QueryResourceInterface} from "../../../../core/models/query-resource.interface";

@Injectable({
  providedIn: 'root'
})
export class AddEventProviderService {
  public resultSubject$: Subject<string> = new Subject();
  public resources: any[] = [];
  public loading = true;


  constructor(
    private readonly eventRS: EventRestService,
    private readonly userRS: UserRestService,
    private readonly personalInformationRS: PersonalInformationRestService,
    private readonly mediaObjectRS: MediaObjectRestService
  ) {
  }

  public createEvent(eventVM: EventViewModel): Observable<EventApiModel> {
    const eventAM = EventApiModel.fromEventVM(eventVM);
    return this.eventRS.create(eventAM);
  }

  public fetchResourcesFromQuerySubject(): Observable<UserViewModel[][]> {
    return this.resultSubject$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap((query: string) => {
        this.loading = true;
        return this.fetchResources(query);
      })).pipe(tap(() => this.loading = false));
  }

  public fetchResources(query: string): Observable<(UserViewModel[])[]> {
    const queryParams = new QueryParamsApiModel();
    queryParams.filters.push(new FilterParameterApiModel('info.firstName', query, 'single'));
    queryParams.filters.push(new FilterParameterApiModel('info.lastName', query, 'single'));
    queryParams.filters.push(new FilterParameterApiModel('username', query, 'single'));

    return forkJoin([
      this.userRS.getCollection(queryParams)
    ]).pipe(mergeMap(([usersAM]: [ ResultListApiModel<UserApiModel>]) => {
      return this.forkDeps([usersAM]);
    }), tap(([usersVM]: [UserViewModel[]]) => {
    }));
  }

  private forkDeps([usersAM]: [ResultListApiModel<UserApiModel>]): Observable<(UserViewModel[])[]> {
    return forkJoin([
      this.getMediaObjectCollection(usersAM.records),
    ]).pipe(mergeMap( ([mediaObjectVMs]: [MediaObjectViewModel[]]) => {
      return this.getPersonalInformationCollection(usersAM.records).pipe(map((personalInfoVMs: PersonalInformationViewModel[]) => {
        const users = usersAM.records.map((e: UserApiModel) => {
          const user = new UserViewModel(e);
          user.info = personalInfoVMs.find(p => p['@id'] ===  e.info);
          return Object.assign({
            name: user.info.firstName + ' ' + user.info.lastName,
            alias : e.username,
            uri: '/' + e.slug,
            slug: e.slug,
            user: e,
            avatar: mediaObjectVMs.find(m => m['@id'] ===  e.avatar)?.hyperlinks[0]?.uri || 'assets/img/profile-bg-dark.png',
            type: 'user'
          });
        });
        this.resources = [...users].sort(AddEventProviderService.sortByUsername);
        return [
          usersAM.records.map((userAM: UserApiModel) => new UserViewModel(userAM))
        ];
      }));
    }));
  }

  private getPersonalInformationCollection(resultAMs: UserApiModel[]): Observable<PersonalInformationViewModel[]> {
    const params = new QueryParamsApiModel();
    params.filters = resultAMs.map(e => new FilterParameterApiModel('id', e.info));
    return this.personalInformationRS.getCollection(params)
      .pipe(map((resultListAM: ResultListApiModel<PersonalInformationApiModel>) => {
        return resultListAM.records.map((personalInformationAM: PersonalInformationApiModel) => new PersonalInformationViewModel(personalInformationAM));
      }));
  }

  private getMediaObjectCollection(resultAMs: UserApiModel[]): Observable<MediaObjectViewModel[]> {
    const params = new QueryParamsApiModel();
    params.filters = resultAMs
      .filter((e: UserApiModel) => typeof e.avatar === 'string')
      .map(e => new FilterParameterApiModel('id', e.avatar));
    if (params.filters.length) {
      return this.mediaObjectRS.getMediaObjectCollection(params)
        .pipe(map((resultListAM: ResultListApiModel<MediaObjectApiModel>) => {
          return resultListAM.records.map((mediaObjectAM: MediaObjectApiModel) => new MediaObjectViewModel(mediaObjectAM));
        }));
    }

    return of<MediaObjectViewModel[]>([]);
  }

  private static sortByUsername(a: {alias: string}, b: {alias: string}): number {
    return ('' + a.alias).localeCompare(b.alias);
  }
}
