import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MediaViewComponent} from "./media-view.component";
import {PipesModule} from "../../core/pipes/pipes.module";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {TranslateModule} from "@ngx-translate/core";



@NgModule({
  declarations: [MediaViewComponent],
  exports: [
    MediaViewComponent
  ],
    imports: [
        CommonModule,
        PipesModule,
        ButtonModule,
        RippleModule,
        TranslateModule
    ]
})
export class MediaViewModule { }
