import { Injectable } from '@angular/core';
import {forkJoin, Observable, of, Subject} from "rxjs";
import {classToClassFromExist, plainToClass, plainToInstance} from "class-transformer";

import {GoalRestService} from "../../../../api/services/goal.rest.service";
import {GoalApiModel} from "../../../../api/models/goal.api.model";
import {ProjectViewModel} from "../../garage/project.view-model";
import {GoalViewModel} from "./goal.view-model";
import {map} from "rxjs/operators";

@Injectable()
export class GoalDataProviderService {

  constructor(
    private goalRS: GoalRestService
  )
  {
  }

  // public fetchResources(projectVM: ProjectViewModel): Observable<ProjectViewModel> {
  //   const projectAM = projectVM.getApiModel();
  //   return this.userRS.get(projectAM.user).pipe(
  //     mergeMap((userAM: UserApiModel) => {
  //       const userVM = new UserViewModel(userAM);
  //       projectVM.user = userVM;
  //       const params = new QueryParamsApiModel();
  //       if (projectAM.theme) {
  //         params.filters.push(new FilterParameterApiModel('id', projectAM.theme));
  //       }
  //       if (userAM.avatar) {
  //         params.filters.push(new FilterParameterApiModel('id', userAM.avatar));
  //       }
  //       if (params.filters.length) {
  //         return this.mediaRS.getMediaObjectCollection(params).pipe(map((resultListAM: ResultListApiModel<MediaObjectApiModel>) => {
  //           const avatar = resultListAM.records.find(e => e["@id"] === userAM.avatar);
  //           const theme = resultListAM.records.find(e => e["@id"] === projectAM.theme);
  //           userVM.avatar = new MediaObjectViewModel(avatar);
  //           projectVM.theme = new MediaObjectViewModel(theme);
  //           return projectVM;
  //         }));
  //       }
  //       return of(projectVM);
  //     }),
  //     mergeMap((projectVM: ProjectViewModel) => {
  //       if (projectAM.hashTags.length) {
  //         const params = new QueryParamsApiModel();
  //         for (let i of projectAM.hashTags) {
  //           params.filters.push(new FilterParameterApiModel('id', i));
  //         }
  //         return this.hashTagRS.getCollection(params).pipe(map((hashTagRLAM: ResultListApiModel<HashTagApiModel>) => {
  //           projectVM.hashTags = projectAM.hashTags.map(e => hashTagRLAM.records.find(r => r["@id"] === e))
  //           return projectVM;
  //         }));
  //       }
  //       return of(projectVM);
  //     })
  //   );
  // }

  public postGoal(goalVM: GoalViewModel): Observable<GoalViewModel> {
    return this.goalRS.create(plainToClass(GoalApiModel, goalVM, {
      excludeExtraneousValues: true,
      enableImplicitConversion: true
    })).pipe(map((goalAM: GoalApiModel) => GoalApiModel.toGoalVM(goalAM)));
  }

  public putGoal(goalVM: GoalViewModel): Observable<GoalViewModel> {
    return this.goalRS.put(GoalApiModel.fromGoalVM(goalVM)).pipe(map((goalAM: GoalApiModel) => GoalApiModel.toGoalVM(goalAM)));
  }
}
