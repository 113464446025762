import {Exclude, Expose, Type} from 'class-transformer';
import {AbstractApiModel} from './abstract.api.model';
import {UserApiModel} from './user.api-model';
import {SubjectApiModel} from './follow.api-model';

@Exclude()
export class ReactionApiModel extends AbstractApiModel {
  @Expose() public id: number;
  @Type(() => UserApiModel)
  @Expose() public author: UserApiModel|string;
  @Expose() public reactedWith: string;
  @Expose() public type: string;
  @Expose() public subject: string|SubjectApiModel;
}
