import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from "rxjs";
import { HttpClient } from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {plainToClassFromExist} from "class-transformer";
import {ResultListApiModel} from "../models/result-list.api.model";
import {VehicleTypeApiModel} from "../models/vehicle-type.api-model";
import {QueryParamsService} from "../../core/services/query-params.service";
import {QueryParamsApiModel} from "../models/query-params-api.model";

@Injectable()
export class VehicleTypeRestService {
  private readonly resource: string = '/vehicle_types';

  constructor(
    public http: HttpClient,
    public params: QueryParamsService
  ) {
  }

  public getVehicleTypeCollection(queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<ResultListApiModel<VehicleTypeApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<VehicleTypeApiModel>>(environment.apiUrl + this.resource, {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<VehicleTypeApiModel>(VehicleTypeApiModel), result as object)));
  }
}
