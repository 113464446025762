import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ResponseNotificationService } from '../../core/services/response-notification.service';
import { Notification } from '../../core/interfaces/notification';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  providers: [MessageService]
})
export class NotificationComponent {

  public content: Notification;
  public type: number = null;

  constructor(private rns: ResponseNotificationService, private messageService: MessageService, private translate: TranslateService) {
    this.rns.notification.subscribe(data => {
      this.content = data.content;
      this.type = data.type;

      switch (this.type) {
        case ResponseNotificationService.MESSAGE: this.showMessage(); break;
        case ResponseNotificationService.VALIDATION_ERROR: this.showError(); break;
        case ResponseNotificationService.VALIDATION_WARNING: this.showWarning(); break;
      }
    });
  }

  showWarning(): void {
    this.messageService.add({severity: 'warn', summary: this.translate.instant(this.content.heading), detail: this.content.message, sticky: false});
  }

  showError(): void {
    this.messageService.add({severity: 'error', summary: this.translate.instant(this.content.heading), detail: this.content.message, sticky: false});
  }
  showMessage(): void {
    this.messageService.add({severity: 'success', summary: this.translate.instant(this.content.heading), detail: this.content.message, sticky: false});
  }
}
