import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {PostApiModel} from "../models/post-api.model";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {plainToClass, plainToClassFromExist} from "class-transformer";
import {ResultListApiModel} from "../models/result-list.api.model";
import { HttpClient, HttpResponse } from "@angular/common/http";
import {QueryParamsApiModel} from "../models/query-params-api.model";
import {QueryParamsService} from "../../core/services/query-params.service";

@Injectable({
  providedIn: 'root'
})
export class PostRestService {
  private readonly resource: string = `/posts`;

  constructor(
    private http: HttpClient,
    public params: QueryParamsService
  ) {
  }

  public create(postAM: PostApiModel): Observable<PostApiModel> {
    return this.http.post<PostApiModel>(environment.apiUrl + this.resource, postAM)
      .pipe(map((result: PostApiModel) => plainToClass(PostApiModel, result)));
  }

  public put(postAM: PostApiModel): Observable<PostApiModel> {
    return this.http.put<PostApiModel>(environment.apiUrl + postAM['@id'], postAM)
      .pipe(map((result: PostApiModel) => plainToClass(PostApiModel, result)));
  }

  public get(postAM: PostApiModel): Observable<PostApiModel> {
    return this.http.get<PostApiModel>(environment.apiUrl+ postAM['@id'])
      .pipe(map((result: PostApiModel) => plainToClass(PostApiModel, result)));
  }

  public delete(postAM: PostApiModel): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(environment.apiUrl + postAM['@id']);
  }

  public getCollection(queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel(), resource: string = null): Observable<ResultListApiModel<PostApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<PostApiModel>>(environment.apiUrl + (resource || this.resource), {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<PostApiModel>(PostApiModel), result as object)));
  }

  public getUserSubResourceCollection(userId: string): Observable<ResultListApiModel<PostApiModel>> {
    return this.http.get<ResultListApiModel<PostApiModel>>(environment.apiUrl + userId + this.resource)
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<PostApiModel>(PostApiModel), result as object)));
  }
}
