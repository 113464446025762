import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {QueryParamsApiModel} from '../../api/models/query-params-api.model';
import {ResultListApiModel} from '../../api/models/result-list.api.model';
import {debounceTime, distinctUntilChanged, mergeMap} from 'rxjs/operators';
import {HashtagRestService} from '../../api/services/hashtag.rest.service';
import {HashtagApiModel} from '../../api/models/hashtag.api.model';

@Injectable({
  providedIn: 'root'
})
export class HashtagsService {

  constructor(
    private hashTagRS: HashtagRestService
  ) { }

  public getHashTagCollectionBySubjectValue(listSubject$: Subject<QueryParamsApiModel>): Observable<ResultListApiModel<HashtagApiModel>> {
    return listSubject$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      mergeMap((queryParams: QueryParamsApiModel) => {
        return this.hashTagRS.getCollection(queryParams);
      })
    );
  }
}
