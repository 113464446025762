import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResponseNotificationService {

  static readonly MESSAGE = 0;
  static readonly VALIDATION_ERROR = 1;
  static readonly VALIDATION_WARNING = 2;


  notification: any = new Subject();

  constructor() {
  }

  public emitError(content): void {
    this.notification.next({
      content: {heading: 'app.error.title', message: content},
      type: ResponseNotificationService.VALIDATION_ERROR
    });
  }

  public emitWarning(content): void {
    this.notification.next({
      content: {heading: 'app.warning.title', message: content},
      type: ResponseNotificationService.VALIDATION_WARNING
    });
  }

  public emitMessage(content: string) {
    this.notification.next({
      content: {heading: 'app.success.title', message: content},
      type: ResponseNotificationService.MESSAGE
    });
  }
}
