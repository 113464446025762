import {AbstractViewModel} from "../interfaces/abstract-view-model";

export abstract class AbstractDictionaryViewModel<AbstractDictionaryApiModel> extends AbstractViewModel<AbstractDictionaryApiModel> {
  public id: number;
  public name: string;
  public description: string;

  protected constructor(protected apiModel: AbstractDictionaryApiModel) {
    super(apiModel);
    this.fromApiModel(apiModel);
  }

  fromApiModel(apiModel: AbstractDictionaryApiModel): AbstractDictionaryViewModel<AbstractDictionaryApiModel> {
    this.id = apiModel['id'];
    this.name = apiModel['name'];
    this.description = apiModel['description'];

    return this;
  }

  toApiModel(): AbstractDictionaryApiModel {
    return this.apiModel;
  }

}
