import {Component, OnInit, OnDestroy} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {ModalInterface} from "../../modal.interface";
import {ModalState} from "../../modal-state.enum";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import {ModalStateEvent} from "../../modal-state-event";
import {ResponseNotificationService} from "../../../../core/services/response-notification.service";

@Component({
  selector: 'app-remove',
  templateUrl: './remove.component.html',
  styleUrls: ['./remove.component.scss']
})
export class RemoveComponent implements OnInit, ModalInterface, OnDestroy {

  get state(): Observable<ModalStateEvent> {
    return this._state;
  }

  set state(value: Observable<ModalStateEvent>) {
    this._state = value;
  }

  set subject(value: Subject<ModalStateEvent>) {
    this._subject = value;
  }

  public data: any;
  private _state: Observable<ModalStateEvent>;
  private _subject: Subject<ModalStateEvent>;
  private subscription: Subscription;

  constructor(private http: HttpClient, private rns: ResponseNotificationService) {
  }

  public ngOnInit() {
    this._subject.next(new ModalStateEvent(ModalState.VALID));
    this.watchParentModalState();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private watchParentModalState(): void {
    this.subscription = this._state.subscribe((value: ModalStateEvent) => {
      if (value.status === ModalState.PENDING) {
        this.removeItem(this.data.route, this.data.id).subscribe(
          result => {
            this._subject.next(new ModalStateEvent(ModalState.SUCCESS));
            this.rns.emitMessage(this.data.successMessage)
          },
          (errorResponse: HttpErrorResponse) => {
            this.rns.emitError(this.data.errorMessage)
          }
        );
      }
    });
  }

  private removeItem(route: string, id: string) {
    return this.http.delete(route + id);
  }
}
