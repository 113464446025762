import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BottomSidebarComponent} from "./bottom-sidebar/bottom-sidebar.component";
import {SidebarModule as PrimeNgSidebarModule} from "primeng/sidebar";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import { BottomActionSheetComponent } from './bottom-sidebar/bottom-action-sheet/bottom-action-sheet.component';


@NgModule({
  declarations: [BottomSidebarComponent, BottomActionSheetComponent],
  imports: [
    CommonModule,
    PrimeNgSidebarModule,
    ButtonModule,
    RippleModule
  ],
  exports: [BottomSidebarComponent, BottomActionSheetComponent]
})
export class SidebarModule { }
