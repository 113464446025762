import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import {TooltipModule} from 'primeng/tooltip';
import {RouterModule} from '@angular/router';
import {SpeedDialComponent} from './speed-dial.component';
import {EmojiModule} from '@ctrl/ngx-emoji-mart/ngx-emoji';



@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    RippleModule,
    TooltipModule,
    RouterModule,
    EmojiModule,
  ],
  exports: [
    SpeedDialComponent,
    ButtonModule,
    TooltipModule,
    RouterModule,
  ],
  declarations: [SpeedDialComponent],
})
export class SpeedDialModule { }
