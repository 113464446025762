import { Action } from '@ngrx/store';
import {ReactionApiModel} from '../../api/models/reaction.api.model';

export enum ReactionActionTypes {
  ADD_ONE = '[Reaction] Add One',
  ADD_MANY = '[Reaction] Add Many',
  UPDATE_ONE = '[Reaction] Update One',
  DELETE_ONE = '[Reaction] Delete One',
  GET_ALL = '[Reaction] Get All',
  GET_ONE = '[Reaction] Get One'
}
export class AddOne implements Action {
  readonly type = ReactionActionTypes.ADD_ONE;
  constructor(public reaction: ReactionApiModel) { }
}

export class AddMany implements Action {
  readonly type = ReactionActionTypes.ADD_MANY;
  constructor(public reactions: ReactionApiModel[]) { }
}

export class UpdateOne implements Action {
  readonly type = ReactionActionTypes.UPDATE_ONE;
  constructor(
    public id: string,
    public changes: Partial<ReactionApiModel>,
  ) { }
}
export class DeleteOne implements Action {
  readonly type = ReactionActionTypes.DELETE_ONE;
  constructor(public id: string) { }
}
export class GetAll implements Action {
  readonly type = ReactionActionTypes.GET_ALL;
  constructor(public reactions: ReactionApiModel[]) { }
}

export class GetOne implements Action {
  readonly type = ReactionActionTypes.GET_ONE;
  constructor(public reaction: ReactionApiModel) { }
}

export type Actions
  = GetOne
  | AddOne
  | AddMany
  | UpdateOne
  | DeleteOne
  | GetAll;
