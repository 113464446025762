<div #container [attr.id]="id" [ngClass]="containerClass()" [class]="className" [ngStyle]="style">
  <button pRipple pButton [style]="buttonStyle" [icon]="buttonIconClass" [ngClass]="buttonClass()" (click)="onButtonClick($event)">
    <ng-container *ngIf="buttonTemplate">
      <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
    </ng-container>
  </button>
  <ul #list class="p-speeddial-list" role="menu">
    <li *ngFor="let item of model; let i = index" [ngStyle]="getItemStyle(i)" class="p-speeddial-item" pTooltip [tooltipOptions]="item.tooltipOptions" [ngClass]="{'p-hidden': item.visible === false}">
      <a *ngIf="isClickableRouterLink(item); else elseBlock" pRipple [routerLink]="item.routerLink" [queryParams]="item.queryParams" class="p-speeddial-action" [ngClass]="{'p-disabled':item.disabled}"  role="menuitem"  (click)="onItemClick($event, item, i)" (keydown.enter)="onItemClick($event, item, i)"
         [attr.target]="item.target" [attr.id]="item.id" [attr.tabindex]="item.disabled || readonly ||!visible ? null : (item.tabindex ? item.tabindex : '0')"
         [fragment]="item.fragment" [queryParamsHandling]="item.queryParamsHandling" [preserveFragment]="item.preserveFragment" [skipLocationChange]="item.skipLocationChange" [replaceUrl]="item.replaceUrl" [state]="item.state">
        <span class="p-speeddial-action-icon" *ngIf="item.icon" [ngClass]="item.icon"></span>
        <span *ngIf="item.label">{{item.label}}</span>
      </a>
      <ng-template #elseBlock>
        <a [attr.href]="item.url||null" class="p-speeddial-action {{item?.styleClass}}" role="menuitem" pRipple (click)="onItemClick($event, item, i)" [ngClass]="{'p-disabled':item.disabled}"
           (keydown.enter)="onItemClick($event, item, i)" [attr.target]="item.target" [attr.id]="item.id" [attr.tabindex]="item.disabled||(i !== activeIndex && readonly)||!visible ? null : (item.tabindex ? item.tabindex : '0')">
          <span class="p-speeddial-action-icon" *ngIf="item.icon" [ngClass]="item.icon"></span>
          <span *ngIf="item.label && !item.escape" [innerHTML]="item.label"></span>
          <span *ngIf="item.label && item.escape">{{item.label}}</span>
          <ngx-emoji *ngIf="item.state?.emoji" [emoji]="item.state?.emoji" size="20"></ngx-emoji>
        </a>
      </ng-template>
    </li>
  </ul>
</div>
<div *ngIf="mask && visible" [ngClass]="{'p-speeddial-mask': true, 'p-speeddial-mask-visible': visible}" [class]="maskClassName" [ngStyle]="maskStyle"></div>
