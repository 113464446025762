import { Action } from '@ngrx/store';
import {ParticipantApiModel} from '../../api/models/participant.api.model';

export enum ParticipantActionTypes {
  ADD_ONE = '[Participant] Add One',
  ADD_MANY = '[Participant] Add Many',
  UPDATE_ONE = '[Participant] Update One',
  DELETE_ONE = '[Participant] Delete One',
  GET_ALL = '[Participant] Get All',
  GET_ONE = '[Participant] Get One'
}
export class AddOne implements Action {
  readonly type = ParticipantActionTypes.ADD_ONE;
  constructor(public participant: ParticipantApiModel) { }
}

export class AddMany implements Action {
  readonly type = ParticipantActionTypes.ADD_MANY;
  constructor(public participants: ParticipantApiModel[]) { }
}

export class UpdateOne implements Action {
  readonly type = ParticipantActionTypes.UPDATE_ONE;
  constructor(
    public id: string,
    public changes: Partial<ParticipantApiModel>,
  ) { }
}
export class DeleteOne implements Action {
  readonly type = ParticipantActionTypes.DELETE_ONE;
  constructor(public id: string) { }
}
export class GetAll implements Action {
  readonly type = ParticipantActionTypes.GET_ALL;
  constructor(public participants: ParticipantApiModel[]) { }
}

export class GetOne implements Action {
  readonly type = ParticipantActionTypes.GET_ONE;
  constructor(public participant: ParticipantApiModel) { }
}

export type Actions
  = GetOne
  | AddOne
  | AddMany
  | UpdateOne
  | DeleteOne
  | GetAll;
