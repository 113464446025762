import {Exclude, Expose, Type} from 'class-transformer';
import {AbstractApiModel} from './abstract.api.model';
import {PasswordApiModel} from './password.api-model';
import {MediaObjectApiModel} from './media-object.api-model';
import {PersonalInformationApiModel} from "./personal-information.api.model";

@Exclude()
export class UserApiModel extends AbstractApiModel {

  @Expose() public id: number;
  @Expose() public username: string;
  @Expose() public email: string;
  @Expose() public password: PasswordApiModel;
  @Expose() public slug: string;
  @Type(() => MediaObjectApiModel)
  @Expose() public avatar: MediaObjectApiModel|string;
  @Type(() => MediaObjectApiModel)
  @Expose() public background: MediaObjectApiModel|string;
  @Expose() public images: string[];
  @Expose() public info: PersonalInformationApiModel|string;
  @Expose() public roles: string[];
  @Expose() public currentPassword: string;
  @Expose() public projects: string[];
  @Expose() public follows: string[];
  @Expose() public reactions: string[];
  @Expose() public individualProjects: number;
  @Expose() public collaborationProjects: number;
  @Expose() public saleProjects: number;
  @Expose() public soldProjects: number;
  @Expose() public createdAt: Date;
  @Expose() public updatedAt: Date;
}
