import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from "rxjs";
import { HttpClient } from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {plainToClassFromExist} from "class-transformer";
import {ResultListApiModel} from "../models/result-list.api.model";
import {QueryParamsService} from "../../core/services/query-params.service";
import {QueryParamsApiModel} from "../models/query-params-api.model";
import {BodyGenApiModel} from "../models/body-gen.api-model";

@Injectable()
export class BodyGenRestService {
  private readonly resource: string = '/body_gens';

  constructor(
    public http: HttpClient,
    public params: QueryParamsService
  ) {
  }

  public getBodyGenCollection(queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<ResultListApiModel<BodyGenApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<BodyGenApiModel>>(environment.apiUrl + this.resource, {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<BodyGenApiModel>(BodyGenApiModel), result as object)));
  }
}
