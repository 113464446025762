import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {environment} from "../../../environments/environment";
import {FacebookLogin, FacebookLoginResponse} from "@capacitor-community/facebook-login";


@Injectable({
  providedIn: 'root'
})
export class FacebookService {

  constructor(
  ) {
  }

  async initFacebook(): Promise<void> {
    await FacebookLogin.initialize({appId: environment.facebookAppId});
  }
  loginWithFacebook(): Promise<FacebookLoginResponse> {
    const FACEBOOK_PERMISSIONS = ['email', 'public_profile'];
    return FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
  }
}
