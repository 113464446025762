<div class="action-buttons">
  <button (click)="onDiscard()" class="back-button">
<!--    <mat-icon>arrow_back</mat-icon>-->
  </button>

  <button
    (click)="onConfirm()"
    class="confirm-button"
    *ngIf="(isProcessingImage$ | ngrxPush) === false"
  >
    {{ 'customCamera.confirmCapture' }}
  </button>
</div>

<div class="preview">
<!--  <app-media-->
<!--    *ngIf="isVideo$ | ngrxPush"-->
<!--    [src]="curCaptureSrc$ | ngrxPush"-->
<!--    [mimeType]="curCaptureMimeType$ | ngrxPush"-->
<!--  ></app-media>-->

  <div
    *ngIf="curImageBase64$ | ngrxPush as curImageBase64"
    class="image-editor-container"
  >
<!--    <pintura-editor-->
<!--      #pinturaEditor-->
<!--      [src]="curImageBase64"-->
<!--      [options]="pinturaEditorOptions$ | ngrxPush"-->
<!--      (processstart)="handleEditorProcessStart()"-->
<!--      (processabort)="handleEditorProcessAbort()"-->
<!--      (processerror)="handelEditorProcessError()"-->
<!--      (process)="handleEditorProcess($event)"-->
<!--    ></pintura-editor>-->
  </div>

<!--  <div class="image-editor-custom-buttons">-->
<!--    <button-->
<!--      (click)="applyBlackAndWhiteFilter()"-->
<!--      class="image-editor-button"-->
<!--      mat-mini-fab-->
<!--    >-->
<!--      <mat-icon-->
<!--        class="black-and-white-filter-button"-->
<!--        svgIcon="capture-rebrand-camera-auto-enhance"-->
<!--      ></mat-icon>-->
<!--    </button>-->

<!--    <button-->
<!--      (click)="toggleCropImageFeature()"-->
<!--      class="image-editor-button"-->
<!--      mat-mini-fab-->
<!--    >-->
<!--      <mat-icon-->
<!--        class="black-and-white-filter-button"-->
<!--        svgIcon="capture-rebrand-camera-crop"-->
<!--      ></mat-icon>-->
<!--    </button>-->
<!--  </div>-->
</div>

<div class="footer" *ngIf="isVideo$ | ngrxPush"></div>
