import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {plainToClassFromExist} from "class-transformer";
import {ResultListApiModel} from "../models/result-list.api.model";
import {WheelDriveApiModel} from "../models/wheel-drive-api.model";
import {QueryParamsService} from "../../core/services/query-params.service";
import {QueryParamsApiModel} from "../models/query-params-api.model";

@Injectable()
export class WheelDriveRestService {
  private readonly resource: string = '/wheel_drives';

  constructor(
    public http: HttpClient,
    public params: QueryParamsService
  ) {
  }

  public getWheelDriveCollection(queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<ResultListApiModel<WheelDriveApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<WheelDriveApiModel>>(environment.apiUrl + this.resource, {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<WheelDriveApiModel>(WheelDriveApiModel), result as object)));
  }
}
