import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TimelineTileComponent} from './timeline-tile/timeline-tile.component';
import {TimelineActionPanelComponent} from './timeline-action-panel/timeline-action-panel.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {CardModule} from 'primeng/card';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {EndCapModule} from '../end-cap/end-cap.module';
import {TimelineComponent} from './timeline.component';
import {TimelineDataProviderService} from './timeline.data-provider.service';
import {ThumbnailModule} from '../thumbnail/thumbnail.module';
import {ButtonModule} from 'primeng/button';
import {SplitButtonModule} from 'primeng/splitbutton';
import {PipesModule} from '../../core/pipes/pipes.module';
import {MenuModule} from 'primeng/menu';
import {TranslateModule} from '@ngx-translate/core';
import {NgxPermissionsModule} from 'ngx-permissions';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SpinnerModule} from '../spinner/spinner.module';
import {InplaceModule} from 'primeng/inplace';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DividerModule} from 'primeng/divider';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {RippleModule} from 'primeng/ripple';
import {InputTextModule} from 'primeng/inputtext';
import {SkeletonModule} from 'primeng/skeleton';
import {GalleriaModule} from 'primeng/galleria';
import {SocialModule} from '../social/social.module';
import {SidebarModule} from '../sidebar/sidebar.module';
import {NgxInfiniteGridModule} from '@egjs/ngx-infinitegrid';
import {OverlayGalleryComponent} from './overlay-gallery/overlay-gallery.component';
import {DirectivesModule} from '../directives/directives.module';
import {SwiperModule} from 'swiper/angular';
import {MediaViewModule} from '../media-view/media-view.module';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {SpeedDialModule} from '../speed-dial/speed-dial.module';
import {EmojiModule} from '@ctrl/ngx-emoji-mart/ngx-emoji';
import {NgxLongPress2Module} from 'ngx-long-press2';
import {SocialTimelineComponent} from './social-timeline/social-timeline.component';
import {SocialGridListComponent} from './social-grid-list/social-grid-list.component';
import {SearchInfiniteListComponent} from './search-infinite-list/search-infinite-list.component';
import { SearchTileComponent } from './search-tile/search-tile.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    CardModule,
    InputTextareaModule,
    EndCapModule,
    ThumbnailModule,
    ButtonModule,
    SplitButtonModule,
    PipesModule,
    MenuModule,
    TranslateModule,
    NgxPermissionsModule,
    SidebarModule,
    SlideMenuModule,
    SpinnerModule,
    InplaceModule,
    ConfirmDialogModule,
    DividerModule,
    OverlayPanelModule,
    RippleModule,
    InputTextModule,
    SkeletonModule,
    GalleriaModule,
    SidebarModule,
    NgxInfiniteGridModule,
    DirectivesModule,
    SwiperModule,
    SocialModule,
    MediaViewModule,
    ProgressSpinnerModule,
    SpeedDialModule,
    EmojiModule,
    NgxLongPress2Module,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [TimelineDataProviderService],
  declarations: [
    TimelineComponent, TimelineTileComponent, TimelineActionPanelComponent,
    OverlayGalleryComponent, SocialGridListComponent, SocialTimelineComponent, SearchInfiniteListComponent, SearchTileComponent
  ],
  exports: [TimelineComponent, TimelineTileComponent, TimelineActionPanelComponent, SocialGridListComponent, SearchInfiniteListComponent, SearchTileComponent]
})
export class TimelineModule {
}
