import {Component} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ModalInterface} from '../../modal.interface';
import {ModalState} from "../../modal-state.enum";
import {Observable, Subject} from "rxjs";
import {RxFormBuilder, RxwebValidators} from "@rxweb/reactive-form-validators";
import {ModalStateEvent} from "../../modal-state-event";

@Component({
  selector: 'app-current-password',
  templateUrl: './current-password.component.html',
  styleUrls: ['./current-password.component.scss']
})
export class CurrentPasswordComponent implements ModalInterface {
  get data(): UntypedFormGroup {
    return this._data;
  }

  set data(value: UntypedFormGroup) {
    this._data = value;
  }

  get state(): Observable<ModalStateEvent> {
    return this._state;
  }

  set state(value: Observable<ModalStateEvent>) {
    this._state = value;
  }

  set subject(value: Subject<ModalStateEvent>) {
    this._subject = value;
  }
  public form: UntypedFormGroup;

  private _data: UntypedFormGroup;
  private _state: Observable<ModalStateEvent>;
  private _subject: Subject<ModalStateEvent>;
  public spinnerState: boolean;

  constructor(private fb: RxFormBuilder) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      currentPassword: [null, [RxwebValidators.required(), RxwebValidators.minLength({value: 6}), RxwebValidators.maxLength({value: 50})]]
    });
    this.watchInputStatusChange();
  }

  private watchInputStatusChange(): void {
    const currentPassword = this.form.get('currentPassword');
    this.form.get('currentPassword').statusChanges.subscribe((e) => {
      if (currentPassword.valid) {
        this._subject.next(new ModalStateEvent(ModalState.VALID, this.form));
      }
    });
  }
}

