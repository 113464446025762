import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {StatusApiModel} from "../models/status.api.model";
import {ProjectApiModel} from "../models/project.api-model";
import {ResultListApiModel} from "../models/result-list.api.model";
import { HttpClient } from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {plainToClass, plainToClassFromExist} from "class-transformer";
import {QueryParamsApiModel} from "../models/query-params-api.model";
import {QueryParamsService} from "../../core/services/query-params.service";

// TODO: move corresponding elements HashTags, Targets, Sections methods to another independent services
@Injectable()
export class ProjectRestService {
  private readonly resource: string = '/projects';
  public statuses: Array<StatusApiModel>;

  constructor(
    private http: HttpClient,
    private params: QueryParamsService
  ) {
  }

  public create(projectAM: ProjectApiModel): Observable<ProjectApiModel> {
    return this.http.post<ProjectApiModel>(environment.apiUrl + this.resource, projectAM)
      .pipe(map((result: ProjectApiModel) => plainToClass(ProjectApiModel, result)))
  }

  public put(projectAM: ProjectApiModel): Observable<ProjectApiModel> {
    return this.http.put<ProjectApiModel>(environment.apiUrl + projectAM['@id'], projectAM)
      .pipe(map((result: ProjectApiModel) => plainToClass(ProjectApiModel, result)))
  }

  public get(id: string): Observable<ProjectApiModel> {
    return this.http.get<ProjectApiModel>(environment.apiUrl + this.resource + '/' + id)
      .pipe(map((result: ProjectApiModel) => plainToClass(ProjectApiModel, result)));
  }

  public getCollection(queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<ResultListApiModel<ProjectApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<ProjectApiModel>>(environment.apiUrl + this.resource, {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<ProjectApiModel>(ProjectApiModel), result as object)));
  }

  public getRatedCollection(): Observable<ResultListApiModel<ProjectApiModel>> {
    return this.http.get<ResultListApiModel<ProjectApiModel>>(environment.apiUrl + this.resource + '/rated')
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<ProjectApiModel>(ProjectApiModel), result as object)));
  }

  public getUserSubResourceCollection(userId: string): Observable<ResultListApiModel<ProjectApiModel>> {
    return this.http.get<ResultListApiModel<ProjectApiModel>>(environment.apiUrl + userId + this.resource)
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<ProjectApiModel>(ProjectApiModel), result as object)));
  }

  public getStatusCollection(): Observable<ResultListApiModel<StatusApiModel>> {
    return this.http.get<ResultListApiModel<StatusApiModel>>(environment.apiUrl+ '/statuses')
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<StatusApiModel>(StatusApiModel), result as object)));
  }

  updateProjectStatus(project: any, status: number) {
    const request = {
      status: status,
    };
    // return this.putProject(project, request);
  }

  toggleLockProject(project: any) {
    const request = {
      locked: !project.locked,
    };
    // return this.putProject(project, request);
  }
}
