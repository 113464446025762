import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {ResetConfirmationComponent} from './reset-confirmation/reset-confirmation.component';
import {RegistrationConfirmationComponent} from './registration-confirmation/registration-confirmation.component';
import {CompleteOauth2RegistrationComponent} from './complete-oauth2-registration/complete-oauth2-registration.component';
import {AuthGuard} from '../../core/guards/auth.guard';
import {Oauth2UserResolverService} from '../../core/services/oauth2-user.resolver.service';
import {AuthComponent} from './auth.component';
import {RegisterComponent} from './register/register.component';
import {BackgroundResolverService} from '../../core/services/background.resolver.service';
import {NoAuthGuard} from '../../core/guards/no-auth.guard';

const routes: Routes = [
  {
    path: 'login', component: AuthComponent, canActivate: [NoAuthGuard],  children: [
      {path: '', component: LoginComponent},
    ],
    resolve: {
      background: BackgroundResolverService,
    }
  },
  {
    path: 'register', component: AuthComponent, canActivate: [NoAuthGuard], children: [
      {path: '', component: RegisterComponent},
      {path: 'confirm', component: RegistrationConfirmationComponent},
    ],
    resolve: {
      background: BackgroundResolverService,
    }
  },
  {
    path: 'reset-password', component: AuthComponent, canActivate: [NoAuthGuard], children: [
      {path: '', component: ResetPasswordComponent},
      {path: 'confirm', component: ResetConfirmationComponent},
    ],
    resolve: {
      background: BackgroundResolverService,
    }
  },
  {
    path: 'oauth2-registration', component: AuthComponent, children: [
      {path: 'complete', component: CompleteOauth2RegistrationComponent},
    ],
    canActivate: [AuthGuard],
    resolve: {
      background: BackgroundResolverService,
      currentUser: Oauth2UserResolverService
    }
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}
