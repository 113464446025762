import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2, Sanitizer,
  ViewChild
} from '@angular/core';
import {FileUpload} from 'primeng/fileupload';
import {FilesUploadEventInterface} from '../../interfaces/files-upload-event.interface';
import {RxFormBuilder, RxwebValidators} from '@rxweb/reactive-form-validators';
import {UntypedFormGroup} from '@angular/forms';
import {FileRemoveEventInterface} from '../../interfaces/file-remove-event.interface';
import {defer, forkJoin, merge, Observable, of, Subject} from 'rxjs';
import {MediaObjectApiModel} from '../../../api/models/media-object.api-model';
import {finalize, ignoreElements, map, mergeMap, tap} from 'rxjs/operators';
import {MediaObjectRestService} from '../../../api/services/media-object.rest.service';
import {SocialInputSubmitInterface} from './social-input-submit.interface';
import {MediaObjectPipe} from '../../../core/pipes/media-object.pipe';
import {FileRestService} from '../../../api/services/file.rest.service';
import {DomSanitizer} from '@angular/platform-browser';
import {MediaTypeEnum} from '../../../core/enums/media-type.enum';

@Component({
  selector: 'app-social-input',
  templateUrl: './social-input.component.html',
  styleUrls: ['./social-input.component.scss']
})
export class SocialInputComponent implements OnInit, AfterViewInit {
  get value(): string {
    return this._value;
  }

  @Input() set value(value: string) {
    this._value = value;
  }
  get files(): File[] {
    return this._files;
  }

  set files(value: File[]) {
    this._files = value;
  }
  get mediaProgress(): number {
    return this._mediaProgress;
  }

  @Input() set mediaProgress(value: number) {
    this._mediaProgress = value;
  }


  get chatInputWidth(): number {
    return this.chatInput.nativeElement.clientWidth - (0.75 * 16) - 135;
  }

  get chatPreviewWidth(): number {
    return this.preview.nativeElement.clientWidth - (0.75 * 16);
  }

  constructor(
    private readonly fb: RxFormBuilder,
    private readonly renderer: Renderer2,
    private readonly mediaObjectRS: MediaObjectRestService,
    private readonly frs: FileRestService,
    private readonly sanitizer: DomSanitizer
  ) {
  }

  private _value: string = null;
  @Input() public label: string;
  @Input() public loading = false;
  @Input() public disabled = false;
  private _files: File[] = [];
  @Output() public onSubmit: EventEmitter<SocialInputSubmitInterface> = new EventEmitter<SocialInputSubmitInterface>();
  @Output() public onMediaUpload: EventEmitter<FilesUploadEventInterface> = new EventEmitter<FilesUploadEventInterface>();
  @Output() public onMediaRemove: EventEmitter<SocialInputSubmitInterface> = new EventEmitter<SocialInputSubmitInterface>();
  @ViewChild('chatInput') chatInput: ElementRef;
  @ViewChild('preview') preview: ElementRef;
  @ViewChild(FileUpload) fileUpload: FileUpload;

  public windowWidth: number;
  public isMediaBoxHidden = true;
  public isFileUploadInitialized = false;
  public form: UntypedFormGroup;
  public chatEmojis: any[] = [
    '😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😇', '😉', '😊', '🙂', '🙃', '😋', '😌', '😍', '🥰', '😘', '😗', '😙', '😚', '🤪', '😜', '😝', '😛',
    '🤑', '😎', '🤓', '🧐', '🤠', '🥳', '🤗', '🤡', '😏', '😶', '😐', '😑', '😒', '🙄', '🤨', '🤔', '🤫', '🤭', '🤥', '😳', '😞', '😟', '😠', '😡', '🤬', '😔',
    '😟', '😠', '😡', '🤬', '😔', '😕', '🙁', '😬', '🥺', '😣', '😖', '😫', '😩', '🥱', '😤', '😮', '😱', '😨', '😰', '😯', '😦', '😧', '😢', '😥', '😪', '🤤'
  ];
  private isMultirow = false;
  @Input() public mediaObjectAMs: MediaObjectApiModel[] = [];
  private _mediaProgress: number;

  public readonly MediaTypeEnum = MediaTypeEnum;

  ngOnInit() {
    this.isMediaBoxHidden = !this._files?.length;
    this.windowWidth = window.innerWidth;
    this.form = this.fb.group(
      {
        content: [null, [
          RxwebValidators.required(),
          RxwebValidators.minLength({value: 1}),
          RxwebValidators.maxLength({value: 650000})
        ]],
      }
    );
    this.form.patchValue({content: this._value});
  }

  ngAfterViewInit(): void {
    this.chatInput.nativeElement.value = this._value;
    if (this.mediaObjectAMs.length) {
      this.convertUrlToFile(this.mediaObjectAMs.map(e => MediaObjectPipe.transformToLink(e)))
        .subscribe(() => {
          this.fileUpload.files = this.files;
          this.fileUpload.upload();
          this.openMediaBox();
        });
    }
    this.fileUpload.onSelect.subscribe(() => {
      this.openMediaBox();
    });
  }

  public openMediaBox(): void {
    this.isFileUploadInitialized = true;
    setTimeout(() => {
      const elements = document.querySelectorAll('.p-fileupload-row button');
      elements.forEach((e) => {
        this.renderer.addClass(e, 'p-button-text');
      });
      this.isMediaBoxHidden = false;
    }, 1);
  }

  public onEmojiClick(chatInput, emoji): void {
    if (chatInput) {
      chatInput.value += emoji;
      chatInput.focus();
    }
  }

  onInputSubmitClick(chatInput): void {
    if (this.form.valid) {
      this.loading = true;
      this.disabled = true;
      this.addMedia(this._files).subscribe((mediaObjectAMs: MediaObjectApiModel[]) => {
        this.mediaObjectAMs = [...this.mediaObjectAMs, ...mediaObjectAMs];
        this.closeMediaLoadProcess();
        this.onSubmit.emit({mediaObjectAMs: this.mediaObjectAMs, content: chatInput.value, input: this});
      });
    }
  }

  onKeydown(event, chatInput): void {
    this.form.patchValue({content: chatInput.value});
    if (window.innerWidth > 768) {
      if (event.keyCode === 13 && !event.shiftKey) {
        this.onInputSubmitClick(chatInput);
        event.preventDefault();
      }
    }
  }

  onTextareaResize(): void {
    if (!this.isMultirow && this.chatPreviewWidth > this.chatInputWidth) {
      this.renderer.addClass(this.chatInput.nativeElement, 'input-multi-row');
      this.renderer.removeClass(this.chatInput.nativeElement, 'input-single-row');
      this.isMultirow = true;
    }

    if (this.isMultirow && this.chatPreviewWidth < this.chatInputWidth) {
      this.renderer.removeClass(this.chatInput.nativeElement, 'input-multi-row');
      this.renderer.addClass(this.chatInput.nativeElement, 'input-single-row');
      this.isMultirow = false;
    }
  }

  public onMediaBoxOpen(): void {
    this.fileUpload.choose();
  }

  public onFileSelect(event: FilesUploadEventInterface): void {
    event.files.forEach((file: File) => {
      if (!this.files.includes(file)) {
        this.files.push(file);
      }
    });
    console.log(this.files);

    this.onMediaUpload.emit(event);
  }

  public closeMediaLoadProcess(): void {
    this.mediaProgress = null;
  }

  onFileRemove(event: FileRemoveEventInterface): void {
    console.log(event);
    this.removeMedia(event).subscribe((mediaObjectAM: MediaObjectApiModel) => {
      this.mediaObjectAMs = this.mediaObjectAMs.filter(e => e['@id'] !== mediaObjectAM['@id']);
      this.files = this.files.filter(e => e.name !== event.file.name);
      this.closeMediaLoadProcess();
      this.onMediaRemove.emit({mediaObjectAMs: this.mediaObjectAMs, content: this.chatInput.nativeElement.value});
      this.disabled = false;
    });
  }

  addMedia(files: File[]): Observable<any> {
    this.disabled = true;
    const requests: Observable<MediaObjectApiModel>[] = [];
    files.forEach((file: File) => {
      if (!this.mediaObjectAMs.find((e: MediaObjectApiModel) => {
        return e.name === file.name;
      })) {
        requests.push(this.postMediaObject(file));
      }
    });

    if (!requests.length) {
      return of([]);
    }

    return defer(() => {
      let counter = 0;
      const percent$ = new Subject();

      const modifiedObservablesList = requests.map(
        (item, index) => item.pipe(
          finalize(() => {
            const percentValue = ++counter * 100 / requests.length;
            percent$.next(percentValue);
          })
        )
      );

      const finalResult$ = forkJoin(modifiedObservablesList).pipe(
        tap(() => {
            percent$.next(100);
            percent$.complete();
          }
        ));

      return of([finalResult$, percent$.asObservable()]);
    }).pipe(
      mergeMap(([finalResult, progress]) => merge(
        progress.pipe(
          tap((value: number) => this.mediaProgress = value),
          ignoreElements()
        ),
        finalResult
      ))
    );
  }

  removeMedia(event: FileRemoveEventInterface): Observable<any> {
    this.disabled = true;
    const mediaObjectAM = this.mediaObjectAMs.find((mediaObjectAM: MediaObjectApiModel) => mediaObjectAM.originalName === event.file.name || mediaObjectAM.name === event.file.name);
    if (!mediaObjectAM) {
      return  of<MediaObjectApiModel>(new MediaObjectApiModel());
    }
    return this.deleteMediaObject(mediaObjectAM);
  }

  public postMediaObject(file): Observable<MediaObjectApiModel> {
    switch (this.getTypeByMimeTypes(file)) {
      case MediaTypeEnum.IMAGE: return this.mediaObjectRS.uploadImage(file);
      case MediaTypeEnum.VIDEO: return this.mediaObjectRS.uploadVideo(file);
      default: return this.mediaObjectRS.uploadImage(file);
    }
  }

  public deleteMediaObject(mediaObjectAM: MediaObjectApiModel): Observable<MediaObjectApiModel> {
    return this.mediaObjectRS.deleteMediaObject(mediaObjectAM['@id']).pipe(map(() => mediaObjectAM));
  }

  public reset(): void {
    this.chatInput.nativeElement.value = '';
    this.fileUpload.clear();
    this.isMediaBoxHidden = true;
    this.loading = false;
    this.disabled = false;
    this.mediaObjectAMs = [];
    this.files = [];
  }

  convertUrlToFile(fileUrls: string[]): Observable<File[]> {
    const requests: Observable<File>[] = [];
    for (const url of fileUrls) {
      // arr.push(new File([await (await fetch(url)).blob()], url, {type: 'image/jpeg'}));
      requests.push(this.frs.getImage(url).pipe(tap(file => this.files.push(file))));
    }

    return forkJoin(requests);
  }

  log(i) {
    console.log(i);
  }

  public getTypeByMimeTypes(file: File): string  {
    return file.type.includes(MediaTypeEnum.IMAGE.toLowerCase()) ? MediaTypeEnum.IMAGE :
      file.type.includes(MediaTypeEnum.VIDEO.toLowerCase()) ? MediaTypeEnum.VIDEO : null;
  }

  togglePlay(video: any): void {
    if (video.paused) {
        video.play();
    } else {
        video.pause();
    }
  }

  removeFile(file: File, event: Event) {
    const index = this.fileUpload.files.indexOf(file);
    this.fileUpload.remove(event, index);
  }
}
