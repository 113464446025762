import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ITEM_TYPE, OnRequestAppend} from '@egjs/infinitegrid';
import {NgxFrameInfiniteGridComponent} from '@egjs/ngx-infinitegrid/lib/grids/ngx-frame-infinitegrid.component';
import {SwiperComponent} from 'swiper/angular';
import {PersonalInformationViewModel} from '../../../features/user/settings/personal-information/personal-information-view-model';
import {UserViewModel} from '../../../features/user/settings/user.view-model';
import {SwiperOptions} from 'swiper/types';
import {ActivatedRoute, Router} from '@angular/router';
import Swiper, {Controller, FreeMode, Keyboard, Mousewheel} from 'swiper';
import {SwiperItemInterface, SwiperItemsInterface} from './swiper-items.interface';
import {SocialListTypeEnum} from './social-list-type.enum';
import {SocialDataProviderService} from './social-data-provider.service';
import {KeyValue} from '@angular/common';
import {SocialGridItemInterface} from './social-grid-item.interface';
import {tap} from 'rxjs/operators';
import {PostViewModel} from '../post.view-model';
import {EventApiModel} from '../../../api/models/event.api.model';

Swiper.use([FreeMode, Keyboard, Mousewheel, Controller]);

@Component({
  selector: 'app-social-grid-list',
  templateUrl: './social-grid-list.component.html',
  styleUrls: ['./social-grid-list.component.scss'],
  providers: [
    SocialDataProviderService
  ]
})
export class SocialGridListComponent implements OnInit, AfterViewInit {

  constructor(
    private readonly route: ActivatedRoute
  ) { }


  @Input() public listType: SocialListTypeEnum;
  @Input() public user: UserViewModel;
  @Input() public event: EventApiModel;
  public subjectType: string;
  public config: SwiperOptions;
  public childConfig: SwiperOptions;
  @ViewChild('mainSwiper') public mainSwiper: SwiperComponent;
  @ViewChild('slaveSwiper') public slaveSwiper: SwiperComponent;
  public currentItem: SwiperItemInterface;
  private swiperIndex: number;


  @Input() public slides: SwiperItemsInterface;

  ngOnInit(): void {
    this.config = {
      slidesPerView: 3,
      autoHeight: true,
      freeMode: {
        enabled: true,
        sticky: true,
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
    };
    this.childConfig = {
      slidesPerView: 1,
      autoHeight: true,
      freeMode: {
        enabled: true,
        sticky: true,
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
    };
    // this.getPlaceHolders();
    this.currentItem = this.slides[Object.keys(this.slides)[0]];
    this.swiperIndex = 0;
    // this.getCollection(this.swiperIndex, this.currentItem, 1);
  }

  ngAfterViewInit(): void {
    // this.controlChildSwiper();
    // setTimeout(() => {
    //   this.updateSlaveSwiper(0);
    // }, 50);
  }

  public controlChildSwiper(): void {
    this.config.controller = {
      control: this.slaveSwiper.swiperRef
    };
    this.mainSwiper.config = this.config;
  }

  setActiveSlide(swiper: Swiper[]) {
    this.mainSwiper.swiperRef.activeIndex = swiper[0].activeIndex;
    this.mainSwiper.swiperRef.updateSlidesClasses();
  }

  setActiveSlaveSlide(index: number, item: SwiperItemInterface, swiper: SwiperComponent, event: Event): void {
    this.currentItem = item;
    this.swiperIndex = index;
    this.updateSlaveSwiper(index);
    // this.getCollection(index, item, this.page);
    item.command(index, swiper, event);
  }

  updateSlaveSwiper(index: number): void {
    this.slaveSwiper.swiperRef.slideTo(index);
    this.mainSwiper.swiperRef.activeIndex = index;
    this.mainSwiper.swiperRef.updateSlidesClasses();
    this.slaveSwiper.swiperRef.updateSlidesClasses();
  }

  originalOrder(a: KeyValue<string, SwiperItemInterface>, b: KeyValue<string, SwiperItemInterface>): number {
    return (a.value.order || 0) > (b.value.order || 0) ? 1 : (b.value.order > a.value.order ? -1 : 0) ;
  }
}
