import {Pipe, PipeTransform} from '@angular/core';
import {MediaObjectViewModel} from '../models/media-object.view-model';
import {MediaObjectInterface} from '../interfaces/media-object.interface';
import {MediaTypeEnum} from '../enums/media-type.enum';

export interface MediaObjectOptionsInterface {
  formatType?: 'thumbnail' | 'original' | 'timeline' | 'm3u8' | 'm3u8_timeline';
  defaultImageType?: 'background' | 'avatar' | 'car' | 'loading';
}

@Pipe({
  name: 'mediaObject',
})
export class MediaObjectPipe implements PipeTransform {

  constructor() {
  }

  static transformToLink(value: MediaObjectInterface, options: MediaObjectOptionsInterface = {}): string {
    let defaultImage: string;
    switch (options.defaultImageType) {
      case 'background':
        defaultImage = 'assets/img/profile-bg-dark.png';
        break;
      case 'car':
        defaultImage = 'assets/img/car.jpg';
        break;
      case 'loading':
        defaultImage = null;
        break;
      case 'avatar':
      default:
        defaultImage = 'assets/img/avatar.png';
    }

    if (value?.type === MediaTypeEnum.VIDEO) {
      const uri = value.hyperlinks.find(e => e.name === (options.formatType || 'original'))?.uri;
      return uri || defaultImage;
    }

    if (value && value.hyperlinks.length) {
      const uri = value.hyperlinks.find(e => e.name === (options.formatType || 'thumbnail'))?.uri;
      return uri || defaultImage;
    }
    return defaultImage;
  }

  transform(value: MediaObjectInterface, options: MediaObjectOptionsInterface = {}): string {
    return MediaObjectPipe.transformToLink(value, options);
  }
}
