<p-virtualScroller *ngIf="parentModalRendered" [value]="reactions | async" [scrollHeight]="viewScrollerHeight + 'px'" [itemSize]="85"
                   [lazy]="true">
  <ng-template let-reaction pTemplate="item">
    <div class="grid grid-nogutter flex justify-content-start align-items-center width-100" style="gap: 10px">
      <div class="col-fixed">
        <app-thumbnail (click)="navigateTo(reaction, 'profile')"
                       [imgSrc]="reaction.author.avatar | mediaObject"
                       [circle]="true"
                       size="sm"
        >
        </app-thumbnail>
      </div>
      <div class="col-6" style="min-width: 200px">
        <div>
          <a (click)="navigateTo(reaction, 'profile')">{{reaction.author.username}}</a>
        </div>
        <span *ngIf="reaction|projectUserCount: currentUser as projectUserCount">
            <ng-container *ngIf="projectUserCount.hasUser || projectUserCount.projectCount">
              {{ 'common.reactions.user_projects'|translate: {
              user: projectUserCount.hasUser ? (projectUserCount.userTranslateParam|lowercase) : '',
              project: projectUserCount.projectTranslateParam|translateSelector: projectUserCount.projectCount,
              and: projectUserCount.hasUser && projectUserCount.projectCount ? ('common.and'|translate) : ''
            }
              }}</ng-container>
          </span>
        <div
          *ngIf="reaction.reactedTo.length > 1"
          class="width-100">
          <swiper
            [config]="config"
          >
            <ng-template swiperSlide *ngFor="let reacted of reaction.reactedTo|filterCollectionByType: 'Project'; let last = last; let i = index">
              <div>
                <app-thumbnail
                  [imgSrc]="reacted.avatar | mediaObject"
                  [circle]="true"
                  size="xs"
                >
                </app-thumbnail>
              </div>
            </ng-template>
          </swiper>
        </div>

        <div *ngIf="reaction.reactedTo.length === 1" class="flex align-items-center">
          <ngx-emoji [emoji]="reaction.reactedWith[0]|reactionClassMap" size="20"></ngx-emoji><span class="ml-2">{{('common.reaction.' + reaction.reactedWith[0].toLowerCase())|translate|translateSelector: reaction.author['@id'] !== currentUser['@id'] ? 'c': 'o'}}</span>
        </div>
      </div>
      <div class="col-fixed flex ml-auto gap-2 relative w-3rem h-3rem">
        <app-item-action-dial
          [isFollowedByCurrentUser]="socialDPS.isFollowedByCurrentUser(reaction.author)"
          [isReactedByCurrentUser]="socialDPS.isLikedByCurrentUser(reaction.author)"
          [subjectUserVM]="reaction.author"
          [currentUserVM]="currentUser"
        ></app-item-action-dial>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="loadingItem">
          <div class="flex">
            <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
            <div style="flex: 1">
              <p-skeleton width="100%" height="1.5rem" borderRadius="16px" styleClass="mb-2 mt-1"></p-skeleton>
              <p-skeleton width="75%" height="1.5rem" borderRadius="16px"></p-skeleton>
            </div>
          </div>
  </ng-template>
</p-virtualScroller>
