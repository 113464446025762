import {Exclude, Expose} from "class-transformer";

@Exclude()
export class AuthApiModel {
  @Expose()
  public message: string;
  @Expose()
  public token: string;
  @Expose()
  public refreshToken: string
}
