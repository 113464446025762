<div class="grid infinite justify-content-center grid-nogutter mt-0 relative">
  <div
    NgxFrameInfiniteGrid
    [items]="items"
    [gap]="0"
    [autoResize]="true"
    [frame]="[[1,2,3],[4,5,6],[7,8,9]]"
    [useResizeObserver]="true"
    [observeChildren]="true"
    [useLoading]="true"
    #ig
    (requestAppend)="onRequestAppend($event)"
    class="container timeline-container m-0 p-0 mb-3">
    <div *ngFor="let item of ig.visibleItems; let i = index" class="item item-padding" [id]="'item' + item.data.id">
      <ng-container *ngIf="item.type === ITEM_TYPE.NORMAL">
        <p-skeleton *ngIf="item.data.isPlaceholder" width="100%" height="100%"></p-skeleton>
        <div (click)="navigateTo(item.data)" *ngIf="!item.data.isPlaceholder" class="relative height-100 width-100">
          <div style="position: absolute" class="flex justify-content-center align-items-center mask height-100 width-100">
            <div class="text-center" [innerHTML]="item.data.name"></div>
          </div>
          <img alt="media thumbnail" class="media-thumbnail" [src]="item.data.thumbnail | mediaObject: {defaultImageType: getDefaultThumbnailByType(slide.key)}" width="100%"/>
        </div>
      </ng-container>
      <div class="width-100 flex justify-content-center mb-5" *ngIf="item.type !== ITEM_TYPE.NORMAL">
        <p-progressSpinner styleClass="w-2rem h-2rem" strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s">
        </p-progressSpinner>
      </div>
    </div>
  </div>
  <div *ngIf="!items.length && noResultsMsg">
    <p>{{noResultsMsg}}</p>
  </div>
</div>
