import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {StatusApiModel} from "../models/status.api.model";
import {ResultListApiModel} from "../models/result-list.api.model";
import { HttpClient, HttpResponse } from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {plainToClass, plainToClassFromExist} from "class-transformer";
import {QueryParamsApiModel} from "../models/query-params-api.model";
import {QueryParamsService} from "../../core/services/query-params.service";
import {SearchLogApiModel} from "../models/search-log.api-model";
import {PostApiModel} from "../models/post-api.model";

@Injectable()
export class SearchLogRestService {
  private readonly resource: string = '/search_logs';
  public statuses: Array<StatusApiModel>;

  constructor(
    private http: HttpClient,
    private params: QueryParamsService
  ) {
  }

  public create(projectAM: SearchLogApiModel): Observable<SearchLogApiModel> {
    return this.http.post<SearchLogApiModel>(environment.apiUrl + this.resource, projectAM)
      .pipe(map((result: SearchLogApiModel) => plainToClass(SearchLogApiModel, result)))
  }

  public getCollection(queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<ResultListApiModel<SearchLogApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<SearchLogApiModel>>(environment.apiUrl + this.resource, {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<SearchLogApiModel>(SearchLogApiModel), result as object)));
  }

  public delete(searchLogAM: SearchLogApiModel): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(environment.apiUrl + searchLogAM['@id']);
  }
}
