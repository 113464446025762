import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PipesModule} from '../../core/pipes/pipes.module';
import {GalleriaModule} from 'primeng/galleria';
import {MediaComponent} from './component/media.component';
import {MediaRebrandedComponent} from './component/media-rebranded.component';
import {MediaGridComponent} from './media-grid/media-grid.component';
import {SidebarModule} from '../sidebar/sidebar.module';
import {TimelineModule} from '../timeline/timeline.module';
import {SlideMenuModule} from 'primeng/slidemenu';
import {TranslateModule} from '@ngx-translate/core';
import {ButtonModule} from 'primeng/button';
import {NgxPermissionsRestrictStubModule} from 'ngx-permissions';
import {PushPipe} from '@ngrx/component';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    GalleriaModule,
    SidebarModule,
    TimelineModule,
    SlideMenuModule,
    TranslateModule,
    ButtonModule,
    NgxPermissionsRestrictStubModule,
    PushPipe,
  ],
  declarations: [ MediaComponent, MediaRebrandedComponent, MediaGridComponent],
    exports: [MediaGridComponent]
})
export class MediaModule {
}
