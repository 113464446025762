import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ModalInterface} from '../../modal.interface';
import {concat, EMPTY, Observable, of, Subject} from 'rxjs';
import {ModalStateEvent} from '../../modal-state-event';
import {TokenDataProviderService} from '../../../../core/services/token-data-provider.service';
import {CurrentUserViewModel} from '../../../../core/models/current-user.view-model';
import {Store} from '@ngrx/store';
import {delay, map, mergeMap} from 'rxjs/operators';
import * as fromParticipant from '../../../../state/participant/participant.reducer';
import {AppState} from '../../../../state/app.state';
import {FollowApiModel} from '../../../../api/models/follow.api-model';
import {UserApiModel} from '../../../../api/models/user.api-model';
import {MediaObjectViewModel} from '../../../../core/models/media-object.view-model';
import {QueryParamsApiModel} from '../../../../api/models/query-params-api.model';
import {FilterParameterApiModel} from '../../../../api/models/filter-parameter.api.model';
import {UserRestService} from '../../../../api/services/user.rest.service';
import {ResultListApiModel} from '../../../../api/models/result-list.api.model';
import {UserDataProviderService} from '../../../../features/user/settings/user.data-provider.service';
import {MediaObjectApiModel} from '../../../../api/models/media-object.api-model';
import {MediaObjectRestService} from '../../../../api/services/media-object.rest.service';
import {Router} from '@angular/router';
import {ModalState} from '../../modal-state.enum';
import {ModalService} from '../../modal.service';
import {LoaderService} from '../../../../core/services/loader.service';
import {ParticipantApiModel} from "../../../../api/models/participant.api.model";
import {FollowerListItemInterface} from "../../../../core/interfaces/follower-item.interface";
import {ParticipantActionTypes} from "../../../../state/participant/participant.actions";


@Component({
  selector: 'app-participation-list',
  templateUrl: './participation-list.component.html',
  styleUrls: ['./participation-list.component.scss'],
  providers: [UserDataProviderService]
})
export class ParticipationListComponent implements OnInit, ModalInterface, OnDestroy {

  public currentUser: CurrentUserViewModel;
  public observeState: string;

  @Input() data: {participantAMs: ParticipantApiModel[], state: ('TAKES_PART' | 'INTERESTED' | 'ORGANIZES')[]};
  public state: Observable<ModalStateEvent>;
  public subject: Subject<ModalStateEvent>;
  private _participants: Observable<ParticipantApiModel[]>;

  get participants(): Observable<ParticipantApiModel[]> {
    return this._participants;
  }

  constructor(private tokenDPS: TokenDataProviderService,
              private userRS: UserRestService,
              private mediaRS: MediaObjectRestService,
              private store: Store<AppState>,
              private router: Router,
              private ms: ModalService,
              private ls: LoaderService
  ) {
  }

  ngOnInit() {
    this.tokenDPS.currentUser.subscribe((currentUser: CurrentUserViewModel) => this.currentUser = currentUser);
    const participants = this.store.select(state => fromParticipant.selectAll(state.participants)).pipe(delay(500), mergeMap((participantAMs: ParticipantApiModel[]) => {
      // const params = new QueryParamsApiModel();
      // const mediaIds = participantAMs.map(e => {
      //   const data = e.data as UserApiModel;
      //   return data.avatar;
      // });
      // for (const i of mediaIds) {
      //   params.filters.push(new FilterParameterApiModel('id', i));
      // }
      //
      // if (params.filters.length) {
      //   return this.mediaRS.getMediaObjectCollection(params).pipe(map((resultListAM: ResultListApiModel<MediaObjectApiModel>) => {
      //     return participantAMs.map((i: ParticipantApiModel) => {
      //       const avatar = resultListAM.records.find(e => {
      //         e['@id'] === i['data']['avatar'];
      //       });
      //       i = Object.assign({...i, data: {...i.data as UserApiModel, avatar: new MediaObjectViewModel(avatar)}});
      //       return i;
      //     });
      //   }));
      // }
      return of(participantAMs.filter(e => this.data.state.includes(e.state)));
    }));

    this._participants = concat(of<ParticipantApiModel[]>(Array.from({length: 6}) as ParticipantApiModel[]), participants);
  }

  postFollowing(following: FollowerListItemInterface) {
    this.observeState = 'pending';
    this.store.dispatch({type: ParticipantActionTypes.ADD_ONE, following});
  }

  removeFollowing(following: FollowerListItemInterface) {

  }

  navigateToProfile(participant: ParticipantApiModel): void {
    this.ls.show();
    this.router.navigate(['/', participant.data['slug'], 'profile']).then(() => {
      this.subject.next(new ModalStateEvent(ModalState.SUCCESS));
    });
  }

  ngOnDestroy(): void {
  }
}
