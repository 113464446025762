import {Injectable} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {map} from 'rxjs/operators';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {AuthApiModel} from "../models/auth.api.model";
import {plainToClass} from "class-transformer";
import {MessageApiModel} from "../models/message.api.model";
import {environment} from "../../../environments/environment";
import {Oauth2UserApiModel} from "../models/oauth2-user.api.model";

@Injectable()
export class RegistrationRestService {
  private readonly resource: string = '/users';

  constructor(private http: HttpClient) {
  }

  register(user: UntypedFormGroup): Observable<AuthApiModel> {
    return this.http.post<AuthApiModel>(`${environment.apiUrl}${this.resource}/register`, user.value)
      .pipe(map((response: AuthApiModel) => plainToClass(AuthApiModel, response)));
  }

  registrationConfirm(token: string): Observable<MessageApiModel> {
    return this.http.post<MessageApiModel>(`${environment.apiUrl}${this.resource}/registration-confirm`, {token: token})
      .pipe(map((response: MessageApiModel) => {
        return plainToClass(MessageApiModel, response as Object);
      }));
  }

  completeOauth2Registration(oauth2UserAM: Oauth2UserApiModel): Observable<AuthApiModel> {
    return this.http.post<AuthApiModel>(`${environment.apiUrl}${this.resource}/complete-oauth2-registration`, oauth2UserAM)
      .pipe(map((response: AuthApiModel) => plainToClass(AuthApiModel, response)));
  }
}
