import {Injectable} from '@angular/core';
import {NotificationRestService} from "../../api/services/notification.rest.service";
import {Observable} from "rxjs";
import {NotificationInterface} from "../../shared/interfaces/notification.interface";
import {map, tap} from "rxjs/operators";
import {ResultListApiModel} from "../../api/models/result-list.api.model";
import {CurrentUserViewModel} from "../models/current-user.view-model";
import {NotificationApiModel} from "../../api/models/notification.api-model";
import {QueryParamsApiModel} from "../../api/models/query-params-api.model";
import {EventSourceService} from "./event-source.service";
import {EventSourceInterface} from "../interfaces/event-source.interface";



@Injectable({
  providedIn: 'root'
})
export class NotificationDataProviderService {

  public notifications: ResultListApiModel<NotificationInterface> = new ResultListApiModel<NotificationInterface>(NotificationApiModel);

  constructor(private readonly notificationRS: NotificationRestService, private readonly eventSourceService: EventSourceService) {
  }


  public getCollection(page: number = 1): Observable<ResultListApiModel<NotificationInterface>> {
    const queryParams = new QueryParamsApiModel();
    queryParams.page = page
    return this.notificationRS.getCollection(queryParams).pipe(map((resultListAM:ResultListApiModel<NotificationInterface>) => {
      this.notifications = resultListAM;
      return this.notifications;
    }));
  }

  public massUpdateReadAt(notifications: NotificationInterface[]): Observable<ResultListApiModel<NotificationInterface>> {
    return this.notificationRS.massUpdateReadAt({notifications: notifications.map(e => e.id), readAt: true}).pipe(map((resultListAM:ResultListApiModel<NotificationInterface>) => {
      this.notifications = resultListAM;
      return this.notifications;
    }));
  }

  public setEventSource(user: CurrentUserViewModel): Observable<EventSourceInterface<NotificationInterface>> {
    return this.eventSourceService.listenSingleTopic<NotificationInterface>(`/users/${user.id}/notifications`).pipe(tap((es: EventSourceInterface<NotificationInterface>) => {
        this.notifications.total++;
    }));

  }
}
