import {Injectable} from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class RequestHttpInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!(req.url.endsWith('.json') || req.responseType === 'blob')) {
      // add content-type header
      if (!(req.serializeBody() instanceof FormData)) {
        req = req.clone({headers: req.headers.set('Content-Type', 'application/json')});
      }

      req = req.clone({headers: req.headers.set('Accept', 'application/ld+json; application/json')});
      // fixup for duplicated /api (hydra always contains /api prefix,and in env also /api is used)
      if (req.url.match(/\/api/g)?.length > 1) {
        const url = req.url.replace('/api', '');
        req = req.clone({url: url});

      }
    }

    if (req.responseType === 'blob') {
      req = req.clone({headers: req.headers.set('Accept', 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng').set('Content-Type', 'image/jpeg')})
    }

    return next.handle(req);
  }
}
