<div [formGroup]="form" class="widget-chat">
  <div class="p-inputgroup write-message">
    <textarea
      #chatInput
      type="text"
      pInputTextarea
      [autoResize]="true"
      [rows]="1"
      [cols]="10"
      class="input-single-row"
      [placeholder]="label"
      (keydown)="onKeydown($event, chatInput)"
      (onResize)="onTextareaResize()"
    ></textarea>

    <div class="input-action-box input-action-box-flex">
      <button pButton pRipple type="button" icon="pi pi-clock" (click)="op.toggle($event)"
              class="p-button-text rounded-circle p-button-plain"></button>
      <button pButton pRipple type="button" icon="pi pi-image" (click)="onMediaBoxOpen()"
              class="p-button-text rounded-circle p-button-plain"></button>
      <button pButton pRipple type="button" icon="pi pi-send" (click)="onInputSubmitClick(chatInput)"
              [loading]="loading" [disabled]="form.invalid || loading || disabled"
              class="p-button-text rounded-circle p-button-plain"></button>
      <p-overlayPanel #op styleClass="emoji">
        <ng-template pTemplate style="width: 600px">
          <button *ngFor="let emoji of chatEmojis;" pButton pRipple
                  (click)="op.hide(); onEmojiClick(chatInput, emoji)" type="button" [label]="emoji"
                  class="emoji-button p-2 p-button-text p-button-plain"></button>
        </ng-template>
      </p-overlayPanel>
    </div>
    <div #preview
         style="position: absolute; z-index: -1; padding-left: 0.75rem; top: 0; max-width: 100%; overflow: hidden">{{ form.get('content').value }}
    </div>
  </div>
  <div *ngIf="form.get('content').invalid && form.get('content').dirty" class="app-form-error">
    <p>{{ form.get('content')['errorMessage'] }}</p>
    <p *ngIf="form.get('content').errors?.custom">{{ form.get('content').errors?.custom }}</p>
  </div>
  <div *ngIf="disabled" class="pt-3">
    <p-progressBar [style]="{'height': '6px'}" [showValue]="false" [value]="mediaProgress"
                   [mode]="mediaProgress ? 'determinate' : 'indeterminate' "></p-progressBar>
  </div>
  <div class="pt-3" [hidden]="isMediaBoxHidden">
    <p-fileUpload
      [styleClass]="'chat-upload'"
      customUpload="true"
      [previewWidth]="128"
      [multiple]="true"
      [auto]="true"
      (uploadHandler)="onFileSelect($event)"
      (onRemove)="onFileRemove($event)"
      accept="image/.png,.jpg,.jpeg,.mp3,.mkv,.mp4"
    >
      <ng-template let-file pTemplate="file">
        <div class="p-fileupload-row ng-star-inserted">
          <div>
            <img alt="post image attachment" [src]="file|sanitizeUrl" *ngIf="file.type.includes(MediaTypeEnum.IMAGE.toLowerCase())">
            <video #videoElement [src]="file|sanitizeUrl" *ngIf="file.type.includes(MediaTypeEnum.VIDEO.toLowerCase())" height="150" (click)="togglePlay(videoElement)"></video>
          </div>
          <div class="p-fileupload-filename">17130_797764183634824_2288942896205325782_n.jpg</div>
          <div>50.981 KB</div>
          <div>
            <button (click)="removeFile(file, $event)" type="button" icon="pi pi-times" pButton class="p-element p-button p-component p-button-icon-only p-button-text"></button>
          </div>
        </div>
      </ng-template>
    </p-fileUpload>
  </div>
</div>
