import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HashtagsComponent } from './hashtags.component';
import {AutoCompleteModule} from "primeng/autocomplete";
import {ChipsModule} from "primeng/chips";
import {EndCapModule} from "../end-cap/end-cap.module";
import {FocusTrapModule} from "primeng/focustrap";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule} from "@angular/forms";
import {ChipModule} from "primeng/chip";
import { HashtagsChipsComponent } from './hashtags-chips/hashtags-chips.component';
import {TagModule} from "primeng/tag";



@NgModule({
  declarations: [
    HashtagsComponent,
    HashtagsChipsComponent,
  ],
  exports: [
    HashtagsComponent,
    HashtagsChipsComponent
  ],
  imports: [
    CommonModule,
    AutoCompleteModule,
    ChipsModule,
    EndCapModule,
    FocusTrapModule,
    TranslateModule,
    FormsModule,
    ChipModule,
    TagModule
  ]
})
export class HashtagsModule { }
