import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {Sidebar} from "primeng/sidebar";

@Component({
  selector: 'app-bottom-sidebar',
  templateUrl: './bottom-sidebar.component.html',
  styleUrls: ['./bottom-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BottomSidebarComponent implements OnInit {

  @Input() public isSidebarOpened: boolean = false;
  @Input() public isBackButtonVisible: boolean = false;
  @Output() public onHide: EventEmitter<void> = new EventEmitter<void>();
  @Output() public onGoBack: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild(Sidebar) private sidebar: Sidebar;

  constructor() { }

  ngOnInit(): void {
  }

  public hideSidebar(event): void {
    this.sidebar.close(event);
    this.onHide.emit();
  }

  goBack(): void {
    this.onGoBack.emit();
  }
}
