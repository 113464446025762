import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ITEM_TYPE, OnRequestAppend} from '@egjs/infinitegrid';
import {NgxFrameInfiniteGridComponent} from '@egjs/ngx-infinitegrid/lib/grids/ngx-frame-infinitegrid.component';
import {SocialDataProviderService} from '../social-grid-list/social-data-provider.service';
import {tap} from 'rxjs/operators';
import {SocialGridItemInterface} from '../social-grid-list/social-grid-item.interface';
import {SwiperItemInterface} from '../social-grid-list/swiper-items.interface';
import {SocialGridListComponent} from '../social-grid-list/social-grid-list.component';
import {KeyValue} from '@angular/common';
import {Router} from '@angular/router';

@Component({
  selector: 'app-social-timeline',
  templateUrl: './social-timeline.component.html',
  styleUrls: ['./social-timeline.component.scss']
})
export class SocialTimelineComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    public readonly parentComponent: SocialGridListComponent,
    private readonly socialDP: SocialDataProviderService,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef
  ) { }

  public readonly ITEM_TYPE = ITEM_TYPE;
  public page = 2;
  private endOfResults = false;
  public totalItems: {[key: string]: number} = {projects: 0, users: 0, events: 0, interested: 0, participate: 0, participated: 0};
  public items: SocialGridItemInterface[];
  @Input() public slide: KeyValue<string, SwiperItemInterface>;
  @Input() public noResultsMsg: string;
  @ViewChild('ig') public ig: NgxFrameInfiniteGridComponent;

  ngOnInit(): void {
    this.getPlaceHolders();
    this.getCollection(1);
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.items = [];
    this.page = 2;
    this.endOfResults = false;
    delete this.ig;
  }

  onRequestAppend(event: OnRequestAppend): void {
    if (this.endOfResults || (this.totalItems[this.slide.key] && this.items.length >= this.totalItems[this.slide.key])) {
      return;
    }

    event.wait();

    this.socialDP.getCollection(this.parentComponent.listType, this.slide, this.parentComponent.user.toApiModel(), this.parentComponent.event, this.page)
      .pipe(tap(() => this.totalItems[this.slide.key] = this.socialDP.totalItems[this.slide.key]))
      .subscribe((items: SocialGridItemInterface[]) => {
        console.log(items);

        if (!items.length) {
          this.endOfResults = true;
        }

        const oldItems = this.items.filter(e => e.isPlaceholder === false);
        this.items = [...oldItems, ...items];

        this.page++;
        this.ig?.updateItems();

        event.ready();
    });
  }

  private getPlaceHolders(): void {
    this.items = [
      {isPlaceholder: true, thumbnail: {hyperlinks: [{name: 'thumbnail', uri: ''}]}},
      {isPlaceholder: true, thumbnail: {hyperlinks: [{name: 'thumbnail', uri: ''}]}},
      {isPlaceholder: true, thumbnail: {hyperlinks: [{name: 'thumbnail', uri: ''}]}},
      {isPlaceholder: true, thumbnail: {hyperlinks: [{name: 'thumbnail', uri: ''}]}},
      {isPlaceholder: true, thumbnail: {hyperlinks: [{name: 'thumbnail', uri: ''}]}},
      {isPlaceholder: true, thumbnail: {hyperlinks: [{name: 'thumbnail', uri: ''}]}},
    ];
  }

  public getDefaultThumbnailByType(type: string): 'background'|'car'|'avatar' {
    switch (type) {
      case 'events': return  'background';
      case 'projects': return  'car';
      case 'users': return  'avatar';
      default: return 'background';
    }
  }

  private getCollection(page: number) {
    this.socialDP.getCollection(this.parentComponent.listType, this.slide, this.parentComponent.user.toApiModel(), this.parentComponent.event, page)
      .pipe(tap(() => this.totalItems[this.slide.key] = this.socialDP.totalItems[this.slide.key]))
      .subscribe((socialGridItems: SocialGridItemInterface[]) => {
        this.items = [];
        this.items = socialGridItems;
        this.ig.renderItems();
    });
  }

  navigateTo(data: SocialGridItemInterface): void {
    if (!data.previewUrl) {
      return;
    }

    this.router.navigate([data.previewUrl]);
  }
}
