import { Injectable } from '@angular/core';
import {map, mergeMap} from 'rxjs/operators';
import {ResultListApiModel} from '../../api/models/result-list.api.model';
import {ProjectApiModel} from '../../api/models/project.api-model';
import {forkJoin, Observable, of} from 'rxjs';
import {VehicleViewModel} from '../user/garage/vehicle.view-model';
import {MediaObjectViewModel} from '../../core/models/media-object.view-model';
import {ProjectViewModel} from '../user/garage/project.view-model';
import {QueryParamsApiModel} from '../../api/models/query-params-api.model';
import {FilterParameterApiModel} from '../../api/models/filter-parameter.api.model';
import {MediaObjectApiModel} from '../../api/models/media-object.api-model';
import {VehicleTypeViewModel} from '../../core/models/vehicle-type.view-model';
import {VehicleTypeApiModel} from '../../api/models/vehicle-type.api-model';
import {ProjectRestService} from '../../api/services/project.rest.service';
import {VehicleRestService} from '../../api/services/vehicle.rest.service';
import {VehicleTypeRestService} from '../../api/services/vehicle-type.rest.service';
import {MediaObjectRestService} from '../../api/services/media-object.rest.service';
import {VehicleApiModel} from '../../api/models/vehicle.api-model';
import {LoaderService} from '../../core/services/loader.service';
import {UserApiModel} from '../../api/models/user.api-model';
import {UserViewModel} from '../user/settings/user.view-model';

@Injectable({
  providedIn: 'root'
})
export class RankingDataProviderService {

  constructor(
    private projectRS: ProjectRestService,
    private vehicleRS: VehicleRestService,
    private vehicleTypeRS: VehicleTypeRestService,
    private mediaObjectRS: MediaObjectRestService,
    private ls: LoaderService,
  ) { }

  fetchRankingResources(): Observable<ProjectViewModel[]> {
    return this.projectRS.getRatedCollection().pipe(mergeMap((resultListAM: ResultListApiModel<ProjectApiModel>) => {
      const deps = forkJoin([
        this.getVehicleCollection(resultListAM.records),
        this.getMediaObjectCollection(resultListAM.records)
      ]);
      return deps.pipe(map((
        [vehicleVMs, mediaObjectMVs]:
          [VehicleViewModel[], MediaObjectViewModel[]]) => {
        return resultListAM.records.map((projectAM: ProjectApiModel) => {
          const projectVM = new ProjectViewModel(projectAM);
          projectVM.vehicle = vehicleVMs.find(e => e['@id'] === projectAM.vehicle);
          const user = projectAM.user as unknown as UserApiModel;
          projectVM.user = new UserViewModel(user);
          projectVM.theme = mediaObjectMVs.find(e => e['@id'] === projectAM.theme);
          return projectVM;
        });
      }));
    }));
  }

  public getMediaObjectCollection(projectAMs: ProjectApiModel[]): Observable<MediaObjectViewModel[]> {
    const params = new QueryParamsApiModel();
    params.filters = projectAMs
      .filter((e: ProjectApiModel) => typeof e.theme === 'string')
      .map(e => new FilterParameterApiModel('id', e.theme));
    if (params.filters.length) {
      return this.mediaObjectRS.getMediaObjectCollection(params)
        .pipe(map((resultListAM: ResultListApiModel<MediaObjectApiModel>) => {
          return resultListAM.records.map((mediaObjectAM: MediaObjectApiModel) => new MediaObjectViewModel(mediaObjectAM));
        }));
    }

    return of<MediaObjectViewModel[]>([]);
  }

  public getVehicleCollection(projectAMs: ProjectApiModel[]): Observable<VehicleViewModel[]> {
    const params = new QueryParamsApiModel();
    params.filters = projectAMs.map(e => new FilterParameterApiModel('id', e.vehicle));
    return this.vehicleRS.getVehicleCollection(params)
      .pipe(mergeMap((resultListAM: ResultListApiModel<VehicleApiModel>) => {
        return this.getVehicleTypeCollection().pipe(map((vehicleTypeVMs: VehicleTypeViewModel[]) => {
          return resultListAM.records.map((vehicleAM: VehicleApiModel) => {
            const vehicleVM = new VehicleViewModel(vehicleAM);
            vehicleVM.vehicleType = vehicleTypeVMs.find(e => e['@id'] === vehicleAM.vehicleType);
            return vehicleVM;
          });
        }));
      }));
  }

  public getVehicleTypeCollection(queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<VehicleTypeViewModel[]> {
    return this.vehicleTypeRS.getVehicleTypeCollection(queryParamsApiModel)
      .pipe(map((resultListAM: ResultListApiModel<VehicleTypeApiModel>) => {
        return resultListAM.records.map((vehicleAM: VehicleTypeApiModel) => new VehicleTypeViewModel(vehicleAM));
      }));
  }
}
