import {AfterContentInit, AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ModalInterface} from '../../modal.interface';
import {concat, Observable, of, Subject} from 'rxjs';
import {ModalStateEvent} from '../../modal-state-event';
import {TokenDataProviderService} from '../../../../core/services/token-data-provider.service';
import {CurrentUserViewModel} from '../../../../core/models/current-user.view-model';
import {Store} from '@ngrx/store';
import {map, mergeMap} from 'rxjs/operators';
import * as fromReaction from '../../../../state/reaction/reaction.reducer';
import {FollowerListItemInterface} from '../../../../core/interfaces/follower-item.interface';
import {AppState} from '../../../../state/app.state';
import {FollowActionTypes} from '../../../../state/follow/following.actions';
import {UserApiModel} from '../../../../api/models/user.api-model';
import {UserDataProviderService} from '../../../../features/user/settings/user.data-provider.service';
import {Router} from '@angular/router';
import {ModalState} from '../../modal-state.enum';
import {LoaderService} from '../../../../core/services/loader.service';
import {ReactionApiModel} from '../../../../api/models/reaction.api.model';
import {QueryParamsApiModel} from '../../../../api/models/query-params-api.model';
import {FilterParameterApiModel} from '../../../../api/models/filter-parameter.api.model';
import {ResultListApiModel} from '../../../../api/models/result-list.api.model';
import {MediaObjectApiModel} from '../../../../api/models/media-object.api-model';
import {UserRestService} from '../../../../api/services/user.rest.service';
import {MediaObjectRestService} from '../../../../api/services/media-object.rest.service';
import {
  ReactedSubjectInterface,
  ReactionListItemInterface
} from '../../../../core/interfaces/reaction-list-item.interface';
import {SubjectApiModel} from '../../../../api/models/follow.api-model';
import {MediaObjectViewModel} from '../../../../core/models/media-object.view-model';
import {SwiperOptions} from 'swiper/types';
import {
  SocialDataProviderService
} from '../../../timeline/social-grid-list/social-data-provider.service';
import {ModalComponent} from "../../modal.component";

type reactions = 'reactions';
@Component({
  selector: 'app-reaction-list',
  templateUrl: './reaction-list.component.html',
  styleUrls: ['./reaction-list.component.scss'],
  providers: [UserDataProviderService]
})
export class ReactionListComponent implements OnInit, AfterViewInit, ModalInterface {

  public currentUser: CurrentUserViewModel;
  public observeState: string;

  @Input() data: ReactionApiModel[];
  public state: Observable<ModalStateEvent>;
  public subject: Subject<ModalStateEvent>;
  private _reactions: Observable<ReactionListItemInterface[]>;
  public config: SwiperOptions;

  get reactions(): Observable<ReactionListItemInterface[]> {
    return this._reactions;
  }

  get parentModalRendered(): boolean {
    return this.modalComponent.rendered;
  }

  constructor(private readonly tokenDPS: TokenDataProviderService,
              private readonly store: Store<AppState>,
              private readonly router: Router,
              private readonly ls: LoaderService,
              private readonly userRS: UserRestService,
              private readonly mediaRS: MediaObjectRestService,
              private readonly modalComponent: ModalComponent,
              public readonly socialDPS: SocialDataProviderService,
  ) {
  }

  ngOnInit() {
    this.tokenDPS.currentUser.subscribe((currentUser: CurrentUserViewModel) => this.currentUser = currentUser);
    this.initReactionList();
    this.fetchCurrentUserFollows();
    this.fetchCurrentUserLikes();
    this.initSwiperConfig();
  }

  ngAfterViewInit(): void {
  }
  private initReactionList(): void {
    const reactionListItems: Observable<ReactionListItemInterface[]> = this.store.select((state: AppState) => fromReaction.selectAll(state.reactions)).pipe(mergeMap((reactionAMs: ReactionApiModel[]) => {
      const authorIds = reactionAMs.map(reactionAM => reactionAM.author instanceof UserApiModel ? reactionAM.author['@id'] : reactionAM.author);
      const params = new QueryParamsApiModel();
      for (const authorId of authorIds) {
        params.filters.push(new FilterParameterApiModel('slug', authorId.split('/').pop()));
      }
      return this.userRS.getCollection(params).pipe(mergeMap((userAMs: ResultListApiModel<UserApiModel>) => {
        const params = new QueryParamsApiModel();
        console.log(userAMs);

        const mediaIds = userAMs.records.map(e => e.avatar);

        for (const mediaId of mediaIds) {
          if (mediaId) {
            params.filters.push(new FilterParameterApiModel('id', mediaId));
          }
        }

        reactionAMs = reactionAMs.map((reactionAM: ReactionApiModel) => {
          const userAM: UserApiModel = userAMs.records.find(u => u['@id'] === reactionAM.author || u['@id'] === reactionAM.author['@id']);
          return {...reactionAM, author: userAM};
        });

        const reactionListItems = reactionAMs.reduce((groupedReactionListItems: ReactionListItemInterface[], reactionAM: ReactionApiModel) => {
          const existingItem = groupedReactionListItems.find(e => e.author['@id'] === reactionAM.author['@id']);
          const author = reactionAM.author as UserApiModel;
          const reacted = (reactionAM.subject || this.data) as SubjectApiModel;
          const reactedSubject: ReactedSubjectInterface = {'@id': reacted['@id'], apiModel: reacted, avatar: null, slug: reacted.slug, identity: reacted.identity};
          if (existingItem) {
            existingItem.ids.push(reactionAM['@id']);
            existingItem.reactedTo.push(reactedSubject);
            existingItem.reactedWith.push(reactionAM.reactedWith);
          } else {
            groupedReactionListItems.push({
              ids: [reactionAM['@id']],
              author: {'@id': author['@id'], apiModel: author, avatar: null, slug: author.slug, username: author.username},
              reactedTo: [reactedSubject],
              reactedWith: [reactionAM.reactedWith],
              resource: 'reactions' as reactions
            });
          }
          if (author.avatar) {
            params.filters.push(new FilterParameterApiModel('id', author.avatar['@id'] || author.avatar));
          }
          if (reacted.avatar) {
            params.filters.push(new FilterParameterApiModel('id', reacted.avatar['@id'] || reacted.avatar));
          }

          return groupedReactionListItems;
        }, []);


        if (params.filters.length) {
          return this.mediaRS.getMediaObjectCollection(params).pipe(map((resultListAM: ResultListApiModel<MediaObjectApiModel>) => {
            return reactionListItems.map((i: ReactionListItemInterface) => {
              const authorAvatar = resultListAM.records.find((e: MediaObjectApiModel) => e['@id'] === i.author.apiModel.avatar);
              i.author.avatar = new MediaObjectViewModel(authorAvatar);
              resultListAM.records.forEach((e: MediaObjectApiModel) => {
                const reacted = i.reactedTo.find(r => e['@id'] === (r.apiModel.avatar && r.apiModel.avatar['@id'] || r.apiModel.avatar));
                if (reacted) {
                  reacted.avatar = new MediaObjectViewModel(e);
                }
              });

              return i;
            });
          }));
        }
        return of(reactionListItems);
      }));
    }));
    console.log(reactionListItems)
    ;
    this._reactions = concat(of<ReactionListItemInterface[]>(Array.from({length: 6}) as ReactionListItemInterface[]), reactionListItems);
  }

  private fetchCurrentUserFollows(): void {
    this.socialDPS.fetchCurrentUserFollows(this.currentUser).subscribe();
  }

  private fetchCurrentUserLikes(): void {
    this.socialDPS.fetchCurrentUserLikes(this.currentUser).subscribe();
  }

  postFollowing(following: FollowerListItemInterface) {
    this.observeState = 'pending';
    this.store.dispatch({type: FollowActionTypes.ADD_ONE, following});
  }

  removeFollowing(following: FollowerListItemInterface) {

  }

  navigateTo(reaction: ReactionApiModel, context: string): void {
    this.ls.show();
    this.router.navigate(['/', (reaction.author as UserApiModel).slug , context]).then(() => {
      this.subject.next(new ModalStateEvent(ModalState.SUCCESS));
    });
  }

  initSwiperConfig() {
    this.config = {
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 5,
          spaceBetween: 5,
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 6,
          spaceBetween: 5,
        },
        // when window width is >= 640px
        720: {
          slidesPerView: 8,
        }
      },
      autoHeight: true,
      freeMode: {
        enabled: true,
        sticky: true,
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      mousewheel: {
        invert: true,
      },
    };
  }

  get viewScrollerHeight(): number {
      return this.modalComponent.dialogHeight() - 150;
  }
}
