<app-custom-camera
  *ngIf="!isImagePicked"
  (onCameraFinishedJob)="initResultStep($event)"
  (onCameraLeave)="modalComponent.hide()"
>
</app-custom-camera>
<div *ngIf="media" class="grid grid-nogutter" style="width: 100%">
  <div *ngIf="src && media.type === 'image'" class="col-12 p-0"
       [style]="{height: '70vh', width: '100%', backgroundColor: 'black', backgroundImage: 'url('+ src + ')', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}">
  </div>
  <div *ngIf="media.type === 'video'" class="col-12 p-0 flex justify-content-center align-items-center p-overflow-hidden"
       [style]="{height: '70vh', width: '100%', backgroundColor: 'black'}">
    <p-skeleton height="70vh" width="100%" *ngIf="!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)"></p-skeleton>
    <video #video style="width: 100%" autoplay loop [src]="file | sanitizeUrl" [hidden]="!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)"></video>
  </div>
  <div class="col-12 pt-3 px-3 p-fluid">
    <div class="p-field p-float-label p-input-icon-left">
      <i class="pi pi-pencil"></i>
      <textarea id="mediaCaption" [rows]="1" pInputTextarea autoResize="autoResize" type="text"
                [(ngModel)]="caption"
      ></textarea>
      <label for="mediaCaption">{{'shared.modal.component.add_media.caption.label' | translate }}</label>
    </div>
  </div>
</div>
