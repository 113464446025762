import {Component, OnInit} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {TokenDataProviderService} from "../../../core/services/token-data-provider.service";
import {LoaderService} from "../../../core/services/loader.service";
import {ResponseErrorsHandlerService} from "../../../core/services/response-errors-handler.service";
import {plainToClassFromExist} from "class-transformer";
import {ResponseNotificationService} from "../../../core/services/response-notification.service";
import {UserDataProviderService} from "../../user/settings/user.data-provider.service";
import {RegistrationRestService} from "../../../api/services/registration.rest.service";
import {AuthApiModel} from "../../../api/models/auth.api.model";
import {Oauth2UserApiModel} from "../../../api/models/oauth2-user.api.model";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-complete-oauth2-registration',
  templateUrl: './complete-oauth2-registration.component.html',
  styleUrls: ['./complete-oauth2-registration.component.scss'],
  providers: [UserDataProviderService]
})
export class CompleteOauth2RegistrationComponent implements OnInit {

  public userAM: Oauth2UserApiModel = this.route.snapshot.data['currentUser'];
  public form: UntypedFormGroup;
  public error: string;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ts: TokenDataProviderService,
    private ls: LoaderService,
    private reh: ResponseErrorsHandlerService,
    private rs: RegistrationRestService,
    private rns: ResponseNotificationService,
    private translate: TranslateService
  ) {
    this.createForm();
  }

  ngOnInit() {
  }

  currentStyles() {
    return {
      'background': 'url(' + environment.apiUrl + '/uploads/backgrounds/test1.jpg) no-repeat fixed center',
      'background-size': 'cover',
    };
  }

  createForm() {
    this.form = this.fb.group(
      {
        username: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
        terms: ['', [Validators.requiredTrue]]
      }
    );
  }

  login() {
    this.ls.show();
    this.rs.completeOauth2Registration(plainToClassFromExist(this.userAM, Object.assign(this.form.value))).subscribe(
      (successResponse: AuthApiModel) => {
        this.ts.setSession(successResponse, true)
        this.ts.refreshUser().subscribe();
        this.router.navigate(['/']);
      },
      errorResponse => {
        this.reh.handleFormErrors(this.form, errorResponse);
        this.rns.emitError(this.translate.instant('shared.complete_oauth2_registration.message.error'));
      }
    ).add(() => {
      this.ls.hide();
    });
  }
}
