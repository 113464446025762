import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import {InputTextModule} from 'primeng/inputtext';
import {SocialInputComponent} from './social-input/social-input.component';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {RxReactiveFormsModule} from '@rxweb/reactive-form-validators';
import {FileUploadModule} from 'primeng/fileupload';
import {PipesModule} from '../../core/pipes/pipes.module';
import {SocialReactionsBarComponent} from './social-reactions-bar/social-reactions-bar.component';
import {EmojiModule} from '@ctrl/ngx-emoji-mart/ngx-emoji';

@NgModule({
    imports: [
        CommonModule,
        OverlayPanelModule,
        ButtonModule,
        RippleModule,
        InputTextModule,
        InputTextareaModule,
        RxReactiveFormsModule,
        FileUploadModule,
        PipesModule,
        EmojiModule,
    ],
  declarations: [SocialInputComponent, SocialReactionsBarComponent],
  exports: [SocialInputComponent, SocialReactionsBarComponent],
})
export class SocialModule {
}
