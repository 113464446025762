import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ThumbnailComponent implements OnInit {
  get imgSrc(): string {
    return this._imgSrc;
  }

  get styles(): object {
    return {
      'background-image': 'url(' + this.imgSrc + ')',
    }
  }

  @Input() set imgSrc(value: string) {
    this._imgSrc = value;
  }
  @Input() public classList: string;
  @Input() public stylesList: string;
  @Input() public size: 'xs'|'sm'|'md'|'lg' = 'md';
  @Input() public bordered: boolean;
  @Input() public circle: boolean;
  private _imgSrc: string;

  constructor() { }

  ngOnInit(): void {
  };



}
