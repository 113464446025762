export abstract class AbstractViewModel<T> {
  public ['@id']: string;
  public id: number;
  public state: 'persisted' | 'updated' | 'removed';
  protected constructor(protected apiModel: T) {
    this['@id'] = this.apiModel['@id'];
  }

  public abstract toApiModel(): T;

  public abstract fromApiModel(apiModel: T): AbstractViewModel<T>;
}
