import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeHtmlTags'
})
export class RemoveHtmlTagsPipe implements PipeTransform {

  public static sanitize(value: string): string {
    if (!value) {
      return value;
    }
    return value.replace(/<[^>]*>/g, ' ');
  }

  transform(value: string): string {
    if (!value) {
      return value;
    }
    return RemoveHtmlTagsPipe.sanitize(value);
  }
}

