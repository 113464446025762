import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from "rxjs";
import { HttpClient } from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {plainToClassFromExist} from "class-transformer";
import {ResultListApiModel} from "../models/result-list.api.model";
import {QueryParamsService} from "../../core/services/query-params.service";
import {QueryParamsApiModel} from "../models/query-params-api.model";
import {ModelApiModel} from "../models/model.api-model";

@Injectable()
export class ModelRestService {
  private readonly resource: string = '/models';

  constructor(
    public http: HttpClient,
    public params: QueryParamsService
  ) {
  }

  public getModelCollection(queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<ResultListApiModel<ModelApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<ModelApiModel>>(environment.apiUrl + this.resource, {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<ModelApiModel>(ModelApiModel), result as object)));
  }
}
