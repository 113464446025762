export enum ModalState {
  INIT = 'INIT',
  HIDE = 'HIDE',
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  VALID = 'VALID',
  INVALID = 'INVALID',
  PROCESS = 'PROCESS',
  PROCESS_END = 'PROCESS_END',
}
