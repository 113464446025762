import {Expose, plainToClassFromExist, Type} from 'class-transformer';
import {AbstractApiModel} from './abstract.api.model';
import {MediaObjectApiModel} from './media-object.api-model';
import {EventViewModel} from '../../shared/modal/components/add-event/event.view-model';
import {ParticipantApiModel} from './participant.api.model';
import {PostApiModel} from './post-api.model';
import {FollowApiModel} from './follow.api-model';
import {ReactionApiModel} from './reaction.api.model';
import {MetadataApiModel} from './metadata.api.model';
import {HashtagApiModel} from './hashtag.api.model';

export class EventApiModel extends AbstractApiModel {
  @Expose() public id: number;
  @Expose() public cover: MediaObjectApiModel|string;
  @Expose() public name: string;
  @Expose() public slug: string;
  @Expose() public description: string;
  @Expose() public eventDates: {name: string, from: Date, to: Date, duration?: number, start: Date, end: Date}[];
  @Expose() public addresses: {place: string, coordinates: number[], city: {coordinates: number[]}}[];
  @Expose() public organizers: ParticipantApiModel[]|string[];
  @Expose() public participants: ParticipantApiModel[];
  @Expose() public follows: FollowApiModel[];
  @Expose()
  @Type(() => HashtagApiModel)
  public hashtags: HashtagApiModel[];
  @Expose() public reactions: ReactionApiModel[];
  @Expose() public metadata: MetadataApiModel;
  @Expose()
  @Type(() => PostApiModel)
  public posts: (PostApiModel | string)[];
  @Expose()
  @Type(() => MediaObjectApiModel)
  public media: (MediaObjectApiModel | string)[];
  public identity: string;
  @Expose() public updatedAt: Date;
  @Expose() public createdAt: Date;

  public static fromEventVM(eventVM: EventViewModel): EventApiModel {
    const eventAM = plainToClassFromExist(eventVM.apiModel, eventVM);
    eventAM.cover = eventVM.cover ? eventVM.cover['@id'] : null;
    eventAM.organizers = eventVM.organizers.map(e => e.user['@id']);
    eventAM.media = eventVM.media.map(e => e['@id']);
    eventAM.posts = eventVM.posts.map(e => e['@id']);
    eventAM.metadata = new MetadataApiModel();
    eventAM.metadata.metaTitle  = eventVM.metaTitle;
    eventAM.metadata.metaDescription  = eventVM.metaDescription;
    eventAM.metadata.metaKeywords  = eventVM.metaKeywords;
    eventAM.eventDates = eventVM.eventDates.map((dateObj: {name: string, date: Date, from: Date, to: Date }) => {
      const date = dateObj.date;
      const from = new Date(date.valueOf());
      const to = new Date(date.valueOf());
      from.setHours(dateObj.from.getHours(), dateObj.from.getMinutes());
      to.setHours(dateObj.to.getHours(), dateObj.to.getMinutes());
      return {
        name: dateObj.name,
        from,
        to,
        start: from,
        end: to
      };
    });

    return eventAM;
  }
}
