import {Pipe, PipeTransform} from '@angular/core';
import {OwnerGuard} from '../guards/owner.guard';
import {UserViewModel} from '../../features/user/settings/user.view-model';
import {CurrentUserViewModel} from '../models/current-user.view-model';
import {UserApiModel} from '../../api/models/user.api-model';

@Pipe({
  name: 'ownerRole',
})
export class OwnerRolePipe implements PipeTransform {

  constructor() {
  }

  transform(value: UserViewModel|CurrentUserViewModel|UserApiModel): string {
    return value ? `ROLE_OWNER_${OwnerGuard.swapCase(value.slug)}` : 'ROLE_NOT_PRIVILEGED';
  }
}
