<p-virtualScroller [value]="participants | async" scrollHeight="75vh" [itemSize]="107"
                   [lazy]="true">
  <ng-template let-participant pTemplate="item">
    <div class="flex justify-content-start align-items-center" style="gap: 10px">
      <app-thumbnail
        [imgSrc]="participant.data.avatar | mediaObject"
        [circle]="true"
        size="sm"
      >
      </app-thumbnail>
      <div>
        <a (click)="navigateToProfile(participant)">{{participant.data.username}}</a>
      </div>
      <ng-container [ngSwitch]="observeState">
        <button
          class="p-button p-button-outlined p-button-icon-only p-button-rounded p-button-sm"
          *ngIf="participant.data['@id'] !== currentUser['@id']"
          style="margin-left: auto"
        >
          <span *ngSwitchDefault (click)="postFollowing(participant)" class="material-icons">favorite_border</span>
          <span *ngSwitchCase="'pending'" class="pi pi-spin pi-spinner"></span>
          <span *ngSwitchCase="'filled'" (click)="removeFollowing(participant)" class="material-icons">favorite</span>
        </button>
      </ng-container>
    </div>
  </ng-template>
  <ng-template pTemplate="loadingItem">
          <div class="flex">
            <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
            <div style="flex: 1">
              <p-skeleton width="100%" height="1.5rem" borderRadius="16px" styleClass="mb-2 mt-1"></p-skeleton>
              <p-skeleton width="75%" height="1.5rem" borderRadius="16px"></p-skeleton>
            </div>
          </div>
  </ng-template>
</p-virtualScroller>
