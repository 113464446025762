import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BottomActionSheetComponent} from './bottom-action-sheet.component';
import {SwiperModule} from 'swiper/angular';


@NgModule({
  declarations: [BottomActionSheetComponent],
  exports: [
    BottomActionSheetComponent
  ],
  imports: [
    CommonModule,
    SwiperModule
  ]
})
export class BottomActionSheetModule { }
