<div class="card flex flex-column align-items-center p-0 m-0">
  <div class="grid width-100">
    <div class="col-12 p-0 nav-swiper">
      <swiper
        #mainSwiper
        [config]="config"
        [slideActiveClass]="'active'"
        [watchOverflow]="true"
        [updateOnImagesReady]="true"
        [autoHeight]="true"
        [lazy]="true"
      >
        <ng-template swiperSlide *ngFor="let item of slides|keyvalue: originalOrder; let last = last; let i = index">
          <div class="flex justify-content-center align-self-stretch px-3" (click)="setActiveSlaveSlide(i, item.value, slaveSwiper, $event)">
            <div class="flex flex-column align-items-center">
              <span>{{ item.value.label | translate }}</span>
              <span
                class="fs-large font-bold blue-color mt-2"
              >
                      {{ item.value.count }}
                </span>
            </div>
          </div>
        </ng-template>
      </swiper>
    </div>
  </div>
</div>
<div class="grid grid-nogutter width-100 mb-2">
  <div class="col-12 p-0 my-2">
    <swiper
      #slaveSwiper
      [config]="childConfig"
      (activeIndexChange)="setActiveSlide($event)"
      [updateOnImagesReady]="true"
      [updateOnWindowResize]="true"
      [observeSlideChildren]="true"
      [lazy]="true"
      [preloadImages]="true"
      [autoHeight]="true"
      [watchOverflow]="true"
    >
      <ng-template swiperSlide *ngFor="let slide of slides|keyvalue: originalOrder; let last = last; let i = index">
        <app-social-timeline
          [slide]="slide"
          [noResultsMsg]="('common.no_' + listType + '.' + slide.key)|lowercase|translate"
        >
        </app-social-timeline>
      </ng-template>
    </swiper>
  </div>
</div>


