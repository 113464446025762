import {AfterViewInit, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';

import {CurrentUserViewModel} from '../../models/current-user.view-model';
import {AppComponent} from '../../../app.component';
import {PrimeNGConfig} from 'primeng/api';
import {MenuService} from '../../../shared/navbar/app.menu.service';
import {ActivatedRoute} from '@angular/router';
import {QueryService} from '../../services/query.service';
import {Subject, Subscription} from 'rxjs';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from 'body-scroll-lock';

export interface ItemClickInterface {
  click: boolean;
  previousItem: string;
  nextItem: string;
}

@Component({
  selector: 'app-site',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.scss']
})
export class SiteLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  get user(): CurrentUserViewModel {
    return this._user;
  }

  set user(value: CurrentUserViewModel) {
    this._user = value;
  }

  topbarMenuActive: boolean;
  topbarVisible: boolean = true;

  menuActive: boolean;

  staticMenuDesktopInactive: boolean;

  mobileMenuActive: boolean;

  menuClick: boolean;

  mobileTopbarActive: boolean;

  topbarRightClick: boolean;

  topbarItemClick: boolean;
  mobileTopbarItemClick: boolean;

  activeTopbarItem: string;

  documentClickListener: () => void;

  configClick: boolean;

  rightMenuActive: boolean;

  menuHoverActive = false;

  searchClick = false;
  searchMenuClick = false;
  search = false;

  currentInlineMenuKey: string;

  inlineMenuActive: any[] = [];

  inlineMenuClick: boolean;

  locked = false;

  private _user: CurrentUserViewModel;

  public onClickTopBarItem$: Subject<ItemClickInterface> = new Subject<ItemClickInterface>();
  private subscriptions: Subscription[] = [];
  constructor(public renderer: Renderer2, private menuService: MenuService, private primengConfig: PrimeNGConfig,
              public app: AppComponent,
              private route: ActivatedRoute,
              public queryService: QueryService,
  ) {
  }

  ngOnInit() {
    this.menuActive = this.isStatic() && !this.isMobile();
    this.route.data.subscribe(({currentUser}: { currentUser }) => {
      this._user = currentUser;
    });
    // eslint-ignore-next-line
    this.inlineMenuActive['top'] = true; // default visible side menu user items
    const sub = this.queryService.fetchResourcesFromQuerySubject().subscribe(() => {
    });
    this.subscriptions.push(sub);
  }

  ngAfterViewInit() {
    // hides the horizontal submenus or top menu if outside is clicked
    this.documentClickListener = this.renderer.listen('body', 'click', () => {
      if (!this.topbarItemClick) {
        this.onClickTopBarItem$.next({
          click: this.topbarItemClick,
          previousItem: this.activeTopbarItem,
          nextItem: null
        });
        this.activeTopbarItem = null;
        this.topbarMenuActive = false;
      }

      if (!this.mobileTopbarItemClick) {
        this.mobileTopbarActive = false;
      }

      if (!this.menuClick && (this.isHorizontal() || this.isSlim())) {
        this.menuService.reset();
      }

      // if (this.menuService.configMenuActive && !this.configClick) {
      //   this.menuService.configMenuActive = false;
      // }

      if (!this.menuClick) {
        if (this.mobileMenuActive) {
          this.mobileMenuActive = false;
        }

        if (this.isOverlay()) {
          this.menuActive = false;
        }

        this.menuHoverActive = false;
      }

      if (!(this.searchClick || this.searchMenuClick)) {
        this.search = false;
      }

      if (this.inlineMenuActive[this.currentInlineMenuKey] && !this.inlineMenuClick) {
        this.inlineMenuActive[this.currentInlineMenuKey] = false;
      }
      this.inlineMenuClick = false;
      this.searchClick = false;
      this.searchMenuClick = false;
      this.configClick = false;
      this.topbarItemClick = false;
      this.topbarRightClick = false;
      this.menuClick = false;
    });
  }

  onMenuButtonClick(event) {
    this.menuActive = !this.menuActive;
    this.topbarMenuActive = false;
    this.topbarRightClick = true;
    this.menuClick = true;

    if (this.isDesktop()) {
      this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
    } else {
      this.mobileMenuActive = !this.mobileMenuActive;
    }

    event.preventDefault();
  }

  onTopbarMobileButtonClick(event) {
    this.mobileTopbarActive = !this.mobileTopbarActive;
    this.mobileTopbarItemClick = true;
    event.preventDefault();
  }

  onRightMenuButtonClick(event) {
    this.rightMenuActive = !this.rightMenuActive;
    event.preventDefault();
  }

  onMenuClick($event) {
    this.menuClick = true;

    if (this.inlineMenuActive[this.currentInlineMenuKey] && !this.inlineMenuClick) {
      this.inlineMenuActive[this.currentInlineMenuKey] = false;
    }
  }

  onSearchKeydown(event) {
    switch (event.keyCode) {
      case 13:
        break;
      case 27:
        this.search = false;
    }
  }

  emitQuery(query) {
    this.queryService.resultSubject$.next(query);
  }

  onInlineMenuClick(event, key) {
    if (key !== this.currentInlineMenuKey) {
      this.inlineMenuActive[this.currentInlineMenuKey] = false;
    }

    this.inlineMenuActive[key] = !this.inlineMenuActive[key];
    this.currentInlineMenuKey = key;
    this.inlineMenuClick = true;
  }

  onTopbarItemClick(event, item) {
    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null;
    } else {
      this.activeTopbarItem = item;
    }

    if (item === 'search') {
      this.queryService.loading = true;
      this.search = !this.search;
      this.searchClick = !this.searchClick;
      this.searchMenuClick = true;
    }
    this.onClickTopBarItem$.next({click: this.topbarItemClick, previousItem: item, nextItem: this.activeTopbarItem});
    event.preventDefault();
  }

  onTopbarSubItemClick(event) {
    event.preventDefault();
  }

  onRTLChange(event) {
    this.app.isRTL = event.checked;
  }

  onRippleChange(event) {
    this.app.ripple = event.checked;
    this.primengConfig.ripple = event.checked;
  }

  onConfigClick(event) {
    this.configClick = true;
  }

  isDesktop() {
    return window.innerWidth > 991;
  }

  isMobile() {
    return window.innerWidth <= 991;
  }

  isOverlay() {
    return this.app.menuMode === 'overlay';
  }

  isStatic() {
    return this.app.menuMode === 'static';
  }

  isHorizontal() {
    return this.app.menuMode === 'horizontal';
  }

  isSlim() {
    return this.app.menuMode === 'slim';
  }

  onMainLayoutClick(): void {
    this.mobileTopbarItemClick = false;
  }

  disableBodyScroll() {
    disableBodyScroll(document.body);
  }

  enableBodyScroll(): void {
    enableBodyScroll(document.body);
  }

  resetActiveMenus(): void {
    this.menuActive = false;
    this.mobileMenuActive = false;
    this.topbarMenuActive = false;
    this.mobileTopbarActive = false;
  }

  ngOnDestroy() {
    if (this.documentClickListener) {
      this.documentClickListener();
    }

    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
