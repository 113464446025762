import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject, Input,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import {MenuItem} from "primeng/api";
import {LanguageChangedEvent} from "../../core/interfaces/language-changed-event";
import {tap} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {LanguageService} from "../../core/services/language.service";

@Component({
  selector: 'app-language-dropdown-button',
  templateUrl: './language-dropdown-button.component.html',
  styleUrls: ['./language-dropdown-button.component.scss']
})
export class LanguageDropdownButtonComponent implements OnInit, AfterViewInit {

  @Input()
  public label: string = '';
  @Input()
  public classList: string = '';
  @Output()
  public languageChanged: EventEmitter<LanguageChangedEvent> = new EventEmitter<LanguageChangedEvent>()
  @ViewChild('flagBtn') flagViewChild: ElementRef;

  public items: MenuItem[] = [
    {
      label: null,
      icon: 'flag flag-pl',
      state: {transKey: 'language.pl'},
      routerLink: [],
      queryParams: {'lang': 'pl-PL'},
      queryParamsHandling: 'replace',
      replaceUrl: true,
      command: () => {
        return this.useLanguage('pl-PL');
      }
    },
    {
      label: null,
      icon: 'flag flag-us',
      state: {transKey: 'language.us'},
      routerLink: [],
      queryParams: {'lang': 'en-US'},
      queryParamsHandling: 'replace',
      replaceUrl: true,
      command: () => {
        return this.useLanguage('en-US');
      }
    },
    {
      label: null,
      icon: 'flag flag-de',
      state: {transKey: 'language.de'},
      routerLink: [],
      queryParams: {'lang': 'de-DE'},
      queryParamsHandling: 'replace',
      replaceUrl: true,
      command: () => {
        return this.useLanguage('de-DE');
      }
    },
  ];

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    private languageService: LanguageService
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.useLanguage(this.languageService.getLanguage());
  }

  private useLanguage(language: string): void {
    const oldLang = this.getCurrentLang().toLowerCase().split('-')[1];
    this.flagViewChild.nativeElement.classList.remove(`flag-${oldLang}`)
    this.translate.use(language);
    const newLang = language.toLowerCase().split('-')[1];
    this.flagViewChild.nativeElement.classList.add(`flag-${newLang}`);
    this.translateMenuItems();
    this.languageService.setLanguage(language);
    this.languageChanged.emit({oldLanguage: oldLang, currentLanguage: language})
  }

  private translateMenuItems(): void {
    const labels = this.items.map(e => e.state.transKey);
    this.translate.get(labels).pipe(tap((res) => {
      this.items.forEach((item: MenuItem) => {
        if (res.hasOwnProperty(item.state.transKey)) {
          item.label = res[item.state.transKey];
        }
      });
    })).subscribe();
  }

  public getCurrentLang(): string {
    return this.translate.currentLang || this.translate.getDefaultLang();
  }
}
