<img loading="lazy" alt="picture" [id]="id || mediaObject.id" *ngIf="mediaObject.type === MediaTypeEnum.IMAGE" class="dummy-container-image" [src]="mediaObject | mediaObject: {formatType: 'timeline'}" width="100%"
>
<div [id]="id || mediaObject.id" class="relative video-container" *ngIf="mediaObject.type === MediaTypeEnum.VIDEO">
  <video
    preload="metadata"
    id="{{prefix}}-{{id || mediaObject.id}}"
    class="timeline-player"
    width="100%"
    crossorigin
    muted="muted"
    [src]="mediaObject | mediaObject:{formatType: 'original'}"
    [poster]="mediaObject | mediaObject:{formatType: 'm3u8_timeline'}"
  >
<!--    <source [src]="mediaObject | mediaObject:{formatType: 'original'}" type="{{mediaObject.mimeType}}">-->
  </video>
  <div class="absolute volume">
    <button (click)="toggleVolume($event)" pButton pRipple *ngIf="videoService.muted" icon="fa fa-volume-high" class="p-button-rounded p-button-default p-button-text"></button>
    <button (click)="toggleVolume($event)"  pButton pRipple *ngIf="!videoService.muted" icon="fa fa-volume-xmark" class="p-button-rounded p-button-default p-button-text"></button>
  </div>
  <div *ngIf="isEnded" class="absolute end-credits"><button pButton pRipple type="button" label="{{'shared.media-view.replay'|translate}}" class="p-button-rounded text-white"></button></div>
</div>

