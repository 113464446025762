import { Injectable } from '@angular/core';
import {ReactiveFormConfig} from "@rxweb/reactive-form-validators";
import {TranslateService} from "@ngx-translate/core";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ValidatorConfigService {

  constructor(private translate: TranslateService) { }

  config = {
    "validationMessage":{
      "required": 'app.validator.required',
      "minLength": 'app.validator.min_length',
      "maxLength": 'app.validator.max_length',
      "alpha": 'app.validator.alpha',
      "alphaNumeric": 'app.validator.alpha_numeric',
      "compare": 'app.validator.compare',
      "email": 'app.validator.email',
    }
  };

  loadValidationMessages(): void {
    this.translate.get(Object.values(this.config['validationMessage'])).pipe(tap((res) => {
      for (const [k, v] of Object.entries(this.config["validationMessage"])) {
        this.config["validationMessage"][k] = res[v];
      }
    })).subscribe();
    ReactiveFormConfig.set(this.config);
  }
}
