import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {SocialListTypeEnum} from './social-list-type.enum';
import {FollowerRestService} from '../../../api/services/follower.rest.service';
import {ReactionRestService} from '../../../api/services/reaction.rest.service';
import {QueryParamsApiModel} from '../../../api/models/query-params-api.model';
import {FilterParameterApiModel} from '../../../api/models/filter-parameter.api.model';
import {UserApiModel} from '../../../api/models/user.api-model';
import {map, mergeMap, tap} from 'rxjs/operators';
import {ResultListApiModel} from '../../../api/models/result-list.api.model';
import {FollowApiModel, SubjectApiModel} from '../../../api/models/follow.api-model';
import {SocialGridItemInterface} from './social-grid-item.interface';
import {MediaObjectApiModel} from '../../../api/models/media-object.api-model';
import {MediaObjectRestService} from '../../../api/services/media-object.rest.service';
import {ReactionApiModel} from '../../../api/models/reaction.api.model';
import {SwiperItemInterface} from './swiper-items.interface';
import {FollowTypeEnum} from '../../../core/enums/follow-type.enum';
import {ReactionTypeEnum} from '../../../core/enums/reaction-type.enum';
import {plainToClass} from 'class-transformer';
import { HttpResponse } from '@angular/common/http';
import {ReactedWithEnum} from '../../../core/enums/reacted-with.enum';
import {UserViewModel} from '../../../features/user/settings/user.view-model';
import {UserRestService} from '../../../api/services/user.rest.service';
import {CurrentUserViewModel} from '../../../core/models/current-user.view-model';
import {ParticipantRestService} from '../../../api/services/participant.rest.service';
import {ParticipantApiModel} from '../../../api/models/participant.api.model';
import {EventRestService} from '../../../api/services/event.rest.service';
import {EventApiModel} from '../../../api/models/event.api.model';
import {KeyValue} from '@angular/common';
import {format} from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class SocialDataProviderService {

  public totalItems: {[key: string]: number} = {follows: 0, users: 0, events: 0, interested: 0, participate: 0, participated: 0};
  public currentUserFollowersIds: string[];
  public currentUserLikedIds: string[];
  constructor(
    private readonly followerRS: FollowerRestService,
    private readonly reactionRS: ReactionRestService,
    private readonly mediaRS: MediaObjectRestService,
    private readonly userRS: UserRestService,
    private readonly participantRS: ParticipantRestService,
    private readonly eventRS: EventRestService
  ) {
  }

  public getCollection(listTypeEnum: SocialListTypeEnum, item: KeyValue<string, SwiperItemInterface>, author: UserApiModel, event: EventApiModel = null, page: number): Observable<SocialGridItemInterface[]> {
    const queryParams = new QueryParamsApiModel();
    queryParams.filters.push(new FilterParameterApiModel('limit', 9, 'single'));
    queryParams.filters.push(new FilterParameterApiModel('discriminator', item.value.type, 'single'));
    queryParams.page = page;

    switch (listTypeEnum) {
      case SocialListTypeEnum.FOLLOWS:
        queryParams.filters.push(new FilterParameterApiModel('followedBy.slug', author.slug, 'single'));
        return this.followerRS.getCollection(queryParams).pipe(mergeMap((resultListAM: ResultListApiModel<FollowApiModel>) => {
          item.value.count = resultListAM.total;
          this.totalItems[item.key] = resultListAM.total;
          const params = new QueryParamsApiModel();
          resultListAM.records.forEach((followAM: FollowApiModel) => {
            if (followAM.followed.avatar !== null) {
              params.filters.push(new FilterParameterApiModel('id', followAM.followed.avatar));
            }
          });
          return this.mediaRS.getMediaObjectCollection(params).pipe(map((resultListAM1: ResultListApiModel<MediaObjectApiModel>) => {
            return resultListAM.records.map((followAM: FollowApiModel) => {
              let avatar = {hyperlinks: []};
              if (followAM.followed.avatar !== null) {
                avatar = resultListAM1.records.find( e => e['@id'] === followAM.followed.avatar);
              }

              return {
                isPlaceholder: false,
                name: followAM.followed.identity,
                subject: followAM.followed,
                thumbnail: {name: 'thumbnail', hyperlinks: avatar.hyperlinks},
                previewUrl: this.generateLink(author, listTypeEnum, item.value.type, followAM.followed)
              };
            });
          }));
        }));
      case SocialListTypeEnum.REACTIONS:
        queryParams.filters.push(new FilterParameterApiModel('author.slug', author.slug, 'single'));
        return this.reactionRS.getCollection(queryParams).pipe(mergeMap((resultListAM: ResultListApiModel<ReactionApiModel>) => {
          item.value.count = resultListAM.total;
          this.totalItems[item.value.type] = resultListAM.total;
          const params = new QueryParamsApiModel();
          resultListAM.records.forEach((reactionAM: ReactionApiModel) => {
            if ((reactionAM.subject as SubjectApiModel).avatar !== null) {
              params.filters.push(new FilterParameterApiModel('id', (reactionAM.subject as SubjectApiModel).avatar['@id']));
            }
          });


          return this.mediaRS.getMediaObjectCollection(params).pipe(map((resultListAM1: ResultListApiModel<MediaObjectApiModel>) => {
            return resultListAM.records.map((reactionAM: ReactionApiModel) => {
              let avatar = {hyperlinks: []};
              if ((reactionAM.subject as SubjectApiModel).avatar !== null) {
                avatar = resultListAM1.records.find( e => e['@id'] === (reactionAM.subject as SubjectApiModel).avatar['@id']);
              }

              return {
                isPlaceholder: false,
                name: (reactionAM.subject as SubjectApiModel).identity,
                subject: reactionAM.subject,
                thumbnail: {name: 'thumbnail', hyperlinks: avatar.hyperlinks},
                previewUrl: this.generateLink(author, listTypeEnum, item.value.type, reactionAM.subject)
              };
            });
          }));
        }));
      case SocialListTypeEnum.EVENTS:
        queryParams.filters.push(new FilterParameterApiModel('data.id', author.id, 'single'));
        queryParams.filters = queryParams.filters.filter(e => e.name !== 'discriminator');
        queryParams.filters.push(new FilterParameterApiModel('discriminator', 'PRIVATE', 'array'));
        queryParams.filters.push(new FilterParameterApiModel('state', item.value.type, 'single'));

        if (item.key === 'interested' || item.key === 'participate') {
          queryParams.filters.push(new FilterParameterApiModel('event.eventDates.end', format(new Date(), 'yyyy-MM-dd HH:mm:ss'), 'array_with_key', 'strictly_after'));

        }

        if (item.key === 'participated') {
          queryParams.filters.push(new FilterParameterApiModel('event.eventDates.end', format(new Date(), 'yyyy-MM-dd HH:mm:ss'), 'array_with_key', 'strictly_before'));
        }

        return this.participantRS.getCollection(queryParams).pipe(mergeMap((resultListAM: ResultListApiModel<ParticipantApiModel>) => {
          item.value.count = resultListAM.total;
          this.totalItems[item.key] = resultListAM.total;
          const params = new QueryParamsApiModel();
          resultListAM.records.forEach((participantAM: ParticipantApiModel) => {
              params.filters.push(new FilterParameterApiModel('slug', (participantAM.event as string).split('/').pop()));
          });

          if (params.filters.length === 0) {
            return of([]);
          }

          return this.eventRS.getCollection(params).pipe(map((resultListAM1: ResultListApiModel<EventApiModel>) => {
            return resultListAM1.records.map((eventAM: EventApiModel) => {

              return {
                isPlaceholder: false,
                name: eventAM.identity,
                subject: eventAM,
                thumbnail: {name: 'thumbnail', hyperlinks: (eventAM.cover as MediaObjectApiModel).hyperlinks},
                previewUrl: this.generateLink(author, listTypeEnum, item.value.type, eventAM)
              };
            });
          }));
        }));
      case SocialListTypeEnum.EVENT_PARTICIPANTS:
        queryParams.filters.push(new FilterParameterApiModel('event.slug', event.slug, 'single'));
        queryParams.filters.push(new FilterParameterApiModel('state', item.value.type, 'single'));

        if (item.key === 'interested' || item.key === 'participate') {
          queryParams.filters.push(new FilterParameterApiModel('event.eventDates.end', format(new Date(), 'yyyy-MM-dd HH:mm:ss'), 'array_with_key', 'strictly_after'));

        }

        if (item.key === 'participated') {
          queryParams.filters.push(new FilterParameterApiModel('event.eventDates.end', format(new Date(), 'yyyy-MM-dd HH:mm:ss'), 'array_with_key', 'strictly_before'));
        }

        return this.participantRS.getCollection(queryParams).pipe(map((resultListAM: ResultListApiModel<ParticipantApiModel>) => {
          item.value.count = resultListAM.total;
          this.totalItems[item.key] = resultListAM.total;
          return resultListAM.records.map((participantAM: ParticipantApiModel) => {
            const subject = participantAM.data as SubjectApiModel;
            return {
              isPlaceholder: false,
              name: subject.identity,
              subject: participantAM,
              thumbnail: {name: 'thumbnail', hyperlinks: (subject.avatar as MediaObjectApiModel).hyperlinks},
              previewUrl: this.generateLink(author, listTypeEnum, item.value.type, participantAM.data)
            };
          });
        }));
    }
  }

  public postFollow(userVM: UserViewModel): Observable<UserViewModel> {
    const userAM = userVM.toApiModel();
    return this.followerRS.create(plainToClass(FollowApiModel, {followed: userAM['@id'], type: FollowTypeEnum.USER}))
      .pipe(map((followAM: FollowApiModel) => {
        userVM.receivedFollows.unshift(followAM);
        return userVM;
      }));
  }

  public removeFollow(followAM: FollowApiModel, userVM: UserViewModel): Observable<UserViewModel> {
    return this.followerRS.delete(followAM).pipe(map((e: HttpResponse<any>) => {
      userVM.receivedFollows = userVM.receivedFollows.filter(x => x.followedBy !== followAM.followedBy);
      return userVM;
    }));
  }

  public postReaction(userVM: UserViewModel): Observable<UserViewModel> {
    const userAM = userVM.toApiModel();
    return this.reactionRS.create(plainToClass(ReactionApiModel, {reactedWith: ReactedWithEnum.THUMBS_UP, subject: userAM['@id'], type: ReactionTypeEnum.USER_REACTION}))
      .pipe(map((reactionAM: ReactionApiModel) => {
        userVM.receivedReactions.unshift(reactionAM);
        return userVM;
      }));
  }

  public removeReaction(reactionAM: ReactionApiModel, userVM: UserViewModel): Observable<UserViewModel> {
    return this.reactionRS.delete(reactionAM).pipe(map((e: HttpResponse<any>) => {
      userVM.receivedReactions = userVM.receivedReactions.filter(x => x.author !== reactionAM.author);
      return userVM;
    }));
  }

  public getAllFollowedCollection(userVM: UserViewModel): Observable<FollowApiModel[]> {
    const queryParams = new QueryParamsApiModel();
    // queryParams.filters.push(new FilterParameterApiModel('limit', 100, 'single'));
    queryParams.filters.push(new FilterParameterApiModel('followed.slug', userVM.slug, 'array'));
    queryParams.filters.push(new FilterParameterApiModel('followedBy.slug', userVM.slug, 'array_with_key', 'neq'));
    queryParams.filters.push(new FilterParameterApiModel('discriminator', FollowTypeEnum.USER, 'array'));
    queryParams.filters.push(new FilterParameterApiModel('discriminator', FollowTypeEnum.PROJECT, 'array'));
    userVM.getApiModel().projects.forEach((followedId: string) => {
      const arr = followedId.split('/');
      queryParams.filters.push(new FilterParameterApiModel('followed.slug', arr[arr.length - 1], 'array'));
    });
    return this.followerRS.getCollection(queryParams).pipe(map((resultListAM: ResultListApiModel<FollowApiModel>) => {
      return resultListAM.records;
    }));
  }

  public getAllLikesCollection(userVM: UserViewModel): Observable<ReactionApiModel[]> {
    const queryParams = new QueryParamsApiModel();
    // queryParams.filters.push(new FilterParameterApiModel('limit', 100, 'single'));
    queryParams.filters.push(new FilterParameterApiModel('user.slug', userVM.slug, 'array'));
    queryParams.filters.push(new FilterParameterApiModel('author.slug', userVM.slug, 'array_with_key', 'neq'));
    queryParams.filters.push(new FilterParameterApiModel('discriminator', ReactionTypeEnum.USER_REACTION, 'array'));
    queryParams.filters.push(new FilterParameterApiModel('discriminator', ReactionTypeEnum.PROJECT_REACTION, 'array'));
    userVM.getApiModel().projects.forEach((followedId: string) => {
      const arr = followedId.split('/');
      queryParams.filters.push(new FilterParameterApiModel('project.slug', arr[arr.length - 1], 'array'));
    });
    // queryParams.page = 1;
    return this.reactionRS.getCollection(queryParams).pipe(map((resultListAM: ResultListApiModel<ReactionApiModel>) => {
      return resultListAM.records;
    }));
  }

  public getAllUserFollowsCollection(userVM: UserViewModel): Observable<FollowApiModel[]> {
    const params = new QueryParamsApiModel();
    params.pagination = false;
    return this.userRS.getReceivedFollowsSubResourceCollection(userVM['@id'], params).pipe(map((resultListAM: ResultListApiModel<FollowApiModel>) => {
      userVM.receivedFollows = resultListAM.records;
      return resultListAM.records;
    }));
  }

  public getAllUserLikesCollection(userVM: UserViewModel): Observable<ReactionApiModel[]> {
    const params = new QueryParamsApiModel();
    params.pagination = false;
    return this.userRS.getReceivedLikesSubResourceCollection(userVM['@id'], params).pipe(map((resultListAM: ResultListApiModel<ReactionApiModel>) => {
      userVM.receivedReactions = resultListAM.records;
      return resultListAM.records;
    }));
  }

  public fetchCurrentUserFollows(currentUserVM: CurrentUserViewModel): Observable<ResultListApiModel<FollowApiModel>> {
    const params = new QueryParamsApiModel();
    params.pagination = false;
    return this.userRS.getFollowsSubResourceCollection(currentUserVM['@id'], params).pipe(tap((resultListAM: ResultListApiModel<FollowApiModel>) => {
      this.currentUserFollowersIds = resultListAM.records.map(e => e.followed['@id']);
    }));
  }

  public fetchCurrentUserLikes(currentUserVM: CurrentUserViewModel): Observable<ResultListApiModel<ReactionApiModel>> {
    const params = new QueryParamsApiModel();
    params.pagination = false;
    return this.userRS.getReactionsSubResourceCollection(currentUserVM['@id'], params).pipe(tap((resultListAM: ResultListApiModel<ReactionApiModel>) => {
      this.currentUserLikedIds = resultListAM.records.map(e => e.subject['@id']);
    }));
  }

  public isFollowedByCurrentUser(subjectUser: UserViewModel): boolean {
    return !!this.currentUserFollowersIds.find(id => id === subjectUser['@id']);
  }

  public isLikedByCurrentUser(subjectUser: UserViewModel): boolean {
    return !!this.currentUserLikedIds.find(id => id === subjectUser['@id']);
  }

  private generateLink(user: UserApiModel, listType: SocialListTypeEnum, type: string, item: any): string {
    console.log(listType, type);
    switch (listType) {
      case SocialListTypeEnum.FOLLOWS:
        switch (type) {
          case FollowTypeEnum.PROJECT: return `/${item.user.slug}/garage/${item.slug}`;
          case FollowTypeEnum.USER: return `/${item.slug}/profile`;
          case FollowTypeEnum.EVENT: return `/events/${item.slug}/general`;
        }
        break;
      case SocialListTypeEnum.REACTIONS:
        switch (type) {
          case ReactionTypeEnum.PROJECT_REACTION: return `/${item.user.slug}/garage/${item.slug}`;
          case ReactionTypeEnum.USER_REACTION: return `/${item.slug}/profile`;
          case ReactionTypeEnum.EVENT_REACTION: return `/events/${item.slug}/general`;
        }
        break;
      case SocialListTypeEnum.EVENTS:
        return `/events/${item.slug}`;
      case SocialListTypeEnum.EVENT_PARTICIPANTS:
        return `/${item.slug}/profile`;
    }
  }
}
