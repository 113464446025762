import {afterNextRender, Injectable} from '@angular/core';
import type * as Plyr from 'plyr';
import Hls from 'hls.js';
import {MediaObjectInterface} from '../../core/interfaces/media-object.interface';

@Injectable({
  providedIn: 'root',
})
export class VideoService {

  private players: { [key: string]: Plyr } = {};
  public currentVideoId: string | null = null;
  public firstVisibleVideoIndex: number | null = null;
  public lastVisibleVideoIndex: number | null = null;
  private lastScroll: number | null = null;
  public canScroll = true;
  public muted = true;
  private hls: Hls;
  private player: Plyr;
  private plyrModule: any;

  constructor() {
    afterNextRender(async () => {
      this.plyrModule = await import('plyr');
    })
  }

  async initPlayer(videoElement: HTMLVideoElement, mediaObject: MediaObjectInterface, callback?: () => void): Promise<Plyr> {
    const m3u8 = mediaObject.hyperlinks.find(e => e.name === 'm3u8');
    const Plyr = this.plyrModule.default;
    this.player = new Plyr(videoElement, {
      controls: [],
      muted: true,
      clickToPlay: false,
      enabled: true
    });
    this.player.muted = this.muted;

    if (callback && this.player) {
      this.player.on('ready', callback);
    }

    if (Hls.isSupported() && m3u8) {
      this.hls = new Hls();
      this.hls.loadSource(m3u8.uri);
      this.hls.attachMedia(videoElement);
      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        // this.setupQualitySwitching();
      });
    }

    const videoKey = videoElement.getAttribute('id');
    this.players[videoKey] = this.player;

    return this.player;
  }

  private setupQualitySwitching() {
    this.hls.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
      this.updateQualityLabel(data.level);
    });
  }

  private updateQualityLabel(level: number) {
    const span = document.querySelector(".plyr__menu__container [data-plyr='quality'][value='0'] span");
    if (this.hls.autoLevelEnabled) {
      span.innerHTML = `AUTO (${this.hls.levels[level].height}p)`;
    } else {
      span.innerHTML = `AUTO`;
    }
  }

  videoScroll() {
    const autoplayVideos = document.querySelectorAll('.timeline-player');
    if (autoplayVideos.length > 0 && this.canScroll) {
      const windowHeight = window.innerHeight;
      const scrollDirection = this.getScrollDirection();

      autoplayVideos.forEach((videoEl: HTMLVideoElement, index) => {
        const videoHeight = videoEl.clientHeight;
        const videoClientRect = videoEl.getBoundingClientRect();
        if (scrollDirection === 'down') {
          // console.log('videoClientRectTop:', videoClientRect.top, 'videoClientRectTop:', videoClientRect.bottom);
          // console.log('windowHeight:', windowHeight, 'videoHeight:', videoHeight, 'firstSUM:', windowHeight - videoHeight * 0.1, 'secondSUM:', (0 - videoHeight * 0.9));
          // console.log(videoClientRect.top <= windowHeight - videoHeight - margin);
          if (videoEl.getBoundingClientRect().top <= windowHeight && videoEl.getBoundingClientRect().bottom >= 0) {
            // console.log('down play', videoEl.id, videoEl.getBoundingClientRect().top, windowHeight);
            // console.log('down play', videoEl.id, videoEl.getBoundingClientRect().bottom, windowHeight);
            // console.log('play video down');
            this.playVideo(videoEl);
          } else {
            // console.log('down pause', videoEl.id, videoEl.getBoundingClientRect().top, windowHeight);
            // console.log('down pause', videoEl.id, videoEl.getBoundingClientRect().bottom, windowHeight);
            // console.log('pause video down');
            videoEl.pause();
            this.allowAnotherVideoToBePlayedIfVideoIsPaused(videoEl);
          }
        }

        if (scrollDirection === 'up') {
          // console.log('videoClientRectTop:', videoClientRect.top, 'videoClientRectBottom:', videoClientRect.bottom);
          // console.log('windowHeight:', windowHeight, 'videoHeight:', videoHeight, 'firstSUM:',  windowHeight - videoHeight - margin , 'secondSUM:', videoEl.getBoundingClientRect().bottom - margin >= 0);

          if (videoEl.getBoundingClientRect().top >= 0  && videoEl.getBoundingClientRect().top <= windowHeight) {
            // console.log('up play', videoEl.id, videoEl.getBoundingClientRect().top, windowHeight);
            // console.log('up play', videoEl.id, videoEl.getBoundingClientRect().bottom, windowHeight);
            // console.log('play video up');
            this.playVideo(videoEl);
          } else {
            // console.log('up pause', videoEl.id, videoEl.getBoundingClientRect().top, windowHeight);
            // console.log('up pause', videoEl.id, videoEl.getBoundingClientRect().bottom, windowHeight);
            // console.log('pause video up');
            videoEl.pause();
            this.allowAnotherVideoToBePlayedIfVideoIsPaused(videoEl);
          }
        }
      });
    }
  }

  private allowAnotherVideoToBePlayedIfVideoIsPaused(videoEl: HTMLVideoElement) {
    if (this.currentVideoId === videoEl.id) {
      this.currentVideoId = null;
    }
  }

  private playVideo(videoEl: HTMLVideoElement) {
      if (this.currentVideoId === null) {
        videoEl.play();
        videoEl.muted = this.muted;
        this.currentVideoId = videoEl.id;
      }
  }

  private getScrollDirection(): 'up' | 'down' {
    // Określ kierunek przewijania
    const currentScroll = window.scrollY;
    const lastScroll = this.lastScroll || currentScroll;
    this.lastScroll = currentScroll;

    return currentScroll > lastScroll ? 'down' : 'up';
  }

  public pauseAllExcept(id: number, prefix: string = 'video') {
    const autoplayVideos: NodeListOf<HTMLVideoElement> = document.querySelectorAll(`.timeline-player[id^="${prefix}"]:not([id="${prefix}-${id}"])`);
    autoplayVideos.forEach((e) => {
      e.pause();
    });
  }
}
