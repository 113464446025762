import {Exclude, Expose} from "class-transformer";
import {AbstractApiModel} from "./abstract.api.model";
import {
  FromUserNotificationInterface,
  NotificationInterface,
  ProjectNotificationInterface
} from "../../shared/interfaces/notification.interface";

@Exclude()
export class NotificationApiModel extends AbstractApiModel implements NotificationInterface {
  @Expose() public readonly id: number;
  @Expose() public readonly fromUser: FromUserNotificationInterface;
  @Expose() public readonly subject: ProjectNotificationInterface;
  @Expose() public readonly readAt: Date;
  @Expose() public readonly clickedAt: Date;
  @Expose() public readonly createdAt: Date;
  @Expose() public readonly event: 'project_follow' | 'garage_follow';
  @Expose() public readonly type: string;
}
