import { Injectable } from '@angular/core';
import {Meta} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class TranslatedMetaService {

  constructor(
    private readonly metaService: Meta,
    private readonly translate: TranslateService
  ) { }

  updateMetaTag(key: string) {
    if (this.hasTranslation(`meta.${key}`)) {
      this.metaService.updateTag({name: key, content: this.translate.instant(`meta.${key}`)})
    }
  }

  public hasTranslation(translationKey: string): boolean {
    let isTranslated: boolean = true;
    if (this.translate.instant(translationKey) == translationKey){
      isTranslated = false;
    }
    return isTranslated;
  }
}
