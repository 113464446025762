import {Component, Input, OnInit} from '@angular/core';
import {QueryResourceInterface} from '../../../core/models/query-resource.interface';
import {SiteLayoutComponent} from '../../../core/layout/site/site-layout.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-search-tile',
  templateUrl: './search-tile.component.html',
  styleUrls: ['./search-tile.component.scss']
})
export class SearchTileComponent implements OnInit {
  get hasLastQueriedResources(): boolean {
    return this.appMain.queryService.hasLastQueriedResources;
  }

  @Input() public resource: QueryResourceInterface;
  constructor(
    private readonly appMain: SiteLayoutComponent,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
  }

  goToPage(resource: QueryResourceInterface): void {
    this.router.navigate( [resource.uri]).then(() => {
      this.appMain.searchMenuClick = false;
      this.appMain.queryService.createLog(this.appMain.user, resource).subscribe(() => {
      });
    });
  }

  removeSearchLog(event: any, resource: QueryResourceInterface): void {
    event.preventDefault();
    this.appMain.queryService.removeSearchLog(resource).subscribe(() => {
      this.appMain.queryService.resources = this.appMain.queryService.resources.filter(e => e !== resource);
    });
  }
}
