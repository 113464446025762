import {Pipe, PipeTransform} from '@angular/core';
import {ProjectUserCountInterface} from '../interfaces/project-user-count.interface';
import {FollowedSubjectInterface, FollowerListItemInterface} from '../interfaces/follower-item.interface';
import {CurrentUserViewModel} from '../models/current-user.view-model';
import {TranslateService} from "@ngx-translate/core";
import {ReactedSubjectInterface, ReactionListItemInterface} from "../interfaces/reaction-list-item.interface";
import {ReactionSubjectInterface} from "../../shared/timeline/timeline-tile/reaction-subject.interface";

@Pipe({
  name: 'projectUserCount',
})
export class ProjectUserCountPipe implements PipeTransform {

  constructor(private translator: TranslateService) {
  }

  transform(value: FollowerListItemInterface | ReactionListItemInterface, currentUserVM: CurrentUserViewModel): ProjectUserCountInterface {
    let projects, users, isCurrentUser = null;
    switch (value.resource) {
      case 'reactions':
        projects = value.reactedTo.filter((reacted: ReactedSubjectInterface) => reacted.apiModel['@type'] === 'Project');
        users = value.reactedTo.filter((reacted: ReactedSubjectInterface) => reacted.apiModel['@type'] === 'User');
        isCurrentUser = !!users.find(u => u.apiModel['@id'] === currentUserVM['@id']);
        break;
      case 'follows':
        projects = value.followed.filter((followed: FollowedSubjectInterface) => followed.apiModel['@type'] === 'Project');
        users = value.followed.filter((followed: FollowedSubjectInterface) => followed.apiModel['@type'] === 'User');
        isCurrentUser = !!users.find(u => u.apiModel['@id'] === currentUserVM['@id']);
        break;
    }

    return {
      projectCount: projects.length,
      hasUser: !!users.length,
      userTranslateParam: isCurrentUser ? this.translator.instant('common.you') : this.translator.instant('common.profile'),
      projectTranslateParam: projects.length ? this.translator.instant('common.social.projects', {count: projects.length}) :  '',
      isCurrentUser
    };
  }
}
