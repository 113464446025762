import {Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {PersonalInformationApiModel} from "../models/personal-information.api.model";
import {plainToClass, plainToClassFromExist} from "class-transformer";
import {map} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {QueryParamsApiModel} from "../models/query-params-api.model";
import {ResultListApiModel} from "../models/result-list.api.model";
import {MediaObjectApiModel} from "../models/media-object.api-model";
import {QueryParamsService} from "../../core/services/query-params.service";
import {UserApiModel} from "../models/user.api-model";

@Injectable({
  providedIn: 'root'
})
export class PersonalInformationRestService {

  private readonly resource: string = '/infos';

  constructor(private http: HttpClient, private params: QueryParamsService) {
  }

  getCollection(queryParamsApiModel?: QueryParamsApiModel): Observable<ResultListApiModel<PersonalInformationApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<PersonalInformationApiModel>>(environment.apiUrl + this.resource, {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<PersonalInformationApiModel>(PersonalInformationApiModel), result as object)));
  }

  public getPersonal(id: string): Observable<PersonalInformationApiModel> {
    return this.http.get<PersonalInformationApiModel>(environment.apiUrl + id).pipe(
      map(response => plainToClass(PersonalInformationApiModel, response))
    );
  }

  public putPersonal(id: string, data: PersonalInformationApiModel): Observable<PersonalInformationApiModel> {
    return this.http.put<PersonalInformationApiModel>(environment.apiUrl + id, data).pipe(
      map(response => plainToClass(PersonalInformationApiModel, response))
    );
  }
}
