import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import {TokenDataProviderService} from './token-data-provider.service';
import {environment} from '../../../environments/environment';
import {NumberHelper} from '../helpers/number.helper';

export interface BackgroundInterface {
  background: string;
  ['background-size']: string;
}

@Injectable({
  providedIn: 'root'
})
export class BackgroundResolverService  {
  constructor(private ts: TokenDataProviderService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): BackgroundInterface {
    const url = environment.apiUrl.replace('/api', '');
    return {
      background: `url(${url}/uploads/backgrounds/bg${NumberHelper.getRandomInt(1, 29)}.webp) no-repeat fixed center`,
      'background-size': 'cover',
    };
  }
}
