import {Component, ElementRef, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {BottomActionSheetComponent} from '../sidebar/bottom-sidebar/bottom-action-sheet/bottom-action-sheet.component';
import {BottomSheetProvider} from 'swipe-bottom-sheet/angular';
import {SiteLayoutComponent} from '../../core/layout/site/site-layout.component';
import {AppComponent} from '../../app.component';
import {TokenDataProviderService} from '../../core/services/token-data-provider.service';
import {CurrentUserViewModel} from '../../core/models/current-user.view-model';
import {AnimationEvent} from '@angular/animations';
import {ActionsService} from './actions.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html',
})
export class FooterComponent implements OnInit {
  @Input() public user: CurrentUserViewModel;
  @ViewChild('searchInput') searchInputViewChild: ElementRef;
  count: number = 0;

  public constructor(
    public app: AppComponent,
    public appMain: SiteLayoutComponent,
    public actionsService: ActionsService,
    public ts: TokenDataProviderService,
    // private bottomSheet: BottomSheetProvider,
    private router: Router,
    vcRef: ViewContainerRef
  ) {
    // this.bottomSheet.rootVcRef = vcRef;
  }

  public ngOnInit(): void {
    // this.openSheet();
  }

  // async openSheet<T>() {
  //   const value = await this.bottomSheet.show(BottomActionSheetComponent, {
  //     title: 'Sheet Title',
  //     stops: [270],
  //     height: 200,
  //     maxHeight: 400
  //   });
  //   console.log({ value });
  // }

  public handleSearchButtonClick(event: Event) {
    event.preventDefault();
    this.appMain.onTopbarMobileButtonClick(event);
    this.appMain.onTopbarItemClick(event, 'search');
  }

  navigateToProfileOrGarage(event: Event){
    event.preventDefault();
    this.count++;
    setTimeout(() => {
      if (this.count === 1) {
        this.count = 0;
        this.router.navigate(['/', this.user.slug, 'profile']);
      }

      if (this.count > 1){
        this.count = 0;
        this.router.navigate(['/', this.user.slug, 'garage']);
      }
    }, 250);
  }
}
