import {Expose, plainToClassFromExist, Type} from 'class-transformer';
import {AbstractApiModel} from './abstract.api.model';

import {UserApiModel} from './user.api-model';
import {EventApiModel} from './event.api.model';
import {SubjectApiModel} from './follow.api-model';

export class ParticipantApiModel extends AbstractApiModel {
  @Expose() public id: number;
  @Expose() public event: EventApiModel|string;
  @Expose() public data: SubjectApiModel|UserApiModel|string;
  @Expose() public state: 'INTERESTED'| 'TAKES_PART' | 'ORGANIZES';
  @Expose() public type: 'PRIVATE' | 'BUSINESS';
  @Expose() public updatedAt: Date;
  @Expose() public createdAt: Date;

  public static fromParticipantVM(participantVM: object): ParticipantApiModel {
    return new ParticipantApiModel();
  }
}
