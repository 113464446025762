import {Exclude, Expose, Type} from 'class-transformer';
import {AbstractApiModel} from './abstract.api.model';
import {MediaObjectApiModel} from "./media-object.api-model";


export abstract class SubjectApiModel extends AbstractApiModel {
  id: number;
  theme: string;
  avatar: string|MediaObjectApiModel;
  slug?: string;
  identity: string;
}
@Exclude()
export class FollowApiModel extends AbstractApiModel {
  @Expose() public id: number;
  @Expose() public followedBy: any;
  @Type(() => SubjectApiModel)
  @Expose() public followed: SubjectApiModel;
  @Expose() public type: string;
}
