import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {TokenDataProviderService} from "../services/token-data-provider.service";

@Injectable()
export class Oauth2Guard {

  constructor(private router: Router, private ts: TokenDataProviderService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.ts.isAuthenticatedFully()) {
      return true;
    }

    this.router.navigate(['/oauth2-registration/complete']);

    return false;
  }
}
