import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";
import {StorageService} from "./storage.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {AuthApiModel} from "../../api/models/auth.api.model";
import {plainToClass, plainToInstance} from "class-transformer";
import {CurrentUserViewModel} from "../models/current-user.view-model";
import {BehaviorSubject, EMPTY, from, Observable, of, Subscription} from "rxjs";
import {concatMap, map, mergeMap, tap} from "rxjs/operators";
import {CredentialsApiModel} from "../../api/models/credentials.api.model";
import {AuthenticationRestService} from "../../api/services/authentication.rest.service";
import {NgxRolesService} from "ngx-permissions";
import {MediaObjectRestService} from "../../api/services/media-object.rest.service";
import {MediaObjectApiModel} from "../../api/models/media-object.api-model";
import {MediaObjectViewModel} from "../models/media-object.view-model";
import {QueryParamsApiModel} from "../../api/models/query-params-api.model";
import {FilterParameterApiModel} from "../../api/models/filter-parameter.api.model";
import {ResultListApiModel} from "../../api/models/result-list.api.model";
import {Oauth2UserApiModel} from "../../api/models/oauth2-user.api.model";
import {FacebookService} from "./facebook.service";
import {UserApiModel} from "../../api/models/user.api-model";


@Injectable({
  providedIn: 'root'
})
export class TokenDataProviderService {

  get currentUser(): Observable<CurrentUserViewModel> {
    if (!this.userSource.getValue()['@id']) {
      return this.refreshUser();
    }

    return this.user$;
  }

  get oAuth2User(): Oauth2UserApiModel {
    return plainToClass(Oauth2UserApiModel, this.getJwtUser());
  }

  set currentUser(currentUserViewModelObservable: Observable<CurrentUserViewModel>) {
    currentUserViewModelObservable.subscribe((currentUserViewModel: CurrentUserViewModel) => {
      this.userSource.next(currentUserViewModel);
    });
  }

  private userSource = new BehaviorSubject<CurrentUserViewModel>(new CurrentUserViewModel(this.getJwtUser()));
  public user$: Observable<CurrentUserViewModel>;
  private token: string;
  private refreshToken: string;
  private remember: boolean;

  constructor(
    private jwt: JwtHelperService,
    private storage: StorageService,
    private as: AuthenticationRestService,
    private mo: MediaObjectRestService,
    private rs: NgxRolesService,
    private fs: FacebookService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.user$ = this.userSource.asObservable();
  }

  public getTokenFromStorage(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.token = this.storage.getItem('token');
      this.refreshToken = this.storage.getItem('refresh_token');
    }
  }

  public setRemember(response: AuthApiModel): this {
    this.refreshToken = response.refreshToken;
    this.remember = true;
    this.storage.setItem('refresh_token', this.refreshToken);
    this.storage.setItem('remember', 'true');
    return this;
  }

  private setToken(response: AuthApiModel): this {
    const data = this.jwt.decodeToken(response.token);
    this.token = response.token;
    this.storage.setItem('token', this.token);
    this.storage.setItem('token_expiration', JSON.stringify({exp: data.exp, iat: data.iat}));
    return this;
  }

  public setSession(response: AuthApiModel, remember: boolean): void {
    if (isPlatformBrowser(this.platformId)) {
      this.setToken(response);
      if (remember) {
        this.setRemember(response);
      }
    }
  }

  public hasRemember() {
    let ls: String = '';
    if (isPlatformBrowser(this.platformId)) {
      ls = this.storage.getItem('remember');
    }
    return ls === 'true';
  }

  public getJwtUser(): UserApiModel {
    if (isPlatformBrowser(this.platformId)) {
      const jwtToken = this.storage.getItem('token');
      if (jwtToken) {
        const data = this.jwt.decodeToken(jwtToken);
        const user = plainToInstance(UserApiModel, data);
        this.rs.flushRoles();
        this.loadRoles(user);
        return user;
      }
    }
    return new UserApiModel();
  }

  public isLoggedIn(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      return !this.jwt.isTokenExpired();
    }
  }

  public isAuthenticatedFully(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      return this.isLoggedIn() && !!this.getJwtUser().slug;
    }
  }

  public login(credentials: CredentialsApiModel): Observable<AuthApiModel> {
    return this.as.login(credentials)
      .pipe(tap((response: AuthApiModel) => {
        this.setSession(response, credentials.remember);
        this.userSource.next(new CurrentUserViewModel(this.getJwtUser()))
      }));
  }

  public fbLogin() {
    return from(this.fs.loginWithFacebook())
      .pipe(
        concatMap(({accessToken}) => {
          if (!accessToken) return EMPTY;
          return of(accessToken);
        }),
        concatMap(accessToken => this.as.fbLogin(accessToken.token)),
        tap((response: AuthApiModel) => {
          this.setSession(response, false);
          this.userSource.next(new CurrentUserViewModel(this.getJwtUser()))
        })
      );
  }

  public refreshUser(): Observable<CurrentUserViewModel> {
    return this.as.getCurrentUser()
      .pipe(
        mergeMap((userApiModel: UserApiModel) => {
          const currentUserVM = new CurrentUserViewModel(userApiModel);
          this.loadRoles(userApiModel);
          const params = new QueryParamsApiModel();
          if (userApiModel.avatar) {
            params.filters.push(new FilterParameterApiModel('id', userApiModel.avatar));
          }
          if (userApiModel.background) {
            params.filters.push(new FilterParameterApiModel('id', userApiModel.background));
          }
          if (params.filters.length) {
            return this.mo.getMediaObjectCollection(params).pipe(map((mediaObjectResultList: ResultListApiModel<MediaObjectApiModel>) => {
              const avatar = mediaObjectResultList.records.find(e => e["@id"] === userApiModel.avatar);
              const background = mediaObjectResultList.records.find(e => e["@id"] === userApiModel.background);
              currentUserVM.avatar = new MediaObjectViewModel(avatar);
              currentUserVM.background = new MediaObjectViewModel(background);
              return currentUserVM;
            }));
          }
          return of(currentUserVM);
        }),
        mergeMap((currentUserVM: CurrentUserViewModel) => {
          this.userSource.next(currentUserVM);
          return this.user$;
        })
      );
  }

  private loadRoles(userApiModel: UserApiModel): void {
    userApiModel.roles.forEach((e) => this.rs.addRole(e, []));
  }

  public refreshJwtToken(): Observable<AuthApiModel> {
    this.getTokenFromStorage();
    return this.as.postRefreshToken(this.refreshToken).pipe(tap((authApiModel: AuthApiModel) => {
      this.setSession(authApiModel, this.hasRemember())
    }));
  }

  public logout(): void {
    this.clearAllTokenRelatedFromStorage();
    this.rs.flushRoles();
  }

  private clearAllTokenRelatedFromStorage(): void {
    // clear token remove user from to log user out
    if (isPlatformBrowser(this.platformId)) {
      this.storage.removeItem('token');
      this.storage.removeItem('token_expiration');
      this.storage.removeItem('refresh_token');
      this.storage.removeItem('remember');
    }
  }
}
