<p-sidebar
  appendTo="body"
  [(visible)]="isSidebarOpened"
  position="bottom"
  [showCloseIcon]="false"
  styleClass="p-sidebar-lg timeline-sidebar"
  [autoZIndex]="false"
  [style]="{height: 'auto' ,left: 0, right: 0, margin: '0 auto', maxHeight: '100vh', zIndex: 6002, 'overflow-y': 'auto'}"
  [dismissible]="true"
  [modal]="true"
  (onHide)="onHide.emit()"
>
  <div class="grid grid-nogutter justify-content-center" style="width: 100%">
    <div class="col-12 sm:col-10 xl:col-5" [style]="{maxWidth: '900px'}">
      <div class="grid grid-nogutter pt-0 mt-0 px-0 pb-2 flex justify-content-around align-items-center justify-content-center">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
  <button pButton
          icon="pi pi-arrow-left fa-1x" class="p-button-rounded p-button-text p-button-plain"
          style="position: absolute; left: 0; top: -1.5em;"
          pRipple (click)="goBack()"
          *ngIf="isBackButtonVisible"
  ></button>
  <button pButton icon="pi pi-times fa-1x" class="p-button-rounded p-button-text p-button-plain"
          style="position: absolute; right: 0; top: -1.5em;" pRipple (click)="hideSidebar($event)"></button>
</p-sidebar>
