import {AbstractApiModel} from "./abstract.api.model";
import {Exclude, Expose} from "class-transformer";
import {BodyworkInterface} from "../../features/user/project/parameters/bodywork-interface";
import {ParameterInterface} from "../../features/user/project/parameters/parameter-interface";
import {EngineInterface} from "../../features/user/project/parameters/engine-interface";
import {DictInterface} from "../../features/user/project/parameters/dict-interface";
import {TransmissionInterface} from "../../features/user/project/parameters/transmission-interface";

@Exclude()
export class VehicleApiModel extends AbstractApiModel {
  @Expose() public id: number;
  @Expose() public vehicleType: string|DictInterface;
  @Expose() public make: string|DictInterface;
  @Expose() public bodyGen: string|DictInterface;
  @Expose() public bodyType: string|DictInterface;
  @Expose() public edition: string|DictInterface;
  @Expose() public engine: string|EngineInterface;
  @Expose() public transmission: string|TransmissionInterface;
  @Expose() public wheelDrive: string|DictInterface;
  @Expose() public model: string|DictInterface;
  @Expose() public fullVehicleIdentity: string;
  @Expose() public bodyWork: BodyworkInterface;
  @Expose() public parameter: ParameterInterface;
  @Expose() public frontSuspension: string|DictInterface;
  @Expose() public rearSuspension: string|DictInterface;
  @Expose() public frontBrake: string|DictInterface;
  @Expose() public rearBrake: string|DictInterface;
}
