import {Component, OnInit, OnDestroy} from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {ModalInterface} from '../../modal.interface';
import {Subscription, Observable, Subject} from 'rxjs';
import {ModalState} from '../../modal-state.enum';
import {ModalStateEvent} from "../../modal-state-event";
import {AddVehicleDataProviderService} from "./add-vehicle.data-provider.service";
import {VehicleTypeViewModel} from "../../../../core/models/vehicle-type.view-model";
import {MakeViewModel} from "../../../../core/models/make.view-model";
import {VehicleViewModel} from "../../../../features/user/garage/vehicle.view-model";
import {BodyTypeViewModel} from "../../../../core/models/body-type.view-model";
import {ModelViewModel} from "../../../../core/models/model.view-model";
import {BodyGenViewModel} from "../../../../core/models/body-gen.view-model";
import {EditionViewModel} from "../../../../core/models/edition.view-model";

@Component({
  selector: 'app-add-vehicle',
  templateUrl: './add-vehicle.component.html',
  styleUrls: ['./add-vehicle.component.scss'],
  providers: [AddVehicleDataProviderService]
})
export class AddVehicleComponent implements OnInit, ModalInterface, OnDestroy {

  get state(): Observable<ModalStateEvent> {
    return this._state;
  }

  set state(value: Observable<ModalStateEvent>) {
    this._state = value;
  }

  set subject(value: Subject<ModalStateEvent>) {
    this._subject = value;
  }

  public data: UntypedFormGroup;
  private _state: Observable<ModalStateEvent>;
  private _subject: Subject<ModalStateEvent>;
  public vehicleForm: UntypedFormGroup;
  public vehicleTypes: VehicleTypeViewModel[];
  public makes: MakeViewModel[];
  public bodyTypes: BodyTypeViewModel[];
  public models: ModelViewModel[];
  public bodyGens: BodyGenViewModel[];
  public editions: EditionViewModel[];
  public vehicle: VehicleViewModel;
  private subscription: Subscription;

  constructor(
    public addVehicleDPS: AddVehicleDataProviderService,
    private fb: UntypedFormBuilder
  ) {
  }

  public ngOnInit(): void {
    this.createVehicleForm();
    this.seekVehicleTypes();
  }

  public ngOnDestroy(): void {
    this.subscription ? this.subscription.unsubscribe() : null;
  }

  /**
   * THIS DECLARATION MUST BE IN THE SAME FLOW ORDER AS IN THE VIEW BECAUSE disableRelatedChoices METHOD WILL BROKE.
   */
  private createVehicleForm(): void {
    this.vehicleForm = this.fb.group({
      vehicleType: new UntypedFormControl({value: ''}, [Validators.required]),
      make: new UntypedFormControl({value: '', disabled: true}, [Validators.required]),
      bodyType: new UntypedFormControl({value: '', disabled: true}, [Validators.required]),
      model: new UntypedFormControl({value: '', disabled: true}, [Validators.required]),
      bodyGen: new UntypedFormControl({value: '', disabled: true}, [Validators.required]),
      edition: new UntypedFormControl({value: '', disabled: true}, [Validators.required]),
    });
  }

  public seekVehicleTypes(): void {
    this.addVehicleDPS.getVehicleTypeCollection()
      .subscribe((vehicleTypeVMs: VehicleTypeViewModel[]) =>  {
        this.vehicleTypes = vehicleTypeVMs;
      });
  }

  public seekMakes(vehicleTypeVM: VehicleTypeViewModel): void {
    this._subject.next(new ModalStateEvent(ModalState.PROCESS));
    this.disableRelatedChoices('make');
    this.unsubscribePending();
    this.subscription = this.addVehicleDPS.getMakeCollection(vehicleTypeVM).subscribe(
      (makeVMs: MakeViewModel[]) => {
        this.makes = makeVMs;
        this.vehicleForm.get('make').enable();
        this._subject.next(new ModalStateEvent(ModalState.PROCESS_END));
      },
      error => {

      }
    );
  }

  public seekBodyTypes(vehicleTypeVM: VehicleTypeViewModel, makeVM: MakeViewModel): void {
    this._subject.next(new ModalStateEvent(ModalState.PROCESS));
    this.disableRelatedChoices('bodyType');
    this.unsubscribePending();
    this.subscription = this.addVehicleDPS.getBodyTypeCollection(vehicleTypeVM, makeVM).subscribe(
      (bodyTypeVMs: BodyTypeViewModel[]) => {
        this.bodyTypes = bodyTypeVMs;
        this.vehicleForm.get('bodyType').enable();
        this._subject.next(new ModalStateEvent(ModalState.PROCESS_END));
      },
      error => {

      }
    );
  }

  public seekModels(vehicleTypeVM: VehicleTypeViewModel, makeVM: MakeViewModel, bodyTypeVM: BodyTypeViewModel): void {
    this._subject.next(new ModalStateEvent(ModalState.PROCESS));
    this.disableRelatedChoices('model');
    this.unsubscribePending();
    this.subscription = this.addVehicleDPS.getModelCollection(vehicleTypeVM, makeVM, bodyTypeVM).subscribe(
      (modelVMs: ModelViewModel[]) => {
        this.models = modelVMs;
        this.vehicleForm.get('model').enable();
        this._subject.next(new ModalStateEvent(ModalState.PROCESS_END));
      },
      error => {

      }
    );
  }

  public seekBodyGens(
    vehicleTypeVM: VehicleTypeViewModel,
    makeVM: MakeViewModel,
    bodyTypeVM: BodyTypeViewModel,
    modelVM: ModelViewModel
  ): void {
    this._subject.next(new ModalStateEvent(ModalState.PROCESS));
    this.disableRelatedChoices('bodyGen');
    this.unsubscribePending();
    this.subscription = this.addVehicleDPS.getBodyGenCollection(vehicleTypeVM, makeVM, bodyTypeVM, modelVM).subscribe(
      (bodyGenVMs: BodyGenViewModel[]) => {
        this.bodyGens = bodyGenVMs;
        this.vehicleForm.get('bodyGen').enable();
        this._subject.next(new ModalStateEvent(ModalState.PROCESS_END));
      },
      error => {

      }
    );
  }

  public seekEditions(
    vehicleTypeVM: VehicleTypeViewModel,
    makeVM: MakeViewModel,
    bodyTypeVM: BodyTypeViewModel,
    modelVM: ModelViewModel,
    bodyGenVM: BodyGenViewModel
  ): void {
    this._subject.next(new ModalStateEvent(ModalState.PROCESS));
    this.disableRelatedChoices('edition');
    this.unsubscribePending();
    this.subscription = this.addVehicleDPS.getEditionCollection(vehicleTypeVM, makeVM, bodyTypeVM, modelVM, bodyGenVM)
      .subscribe(
      (editionVMs: EditionViewModel[]) => {
        this.editions = editionVMs;
        this.vehicleForm.get('edition').enable();
        this._subject.next(new ModalStateEvent(ModalState.PROCESS_END));
      },
      error => {

      }
    );
  }

  public seekVehicle(): void {
    this._subject.next(new ModalStateEvent(ModalState.PROCESS));
    this.unsubscribePending();
    this.addVehicleDPS.getVehicleByParams(this.vehicleForm.value)
      .subscribe((vehicles: VehicleViewModel[]) => {
        const vehicleVM = vehicles[0];
        vehicleVM.vehicleType = this.vehicleForm.get('vehicleType').value;
        this._subject.next(new ModalStateEvent(ModalState.VALID, vehicleVM));
      });
  }

  /**
   * TODO: Refactor this, as this method require specific declaration flow of form fields;
   * @param {string} startField
   */
  private disableRelatedChoices(startField: string): void {
    let flag = false;
    for (const key in this.vehicleForm.controls) {
      if (key === startField) {
        flag = true;
      }
      if (flag) {
        this.vehicleForm.controls[key].reset();
        this.vehicleForm.controls[key].disable({onlySelf: true, emitEvent: false});
      }
    }
  }

  private unsubscribePending(): void {
    if (this.subscription instanceof Subscription) {
      this.subscription.unsubscribe();
    }
  }
}
