<div class="footer shadow-4 flex justify-content-center">
  <div class="flex height-100 width-100 justify-content-between" style="max-width: 900px">
    <div class="ml-3">
      <a pButton pRipple class="hoverable p-button-text p-button-plain rounded-circle p-button-icon-only" type="button" icon="pi pi-home fs-large" [routerLinkActive]="['p-highlight']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/']">
<!--        <i class="pi pi-search fs-large"></i>-->
      </a>
    </div>
    <div>
      <a pButton pRipple class="hoverable p-button-text p-button-plain rounded-circle p-button-icon-only" type="button" icon="pi pi-search fs-large" (click)="handleSearchButtonClick($event)">
        <!--        <i class="pi pi-search fs-large"></i>-->
      </a>
    </div>
    <div *ngxPermissionsOnly="actionsService.contextUser ? [actionsService.contextUser | ownerRole] : actionsService.permissions" >
      <button type="button" icon="pi pi-plus fs-large" pButton class="hoverable p-button-text rounded-circle p-button-icon-only" (click)="actionsService.onAdd()"></button>
    </div>
    <div>
      <button type="button" icon="pi pi-calendar fs-large" pButton class="hoverable p-button-text p-button-plain  rounded-circle p-button-icon-only" [routerLinkActive]="['p-highlight']" [routerLink]="['/events']"></button>
    </div>
    <div class="mr-3">
      <button (click)="navigateToProfileOrGarage($event)" (tap)="navigateToProfileOrGarage($event)" pButton pRipple type="button" [routerLinkActive]="['p-highlight']" [routerLink]="[user.slug]"
              class="hoverable p-button-text p-button-plain mr-1 rounded-circle p-button-icon-only">
        <app-thumbnail
          [imgSrc]="this.user.avatar | mediaObject" [circle]="true" [bordered]="true"
          stylesList="width: 28px; height: 28px;"
        ></app-thumbnail>
      </button>
<!--      <a [routerLink]="['/', user.slug, 'garage']" title="Garage" pButton pRipple type="button"-->
<!--         icon="fas fa-warehouse fs-large" class="hoverable p-button-text p-button-plain mr-1 rounded-circle"></a>-->
    </div>
  </div>

</div>
