<form [formGroup]="form" novalidate #wrapper>
  <div class="grid grid-nogutter">
    <div class="col-12 py-2">
      <p>{{'shared.modal.component.add_goal.basic_information' | translate }}</p>
    </div>
    <div class="col-12 py-2 lg:px-5 p-fluid">
      <div class="p-field p-float-label p-input-icon-left">
        <i class="pi pi-user"></i>
        <input id="name" pInputText type="text" formControlName="name">
        <label for="name">{{'shared.modal.component.add_goal.goal_name' | translate }}</label>
      </div>
    </div>
    <div class="col-12 py-2 lg:px-5 p-fluid">
      <div class="p-field p-float-label p-input-icon-left">
        <i class="pi pi-pencil"></i>
        <textarea id="timeLinePost" [rows]="1" pInputTextarea autoResize="autoResize" type="text"
                  formControlName="description"
        ></textarea>
        <label for="timeLinePost">{{'shared.modal.component.add_goal.describe_goal' | translate }}</label>
      </div>
    </div>
    <div class="col-12 py-2 lg:px-5 p-fluid">
      <div class="p-field p-float-label p-input-icon-left">
        <p-calendar
          formControlName="startAt"
          appendTo="body"
          inputId="icon"
          [readonlyInput]="true"
          [showIcon]="true"
          [touchUI]="true"
        ></p-calendar>
        <label>{{ 'Start date' }}</label>

      </div>
    </div>
    <div class="col-6 py-2 lg:px-5 p-fluid">
      <div class="p-field flex justify-content-start">
        <label for="timeLinePost" class="pr-2">{{'shared.modal.component.add_goal.is_finished' | translate }}</label>
        <p-inputSwitch formControlName="isFinished"></p-inputSwitch>
      </div>
    </div>
    <div class="col-6 py-2 lg:px-5 p-fluid">
      <div class="p-field flex justify-content-start">
        <label for="timeLinePost" class="pr-2">{{'shared.modal.component.add_goal.has_tasks' | translate }}</label>
        <p-inputSwitch formControlName="hasTasks"></p-inputSwitch>
      </div>
    </div>
    <div class="col-12 py-2 lg:px-5 p-fluid">
      <div class="p-field p-float-label p-input-icon-left">
        <p-calendar
          *ngIf="form.get('isFinished').value"
          formControlName="finishedAt"
          appendTo="body"
          inputId="icon"
          [readonlyInput]="true"
          [showIcon]="true"
          [touchUI]="true"
        ></p-calendar>
        <p-calendar
          *ngIf="!form.get('isFinished').value"
          formControlName="estimatedFinishAt"
          appendTo="body"
          inputId="icon"
          [readonlyInput]="true"
          [showIcon]="true"
          [touchUI]="true"
        ></p-calendar>
        <label>{{(form.get('isFinished').value ? 'shared.modal.component.add_goal.finish_date' : 'shared.modal.component.add_goal.estimated_date') | translate }}</label>
      </div>
    </div>
  </div>
<!--  <div class="grid">-->
<!--    <div class="col-12">-->
<!--      <div class="p-field">-->
<!--        <label for="timeLinePost">Marker icon</label>-->
<!--        <p-selectButton-->
<!--          [options]="availableMarkerIcons"-->
<!--          formControlName="markerIcon" optionLabel="name"-->
<!--          optionValue="icon"-->
<!--        >-->
<!--          <ng-template let-item>-->
<!--            <div class="p-d-flex p-jc-center" style="width: 100%">-->
<!--              <img [alt]="'assets/icons/car/' + item.icon" [src]="'assets/icons/car/' + item.icon" height="40px">-->
<!--            </div>-->
<!--          </ng-template>-->
<!--        </p-selectButton>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</form>
<div *ngIf="form.get('hasTasks').value" class="grid grid-nogutter">
  <div class="col-12">
    <p>{{'shared.modal.component.add_goal.add_task' | translate }}</p>
  </div>
  <div class="col-12 py-2 lg:px-5 p-fluid">
    <div class="p-field p-float-label p-input-icon-left">
      <i class="pi pi-user"></i>
      <input id="taskName" pInputText type="text" [(ngModel)]="inputTask.name">
      <label for="taskName">{{'shared.modal.component.add_goal.task_name' | translate }}</label>
    </div>
  </div>
  <div class="col-12 py-2 lg:px-5 p-fluid">
    <div class="p-field p-float-label p-input-icon-left">
      <p-inputNumber id="summaryCost" [(ngModel)]="inputTask.summaryCost" mode="currency" currency="USD" locale="en-US" [minFractionDigits]="2"></p-inputNumber>
      <label for="summaryCost">{{'shared.modal.component.add_goal.summary_cost' | translate }}</label>
    </div>
  </div>
  <div class="col-6 py-2 lg:px-5 p-fluid">
    <div class="p-field flex justify-content-start">
      <label for="timeLinePost" class="pr-2">{{'shared.modal.component.add_goal.is_finished' | translate }}</label>
      <p-inputSwitch [disabled]="this.form.get('isFinished').value" [(ngModel)]="inputTask.isFinished"></p-inputSwitch>
    </div>
  </div>
  <div class="col-6 py-2 lg:px-5 flex justify-content-end p-field" style="gap: 10px">
    <button
      pButton
      pRipple
      type="button"
      [icon]="'pi ' + ( inputTask.inEdit ? 'pi-save' : 'pi-plus')"
      class="p-button-rounded p-button-primary p-button-outlined"
      [disabled]="!inputTask.name"
      (click)="addTask()"
    ></button>
    <button
      pButton
      pRipple
      type="button"
      icon="pi pi-times"
      class="p-button-rounded p-button-secondary p-button-outlined"
      (click)="inputTask.inEdit = false; clearInputTask()"
      [disabled]="!inputTask.name"
    ></button>
    <button
      pButton
      pRipple
      type="button"
      icon="pi pi-trash"
      class="p-button-rounded p-button-danger p-button-outlined"
      (click)="removeTask();"
      [disabled]="!(inputTask.name && inputTask.inEdit)"
    ></button>
  </div>
  <div *ngIf="tasks.length" class="col-12 py-2 lg:px-5 p-fluid">
    <p-table [value]="tasks" [columns]="cols" [reorderableColumns]="true" responsiveLayout="scroll" selectionMode="single" dataKey="name" [selection]="inputTask" (onRowSelect)="selectTask($event)" (onRowUnselect)="unselectTask()">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th></th>
          <th *ngFor="let col of columns" pReorderableColumn>
            {{col.header | translate}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
        <tr [pReorderableRow]="index" [pSelectableRow]="rowData">
          <td>
            <span class="pi pi-bars" pReorderableRowHandle="index"></span>
          </td>
          <td *ngFor="let col of columns" [ngSwitch]="col['type']">
            <ng-container *ngSwitchCase="'checkbox'">
              <p-checkbox [value]="true" [(ngModel)]="rowData[col['field']]" [disabled]="true" [binary]="true"></p-checkbox>
            </ng-container>
            <ng-container *ngSwitchCase="'currency'">
              {{rowData[col['field']] | currency: '$'}}
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{rowData[col['field']]}}
            </ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
