import {Component} from '@angular/core';
import {AppComponent} from "../../app.component";
import {SiteLayoutComponent} from "../../core/layout/site/site-layout.component";

@Component({
    selector: 'app-rightmenu',
    templateUrl: './app.rightmenu.component.html'
})
export class AppRightMenuComponent {
    constructor(public appMain: SiteLayoutComponent, public app: AppComponent) {}
}
