import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ResponseNotificationService} from "../../../core/services/response-notification.service";
import {environment} from "../../../../environments/environment";
import {AuthenticationRestService} from "../../../core/services";

@Component({
  selector: 'app-reset-confirmation',
  templateUrl: './reset-confirmation.component.html',
  styleUrls: ['./reset-confirmation.component.scss']
})
export class ResetConfirmationComponent implements OnInit {

  public currentStyles = {
    'background': 'url(' + environment.apiUrl + '/uploads/backgrounds/test.jpg) no-repeat fixed center',
    'background-size': 'cover',
  };

  private token: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private as: AuthenticationRestService,
    private rns: ResponseNotificationService
  ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(
      params => {
        this.token = params.token;
      }
    );
    if (this.token && typeof this.token === 'string') {
      this.as.resetConfirm(this.token).subscribe(
        successResponse => {
          this.rns.emitMessage(successResponse.message);
        },
        errorResponse => {
          this.rns.emitError(errorResponse.error['hydra:description']);
        }
      ).add(() => this.router.navigate(['login']));
    }
  }

}
