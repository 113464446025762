import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';

import {QueryParamsService} from '../../core/services/query-params.service';
import {map} from 'rxjs/operators';


@Injectable()
export class FileRestService {

  constructor(
    public http: HttpClient,
    public params: QueryParamsService
  ) {
  }

  getImage(imageUrl: string): Observable<File> {
    return this.http
      .get(imageUrl, {responseType: 'blob', headers: {
          Accept: '*'
        }}).pipe(map((e) => {
        const imgParts = imageUrl.split('/').pop();
        return new File([e], imgParts, {type: e.type, lastModified: Date.now()});
      }));
  }
}
