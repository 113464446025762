import { HttpClient } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Capacitor, CapacitorGlobal} from '@capacitor/core';
import {Directory, Filesystem, FilesystemPlugin, ReadFileResult} from '@capacitor/filesystem';
import {BehaviorSubject} from 'rxjs';
import {CustomCameraMediaItem, CustomCameraMediaType, CustomCameraMimeType, } from './custom-camera';
import {blobToBase64} from '../../shared/camera/camera.service';
import {CaptureService} from '../../shared/capture/capture.service';
import {FILESYSTEM_PLUGIN} from '../../shared/capacitor-plugins/capacitor-plugins.module';
import {CameraPreview, CameraPreviewOptions, CameraPreviewPictureOptions} from '@capacitor-community/camera-preview';
import {base64ToBlob} from '../../utils/encoding/encoding';
import {v4 as uuidv4} from 'uuid';
import {Media, MediaSaveOptions} from '@capacitor-community/media';
// import {PreviewCamera} from '@numbersprotocol/preview-camera';

@Injectable({
  providedIn: 'root',
})
export class CustomCameraService {
  private readonly globalCSSClass = 'custom-camera-transparent-background';

  uploadInProgress$ = new BehaviorSubject<boolean>(false);
  private readonly platform: CapacitorGlobal;
  private cameraPreviewOptions: CameraPreviewOptions = {
    position: 'rear',
    parent: 'camera-preview',
    y: 64,
    paddingBottom: 168 + 64,
    lockAndroidOrientation: true,
    enableHighResolution: true,
  };

  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly httpClient: HttpClient,
    private readonly captureService: CaptureService,
    @Inject(FILESYSTEM_PLUGIN)
    private readonly filesystemPlugin: FilesystemPlugin,
  ) {
    this.platform = Capacitor;
  }

  public async mediaItemFromFilePath(filePath: string, type: CustomCameraMediaType, base64: string = null) {
    const src = Capacitor.convertFileSrc(filePath);
    const safeUrl = this.sanitizer.bypassSecurityTrustUrl(src);
    const mimeType: CustomCameraMimeType = type === 'image' ? 'image/jpeg' : 'video/mp4';

    return {filePath, base64, src, safeUrl, type, mimeType};
  }

  async uploadToCapture(filePath: string, type: CustomCameraMediaType) {
    const itemToUpload = await this.mediaItemFromFilePath(filePath, type);

    try {
      const itemBlob = await this.httpClient
        .get(itemToUpload.src, { responseType: 'blob' })
        .toPromise();
      const base64 = await blobToBase64(itemBlob);
      const mimeType = itemToUpload.mimeType;
      await this.captureService.capture({ base64, mimeType });
      await this.removeFile(filePath);
    } catch (error) {
      const errMsg = '';
      // await this.errorService.toastError$(errMsg).toPromise();
    }
  }

  async startPreviewCamera() {

    await CameraPreview.start(this.cameraPreviewOptions);
    // return PreviewCamera.startPreview()
    //   .then(this.changeGlobalCSSBackgroundToTransparent.bind(this))
    //   .catch((e) => console.log(e));
  }

  async stopPreviewCamera() {
    // this.changeGlobalCSSBackgroundToTransparentRevert();
    await CameraPreview.stop();
  }

  // eslint-disable-next-line class-methods-use-this
  async flipCamera() {
    // return PreviewCamera.flipCamera().catch(() => ({}));
  }

  // eslint-disable-next-line class-methods-use-this
  async takePhoto() {
    const cameraPreviewPictureOptions: CameraPreviewPictureOptions = {
      quality: 100,
    };

    // const result = await PreviewCamera.takePhoto();
    const result = await CameraPreview.capture(cameraPreviewPictureOptions);
    const base64PictureData = `data:image/jpeg;base64,${result.value}`;
    console.log(base64PictureData);
    const blob = await base64ToBlob(result.value, 'image/jpeg');
    const id = uuidv4();
    let filePath = null;
    console.log(this.platform.isNativePlatform(), 'test');
    if (this.platform.isNativePlatform()) {
      const opts: MediaSaveOptions = { path: base64PictureData, fileName: `${id}.jpeg`, albumIdentifier: await this.ensureDemoAlbum() };
      const res = await Media.savePhoto(opts);
      filePath = res.filePath;
    } else {
      // @ts-ignore
      filePath = URL.createObjectURL(blob);
    }

    return this.mediaItemFromFilePath(filePath, 'image', base64PictureData);
  }

  public startRecord(): void {
    CameraPreview.startRecordVideo(this.cameraPreviewOptions).catch((reason) => console.log(reason));
  }

  async stopRecord(): Promise<CustomCameraMediaItem> {
    const resultRecordVideo = await CameraPreview.stopRecordVideo();
    const id = uuidv4();
    const opts: MediaSaveOptions = { path: resultRecordVideo.videoFilePath, fileName: `${id}.mp4`, albumIdentifier: await this.ensureDemoAlbum() };
    const res = await Media.saveVideo(opts);

    return this.mediaItemFromFilePath(res.filePath, 'video');
  }

  async ensureDemoAlbum() {
    const { albums } = await Media.getAlbums();
    let demoAlbum;
    if (Capacitor.getPlatform() === 'android') {
      const albumsPath = (await Media.getAlbumsPath()).path;
      demoAlbum = albums.find(a => a.name === 'WatchThisGarage' && a.identifier.startsWith(albumsPath));
      console.log(demoAlbum);
    } else {
      demoAlbum = albums.find(a => a.name === 'WatchThisGarage');
    }

    if (!demoAlbum) {
      Media.createAlbum({name: 'WatchThisGarage'});
    }

    return demoAlbum.identifier;
  }

  async readFile(filePath: string): Promise<ReadFileResult> {
    // Here's an example of reading a file with a full file path. Use this to
    // read binary data (base64 encoded) from plugins that return File URIs, such as
    // the Camera.
    const contents = await Filesystem.readFile({
      path: filePath // 'file:///var/mobile/Containers/Data/Application/22A433FD-D82D-4989-8BE6-9FC49DEA20BB/Documents/text.txt',
    });

    console.log('data:', contents);
    return contents;
  }

  async removeFile(filePath: string | undefined) {
    if (!filePath) { return; }
    await this.filesystemPlugin.deleteFile({ path: filePath });
  }

  async isTorchOn() {
    if (this.isNativePlatform) {
      // return await PreviewCamera.isTorchOn();
    }
    return { result: false };
  }

  async enableTorch(enable: boolean): Promise<void> {
    if (this.isNativePlatform) {
      // return await PreviewCamera.enableTorch({ enable });
    }
    return Promise.resolve();
  }

  async focus(x: number, y: number) {
    if (this.isNativePlatform) {
      // return await PreviewCamera.focus({ x, y });
    }
    return Promise.resolve();
  }

  async minZoomFactor(): Promise<number> {
    const defaultMinAvailableZoom = 0;
    if (this.isNativePlatform) {
      // return (await PreviewCamera.minAvailableZoom()).result;
    }
    return defaultMinAvailableZoom;
  }

  async maxZoomFactor(): Promise<number> {
    const defaultMaxAvailableZoom = 0;
    // if (this.isNativePlatform) {
    //   let maxZoomFactor = (await PreviewCamera.maxAvailableZoom()).result;
    //   const sensitivityFactor = 4;
    //   if (maxZoomFactor > 0) {
    //     maxZoomFactor /= sensitivityFactor;
    //   }
    //   return maxZoomFactor;
    // }
    return Promise.resolve(defaultMaxAvailableZoom);
  }

  async zoom(zoomFactor: any) {
    if (this.isNativePlatform) {
      // return await PreviewCamera.zoom({ factor: zoomFactor });
    }
    return Promise.resolve();
  }

  async isTorchAvailable(): Promise<boolean> {
    if (this.isNativePlatform) {
      // return (await PreviewCamera.isTorchAvailable()).result;
    }
    return false;
  }

  async setCameraQuality(quality: 'low' | 'hq'): Promise<void> {
    if (!this.isNativePlatform) { return; }

    // await PreviewCamera.setQuality({ quality }).catch((e) => console.log(e));
  }

  private get isNativePlatform() {
    return this.platform.isNativePlatform();
  }

  private changeGlobalCSSBackgroundToTransparent() {
    document.querySelector('body')?.classList.add(this.globalCSSClass);
  }

  private changeGlobalCSSBackgroundToTransparentRevert() {
    document.querySelector('body')?.classList.remove(this.globalCSSClass);
  }
}
