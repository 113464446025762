import {AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalInterface} from '../../modal.interface';
import {concat, EMPTY, Observable, of, Subject} from 'rxjs';
import {ModalStateEvent} from '../../modal-state-event';
import {TokenDataProviderService} from '../../../../core/services/token-data-provider.service';
import {CurrentUserViewModel} from '../../../../core/models/current-user.view-model';
import {Store} from '@ngrx/store';
import {map, mergeMap} from 'rxjs/operators';
import * as fromFollow from '../../../../state/follow/following.reducer';
import {
  FollowerListItemInterface
} from '../../../../core/interfaces/follower-item.interface';
import {AppState} from '../../../../state/app.state';
import {FollowActionTypes} from '../../../../state/follow/following.actions';
import {FollowApiModel, SubjectApiModel} from '../../../../api/models/follow.api-model';
import {UserApiModel} from '../../../../api/models/user.api-model';
import {MediaObjectViewModel} from '../../../../core/models/media-object.view-model';
import {QueryParamsApiModel} from '../../../../api/models/query-params-api.model';
import {FilterParameterApiModel} from '../../../../api/models/filter-parameter.api.model';
import {UserRestService} from '../../../../api/services/user.rest.service';
import {ResultListApiModel} from '../../../../api/models/result-list.api.model';
import {UserDataProviderService} from '../../../../features/user/settings/user.data-provider.service';
import {MediaObjectApiModel} from '../../../../api/models/media-object.api-model';
import {MediaObjectRestService} from '../../../../api/services/media-object.rest.service';
import {Router} from '@angular/router';
import {ModalState} from '../../modal-state.enum';
import {LoaderService} from '../../../../core/services/loader.service';
import {SwiperOptions} from 'swiper/types';
import Swiper, {FreeMode, Keyboard, Mousewheel} from 'swiper';

import {
  SocialDataProviderService
} from '../../../timeline/social-grid-list/social-data-provider.service';
import {ModalComponent} from '../../modal.component';

Swiper.use([FreeMode, Keyboard, Mousewheel]);

type follows = 'follows';

@Component({
  selector: 'app-follow-list',
  templateUrl: './follow-list.component.html',
  styleUrls: ['./follow-list.component.scss'],
  providers: [UserDataProviderService]
})
export class FollowListComponent implements OnInit, AfterViewInit, ModalInterface {
  get config(): SwiperOptions {
    return this._config;
  }

  set config(value: SwiperOptions) {
    this._config = value;
  }

  get followings(): Observable<FollowerListItemInterface[]> {
    return this._followings;
  }

  get parentModalRendered(): boolean {
    return this.modalComponent.rendered;
  }

  public currentUser: CurrentUserViewModel;
  public observeState: string;

  @Input() data: SubjectApiModel;
  public state: Observable<ModalStateEvent>;
  public subject: Subject<ModalStateEvent>;
  private _followings: Observable<FollowerListItemInterface[]>;
  private _config: SwiperOptions;
  private followedLength: number;

  constructor(
    private readonly tokenDPS: TokenDataProviderService,
    private readonly userRS: UserRestService,
    private readonly mediaRS: MediaObjectRestService,
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly ls: LoaderService,
    private readonly modalComponent: ModalComponent,
    public readonly socialDPS: SocialDataProviderService,
  ) {
  }

  ngOnInit() {
    this.tokenDPS.currentUser.subscribe((currentUser: CurrentUserViewModel) => this.currentUser = currentUser);
    this.initFollowList();
    this.fetchCurrentUserFollows();
    this.fetchCurrentUserLikes();
    this.initSwiperConfig();
  }

  ngAfterViewInit(): void {
  }

  private initFollowList(): void {
    const followings = this.store.select(state => fromFollow.selectAll(state.follows)).pipe(mergeMap((e: FollowApiModel[]) => {
      this.followedLength = e.length;
      const followedByIds = e.map(followAM => followAM.followedBy);
      const params = new QueryParamsApiModel();
      for (const i of followedByIds) {
        params.filters.push(new FilterParameterApiModel('slug', (i['@id'] || i).split('/').pop()));
      }
      return this.userRS.getCollection(params).pipe(mergeMap((userAMs: ResultListApiModel<UserApiModel>) => {
        const params = new QueryParamsApiModel();
        const mediaIds = userAMs.records.map(e => e.avatar);

        for (const i of mediaIds) {
          if (i) {
            params.filters.push(new FilterParameterApiModel('id', i));
          }
        }

        let followerListItems = e.map((followAM: FollowApiModel) => {
          const userAM = userAMs.records.find(u => u['@id'] === (followAM.followedBy['@id'] || followAM.followedBy));
          const followed = followAM.followed || this.data;
          return {
            ids: [followAM['@id']],
            follower: {
              apiModel: userAM,
              '@id': userAM['@id'],
              username: userAM.username,
              slug: userAM.slug,
              avatar: null,
            },
            followed: [{
              apiModel: followed,
              '@id': followed['@id'],
              slug: followed.slug,
              avatar: null,
              identity: followed.identity
            }],
            resource: 'follows' as follows
          };
        });

        followerListItems = followerListItems.reduce((groupedFollowerListItems: FollowerListItemInterface[], followerListItem: FollowerListItemInterface) => {
          const existingItem = groupedFollowerListItems.find(e => e.follower['@id'] === followerListItem.follower['@id']);
          if (existingItem) {
            existingItem.ids.push(followerListItem.ids[0]);
            existingItem.followed.push(followerListItem.followed[0]);
          } else {
            groupedFollowerListItems.push(followerListItem);
          }
          if (followerListItem.followed[0].apiModel.avatar) {
            params.filters.push(new FilterParameterApiModel('id', followerListItem.followed[0].apiModel.avatar));
          }

          return groupedFollowerListItems;
        }, []);

        if (params.filters.length) {
          return this.mediaRS.getMediaObjectCollection(params).pipe(map((resultListAM: ResultListApiModel<MediaObjectApiModel>) => {
            return followerListItems.map((i: FollowerListItemInterface) => {
              const followerAvatar = resultListAM.records.find(e => e['@id'] === i.follower.apiModel.avatar as string);
              i.follower.avatar = new MediaObjectViewModel(followerAvatar);

              resultListAM.records.forEach(e => {
                const followed = i.followed.find(f => f.apiModel.avatar === e['@id']);
                console.log(i, followed, e);
                if (followed) {
                  followed.avatar = new MediaObjectViewModel(e);
                }
              });

              return i;
            });
          }));
        }
        this.initSwiperConfig();
        return of(followerListItems);
      }));
    }));

    this._followings = concat(of<FollowerListItemInterface[]>(Array.from({length: 5}) as FollowerListItemInterface[]), followings);
  }

  private fetchCurrentUserFollows(): void {
    this.socialDPS.fetchCurrentUserFollows(this.currentUser).subscribe();
  }

  private fetchCurrentUserLikes(): void {
    this.socialDPS.fetchCurrentUserLikes(this.currentUser).subscribe();
  }

  initSwiperConfig() {
    this.config = {
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: this.getSlidesPerView(5),
          spaceBetween: 5,
        },
        // when window width is >= 480px
        480: {
          slidesPerView: this.getSlidesPerView(6),
          spaceBetween: 5,
        },
        // when window width is >= 640px
        720: {
          slidesPerView: this.getSlidesPerView(8),
        }
      },
      autoHeight: true,
      freeMode: {
        enabled: true,
        sticky: true,
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      mousewheel: {
        invert: true,
      },
    };
  }

  postFollowing(following: FollowerListItemInterface) {
    this.observeState = 'pending';
    this.store.dispatch({type: FollowActionTypes.ADD_ONE, following});
  }

  removeFollowing(following: FollowerListItemInterface) {

  }

  navigateTo(following: FollowerListItemInterface, context: string): void {
    this.ls.show();
    this.router.navigate(['/', following.follower.slug, context]).then(() => {
      this.subject.next(new ModalStateEvent(ModalState.SUCCESS));
    });
  }

  private getSlidesPerView(desiredNumber: number): number {
    return this.followedLength < desiredNumber ? this.followedLength : desiredNumber;
  }

  get viewScrollerHeight(): number {
    return this.modalComponent.dialogHeight() - 150;
  }
}
