<div class="grid grid-nogutter infinite justify-content-center mt-0 relative">
  <div
    NgxMasonryInfiniteGrid
    [items]="items"
    [gap]="0"
    [autoResize]="true"
    #ig
    [observeChildren]="true"
    [useResizeObserver]="true"
    [useLoading]="true"
    (requestAppend)="onRequestAppend($event)"
    class="container timeline-container m-0 p-0 mb-3">
    <div *ngFor="let item of ig.visibleItems; let i = index" class="col-12 p-0 m-0 px-1 width-100" [id]="'item' + item.data.id">
      <ng-container *ngIf="item.type === ITEM_TYPE.NORMAL">
        <p-skeleton *ngIf="item.data.isPlaceHolder" styleClass="mb-2" width="100%" height="100%"></p-skeleton>
        <app-search-tile
          [resource]="item.data"
        ></app-search-tile>
      </ng-container>
      <ng-container *ngIf="!loading && item.type !== ITEM_TYPE.NORMAL" [ngTemplateOutlet]="progressSpinner"></ng-container>
    </div>
  </div>
  <ng-container *ngIf="loading" [ngTemplateOutlet]="progressSpinner"></ng-container>
  <div *ngIf="!loading && !items.length && noResultsMsg">
    <p>{{noResultsMsg}}</p>
  </div>
</div>

<ng-template #progressSpinner>
  <div class="width-100 flex justify-content-center mb-5">
    <p-progressSpinner styleClass="w-2rem h-2rem" strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s">
    </p-progressSpinner>
  </div>
</ng-template>
