import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MenuItem} from "primeng/api";

@Component({
  selector: 'app-end-cap',
  templateUrl: './end-cap.component.html',
  styleUrls: ['./end-cap.component.scss'],
})
export class EndCapComponent implements OnInit {
  get pointerClass(): string | object {
    return this._pointerClass;
  }

  @Input() set pointerClass(value: string | object) {
    this._pointerClass = value;
  }
  get disabled(): boolean {
    return this._disabled;
  }


  @Input() set disabled(value: boolean) {
    this._disabled = value;
  }

  get showLoader(): boolean {
    return this._showLoader;
  }

  @Input() set showLoader(value: boolean) {
    this._showLoader = value;
  }

  get secondButtonPointerClass() {
    return this._secondButtonPointerClass;
  }

  @Input() set secondButtonPointerClass(value: string|object) {
    this._secondButtonPointerClass = {...this._pointerClass as object, 'p-button-icon-only': true, ...value as object};
  }

  @Input() wrapperClass?: string;
  @Input() label: string;
  private _pointerClass?: string|object;
  private _secondButtonPointerClass?: string|object;
  private _disabled?: boolean = false;
  private _showLoader?: boolean;
  @Input() icon?: string;
  @Input() menuItems: MenuItem[] = [];
  @Output() onClickTrigger: EventEmitter<boolean> = new EventEmitter<boolean>();
  public hovered: boolean = false;

  constructor() {
  }

  ngOnInit() {
    this.secondButtonPointerClass = {...this._pointerClass as object, 'p-button-icon-only': true};
  }

  triggerClick(): void {
    this.onClickTrigger.emit(true);
  }
}
