import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalInterface} from '../../modal.interface';
import {defer, forkJoin, merge, Observable, of, Subject} from 'rxjs';
import {ModalStateEvent} from '../../modal-state-event';
import {UntypedFormGroup} from '@angular/forms';
import {RxFormBuilder, RxwebValidators} from '@rxweb/reactive-form-validators';
import {ProjectViewModel} from '../../../../features/user/garage/project.view-model';
import {ModalState} from '../../modal-state.enum';
import {DescriptionPostViewModel} from '../../../../features/user/project/about/details/description-post.view-model';
import {DomSanitizer} from '@angular/platform-browser';
import {MediaObjectRestService} from '../../../../api/services/media-object.rest.service';
import {MediaObjectApiModel} from '../../../../api/models/media-object.api-model';
import {v4 as uuidv4} from 'uuid';
import {finalize, ignoreElements, map, mapTo, mergeMap, tap} from 'rxjs/operators';
import {ProjectRestService} from '../../../../api/services/project.rest.service';
import {PostApiModel} from '../../../../api/models/post-api.model';
import {PostViewModel} from '../../../timeline/post.view-model';
import {PostRestService} from '../../../../api/services/post-rest.service';
import {PostTypeEnum} from '../../../../core/enums/post-type.enum';
import {EventApiModel} from '../../../../api/models/event.api.model';
import {CustomCameraMediaItem} from "../../../../features/custom-camera/custom-camera";
import {ModalComponent} from "../../modal.component";
import {TimelineComponent} from "../../../timeline/timeline.component";
import {CurrentUserViewModel} from "../../../../core/models/current-user.view-model";
import {TokenDataProviderService} from "../../../../core/services/token-data-provider.service";

@Component({
  selector: 'app-add-media',
  templateUrl: './add-media.component.html',
  styleUrls: ['./add-media.component.scss'],
})
export class AddMediaComponent implements ModalInterface, OnInit {
  public data: any;
  public src: string;
  public blob: Blob;
  public caption: string = null;
  public subject: Subject<ModalStateEvent>;
  public state: Observable<ModalStateEvent>;
  public form: UntypedFormGroup;
  @Input() public projectVM: ProjectViewModel;
  @Input() public eventAM: EventApiModel;
  @Input() public media: CustomCameraMediaItem;
  @Output() onAdd: EventEmitter<DescriptionPostViewModel> = new EventEmitter();
  public file: File;
  public isImagePicked: boolean;
  public currentUser: CurrentUserViewModel;
  private timeline: TimelineComponent;

  constructor(
    private fb: RxFormBuilder,
    private _sanitizer: DomSanitizer,
    private mediaObjectRS: MediaObjectRestService,
    private projectRS: ProjectRestService,
    private readonly postRS: PostRestService,
    public readonly modalComponent: ModalComponent,
    private readonly tokenDPS: TokenDataProviderService,
  ) {
  }

  async ngOnInit() {
    this.projectVM = this.data.projectVM;
    this.eventAM = this.data.eventAM;
    this.isImagePicked = this.data.isImagePicked;
    this.timeline = this.data.timeline;
    this.tokenDPS.currentUser.subscribe((u: CurrentUserViewModel) => this.currentUser = u);

    if (this.isImagePicked) {
      await this.initResultStep(this.data.media);
    }
  }

  async initResultStep(media: CustomCameraMediaItem): Promise<void> {
    this.modalComponent.hasButtons = true;
    this.isImagePicked = true;
    this.media = media;
    let url = null;
    if (this.media.type === 'image') {
      url = this.media.src;
    }

    if (this.media.type === 'video') {
      url = this.media.src;
    }

    const reader = new FileReader();
    reader.onload = async (e: any) => {
      this.src = e.target.result; // e.target.result;
      console.log(this.media, this.src);
    };
    const id = uuidv4();
    this.blob = await fetch(url).then(r => r.blob());

    this.file = new File([this.blob], id, {type: this.media.mimeType, lastModified: Date.now()});
    reader.readAsDataURL(this.blob);

    this.subject.next(new ModalStateEvent(ModalState.VALID));

    this.state.subscribe((mse: ModalStateEvent) => {
      switch (mse.status) {
        case ModalState.PENDING:
          this.submit();
      }
    });
  }

  // private createForm() {
  //   // const validators: Validators[] = [RxwebValidators.required(), RxwebValidators.minLength({value: 3}), RxwebValidators.maxLength({value: 255})];
  //   this.form = this.fb.group(
  //     {
  //       file: [null, [RxwebValidators.minLength({value: 1})]],
  //       caption: [null, [RxwebValidators.minLength({value: 1})]],
  //     }
  //   );
  //
  //   // if (this.media) {
  //   //   this.form.get('content').setValue(this.media.content);
  //   // }
  //
  //   this.form.valueChanges.subscribe(() => {
  //     if (this.form.valid) {
  //       this.subject.next(new ModalStateEvent(ModalState.VALID));
  //     }
  //   })
  // }

  async updateItems(data: PostViewModel): Promise<void> {
    data.postedBy = this.currentUser;
    const newPosts = [...[data], ...this.timeline.posts];
    this.timeline.posts = [];
    this.timeline.ig.updateItems([], {useOrgResize: true});
    setTimeout(() => {
      this.timeline.posts = newPosts;
    }, 20);
  }

  public submit(): void {
    this.addMedia([this.file]).pipe(mergeMap((mediaObjectAMs: MediaObjectApiModel[]) => {
      const postAM = new PostApiModel();
      postAM.media = [];
      mediaObjectAMs.forEach((mediaObjectAM: MediaObjectApiModel) => {
        postAM.media.push(mediaObjectAM['@id']);
      });

      if (this.projectVM) {
        postAM.project = this.projectVM['@id'];
        postAM.type = PostTypeEnum.PROJECT_MEDIA_POST;
      }

      if (this.eventAM) {
        postAM.event = this.eventAM['@id'];
        postAM.type = PostTypeEnum.EVENT_MEDIA_POST;
      }

      postAM.content = this.caption || '';

      return this.postRS.create(postAM).pipe(map((postAMResponse: PostApiModel) => {
        return new PostViewModel(postAMResponse);
      }));
    })).subscribe(async (postVM: PostViewModel) => {
        await this.updateItems(postVM);
        setTimeout(() => {
          this.subject.next(new ModalStateEvent(ModalState.SUCCESS, postVM));

        }, 50);
      }
    );
  }

  addMedia(files: File[]): Observable<any> {
    // this.disabled = true;
    const requests: Observable<MediaObjectApiModel>[] = [];
    files.forEach((file: File) => {

        requests.push(this.postMediaObject(file));
      }
    );

    if (!requests.length) {
      return of([]);
    }

    return defer(() => {
      let counter = 0;
      const percent$ = new Subject();

      const modifiedObservablesList = requests.map(
        (item, index) => item.pipe(
          finalize(() => {
            const percentValue = ++counter * 100 / requests.length;
            percent$.next(percentValue);
          })
        )
      );

      const finalResult$ = forkJoin(modifiedObservablesList).pipe(
        tap(() => {
            percent$.next(100);
            percent$.complete();
          }
        ));

      return of([finalResult$, percent$.asObservable()]);
    }).pipe(
      mergeMap(([finalResult, progress]) => merge(
        progress.pipe(
          // tap((value) => this.mediaProgress = value),
          ignoreElements()
        ),
        finalResult
      ))
    );
  }

  public postMediaObject(file: File, caption: string = null): Observable<MediaObjectApiModel> {
    switch (file.type) {
      case 'image/jpeg': return this.mediaObjectRS.uploadImage(file, caption);
      case 'video/mp4': return this.mediaObjectRS.uploadVideo(file, caption);
    }
  }
}
