<ng-container *ngIf="isLoading || appMain.queryService.resources.length">
  <div class="grid grid-nogutter query-header">
    <div *ngIf="!isLoading" class="col p-2 lg:px-5">
      <h5 *ngIf="hasLastQueriedResources">
        {{'shared.top_bar.search_list.last_queried' | translate}}
      </h5>
      <h5 *ngIf="!hasLastQueriedResources">{{'shared.top_bar.search_list.query_results' | translate}}: {{phrase}}</h5>
    </div>
  </div>
  <div class="p-1 lg:py-4 lg:px-5 query-body mb-5">
    <app-search-tile
      *ngFor="let resource of appMain.queryService.resources"
      [resource]="resource"
    ></app-search-tile>
    <app-spinner *ngIf="isLoading" [overlay]="true"></app-spinner>
  </div>
  <div class="flex justify-content-center align-items-center query-footer">
    <p *ngIf="!isLoading || appMain.queryService.resources.length" class="text-center">
      <a>{{'shared.top_bar.search_list.see_more' | translate}}</a>
    </p>
  </div>
</ng-container>
