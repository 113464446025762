import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SiteLayoutComponent} from '../../core/layout/site/site-layout.component';
import {Router} from '@angular/router';
import {CurrentUserViewModel} from '../../core/models/current-user.view-model';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.scss']
})
export class SearchListComponent implements OnInit, OnDestroy {
  get phrase(): string {
    return this._phrase;
  }

  @Input() set phrase(value: string) {
    this._phrase = value;
  }

  get hasLastQueriedResources(): boolean {
    return this.appMain.queryService.hasLastQueriedResources;
  }

  get isLoading(): boolean {
    return this.appMain.queryService.loading;
  }

  @Input() public user: CurrentUserViewModel;
  private _phrase: string;
  private subscriptions: Subscription[] = [];

  constructor(
    public appMain: SiteLayoutComponent,
    private readonly router: Router
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    const sub = this.appMain.queryService.fetchSearchLogResources(this.user).subscribe(() => this.appMain.queryService.loading = false);
    this.subscriptions.push(sub);
  }

  ngOnDestroy() {
    this.appMain.queryService.resetCachedResults();
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
