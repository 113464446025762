import {Exclude, Expose, Type} from 'class-transformer';
import {AbstractApiModel} from './abstract.api.model';
import {SocialStatisticsInterface} from '../interfaces/social-statistics.interface';

@Exclude()
export class UserStatisticsApiModel extends AbstractApiModel {

  @Expose() public follows: SocialStatisticsInterface;
  @Expose() public reactions: SocialStatisticsInterface;
  @Expose() public ratings: SocialStatisticsInterface;
}
