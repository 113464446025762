import {AbstractViewModel} from "../interfaces/abstract-view-model";
import {HyperlinkApiModel} from "../../api/models/hyperlink.api-model";

export class HyperlinkViewModel extends AbstractViewModel<HyperlinkApiModel> {
  public name: string;
  public uri: string;

  public constructor(protected apiModel: HyperlinkApiModel = new HyperlinkApiModel()) {
    super(apiModel);
    this.fromApiModel(apiModel);
  }

  fromApiModel(apiModel: HyperlinkApiModel): HyperlinkViewModel {
    this.name = this.apiModel.name;
    this.uri = this.apiModel.uri;

    return this;
  }

  toApiModel(): HyperlinkApiModel {
    return this.apiModel;
  }

}
