<!--<p-card [style]="{width: '100%'}">-->
  <form [formGroup]="form">
    <div class="grid grid-nogutter">
<!--      <div class="col-10 p-fluid">-->
<!--        <div class="p-field p-float-label p-input-icon-left">-->
<!--          <input id="heading" pInputText type="text" formControlName="heading">-->
<!--          <label for="heading">{{'feature.user.project.history_post.heading' | translate}}</label>-->
<!--        </div>-->
<!--        <div *ngIf="form.get('heading').invalid && form.get('heading').touched" class="app-form-error">-->
<!--          <div>{{form.get('heading')['errorMessage']}}</div>-->
<!--          <div *ngIf="form.get('heading').errors?.custom">{{form.get('heading').errors?.custom}}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-2">-->
<!--        <app-end-cap-->
<!--          [disabled]="form.invalid"-->
<!--          icon="pi pi-arrow-right"-->
<!--          [pointerClass]="'p-button-rounded p-button-outlined p-button-icon-only p-button-primary'"-->
<!--          (onClickTrigger)="submit()"-->
<!--        >-->
<!--        </app-end-cap>-->
<!--      </div>-->
      <div class="col-12" style="height: 100%">
<!--        <app-html-editor *ngIf="isPlatformBrowser(platformId)" formControlName="content"></app-html-editor>-->
        <div *ngIf="form.get('content').invalid && form.get('content').touched" class="app-form-error">
          <div>{{form.get('content')['errorMessage']}}</div>
          <div *ngIf="form.get('content').errors?.custom">{{form.get('content').errors?.custom}}</div>
        </div>
      </div>
      <!--    <div class="col-2">-->
      <!--      <app-end-cap-->
      <!--        [disabled]="content?.length < 3"-->
      <!--        icon="pi pi-arrow-right"-->
      <!--        [pointerClass]="'p-button-rounded p-button-outlined p-button-icon-only p-button-primary'"-->
      <!--        (onClickTrigger)="addPost()"-->
      <!--      >-->
      <!--      </app-end-cap>-->
      <!--    </div>-->
    </div>
  </form>
<!--</p-card>-->
