import {ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {trigger, style, transition, animate, AnimationEvent} from '@angular/animations';
import {LazyLoadEvent, MegaMenuItem, MenuItem} from 'primeng/api';
import {AppComponent} from "../../app.component";
import {ItemClickInterface, SiteLayoutComponent} from "../../core/layout/site/site-layout.component";
import {CurrentUserViewModel} from "../../core/models/current-user.view-model";
import {TokenDataProviderService} from "../../core/services/token-data-provider.service";
import {TranslateService} from "@ngx-translate/core";
import {tap} from "rxjs/operators";
import {Router} from "@angular/router";
import {NotificationInterface} from "../interfaces/notification.interface";
import {NotificationDataProviderService} from "../../core/services/notification.data-provider.service";
import {ResultListApiModel} from "../../api/models/result-list.api.model";
import {EventSourceInterface} from "../../core/interfaces/event-source.interface";


@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  styleUrls: ['./app.topbar.component.scss'],
  animations: [
    trigger('topbarActionPanelAnimation', [
      transition(':enter', [
        style({opacity: 0, transform: 'scaleY(0.8)'}),
        animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({opacity: 1, transform: '*'})),
      ]),
      transition(':leave', [
        animate('.1s linear', style({opacity: 0}))
      ])
    ])
  ],
  providers: [NotificationDataProviderService]
})
export class AppTopBarComponent implements OnInit {
  get notifications(): NotificationInterface[] {
    return this._notifications;
  }

  set notifications(value: NotificationInterface[]) {
    this._notifications = value;
  }

  get notificationTotalItems(): number {
    return this.notificationDataProvider.notifications.total;
  }

  get newNotificationsCount(): number {
    return this.newNotifications.length;
  }

  get newNotifications(): NotificationInterface[] {
    return this.notifications.filter((e: NotificationInterface) => e.readAt === null)
  }

  get model(): MegaMenuItem[] {
    return this._model;
  }

  set model(value: MegaMenuItem[]) {
    this._model = value;
  }

  get user(): CurrentUserViewModel {
    return this._user;
  }

  @Input() set user(value: CurrentUserViewModel) {
    this._user = value;
  }

  private _user: CurrentUserViewModel;

  private _notifications: NotificationInterface[] = [];
  public pageRows: number = 20;
  public currentPage: number = 1;

  constructor(
    public appMain: SiteLayoutComponent,
    public app: AppComponent,
    private ts: TokenDataProviderService,
    private translate: TranslateService,
    private readonly router: Router,
    private cdr: ChangeDetectorRef,
    public readonly notificationDataProvider: NotificationDataProviderService
  ) {
  }


  ngOnInit(): void {
    this.translateMenuItems();
    this.translate.onLangChange.subscribe((e) => {
      this.translateMenuItems();
    })
    this.notificationDataProvider.setEventSource(this.user).subscribe((es: EventSourceInterface<NotificationInterface>) => {
      const notification = es.payload;
      switch (es.state) {
        case 'persisted':
          this.notifications = [...[notification], ...this.notifications];
          break;
        case 'removed':
          this.notifications = this.notifications.filter(e => e['id'] !== notification['id']);
          break;
      }

      this.cdr.markForCheck();
      this.cdr.detectChanges();
    });
    this.notificationDataProvider.getCollection().subscribe((notifications: ResultListApiModel<NotificationInterface>) => {
      this.notifications = notifications.records;
    });

    this.appMain.onClickTopBarItem$.subscribe((e: ItemClickInterface) => {
      if (this.newNotifications.length && e.previousItem === 'notifications' && !e.nextItem) {
        this.notificationDataProvider.massUpdateReadAt(this.newNotifications)
          .subscribe((notifications: ResultListApiModel<NotificationInterface>) => {
            this.notifications = notifications.records;
            this.cdr.detectChanges();
          });
      }
    });
  }

  activeItem: number;
  query: string;

  private _model: MegaMenuItem[] = [
    //TODO: Commented lines are only for example for build a megamenu
    // {
    //     label: 'UI KIT',
    //     items: [
    //         [
    //             {
    //                 label: 'UI KIT 1',
    //                 items: [
    //                     { label: 'Form Layout', icon: 'pi pi-fw pi-id-card', routerLink: ['/uikit/formlayout'] },
    //                     { label: 'Input', icon: 'pi pi-fw pi-check-square', routerLink: ['/uikit/input'] },
    //                     { label: 'Float Label', icon: 'pi pi-fw pi-bookmark', routerLink: ['/uikit/floatlabel'] },
    //                     { label: 'Button', icon: 'pi pi-fw pi-mobile', routerLink: ['/uikit/button'] },
    //                     { label: 'File', icon: 'pi pi-fw pi-file', routerLink: ['/uikit/file'] }
    //                 ]
    //             }
    //         ],
    //         [
    //             {
    //                 label: 'UI KIT 2',
    //                 items: [
    //                     { label: 'Table', icon: 'pi pi-fw pi-table', routerLink: ['/uikit/table'] },
    //                     { label: 'List', icon: 'pi pi-fw pi-list', routerLink: ['/uikit/list'] },
    //                     { label: 'Tree', icon: 'pi pi-fw pi-share-alt', routerLink: ['/uikit/tree'] },
    //                     { label: 'Panel', icon: 'pi pi-fw pi-tablet', routerLink: ['/uikit/panel'] },
    //                     { label: 'Chart', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/uikit/charts'] }
    //                 ]
    //             }
    //         ],
    //         [
    //             {
    //                 label: 'UI KIT 3',
    //                 items: [
    //                     { label: 'Overlay', icon: 'pi pi-fw pi-clone', routerLink: ['/uikit/overlay'] },
    //                     { label: 'Media', icon: 'pi pi-fw pi-image', routerLink: ['/uikit/media'] },
    //                     { label: 'Menu', icon: 'pi pi-fw pi-bars', routerLink: ['/uikit/menu'] },
    //                     { label: 'Message', icon: 'pi pi-fw pi-comment', routerLink: ['/uikit/message'] },
    //                     { label: 'Misc', icon: 'pi pi-fw pi-circle-off', routerLink: ['/uikit/misc'] }
    //                 ]
    //             }
    //         ]
    //     ]
    // },
    // {
    //     label: 'UTILITIES',
    //     items: [
    //         [
    //             {
    //                 label: 'UTILITIES 1',
    //                 items: [
    //                     { label: 'Display', icon: 'pi pi-fw pi-desktop', routerLink: ['utilities/display'] },
    //                     { label: 'Elevation', icon: 'pi pi-fw pi-external-link', routerLink: ['utilities/elevation'] }
    //                 ]
    //             },
    //             {
    //                 label: 'UTILITIES 2',
    //                 items: [
    //                     { label: 'FlexBox', icon: 'pi pi-fw pi-directions', routerLink: ['utilities/flexbox'] }
    //                 ]
    //             }
    //         ],
    //         [
    //             {
    //                 label: 'UTILITIES 3',
    //                 items: [
    //                     { label: 'Icons', icon: 'pi pi-fw pi-search', routerLink: ['utilities/icons'] }
    //                 ]
    //             },
    //             {
    //                 label: 'UTILITIES 4',
    //                 items: [
    //                     { label: 'Text', icon: 'pi pi-fw pi-pencil', routerLink: ['utilities/text'] },
    //                     { label: 'Widgets', icon: 'pi pi-fw pi-star-o', routerLink: ['utilities/widgets'] }
    //                 ]
    //             }
    //         ],
    //         [
    //             {
    //                 label: 'UTILITIES 5',
    //                 items: [
    //                     { label: 'Grid System', icon: 'pi pi-fw pi-th-large', routerLink: ['utilities/grid'] },
    //                     { label: 'Spacing', icon: 'pi pi-fw pi-arrow-right', routerLink: ['utilities/spacing'] },
    //                     { label: 'Typography', icon: 'pi pi-fw pi-align-center', routerLink: ['utilities/typography'] }
    //                 ]
    //             }
    //         ],
    //     ]
    // },
    {
      label: null,
      state: {transKey: 'shared.top_bar.menu.feed'},
      routerLink: ['/feed'],
      command: event => {
        this.appMain.mobileTopbarActive = !this.appMain.mobileTopbarActive;
      },
    },
    {
      label: null,
      state: {transKey: 'shared.top_bar.menu.events'},
      routerLink: ['/events'],
      command: event => {
        this.appMain.mobileTopbarActive = !this.appMain.mobileTopbarActive;
      },
    },
    {
      label: null,
      state: {transKey: 'shared.top_bar.menu.ranking'},
      routerLink: ['/ranking'],
      command: event => {
        this.appMain.mobileTopbarActive = !this.appMain.mobileTopbarActive;
      },
    },
    {
      label: null,
      state: {transKey: 'shared.top_bar.menu.offers'},
      routerLink: ['/offers'],
      command: event => {
        this.appMain.mobileTopbarActive = !this.appMain.mobileTopbarActive;
      },
    },
  ];

  @ViewChild('searchInput') searchInputViewChild: ElementRef;

  onSearchAnimationEnd(event: AnimationEvent) {
    switch (event.toState) {
      case 'visible':
        this.query = '';
        this.searchInputViewChild.nativeElement.focus();
        break;
    }
  }

  private translateMenuItems(): void {
    const labels = this._model.map(e => e.state.transKey);
    this.translate.get(labels).pipe(tap((res) => {
      this.model = this.model.map((item: MegaMenuItem) => {
        if (res.hasOwnProperty(item.state.transKey)) {
          item.label = res[item.state.transKey];
        }
        return item;
      });
    })).subscribe();
  }

  public logout(event: Event): void {
    this.ts.logout();
  }

  public handleNotificationItemClick(event): void {
    this.appMain.onTopbarItemClick(event, 'notifications');
    this.cdr.detectChanges();
  }

  public loadNextNotificationsChunk(event: LazyLoadEvent) {
    const page = event.rows / this.pageRows;
    if (page > this.currentPage && this._notifications.length < this.notificationTotalItems) {
      this.notificationDataProvider.getCollection(page).subscribe((notifications: ResultListApiModel<NotificationInterface>) => {
        this._notifications = this._notifications.concat(notifications.records);
        this.currentPage = page;
      });
    }
  }

  public goToSearchResults(): void {
    if (this.query.length === 0) {
      return;
    }
    this.router.navigate(['/search-results'], {queryParams: {q: this.query}});
  }
}
