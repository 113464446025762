import {Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID} from '@angular/core';
import {ModalInterface} from "../../modal.interface";
import {Observable, Subject} from "rxjs";
import {ModalStateEvent} from "../../modal-state-event";
import {UntypedFormGroup, Validators} from "@angular/forms";

import {RxFormBuilder, RxwebValidators} from "@rxweb/reactive-form-validators";
import {ProjectViewModel} from "../../../../features/user/garage/project.view-model";
import {HistoryPostViewModel} from "../../../../features/user/project/about/history-post.view-model";
import {AboutDataProviderService} from "../../../../features/user/project/about/about.data-provider.service";
import {PostApiModel} from "../../../../api/models/post-api.model";
import {ModalState} from "../../modal-state.enum";
import {DescriptionPostViewModel} from "../../../../features/user/project/about/details/description-post.view-model";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-project-description',
  templateUrl: './project-description.component.html',
  styleUrls: ['./project-description.component.scss'],
})
export class ProjectDescriptionComponent implements ModalInterface, OnInit {
  data: any;
  subject: Subject<ModalStateEvent>;
  state: Observable<ModalStateEvent>;
  public form: UntypedFormGroup;
  @Input() public projectVM: ProjectViewModel;
  @Input() public descriptionPost: DescriptionPostViewModel;
  @Output() onAdd: EventEmitter<DescriptionPostViewModel> = new EventEmitter()


  constructor(
    @Inject(PLATFORM_ID) public platformId: Object,
    private fb: RxFormBuilder,
    private historyDPS: AboutDataProviderService
  ) { }

  ngOnInit(): void {
    this.projectVM = this.data['projectVM'];
    this.descriptionPost = this.data['descriptionPost'];
    this.createForm();
    this.state.subscribe((mse: ModalStateEvent) => {
      switch (mse.status) {
        case ModalState.PENDING: this.submit();
      }
    })
  }

  private createForm() {
    // const validators: Validators[] = [RxwebValidators.required(), RxwebValidators.minLength({value: 3}), RxwebValidators.maxLength({value: 255})];
    this.form = this.fb.group(
      {
        content: [null, [RxwebValidators.minLength({value: 3})]],
      }
    );

    if (this.descriptionPost) {
      this.form.get('content').setValue(this.descriptionPost.content);
    }

    this.form.valueChanges.subscribe(() => {
      if (this.form.valid) {
        this.subject.next(new ModalStateEvent(ModalState.VALID));
      }
    })
  }

  public submit(): void {
    let req;
    if (this.form.valid) {
      if (!this.descriptionPost) {
        this.descriptionPost = {
          apiModel: new PostApiModel(),
            type: 'DESCRIPTION_POST',
          content: this.form.get('content').value || '',
          project: this.projectVM.getApiModel()['@id'],
        }
        req = this.historyDPS.createDescriptionPost(this.descriptionPost);
      } else {
        this.descriptionPost.content = this.form.get('content').value || '';
        req = this.historyDPS.updateDescriptionPost(this.descriptionPost);
      }


      req.subscribe((descriptionPostVM: DescriptionPostViewModel) => {
        // this.subject.next(new ModalStateEvent(ModalState.PROCESS_END, historyPostVM));
        this.subject.next(new ModalStateEvent(ModalState.SUCCESS, descriptionPostVM));
        this.form.reset({
          content: null,
        });
        this.form.markAsPristine();
        this.form.markAsUntouched();
        this.form.updateValueAndValidity();
      });
    }
  }

    public readonly isPlatformBrowser = isPlatformBrowser;
}
