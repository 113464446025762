import {Exclude, Expose, Type} from 'class-transformer';
import {AbstractApiModel} from './abstract.api.model';
import {HyperlinkApiModel} from './hyperlink.api-model';
import {MediaObjectInterface} from '../../core/interfaces/media-object.interface';

@Exclude()
export class MediaObjectApiModel extends AbstractApiModel implements MediaObjectInterface {

  @Expose() public name: string;
  @Expose() public size: string;
  @Expose() public mimeType: string;
  @Expose() public originalName: string;
  @Expose() public dimensions: number[];
  @Expose() public type: string;
  @Expose() public m3u8: string;
  @Expose()
  @Type(() => HyperlinkApiModel)
  public hyperlinks: HyperlinkApiModel[];
  @Expose() public uploadedBy: string[];
  @Expose() public reactions: string[];
  @Expose() public comments: string[];
}
