import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {plainToClass, plainToClassFromExist} from "class-transformer";
import {ResultListApiModel} from "../models/result-list.api.model";
import { HttpClient, HttpResponse } from "@angular/common/http";
import {QueryParamsApiModel} from "../models/query-params-api.model";
import {QueryParamsService} from "../../core/services/query-params.service";
import {GoalApiModel} from "../models/goal.api.model";

@Injectable({
  providedIn: 'root'
})
export class GoalRestService {
  private readonly resource: string = '/goals';

  constructor(
    private http: HttpClient,
    public params: QueryParamsService
  ) {
  }

  public create(goalAM: GoalApiModel): Observable<GoalApiModel> {
    return this.http.post<GoalApiModel>(environment.apiUrl + this.resource, goalAM)
      .pipe(map((result: GoalApiModel) => plainToClass(GoalApiModel, result)));
  }

  public put(goalAM: GoalApiModel): Observable<GoalApiModel> {
    return this.http.put<GoalApiModel>(environment.apiUrl + goalAM['@id'], goalAM)
      .pipe(map((result: GoalApiModel) => plainToClass(GoalApiModel, result)));
  }

  public get(goalAM: GoalApiModel): Observable<GoalApiModel> {
    return this.http.get<GoalApiModel>(environment.apiUrl+ goalAM['@id'])
      .pipe(map((result: GoalApiModel) => plainToClass(GoalApiModel, result)));
  }

  public delete(goalAM: GoalApiModel): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(environment.apiUrl + goalAM['@id']);
  }

  public getCollection(queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<ResultListApiModel<GoalApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<GoalApiModel>>(environment.apiUrl + this.resource, {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<GoalApiModel>(GoalApiModel), result as object)));
  }

  public getUserSubResourceCollection(userId: string): Observable<ResultListApiModel<GoalApiModel>> {
    return this.http.get<ResultListApiModel<GoalApiModel>>(environment.apiUrl+ userId + this.resource)
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<GoalApiModel>(GoalApiModel), result as object)));
  }
}
