<div class="grid m-0 p-fluid" [ngClass]="{
    'compact-hashtags': mode === HashtagsModeEnum.COMPACT
  }">
  <div class="col-12 p-0 pb-2 p-float-label p-input-icon-left grid-nogutter">
    <ng-container *ngIf="mode === HashtagsModeEnum.COMPACT">
      <i class="pi pi-link"></i>
      <p-chips
        [ngClass]="{
              'ng-invalid': isInvalid(),
              'ng-dirty': isDirty(),
              'ng-pristine': isPristine()
            }"
        [separator]="','"
        [max]="10"
        [styleClass]="'project-tags-input'"
        [(ngModel)]="selectedHashtags"
        [allowDuplicate]="false"
        (onFocus)="keydownDetectEnabled = true"
        (onBlur)="keydownDetectEnabled = false"
        (onRemove)="onRemoveHashtagByInput($event)"
        [inputId]="'hashtagInput'"
        [name]="'hashtagInput'"
        pFocusTrap
        [ngModelOptions]="{standalone: true}"
      >
      </p-chips>
      <label class="chips-label">{{ 'feature.user.project.hashtags' | translate }}</label>
    </ng-container>

    <p-autoComplete
      [ngClass]="{
              'ng-invalid': isInvalid(),
              'ng-dirty': isDirty(),
              'ng-pristine': isPristine()
            }"
      [delay]="0"
      [appendTo]="'body'"
      [suggestions]="hashtags"
      (completeMethod)="searchHashtags($event)"
      (onSelect)="onHashtagSelect($event)"
      [field]="'name'"
      (onFocus)="keydownDetectEnabled = true"
      (onBlur)="keydownDetectEnabled = false"
      [placeholder]="placeholder"
      [inputId]="'hashtagInput'"
      [forceSelection]="forceSelection"
      [completeOnFocus]="completeOnFocus"
      pFocusTrap
    >
    </p-autoComplete>
    <ng-content></ng-content>
  </div>
  <div *ngIf="selectedHashtags.length && mode === HashtagsModeEnum.NORMAL" class="p-1 flex flex-wrap gap-2">
    <p-chip *ngFor="let tag of selectedHashtags; let i = index;" [label]="tag" removable="true" (onRemove)="onRemoveHashtagByInput({value: tag})"></p-chip>
  </div>
</div>
