import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {EndCapComponent} from './end-cap.component';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from "primeng/ripple";
import {MenuModule} from "primeng/menu";


@NgModule({
  declarations: [EndCapComponent],
  imports: [
    CommonModule,
    ButtonModule,
    RippleModule,
    MenuModule
  ],
  exports: [EndCapComponent]
})
export class EndCapModule { }
