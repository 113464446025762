import {
  APP_INITIALIZER,
  NgModule,
  LOCALE_ID,
  isDevMode,
} from '@angular/core';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {PLATFORM_ID, APP_ID, Inject} from '@angular/core';
import {isPlatformBrowser, ViewportScroller} from '@angular/common';
import {JwtModule, JWT_OPTIONS} from '@auth0/angular-jwt';
import {StorageService} from './core/services/storage.service';
import {WINDOW_PROVIDERS} from './core/providers/window.provider';
import {SharedModule} from './shared/shared.module';
import {CoreModule} from './core/core.module';
import {ApiModule} from './api/api.module';
import {registerLocaleData} from '@angular/common';
import localePL from '@angular/common/locales/pl';
import extraLocalePL from '@angular/common/locales/extra/pl';
import localeUS from '@angular/common/locales/en';
import extraLocaleUS from '@angular/common/locales/extra/en';
import localeDE from '@angular/common/locales/de';
import extraLocaleDE from '@angular/common/locales/extra/de';
import {NgxPermissionsModule} from 'ngx-permissions';
import {ValidatorConfigService} from './core/services/validator-config.service';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {HttpClient, HttpHeaders, provideHttpClient, withFetch, withInterceptorsFromDi} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {StoreModule} from '@ngrx/store';
import {followReducer} from './state/follow/following.reducer';
import {participantReducer} from './state/participant/participant.reducer';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {Event, Router, RouteReuseStrategy, Scroll, RouterModule} from '@angular/router';
import {NgxBackForwardCacheModule} from 'ngx-back-forward-cache';
// import {NgxScrollPositionRestorationModule} from 'ngx-scroll-position-restoration';
import {filter} from 'rxjs/operators';
import {reactionReducer} from './state/reaction/reaction.reducer';
// import { BottomSheetModule } from 'swipe-bottom-sheet/angular';
import {BrowserModule, Meta, provideClientHydration} from '@angular/platform-browser';
import {LanguageService} from "./core/services/language.service";
import {firstValueFrom} from "rxjs";
import {TranslatedMetaService} from "./core/services/translated-meta.service";


registerLocaleData(localePL, 'pl-PL', extraLocalePL);
registerLocaleData(localeUS, 'en-US', extraLocaleUS);
registerLocaleData(localeDE, 'de-DE', extraLocaleDE);

export function jwtOptionsFactory(storageService) {
  return {
    tokenGetter: () => {
      let token = storageService.getItem('token');
      storageService.watchStorage().subscribe((data: any) => {
        if (data.hasOwnProperty('token')) {
          token = data.token;
        }
      });
      return token;
    },
    whitelistedDomains: [],
    skipWhenExpired: true
  };
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    // BottomSheetModule,
    // BrowserModule.withServerTransition({ appId: 'serverApp' }).withServerTransition({appId: 'watch-this-garage'}),
    SharedModule,
    CoreModule,
    ApiModule,
    RouterModule,
    RouterModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [StorageService]
      }
    }),
    NgxPermissionsModule.forRoot(),
    StoreModule.forRoot({
      follows: followReducer,
      reactions: reactionReducer,
      participants: participantReducer
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 10,
      logOnly: true, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      scope: './',
      registrationStrategy: 'registerImmediately'
    }),
    // NgxBackForwardCacheModule.forRoot({disableNgxBackForwardCache: true}),
    // NgxScrollPositionRestorationModule.forRoot({debug: true})
  ],
  providers: [
    provideClientHydration(),
    provideHttpClient(
      withInterceptorsFromDi(),
      withFetch()
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: (translate: TranslateService, languageService: LanguageService, validatorConfigService: ValidatorConfigService, tms: TranslatedMetaService) => async () => {
        const lang = languageService.getLanguage();
        translate.setDefaultLang(lang);
        await firstValueFrom(translate.use(lang));
        validatorConfigService.loadValidationMessages();
        tms.updateMetaTag('description');
        tms.updateMetaTag('keywords');
      },
      deps: [TranslateService, LanguageService, ValidatorConfigService, TranslatedMetaService],
      multi: true
    },
    {
      provide: LOCALE_ID,
      useFactory: (languageService: LanguageService) => languageService.getLanguage(),
      deps: [LanguageService]
    },
    WINDOW_PROVIDERS
  ],
  bootstrap: [AppComponent]
})

export class AppModule {

  constructor(
    @Inject(PLATFORM_ID) platformId: object,
    @Inject(APP_ID) appId: string,
    router: Router,
    viewportScroller: ViewportScroller
  ) {
    router.events.pipe(
      filter((e: Event): e is Scroll => e instanceof Scroll)
    ).subscribe(e => {
      if (e.position) {
        console.log(e);
        // backward navigation
        viewportScroller.scrollToPosition(e.position);
      } else if (e.anchor) {
        // anchor navigation
        viewportScroller.scrollToAnchor(e.anchor);
      } else {
        // forward navigation
        viewportScroller.scrollToPosition([0, 0]);
      }
    });

    const platform = isPlatformBrowser(platformId) ? 'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }


}
