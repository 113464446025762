import {Injectable} from '@angular/core';
import {UserRestService} from "../../../api/services/user.rest.service";
import {Observable} from "rxjs";
import {map, mergeMap} from "rxjs/operators";
import {UserApiModel} from "../../../api/models/user.api-model";
import {UserPasswordViewModel} from "./new-password/user-password.view-model";
import {UserViewModel} from "./user.view-model";
import {CurrentUserViewModel} from "../../../core/models/current-user.view-model";
import {MediaObjectRestService} from "../../../api/services/media-object.rest.service";
import {MediaObjectApiModel} from "../../../api/models/media-object.api-model";
import {MediaObjectViewModel} from "../../../core/models/media-object.view-model";

@Injectable()
export class UserDataProviderService {

  constructor(private us: UserRestService, private mors: MediaObjectRestService) {
  }

  public getUser(id: string): Observable<UserViewModel> {
    return this.us.get(id).pipe(map((userApiModel: UserApiModel) => new UserViewModel(userApiModel)))
  }

  public updateUser(userViewModel: UserViewModel): Observable<UserViewModel> {
    return this.us.put(userViewModel["@id"], userViewModel.toApiModel())
      .pipe(map((userApiModel: UserApiModel) => userViewModel.fromApiModel(userApiModel)));
  }

  public changePassword(userViewModel: UserPasswordViewModel) {
    return this.us.changePassword(userViewModel.toPasswordApiModel())
  }

  public updateUserAvatar(currentUserViewModel: CurrentUserViewModel, file: File): Observable<CurrentUserViewModel> {
    return this.mors.uploadImage(file)
      .pipe(mergeMap((mediaObjectApiModel: MediaObjectApiModel) => {
        const currentUserApiModel = currentUserViewModel.toApiModel();
        currentUserApiModel.avatar = mediaObjectApiModel['@id'];
        return this.us.patch(currentUserViewModel['@id'], currentUserApiModel)
          .pipe(map((userApiModel: UserApiModel) => {
            currentUserViewModel.fromApiModel(userApiModel);
            currentUserViewModel.avatar = new MediaObjectViewModel(mediaObjectApiModel);
            return currentUserViewModel;
          }))
      }));
  }

  public removeUserAvatar(currentUserViewModel: CurrentUserViewModel): Observable<CurrentUserViewModel> {
    return this.mors.deleteMediaObject(currentUserViewModel.avatar["@id"])
      .pipe(map(() => {
        currentUserViewModel.avatar = null;
        return currentUserViewModel;
      }));
  }

  public updateUserBackground(currentUserViewModel: CurrentUserViewModel, file: File): Observable<CurrentUserViewModel> {
    return this.mors.uploadImage(file)
      .pipe(mergeMap((mediaObjectApiModel: MediaObjectApiModel) => {
        const currentUserApiModel = currentUserViewModel.toApiModel();
        currentUserApiModel.background = mediaObjectApiModel['@id'];
        return this.us.patch(currentUserViewModel['@id'], currentUserApiModel)
          .pipe(map((userApiModel: UserApiModel) => {
            currentUserViewModel.fromApiModel(userApiModel);
            currentUserViewModel.background = new MediaObjectViewModel(mediaObjectApiModel);
            return currentUserViewModel;
          }))
      }));
  }

  public removeUserBackground(currentUserViewModel: CurrentUserViewModel): Observable<CurrentUserViewModel> {
    return this.mors.deleteMediaObject(currentUserViewModel.background["@id"])
      .pipe(map(() => {
        currentUserViewModel.background = null;
        return currentUserViewModel;
      }));
  }
}
