import {AfterViewInit, Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SwiperOptions} from 'swiper/types';
import {SwiperComponent} from 'swiper/angular';
import {
  SwiperItemInterface,
  SwiperItemsInterface
} from '../../../shared/timeline/social-grid-list/swiper-items.interface';
import {TranslateService} from '@ngx-translate/core';
import {SearchTabsEnum} from './search-tabs.enum';
import Swiper from 'swiper';
import {KeyValue} from '@angular/common';
import {SiteLayoutComponent} from '../../layout/site/site-layout.component';
import {AppBreadcrumbService} from '../../../shared/navbar/app.breadcrumb.service';
import {
  SearchInfiniteListComponent
} from '../../../shared/timeline/search-infinite-list/search-infinite-list.component';
import {ResultsListTabInterface} from './results-list-tab.interface';
import {mergeMap} from "rxjs/operators";
import {ModalService} from "../../../shared/modal/modal.service";


@Component({
  selector: 'app-search-result-list',
  templateUrl: './search-results-list.component.html',
  styleUrls: ['./search-results-list.component.scss']
})
export class SearchResultsListComponent implements OnInit, AfterViewInit, OnDestroy {

  public config: SwiperOptions;
  public childConfig: SwiperOptions;
  public slides: SwiperItemsInterface;
  public selectedTab: ResultsListTabInterface;
  public selectedIndex: number = 0;
  public tabs: ResultsListTabInterface[] = [
    {label: this.translate.instant('core.search.tabs.all'), type: SearchTabsEnum.ALL, id: 0, filtersAvailable: false},
    {label: this.translate.instant('core.search.tabs.vehicles'), type: SearchTabsEnum.PROJECTS, id: 1, filtersAvailable: true},
    {label: this.translate.instant('core.search.tabs.users'), type: SearchTabsEnum.USERS, id: 2, filtersAvailable: true},
    {label: this.translate.instant('core.search.tabs.events'), type: SearchTabsEnum.EVENTS, id: 3, filtersAvailable: true},
    {label: this.translate.instant('core.search.tabs.marketplace'), type: SearchTabsEnum.MARKETPLACE, id: 4, filtersAvailable: true},
  ];
  @ViewChild('slaveSwiper') public slaveSwiper: SwiperComponent;
  @ViewChildren(SearchInfiniteListComponent, {emitDistinctChangesOnly: false}) searchInfiniteListComponents: QueryList<SearchInfiniteListComponent>;
  private tabsCount = this.tabs.length - 1;
  public listType: SearchTabsEnum;

  constructor(
    public readonly appMain: SiteLayoutComponent,
    public readonly breadcrumb: AppBreadcrumbService,
    private readonly route: ActivatedRoute,
    private readonly translate: TranslateService,
    private readonly ms: ModalService
  ) {
    this.appMain.topbarVisible = false;
  }
  ngOnInit(): void {
    this.config = {
      slidesPerView: 3,
      autoHeight: true,
      freeMode: {
        enabled: true,
        sticky: true,
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
    };
    this.route.queryParams.subscribe(({q}: {q: string}) => {
      this.breadcrumb.query = q;
      this.initMenuSlides();
    });
    this.breadcrumb.searchInputVisible = true;
    this.breadcrumb.filtersClickedSubscriber.pipe(mergeMap(() => {
      return this.ms.showSearchFiltersModal({selectedTab: this.selectedTab}).successState;
    })).subscribe(() => {
      this.ms.close();
    });
  }

  ngAfterViewInit(): void {
    // this.searchInfiniteListComponents.changes.subscribe((components: QueryList<SearchInfiniteListComponent>) => {
    //   components.forEach((component: SearchInfiniteListComponent) => {
    //     if (component.selectedTab.type === SearchTabsEnum.ALL) {
    //       component.initList();
    //     }
    //   });
    // });
  }

  public initMenuSlides(): void {
    this.slides = {};
    // this.tabs.forEach((tab) => this.slides[tab.type] = {label: tab.label, type: tab.type, count: 0, command: () => {}});
    // console.log(this.slides);
    this.selectedTab = this.tabs.find(t => t.type === SearchTabsEnum.ALL);
    this.childConfig = {
      slidesPerView: 1,
      autoHeight: true,
      freeMode: {
        enabled: true,
        sticky: true,
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
    };
  }

  selectChange(): void{
    console.log('Selected INDEX: ' + this.selectedIndex);
    this.slaveSwiper.swiperRef.slideTo(this.selectedIndex);
    this.slaveSwiper.swiperRef.updateSlidesClasses();
    this.selectedTab = this.tabs[this.selectedIndex];
    this.breadcrumb.filtersVisible = this.selectedTab.filtersAvailable;
    this.searchInfiniteListComponents.forEach((component: SearchInfiniteListComponent) => {
      // component.items = [];
      // component.page = 2;
      // component.endOfResults = false;
      // component.ig.renderItems();
      // console.log(component);
    });
  }

  setActiveSlide(swiper: Swiper[]) {
    this.selectedIndex = swiper[0].activeIndex;
  }

  originalOrder(a: KeyValue<string, SwiperItemInterface>, b: KeyValue<string, SwiperItemInterface>): number {
    return (a.value.order || 0) > (b.value.order || 0) ? 1 : (b.value.order > a.value.order ? -1 : 0) ;
  }

  ngOnDestroy() {
    this.appMain.topbarVisible = true;
    this.breadcrumb.searchInputVisible = false;
  }
}
