import {
  classToPlain,
  classToPlainFromExist,
  Expose,
  instanceToPlain,
  plainToClass,
  plainToInstance,
  Type
} from "class-transformer";
import {AbstractApiModel} from "./abstract.api.model";
import {TaskApiModel} from "./task.api.model";
import {GoalViewModel} from "../../features/user/project/goals/goal.view-model";
import {TaskViewModel} from "../../features/user/project/goals/task.view-model";

export class GoalApiModel extends AbstractApiModel {
  @Expose() public id: number;
  @Expose() public name: string;
  @Expose() public description: string;
  @Expose() public markerIcon: number;
  @Expose() public state: string;
  @Type(() => Date)
  @Expose() public startAt: Date;
  @Type(() => Date)
  @Expose() public finishedAt: Date;
  @Type(() => Date)
  @Expose() public estimatedFinishAt: Date;
  @Expose()
  @Type(() => TaskApiModel)
  public tasks: TaskApiModel[];
  @Expose() public project: string;
  @Type(() => Date)
  @Expose() public updatedAt: Date;
  @Type(() => Date)
  @Expose() public createdAt: Date;

  public static toGoalVM(apiModel: GoalApiModel): GoalViewModel {
    return {
      apiModel: apiModel,
      '@id': apiModel['@id'],
      id: apiModel.id,
      name: apiModel.name,
      description: apiModel.description,
      state: apiModel.state,
      startAt: apiModel.startAt,
      finishedAt: apiModel.finishedAt,
      estimatedFinishAt: apiModel.estimatedFinishAt,
      tasks: apiModel.tasks.map((e: TaskApiModel) => instanceToPlain(e) as TaskViewModel),
    };
  }

  public static fromGoalVM(viewModel: GoalViewModel): GoalApiModel {
    console.log(viewModel);
    console.log(plainToInstance(GoalApiModel, {...viewModel, tasks: viewModel.tasks.map((e: TaskViewModel) => plainToInstance(TaskApiModel, e))}, {
      excludeExtraneousValues: true,
      enableImplicitConversion: true
    }));
    return plainToInstance(GoalApiModel, {...viewModel, tasks: viewModel.tasks.map((e: TaskViewModel) => {
      const goalAM = plainToInstance(TaskApiModel, e)
        goalAM.id = e["@id"]
        return goalAM;
      })}, {
      excludeExtraneousValues: true,
      enableImplicitConversion: true
    });
  }

}
