import {Exclude, Expose} from "class-transformer";

@Exclude()
export class Oauth2UserApiModel {
  @Expose()
  public username: string;
  @Expose()
  public email: string;
  @Expose()
  public terms: boolean
}
