import {Pipe, PipeTransform} from '@angular/core';
import {ProjectUserCountInterface} from '../interfaces/project-user-count.interface';
import {AbstractApiModel} from '../../api/models/abstract.api.model';

export interface TypedObjectInterface {
  '@type'?: string;
  apiModel?: AbstractApiModel;
}


@Pipe({
  name: 'filterCollectionByType',
})
export class FilterCollectionByTypePipe implements PipeTransform {

  constructor() {
  }

  transform(value: any[], type: string): any[] {
    return value.filter(object => (object['@type'] || object.apiModel['@type']) === type);
  }
}
