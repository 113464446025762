import {Component, OnInit, OnDestroy} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalInterface} from '../../modal.interface';
import {Subscription, Observable, Subject} from 'rxjs';
import {ModalStateEvent} from '../../modal-state-event';
import {ResultsListTabInterface} from '../../../../core/components/search-results-list/results-list-tab.interface';
import {RxFormBuilder, RxwebValidators} from '@rxweb/reactive-form-validators';
import {ModalState} from '../../modal-state.enum';
import {SearchTabsEnum} from "../../../../core/components/search-results-list/search-tabs.enum";

@Component({
  selector: 'app-search-filters',
  templateUrl: './search-filters.component.html',
  styleUrls: ['./search-filters.component.scss'],
})
export class SearchFiltersComponent implements OnInit, ModalInterface, OnDestroy {
  constructor(
    private readonly fb: RxFormBuilder,
  ) {
  }

  public data: {selectedTab: ResultsListTabInterface};
  public form: FormGroup;
  public state: Observable<ModalStateEvent>;
  public subject: Subject<ModalStateEvent>;

  public readonly SearchTabsEnum = SearchTabsEnum;

  public ngOnInit(): void {
    this.createForm();
    this.subscribeToModalStates();
  }

  public ngOnDestroy(): void {

  }

  private subscribeToModalStates() {
    this.state.subscribe((mse: ModalStateEvent) => {
      console.log(mse);
      switch (mse.status) {
        case ModalState.PENDING:
          this.subject.next(new ModalStateEvent(ModalState.SUCCESS, null));
      }
    });
  }

  private createForm() {
    switch (this.data.selectedTab.type) {
      // case SearchTabsEnum.PROJECTS:
      //   this.form = this.fb.group({
      //     vehicleType: new FormControl({value: ''}, [RxwebValidators.required()]),
      //     make: new FormControl({value: '', disabled: true}, [RxwebValidators.required()]),
      //     bodyType: new FormControl({value: '', disabled: true}, [RxwebValidators.required()]),
      //     model: new FormControl({value: '', disabled: true}, [RxwebValidators.required()]),
      //     bodyGen: new FormControl({value: '', disabled: true}, [RxwebValidators.required()]),
      //     edition: new FormControl({value: '', disabled: true}, [RxwebValidators.required()]),
      //   });
      //   break;
      case SearchTabsEnum.EVENTS:
        this.createEventFiltersForm();
        break;
      default:
        this.createEventFiltersForm();
        break;
    }

  }

  private createEventFiltersForm() {
    this.form = this.fb.group({
      address: new FormControl('', [RxwebValidators.required()]),
      distance: new FormControl(1000, [RxwebValidators.required()]),
      hashtags: new FormControl('', [RxwebValidators.required()]),
    });
  }
}
