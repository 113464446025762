import {afterNextRender, afterRender, Inject, Injectable, Optional, PLATFORM_ID} from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpContextToken } from '@angular/common/http';

export const REQUEST = new HttpContextToken<any>(() => null);

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private language: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(REQUEST) private request: any
  ) {
    this.language = this.detectLanguage();
  }

  private detectLanguage(): string {
    let host: string | undefined;
    let langParam: string | null = null;
    let lang = 'en-US';

    const cookieLang = this.getCookie('lang');

    if (isPlatformServer(this.platformId)) {
      const request = this.getRequest();
      if (request) {
        host = request.headers?.host;
        langParam = this.getQueryParam(request.url, 'lang');
      }
    } else if (isPlatformBrowser(this.platformId)) {
      host = window.location.hostname;
      langParam = new URLSearchParams(window.location.search).get('lang');
    }

    if (langParam) {
      lang =  this.getLanguageFromParam(langParam);
      this.setCookie('lang', lang);
      return lang;
    }

    if (cookieLang) {
      return cookieLang;
    }

    if (host) {
      lang =  this.getLanguageFromSubdomain(host);
      this.setCookie('lang', lang);
      return lang;
    }

    const langFromAcceptHeaders = this.getLanguageFromAcceptLanguage();

    if (langFromAcceptHeaders) {
      lang = langFromAcceptHeaders;
    }

    this.setCookie('lang', lang);

    return lang;
  }

  private getLanguageFromSubdomain(hostname: string): string {
    const parts = hostname.split('.');
    const subdomain = parts.length > 2 ? parts[0].toLowerCase() : null;

    return this.getMappedLanguage(subdomain);
  }

  public getLanguage(): string {
    return this.language;
  }

  public setLanguage(lang: string) {
    lang = this.getMappedLanguage(lang.toLowerCase());
    this.language = lang;
    this.setCookie('lang', lang);
  }

  private getRequest() {
    // return isPlatformServer(this.platformId) ? require('express').request : null;
    return this.request ? this.request : null;
  }

  private getLanguageFromParam(param: string): string {
    return this.getMappedLanguage(param.toLowerCase());
  }

  private getQueryParam(url: string, paramName: string): string | null {
    const urlObj = new URL(url, `http://${this.getRequest()?.headers?.host}`);
    return urlObj.searchParams.get(paramName);
  }

  private getMappedLanguage(param: string): string {
    switch (true) {
      case !!['pl', 'pl-pl', 'pl_pl'].find(e => e === param): return 'pl-PL';
      case !!['en', 'en-us', 'en-gb', 'en_us'].find(e => e === param): return 'en-US';
      case !!['de', 'de-de', 'de_de'].find(e => e === param): return 'de-DE';
      default:  return 'en-US';
    }
  }

  private getCookie(name: string): string | null {
    if (isPlatformServer(this.platformId)) {
      const request = this.getRequest();
      if (request) {
        const cookies = request.headers?.cookie;
        if (cookies) {
          const match = cookies.match(new RegExp('(^| )' + name + '=([^;]+)'));
          if (match) {
            return match[2];
          }
        }
      }
    } else if (isPlatformBrowser(this.platformId)) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) {
        return parts.pop()?.split(';').shift() || null;
      }
    }
    return null;
  }

  private setCookie(name: string, value: string, days: number = 7) {
    if (isPlatformServer(this.platformId)) {
      const request = this.getRequest();
      if (request) {
        const response = request.res;
        const expires = new Date();
        expires.setDate(expires.getDate() + days);
        response.setHeader('Set-Cookie', `${name}=${value}; Path=/; Expires=${expires.toUTCString()}`);
      }
    } else if (isPlatformBrowser(this.platformId)) {
      const expires = new Date();
      expires.setDate(expires.getDate() + days);
      document.cookie = `${name}=${value}; path=/; expires=${expires.toUTCString()}`;
    }
  }

  private getLanguageFromAcceptLanguage(): string | null {
    let languageFromHeader: string | undefined;

    if (isPlatformServer(this.platformId)) {
      const request = this.getRequest();
      languageFromHeader = request?.headers['accept-language'];
    } else if (isPlatformBrowser(this.platformId)) {
      languageFromHeader = navigator.language || navigator.languages?.[0];
    }

    if (languageFromHeader) {
      const lang = this.getPrimaryLanguageFromAcceptLanguage(languageFromHeader);
      return lang;
    }

    return null;
  }

  private getPrimaryLanguageFromAcceptLanguage(acceptLanguageHeader: string): string {
    const languages = acceptLanguageHeader.split(',').map(lang => lang.split(';')[0].trim());
    return languages.length > 0 ? languages[0] : 'en-US';
  }
}
