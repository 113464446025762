import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {RegistrationRestService} from '../../../api/services/registration.rest.service';
import {Router} from '@angular/router';
import {PasswordValidator} from '../../../core/validators/password-validator';
import {ResponseNotificationService} from "../../../core/services/response-notification.service";
import {environment} from "../../../../environments/environment";
import {ResponseErrorsHandlerService} from "../../../core/services/response-errors-handler.service";
import {LoaderService} from "../../../core/services/loader.service";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  public registerForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private regService: RegistrationRestService,
    private router: Router,
    public rns: ResponseNotificationService,
    private reh: ResponseErrorsHandlerService,
    private ls: LoaderService
  ) {
  }

  ngOnInit() {
      this.createForm();
  }

  currentStyles() {
    return {
      'background': 'url('+environment.apiUrl+'/uploads/backgrounds/test.jpg) no-repeat fixed center',
      'background-size': 'cover',
    };
  }

  createForm() {
    this.registerForm = this.fb.group(
      {
        username: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
        email: ['', [Validators.required, Validators.email]],
        password: this.fb.group(
          {
            first: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
            second: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
          },
          {validator: PasswordValidator.matchPassword}
        ),
        terms: ['', [Validators.requiredTrue]]
      }
    );
  }

  register(): void {
    this.ls.show();
    this.regService.register(this.registerForm).subscribe(
      result => {
        this.rns.emitMessage(result.message);
        this.router.navigate(['/login']);
      },
      error => {
        this.reh.handleFormErrors(this.registerForm, error);
      }
    ).add(() => this.ls.hide());
  }
}
