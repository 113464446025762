<!--<nav>-->
<!--    <app-navbar></app-navbar>-->
<!--</nav>-->
<!--<main>-->
<!--    <div class="container-fluid pl-5 pr-5  mt-5 mb-4 pt-5">-->
<!--        <router-outlet></router-outlet>-->
<!--    </div>-->
<!--</main>-->
<div class="layout-wrapper" [ngClass]="{'p-input-filled': app.inputStyle === 'filled', 'p-ripple-disabled': !app.ripple,
        'layout-menu-static': isStatic(),
        'layout-menu-overlay': isOverlay(),
        'layout-menu-horizontal': isHorizontal(),
        'layout-menu-slim': isSlim(),
        'layout-menu-active': menuActive,
        'layout-menu-mobile-active': mobileMenuActive,
        'layout-locked': locked,
        'layout-rightmenu-active': rightMenuActive,
        'layout-rtl': app.isRTL}" [class]="'layout-menu-' + app.menuTheme + ' layout-topbar-' + app.topbarTheme">

    <app-topbar *ngIf="topbarVisible" [user]="user"></app-topbar>

    <app-rightmenu></app-rightmenu>

    <div class="menu-wrapper" (click)="onMenuClick($event)">
        <div class="layout-menu-container">
            <app-inline-menu *ngIf="app.inlineMenuPosition=='top' || app.inlineMenuPosition=='both'" key="top" [user]="user"></app-inline-menu>
            <app-menu></app-menu>
            <app-inline-menu *ngIf="app.inlineMenuPosition=='bottom' || app.inlineMenuPosition=='both'" key="bottom" [user]="user"></app-inline-menu>
        </div>
    </div>

    <div class="layout-main" (click)="onMainLayoutClick()">
        <app-breadcrumb></app-breadcrumb>
        <div class="layout-content">
            <router-outlet></router-outlet>
        </div>
    </div>
    <app-footer [user]="user"></app-footer>
    <app-config></app-config>

    <div *ngIf="mobileMenuActive" class="layout-mask modal-in"></div>
</div>
