import { Injectable } from '@angular/core';
import {Subject} from "rxjs";
import {environment} from "../../../environments/environment";
import {EventSourceInterface} from "../interfaces/event-source.interface";
import {plainToClass} from "class-transformer";
import {PostApiModel} from "../../api/models/post-api.model";

@Injectable({
  providedIn: 'root'
})
export class EventSourceService {
  public eventSources: EventSource[] = [];

  constructor() { }

  public listenSingleTopic<T>(topic: string, ngswBypass: boolean = true): Subject<EventSourceInterface<T>> {
    if (ngswBypass) {
      topic = topic + `&ngsw-bypass=true`;
    }
    const onMessageSubject$ = new Subject<EventSourceInterface<T>>();
    const eventSource = new EventSource(environment.mercureUrl + `?topic=` + topic);
    this.eventSources.push(eventSource);
    eventSource.onmessage = event => {
      onMessageSubject$.next(JSON.parse(event.data));
    }

    return onMessageSubject$;
  }

  public listenArrayOfTopics<T>(topics: string[], ngswBypass: boolean = true): Subject<EventSourceInterface<T>> {
    if (ngswBypass) {
      topics.push(`ngsw-bypass=true`);
    }

    const topicStr = topics.join('&')

    const onMessageSubject$ = new Subject<EventSourceInterface<T>>();
    const eventSource = new EventSource(environment.mercureUrl + `?` + topicStr);
    this.eventSources.push(eventSource);
    eventSource.onmessage = event => {
      console.log(event)
      const data = JSON.parse(event.data);
      onMessageSubject$.next({...data});
    }

    return onMessageSubject$;
  }


  public close(index): void {
    this.eventSources.find((e: EventSource) => {
      e.url
    })
  }
}
