import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ModalItemModel} from './modal-item.model';
import {CurrentPasswordComponent} from './components/current-password/current-password.component';
import {AddVehicleComponent} from './components/add-vehicle/add-vehicle.component';
import {RemoveComponent} from './components/remove/remove.component';
import {ChangeStatusComponent} from './components/change-status/change-status.component';
import {ReactionListComponent} from './components/reaction-list/reaction-list.component';
import {UntypedFormGroup} from '@angular/forms';
import {ModalState} from './modal-state.enum';
import {ModalComponent} from './modal.component';
import {PostApiModel} from '../../api/models/post-api.model';
import {MediaObjectViewModel} from '../../core/models/media-object.view-model';
import {environment} from '../../../environments/environment';
import {ModalStateEvent} from './modal-state-event';
import {ProjectViewModel} from '../../features/user/garage/project.view-model';
import {TranslateService} from '@ngx-translate/core';
import {AddGoalComponent} from './components/add-goal/add-goal.component';
import {ProjectDescriptionComponent} from './components/project-description/project-description.component';
import {DescriptionPostViewModel} from '../../features/user/project/about/details/description-post.view-model';
import {HistoryPostViewModel} from '../../features/user/project/about/history-post.view-model';
import {HistoryPostComponent} from './components/history-post/history-post.component';
import {GoalViewModel} from '../../features/user/project/goals/goal.view-model';
import {AddMediaComponent} from './components/add-media/add-media.component';
import {GalleryPhoto, Photo} from '@capacitor/camera';
import {AddEventComponent} from './components/add-event/add-event.component';
import {UserViewModel} from '../../features/user/settings/user.view-model';
import {CurrentUserViewModel} from '../../core/models/current-user.view-model';
import {ParticipantApiModel} from '../../api/models/participant.api.model';
import {ParticipationListComponent} from './components/participation-list/participation-list.component';
import {EventApiModel} from '../../api/models/event.api.model';
import {OverlayGalleryComponent} from './components/overlay-gallery/overlay-gallery.component';
import {PostViewModel} from '../timeline/post.view-model';
import {MediaViewComponent} from '../media-view/media-view.component';
import {FollowApiModel} from '../../api/models/follow.api-model';
import {FollowListComponent} from './components/follow-list/follow-list.component';
import {ReactionApiModel} from "../../api/models/reaction.api.model";
import {FollowedSubjectInterface} from "../../core/interfaces/follower-item.interface";
import {CustomCameraMediaItem} from "../../features/custom-camera/custom-camera";
import {state} from "@angular/animations";
import {TimelineComponent} from "../timeline/timeline.component";
import {ReactedSubjectInterface} from "../../core/interfaces/reaction-list-item.interface";
import {SearchFiltersComponent} from "./components/search-filters/search-filters.component";
import {ResultsListTabInterface} from "../../core/components/search-results-list/results-list-tab.interface";


@Injectable({providedIn: 'root'})
export class ModalService {
  get hasButtons(): boolean {
    return this.modalInstance.hasButtons;
  }

  set hasButtons(value: boolean) {
    this.modalInstance.hasButtons = value;
  }

  set name(value: string) {
    this._modalInstance.name = value;
  }

  set isHeaderVisible(value: boolean) {
    this._modalInstance.isHeaderVisible = value;
  }


  get modalInstance(): ModalComponent {
    return this._modalInstance;
  }

  set modalInstance(value: ModalComponent) {
    this._modalInstance = value;
  }

  private _modalInstance: ModalComponent;
  private successSubject: Subject<ModalStateEvent>;
  public successState: Observable<ModalStateEvent>;
  private pendingSubject: Subject<ModalStateEvent>;
  public pendingState: Observable<ModalStateEvent>;

  public content: any;
  public selectedComponent: ModalItemModel;
  public isVisible: boolean;

  public styles: {slideFrom: string, modalType: string, size: string, light?: boolean};
  public btnSuccessName: string;

  constructor(
    private translate: TranslateService
  ) { }

  private show() {
    this.successSubject = new Subject<ModalStateEvent>();
    this.successState = this.successSubject.asObservable();
    this.pendingSubject = new Subject<ModalStateEvent>();
    this.pendingState = this.pendingSubject.asObservable();
    this._modalInstance.component = this.selectedComponent;
    this._modalInstance.show();
    this.isVisible = true;
    const sub = this._modalInstance.internalState.subscribe((modalState: ModalStateEvent) => {
      switch (modalState.status) {
        case ModalState.SUCCESS:
          sub.unsubscribe();
          this.isVisible = false;
          this.isHeaderVisible = true;
          this.emitSuccess(modalState);
          break;
        case ModalState.PENDING:
          this.emitPending(modalState);

      }
    });
  }

  public close(): void {
    this.isVisible = false;
    this.isHeaderVisible = true;
    this._modalInstance.hide();
  }

  public emitState(state: ModalState): void {
    this._modalInstance.modalStateEvent.status = state;
    this._modalInstance.emitState(this._modalInstance.modalStateEvent);
  }

  private emitSuccess(modalState: ModalStateEvent) {
    this.successSubject.next(modalState);
  }

  private emitPending(modalState: ModalStateEvent) {
    this.pendingSubject.next(modalState);
  }

  public showRemoveProjectModal(project: ProjectViewModel): ModalService {
    this.name = this.translate.instant('shared.modal.modal_service.remove_project.title', {vehicleName: project.vehicle.fullVehicleIdentity});
    this.content = {
      id: project['@id'],
      route: environment.apiUrl,
      confirmMessage: this.translate.instant('shared.modal.modal_service.remove_project.confirm', {vehicleName: project.vehicle.fullVehicleIdentity}),
      successMessage: this.translate.instant('shared.modal.modal_service.remove_project.success', {vehicleName: project.vehicle.fullVehicleIdentity}),
      errorMessage: this.translate.instant('shared.modal.modal_service.remove_project.error')
    };
    this.styles = {slideFrom: 'top', modalType: 'modal-lg modal-notify modal-danger', size: 'modal-fluid'};
    this.hasButtons = true;
    this.selectedComponent = new ModalItemModel(RemoveComponent, this.content, 'remove');
    this.show();
    return this;
  }

  public showRemoveTileModal(timeLineItem: PostApiModel) {
    this.name = 'Remove Post';
    this.content = {
      id: timeLineItem.id,
      route: 'api_time_line_items_delete_item',
      message: 'Are you sure to remove this post? This action permanently wipe all informations about your section'
    };
    this.selectedComponent = new ModalItemModel(RemoveComponent, this.content, 'remove');
    this.show();
  }

  public showRemoveImageModal(mediaObject: MediaObjectViewModel, title: string, element: string) {
    this.name = title;
    this.styles = {slideFrom: 'top', modalType: 'modal-sm modal-notify modal-danger', size: 'modal-fluid'};
    this.content = {
      id: mediaObject['@id'],
      route: environment.apiUrl,
      confirmMessage: this.translate.instant('shared.modal.modal_service.remove_image.confirm', {element}),
      successMessage: this.translate.instant('shared.modal.modal_service.remove_image.success', {element}),
      errorMessage: this.translate.instant('shared.modal.modal_service.remove_image.error', {element}),
    };
    this.selectedComponent = new ModalItemModel(RemoveComponent, this.content, 'remove');
    this.hasButtons = true;
    this.show();
    return this;
  }

  public showAddProjectModal(): ModalService {
    this.name = this.translate.instant('shared.modal.modal_service.add_project.title');
    this.styles = {slideFrom: 'top', modalType: 'modal-md modal-notify modal-info', size: 'modal-fluid'};
    this.hasButtons = true;
    this.selectedComponent = new ModalItemModel(AddVehicleComponent, this.content, 'add-vehicle');
    this.show();
    return this;
  }

  public showProjectDescriptionModal(data: {projectVM: ProjectViewModel, descriptionPost?: DescriptionPostViewModel}): ModalService {
    this.name = this.translate.instant(`shared.modal.modal_service.project_description.title`);
    this.styles = {slideFrom: 'top', modalType: 'modal-md modal-notify modal-info', size: 'modal-fluid'};
    this.hasButtons = true;
    this.content = data;
    this.selectedComponent = new ModalItemModel(ProjectDescriptionComponent, this.content, 'project-description');
    this.show();
    return this;
  }

  public showHistoricalEntryModal(data: {projectVM: ProjectViewModel, historyPost?: HistoryPostViewModel}): ModalService {
    this.name = this.translate.instant(`shared.modal.modal_service.history_entry.title`);
    this.styles = {slideFrom: 'top', modalType: 'modal-md modal-notify modal-info', size: 'modal-fluid'};
    this.hasButtons = true;
    this.content = data;
    this.selectedComponent = new ModalItemModel(HistoryPostComponent, this.content, 'project-history');
    this.show();
    return this;
  }

  public showGoalModal(data: {projectVM: ProjectViewModel, goalVM?: GoalViewModel}): ModalService {
    this.name = this.translate.instant(`shared.modal.modal_service.add_goal.title`);
    this.styles = {slideFrom: 'top', modalType: 'modal-md modal-notify modal-info', size: 'modal-fluid'};
    this.hasButtons = true;
    this.content = data;
    this.selectedComponent = new ModalItemModel(AddGoalComponent, this.content, 'add-goal');
    this.show();
    return this;
  }

  public showMediaModal(data: {projectVM: ProjectViewModel, eventAM: EventApiModel, media: CustomCameraMediaItem, isImagePicked: boolean, timeline: TimelineComponent}): ModalService {
    this.isHeaderVisible = false;
    this.hasButtons = data.isImagePicked;
    this.name = this.translate.instant(`shared.modal.modal_service.add_media.title`);
    this.styles = {slideFrom: 'top', modalType: 'modal-md modal-notify modal-info app-media-modal no-padding-modal', size: 'modal-fluid'};
    this.content = data;
    this.selectedComponent = new ModalItemModel(AddMediaComponent, this.content, 'add-media');
    this.show();
    return this;
  }

  public showEventModal(data: {userVM: CurrentUserViewModel}): ModalService {
    this.name = this.translate.instant(`shared.modal.modal_service.add_event.title`);
    this.styles = {slideFrom: 'top', modalType: 'modal-lg modal-notify modal-info no-padding-modal', size: 'modal-fluid'};
    this.hasButtons = true;
    this.content = data;
    this.selectedComponent = new ModalItemModel(AddEventComponent, this.content, 'add-event');
    this.show();
    return this;
  }

  public showChangeProjectStatusModal(project: any): void {
    this.name = 'Change Vehicle project status';
    this.content = {
      item: project,
      route: 'api_projects_delete_item',
      message: null
    };
    this.styles = {slideFrom: 'top', modalType: 'modal-sm modal-notify modal-warning', size: 'modal-fluid'};
    this.hasButtons = true;
    this.selectedComponent = new ModalItemModel(ChangeStatusComponent, this.content, 'change-status');
    this.show();
  }

   public showAskForCredentialsModal(form: UntypedFormGroup): this {
    this.name = this.translate.instant('shared.modal.modal_service.current_password.title');
    this.content = form;
    this.styles = {slideFrom: 'top', modalType: 'modal-sm modal-top modal-notify modal-warning', size: 'modal-fluid'};
    this.hasButtons = true;
    this.selectedComponent = new ModalItemModel(CurrentPasswordComponent, this.content, 'current-password');
    this.show();
    return this;
  }

  public showFollowingsModal(followed?: FollowedSubjectInterface): this {
    this.name = this.translate.instant('shared.modal.modal_service.follows.title');
    this.styles = {slideFrom: 'top', modalType: 'modal-md modal-notify modal-info social-list', size: 'modal-fluid'};
    this.content = followed;
    this.hasButtons = false;
    this.selectedComponent = new ModalItemModel(FollowListComponent, this.content, 'follow-list');
    this.show();
    return this;
  }

  public showReactionsModal(reacted?: ReactedSubjectInterface): this {
    this.name = this.translate.instant('shared.modal.modal_service.reactions.title');
    this.styles = {slideFrom: 'top', modalType: 'modal-md modal-notify modal-info social-list', size: 'modal-fluid'};
    this.content = reacted;
    this.hasButtons = false;
    this.selectedComponent = new ModalItemModel(ReactionListComponent, this.content, 'reaction-list');
    this.show();
    return this;
  }

  public showParticipationListModal(participantAMs: ParticipantApiModel[], state: ('TAKES_PART' | 'INTERESTED' | 'ORGANIZES')[]): this {
    this.name = this.translate.instant('shared.modal.modal_service.event.participants.title');
    this.styles = {slideFrom: 'top', modalType: 'modal-sm modal-notify modal-info', size: 'modal-fluid'};
    return this.participationModal({participantAMs, state});
  }

  public participationModal(data: {participantAMs: ParticipantApiModel[], state: ('TAKES_PART' | 'INTERESTED' | 'ORGANIZES')[]}) {
    this.content = data;
    this.hasButtons = false;
    this.selectedComponent = new ModalItemModel(ParticipationListComponent, this.content, 'participation-list');
    this.show();
    return this;
  }

  public showOverlayGalleryModal(data: {post: PostViewModel, component: MediaViewComponent, activeIndex: number}): ModalService {
    // this.name = this.translate.instant(`shared.modal.modal_service.add_goal.title`);
    this.styles = {slideFrom: 'top', modalType: 'modal-md modal-notify modal-info modal-maximized modal-nogutter', size: 'modal-fluid'};
    this.hasButtons = false;
    this.isHeaderVisible = false;
    this.content = data;
    this.selectedComponent = new ModalItemModel(OverlayGalleryComponent, this.content, 'add-goal');
    this.show();
    return this;
  }

  public showSearchFiltersModal(data: {selectedTab: ResultsListTabInterface}): ModalService {
    this.name = this.translate.instant(`shared.modal.modal_service.search_filters.title`);
    this.styles = {slideFrom: 'top', modalType: 'modal-md modal-notify modal-info', size: 'modal-fluid'};
    this.hasButtons = true;
    this.btnSuccessName = this.translate.instant(`shared.modal.modal_service.search_filters.apply`);
    this.content = data;
    this.selectedComponent = new ModalItemModel(SearchFiltersComponent, this.content, 'search-filters');
    this.show();
    return this;
  }
}
