import {Expose, Type} from 'class-transformer';
import {UserApiModel} from './user.api-model';
import {AbstractApiModel} from './abstract.api.model';
import {MediaObjectApiModel} from './media-object.api-model';
import {EventApiModel} from './event.api.model';
import {ReactionApiModel} from './reaction.api.model';
import {ProjectApiModel} from "./project.api-model";

export class PostApiModel extends AbstractApiModel {
  @Expose() public id: number;
  @Expose() public type: string;
  @Expose() public heading: string;
  @Expose() public content: string;
  @Expose() public comments: string[];
  @Type(() => ReactionApiModel)
  public reactions: (ReactionApiModel | string)[];
  @Expose() public startDate?: Date;
  @Expose() public endDate?: Date;
  @Expose()
  @Type(() => MediaObjectApiModel)
  public media: (MediaObjectApiModel | string)[];
  @Expose()
  @Type(() => UserApiModel)
  public postedBy: UserApiModel;
  @Expose() public project: (string|ProjectApiModel);
  @Expose() public event: (string|EventApiModel);
  @Expose() public updatedAt: Date;
  @Expose() public createdAt: Date;
}
