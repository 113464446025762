import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-no-auth-layout',
  templateUrl: './no-auth-layout.component.html',
  styleUrls: ['./no-auth-layout.component.scss']
})
export class NoAuthLayoutComponent implements OnInit {
  
  get currentStyles() {
    return this.route.snapshot.data['background'];
  }
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

}
