import { Action } from '@ngrx/store';
import {FollowApiModel} from "../../api/models/follow.api-model";

export enum FollowActionTypes {
  ADD_ONE = '[Follow] Add One',
  ADD_MANY = '[Follow] Add Many',
  UPDATE_ONE = '[Follow] Update One',
  DELETE_ONE = '[Follow] Delete One',
  GET_ALL = '[Follow] Get All',
  GET_ONE = '[Follow] Get One'
}
export class AddOne implements Action {
  readonly type = FollowActionTypes.ADD_ONE;
  constructor(public follow: FollowApiModel) { }
}

export class AddMany implements Action {
  readonly type = FollowActionTypes.ADD_MANY;
  constructor(public follows: FollowApiModel[]) { }
}

export class UpdateOne implements Action {
  readonly type = FollowActionTypes.UPDATE_ONE;
  constructor(
    public id: string,
    public changes: Partial<FollowApiModel>,
  ) { }
}
export class DeleteOne implements Action {
  readonly type = FollowActionTypes.DELETE_ONE;
  constructor(public id: string) { }
}
export class GetAll implements Action {
  readonly type = FollowActionTypes.GET_ALL;
  constructor(public follows: FollowApiModel[]) { }
}

export class GetOne implements Action {
  readonly type = FollowActionTypes.GET_ONE;
  constructor(public follow: FollowApiModel) { }
}

export type Actions
  = GetOne
  | AddOne
  | AddMany
  | UpdateOne
  | DeleteOne
  | GetAll;
