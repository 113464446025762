import {Exclude, Expose} from 'class-transformer';
import {AbstractApiModel} from './abstract.api.model';

@Exclude()
export class SearchLogApiModel extends AbstractApiModel {
  @Expose() public id: number;
  @Expose() public user: string;
  @Expose() public type: string;
  @Expose() public slug: string;
  @Expose() public updatedAt: Date;
  @Expose() public createdAt: Date;
}
