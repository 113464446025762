import {
  afterNextRender,
  afterRender,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {MediaObjectInterface} from '../../core/interfaces/media-object.interface';
import {MediaTypeEnum} from '../../core/enums/media-type.enum';
import {VideoService} from '../timeline/video.service';
// import * as Plyr from 'plyr';
import {TimelineComponent} from "../timeline/timeline.component";
import type * as Plyr from 'plyr';

@Component({
  selector: 'app-media-view',
  templateUrl: './media-view.component.html',
  styleUrls: ['./media-view.component.scss']
})
export class MediaViewComponent implements OnInit, AfterViewInit {
  get currentTime(): number {
    return this._currentTime;
  }

  @Input() set currentTime(value: number) {
    this._currentTime = value;
    this.currentTimeChange.emit(value);
  }

  @Input() public mediaObject: MediaObjectInterface;
  @Input() public id: any;
  @Input() public prefix: string = 'video';
  @Input() public clickable: boolean = false;
  public isEnded: boolean = false;
  private _currentTime: number = 0;
  @Output() public currentTimeChange: EventEmitter<number> = new EventEmitter<number>();
  public readonly MediaTypeEnum = MediaTypeEnum;
  public player: Plyr;
  @Output() playChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public readonly elementRef: ElementRef,
    public videoService: VideoService,
    public cdr: ChangeDetectorRef
  ) {
    afterNextRender(async () => {
      await this.initPlayer();
    });
  }

  ngOnInit(): void {
    if (!this.id) {
      this.id = this.mediaObject.id;
    }
  }

  ngAfterViewInit(): void {

  }

  initClickVideoEvent(): void {
    const element = document.querySelector(`#${this.prefix}-${this.id || this.mediaObject.id} + .plyr__poster`);
    element.addEventListener('click', () => {
      this.togglePlay();
    }, {passive: true});
  }

  togglePlay(toggle?: {value?: boolean, event?: Event}): void {
    if (toggle.event) {
      toggle.event.stopPropagation();
    }
    console.log('toggle', this.player);
    if (this.player) {
      this.player.muted = this.videoService.muted;
      this.player.togglePlay(toggle.value);
    }
  }

  async initPlayer() {
    const videoElement = this.elementRef.nativeElement.querySelector(`#${this.prefix}-${this.id || this.mediaObject.id}`);

    if (videoElement) {
      videoElement.currentTime = this._currentTime;
      this.player = await this.videoService.initPlayer(videoElement, this.mediaObject, () => {
        if (this.clickable) {
          this.initClickVideoEvent();
        }
      });

      if (this.player) {
        this.player.on('playing', () => {
          this.isEnded = false;
        });

        this.player.on('timeupdate', () => {
          this.currentTime = videoElement.currentTime;
        });

        this.player.on('ended', () => {
          this.isEnded = true;
          this.player.stop();
        });
      }

    }
  }

  toggleVolume(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    console.log('sts');
    this.player.muted = !this.player.muted;
    this.videoService.muted = this.player.muted;
    return;
  }
}
