<h3>{{'features.privacy.privacy' | translate}}</h3>
<p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum sed tincidunt enim, sed malesuada nunc. Phasellus est dolor, bibendum et ipsum in, auctor sollicitudin purus. Pellentesque et imperdiet massa, tristique cursus nisl. Etiam ullamcorper scelerisque pharetra. Proin ullamcorper ut neque nec euismod. Nam fringilla luctus congue. Cras euismod ipsum ut vehicula dictum. Donec molestie nulla nulla, vel mollis dui fringilla at. Nunc condimentum sodales sem, ut mollis purus pellentesque vel. Suspendisse eu efficitur dui, nec mattis metus. Nullam pellentesque nunc eu nisi gravida, lobortis molestie lacus pharetra.
</p>
<p>
    Phasellus justo lorem, suscipit rutrum felis mattis, vestibulum rhoncus sapien. Ut posuere turpis sollicitudin sem sagittis lobortis. Aenean vel finibus ipsum, nec ullamcorper nulla. Donec vel posuere orci. Proin quis sem eros. Ut porta semper neque ac viverra. Integer varius ut orci id facilisis. Etiam et libero finibus, vestibulum odio id, euismod tortor. Suspendisse egestas magna non cursus lobortis. Pellentesque ut leo tristique, accumsan risus id, iaculis dui. Praesent viverra diam pellentesque dolor pharetra, id dignissim metus posuere. Cras ornare, arcu quis condimentum viverra, leo tortor congue quam, sed tempor eros tellus eu ligula.
</p>
<p>
    Donec mollis fermentum augue nec ullamcorper. In at eros pharetra, consectetur nisi vitae, sagittis lectus. Etiam congue iaculis ornare. Sed volutpat risus a nunc suscipit, a vehicula erat pharetra. Fusce dui risus, porttitor interdum ipsum sit amet, volutpat sollicitudin ligula. Integer venenatis elit metus, non laoreet lorem semper ut. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nam sit amet iaculis tellus, at convallis dolor. Vivamus condimentum massa nunc, eu porta dui efficitur a. Fusce euismod eu neque ac mattis. Maecenas efficitur ante leo, ut convallis turpis efficitur ut. Quisque ornare pharetra dapibus. In at condimentum sapien. Ut nec dui quis mi pulvinar interdum. Integer vitae quam accumsan neque lacinia varius. Suspendisse commodo tellus in augue malesuada dignissim.
</p>
