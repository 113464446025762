import {Injectable} from '@angular/core';
import {VehicleRestService} from "../../../../api/services/vehicle.rest.service";
import {ProjectRestService} from "../../../../api/services/project.rest.service";
import {VehicleTypeRestService} from "../../../../api/services/vehicle-type.rest.service";
import {Observable} from "rxjs";
import {VehicleTypeViewModel} from "../../../../core/models/vehicle-type.view-model";
import {VehicleTypeApiModel} from "../../../../api/models/vehicle-type.api-model";
import {map} from "rxjs/operators";
import {ResultListApiModel} from "../../../../api/models/result-list.api.model";
import {MakeViewModel} from "../../../../core/models/make.view-model";
import {MakeRestService} from "../../../../api/services/make.rest.service";
import {QueryParamsApiModel} from "../../../../api/models/query-params-api.model";
import {FilterParameterApiModel} from "../../../../api/models/filter-parameter.api.model";
import {WheelDriveApiModel} from "../../../../api/models/wheel-drive-api.model";
import {BodyTypeRestService} from "../../../../api/services/body-type.rest.service";
import {BodyTypeViewModel} from "../../../../core/models/body-type.view-model";
import {BodyTypeApiModel} from "../../../../api/models/body-type.api-model";
import {ModelRestService} from "../../../../api/services/model.rest.service";
import {ModelApiModel} from "../../../../api/models/model.api-model";
import {ModelViewModel} from "../../../../core/models/model.view-model";
import {BodyGenViewModel} from "../../../../core/models/body-gen.view-model";
import {BodyGenApiModel} from "../../../../api/models/body-gen.api-model";
import {BodyGenRestService} from "../../../../api/services/body-gen.rest.service";
import {EditionRestService} from "../../../../api/services/edition.rest.service";
import {EditionViewModel} from "../../../../core/models/edition.view-model";
import {EditionApiModel} from "../../../../api/models/edition.api-model";
import {VehicleViewModel} from "../../../../features/user/garage/vehicle.view-model";
import {VehicleInterface} from "./vehicle.interface";
import {VehicleApiModel} from "../../../../api/models/vehicle.api-model";

@Injectable({
  providedIn: 'root'
})
export class AddVehicleDataProviderService {

  constructor(
    private vehicleTypeRS: VehicleTypeRestService,
    private makeRS: MakeRestService,
    private bodyTypeRS: BodyTypeRestService,
    private modelRS: ModelRestService,
    private bodyGenRS: BodyGenRestService,
    private editionRS: EditionRestService,
    private vehicleRS: VehicleRestService,
    private projectRS: ProjectRestService
  ) {
  }

  public getVehicleTypeCollection(): Observable<VehicleTypeViewModel[]> {
    return this.vehicleTypeRS.getVehicleTypeCollection()
      .pipe(map((vehicleTypeAMs: ResultListApiModel<VehicleTypeApiModel>) => {
        return vehicleTypeAMs.records.map((vehicleTypeAM: VehicleTypeApiModel) => new VehicleTypeViewModel(vehicleTypeAM))
      }))
  }

  public getMakeCollection(vehicleTypeVM: VehicleTypeViewModel): Observable<MakeViewModel[]> {
    const queryParams = new QueryParamsApiModel();
    queryParams.filters.push(new FilterParameterApiModel('vehicles.vehicleType', vehicleTypeVM["@id"], 'single'));
    return this.makeRS.getMakeCollection(queryParams).pipe(map((makeAMs: ResultListApiModel<WheelDriveApiModel>) => {
      return makeAMs.records.map((makeAM: WheelDriveApiModel) => new MakeViewModel(makeAM));
    }));
  }

  public getBodyTypeCollection(
    vehicleTypeVM: VehicleTypeViewModel,
    makeVM: MakeViewModel
  ): Observable<BodyTypeViewModel[]> {
    const queryParams = new QueryParamsApiModel();
    queryParams.filters.push(new FilterParameterApiModel('vehicles.vehicleType', vehicleTypeVM["@id"], 'single'));
    queryParams.filters.push(new FilterParameterApiModel('vehicles.make', makeVM["@id"], 'single'));
    return this.bodyTypeRS.getBodyTypeCollection(queryParams).pipe(map((bodyTypeAMs: ResultListApiModel<BodyTypeApiModel>) => {
      return bodyTypeAMs.records.map((bodyTypeAM: BodyTypeApiModel) => new BodyTypeViewModel(bodyTypeAM));
    }));
  }

  public getModelCollection(
    vehicleTypeVM: VehicleTypeViewModel,
    makeVM: MakeViewModel,
    bodyTypeVM: BodyTypeViewModel
  ): Observable<ModelViewModel[]> {
    const queryParams = new QueryParamsApiModel();
    queryParams.filters.push(new FilterParameterApiModel('vehicles.vehicleType', vehicleTypeVM["@id"], 'single'));
    queryParams.filters.push(new FilterParameterApiModel('vehicles.make', makeVM["@id"], 'single'));
    queryParams.filters.push(new FilterParameterApiModel('vehicles.bodyType', bodyTypeVM["@id"], 'single'));
    return this.modelRS.getModelCollection(queryParams).pipe(map((modelAMs: ResultListApiModel<ModelApiModel>) => {
      return modelAMs.records.map((modelAM: ModelApiModel) => new ModelViewModel(modelAM));
    }));
  }

  public getBodyGenCollection(
    vehicleTypeVM: VehicleTypeViewModel,
    makeVM: MakeViewModel,
    bodyTypeVM: BodyTypeViewModel,
    modelVM: ModelViewModel,
  ): Observable<BodyGenViewModel[]> {
    const queryParams = new QueryParamsApiModel();
    queryParams.filters.push(new FilterParameterApiModel('vehicles.vehicleType', vehicleTypeVM["@id"], 'single'));
    queryParams.filters.push(new FilterParameterApiModel('vehicles.make', makeVM["@id"], 'single'));
    queryParams.filters.push(new FilterParameterApiModel('vehicles.bodyType', bodyTypeVM["@id"], 'single'));
    queryParams.filters.push(new FilterParameterApiModel('vehicles.model', modelVM["@id"], 'single'));
    return this.bodyGenRS.getBodyGenCollection(queryParams).pipe(map((bodyGenAMs: ResultListApiModel<BodyGenApiModel>) => {
      return bodyGenAMs.records.map((bodyGenAM: BodyGenApiModel) => new BodyGenViewModel(bodyGenAM));
    }));
  }

  public getEditionCollection(
    vehicleTypeVM: VehicleTypeViewModel,
    makeVM: MakeViewModel,
    bodyTypeVM: BodyTypeViewModel,
    modelVM: ModelViewModel,
    bodyGenVM: BodyGenViewModel
  ): Observable<EditionViewModel[]> {
    const queryParams = new QueryParamsApiModel();
    queryParams.filters.push(new FilterParameterApiModel('vehicles.vehicleType', vehicleTypeVM["@id"], 'single'));
    queryParams.filters.push(new FilterParameterApiModel('vehicles.make', makeVM["@id"], 'single'));
    queryParams.filters.push(new FilterParameterApiModel('vehicles.bodyType', bodyTypeVM["@id"], 'single'));
    queryParams.filters.push(new FilterParameterApiModel('vehicles.model', modelVM["@id"], 'single'));
    queryParams.filters.push(new FilterParameterApiModel('vehicles.bodyGen', bodyGenVM["@id"], 'single'));
    return this.editionRS.getEditionCollection(queryParams).pipe(map((bodyGenAMs: ResultListApiModel<EditionApiModel>) => {
      return bodyGenAMs.records.map((bodyGenAM: EditionApiModel) => new EditionViewModel(bodyGenAM));
    }));
  }

  public getVehicleByParams(vehicleForm: VehicleInterface): Observable<VehicleViewModel[]> {
    const keys = Object.keys(vehicleForm);
    const queryParams = new QueryParamsApiModel();
    for (let key of keys) {
      queryParams.filters.push(new FilterParameterApiModel(key, vehicleForm[key]['@id'], 'single'));
    }
    return this.vehicleRS.getVehicleCollection(queryParams)
      .pipe(map((vehicleAMs: ResultListApiModel<VehicleApiModel>) => {
         return vehicleAMs.records.map((vehicleAM: VehicleApiModel) => new VehicleViewModel(vehicleAM));
      }));
  }
}
