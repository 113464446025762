import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {Observable} from 'rxjs';
import {TokenDataProviderService} from "../services/token-data-provider.service";
import {ResponseNotificationService} from "../services/response-notification.service";

@Injectable()
export class AdminAuthGuard  {

  constructor(private router: Router, private ts: TokenDataProviderService, private rns: ResponseNotificationService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.ts.isLoggedIn()) {
      this.router.navigate(['/admin/login']);
      this.rns.emitError('Wybrana sekcja wymaga autoryzacji!')
      return false;
    }

    if (!this.ts.getJwtUser().roles.includes('ROLE_ADMIN')) {
      this.router.navigate(['/admin/login']);
      this.rns.emitError('Wybrana sekcja dostepna tylko dla użytkowników z rolą administratora!')
      return false;
    }
    return true;
  }
}
