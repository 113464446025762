<div *ngIf="writingPostEnabled" class="grid justify-content-center grid-nogutter">
  <ng-container>
    <p-card *ngxPermissionsOnly="permissions" [style]="{width: '100%'}" class="col-12 mt-2">
      <app-social-input
        #addPostSocialInput
        style="width: 100%"
        (onSubmit)="addPost($event)"
        [label]="'shared.timeline.post_label' | translate"
      >
      </app-social-input>
    </p-card>
  </ng-container>
</div>
<div class="grid infinite justify-content-center grid-nogutter mt-2 relative">
  <ng-container [ngSwitch]="viewType">
    <ng-container *ngSwitchCase="ViewTypeEnum.GRID">
      <div class="width-100 flex justify-content-center mb-5" *ngIf="!isRendered">
        <p-progressSpinner styleClass="w-2rem h-2rem" strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s">
        </p-progressSpinner>
      </div>
      <div
        NgxFrameInfiniteGrid
        [items]="posts"
        [gap]="0"
        [autoResize]="true"
        [frame]="[[1,2,3], [4,5,6]]"
        [useLoading]="true"
        [useResizeObserver]="true"
        [observeChildren]="true"
        [useRoundedSize]="true"
        [isEqualSize]="true"
        [isConstantSize]="true"
        [useFit]="true"
        #ig
        (requestAppend)="onRequestAppend($event)"
        (renderComplete)="navigateToFragmentIfRequired()"
        class="container timeline-container m-0 p-0 mb-3">
        <div *ngFor="let item of ig.visibleItems; let i = index" class="item item-padding" [id]="'item' + item.data.id">
          <ng-container *ngIf="item.type === ITEM_TYPE.NORMAL">
            <p-skeleton *ngIf="!item.data.isPlaceholder && !item.data.media" width="100%" height="100%"></p-skeleton>
            <img alt="media thumbnail" *ngIf="!item.data.isPlaceholder && item.data.media" class="media-thumbnail" [src]="item.data.media[0] | mediaObject: {
              formatType: item.data.media[0].type === MediaTypeEnum.VIDEO ? 'm3u8_timeline' : 'thumbnail'
            }" width="100%" (click)="navigateToList(item)"/>
          </ng-container>
          <div class="width-100 flex justify-content-center mb-5" *ngIf="item.type !== ITEM_TYPE.NORMAL">
            <p-progressSpinner styleClass="w-2rem h-2rem" strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s">
            </p-progressSpinner>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="ViewTypeEnum.LIST">
      <div class="width-100 flex justify-content-center mb-5" *ngIf="!isRendered">
        <p-progressSpinner styleClass="w-2rem h-2rem" strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s">
        </p-progressSpinner>
      </div>
      <div
        NgxMasonryInfiniteGrid
        [items]="posts"
        [gap]="0"
        [autoResize]="true"
        #ig
        [observeChildren]="true"
        [useResizeObserver]="true"
        [useLoading]="true"
        (requestAppend)="onRequestAppend($event)"
        (renderComplete)="navigateToFragmentIfRequired()"
        class="container timeline-container m-0 p-0 mb-3">
        <div *ngFor="let item of ig.visibleItems; let i = index" class="col-12 m-0 p-0 item-margin" [id]="'item' + item.data.id">
          <app-timeline-tile
            *ngIf="posts.length && item.type === ITEM_TYPE.NORMAL"
            [post]="item.data"
            [currentUser]="currentUser"
            (onPostContextMenuOpen)="openPostContextMenu($event)"
            (onCommentContextMenuOpen)="openCommentContextMenu($event, i)"
            (play)="isPlaying = !isPlaying"
          ></app-timeline-tile>
          <div class="width-100 flex justify-content-center mb-5" *ngIf="item.type !== ITEM_TYPE.NORMAL">
            <p-progressSpinner styleClass="w-2rem h-2rem" strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s">
            </p-progressSpinner>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <div *ngIf="!posts.length && noResultsMsg">
    <p>{{noResultsMsg}}</p>
  </div>
</div>

<app-bottom-sidebar
  [isSidebarOpened]="isSidebarOpened"
  [isBackButtonVisible]="!!sidebarAction"
  (onHide)="onSidebarHide()"
  (onGoBack)="sidebarAction = null"
>
  <ng-container [ngSwitch]="sidebarAction">
    <ng-container *ngSwitchCase="'editPost'" [ngTemplateOutlet]="edit"></ng-container>
    <ng-container *ngSwitchCase="'editComment'" [ngTemplateOutlet]="edit"></ng-container>
    <ng-container *ngSwitchDefault [ngTemplateOutlet]="defaultTemp"></ng-container>
  </ng-container>
</app-bottom-sidebar>

<ng-template #edit>
  <div class="col-12 inline-flex mt-3 mb-2">
    <app-thumbnail
      classList="thumbnail-inline-block mr-2 "
      size="xs"
      [circle]="true"
      [imgSrc]="contextMenuObject.avatar | mediaObject"
    ></app-thumbnail>
    <div>
      {{contextMenuObject.author.username}}
      <span class="date block">{{contextMenuObject.createdAt | dateAgo}}</span>
    </div>
  </div>
  <div class="col-12 p-fluid">
    <ng-container [ngSwitch]="sidebarAction">
      <ng-container *ngSwitchCase="'editPost'">
        <app-social-input
          #editPostSocialInput
          style="width: 100%"
          (onSubmit)="updatePost($event)"
          [mediaObjectAMs]="contextMenuObject.mediaObjectAMs"
          [value]="contextMenuObject.content"
          [label]="'common.write_something' | translate"
          [loading]="submitDisabled"
        >
        </app-social-input>
      </ng-container>
      <ng-container *ngSwitchCase="'editComment'">
        <app-social-input
          style="width: 100%"
          (onSubmit)="updateComment($event)"
          [value]="contextMenuObject.content"
          [label]="'common.write_something' | translate"
          [loading]="submitDisabled"
        >
        </app-social-input>
      </ng-container>
    </ng-container>
    <app-spinner *ngIf="spinnerState"></app-spinner>
  </div>
</ng-template>

<ng-template #defaultTemp>
  <div class="col-12 pt-3">
    <p-slideMenu
      [model]="items"
      [menuWidth]="900"
      styleClass="project-slidemenu"
      [backLabel]="'common.button.back' | translate"
    >
    </p-slideMenu>
  </div>
</ng-template>


<p-confirmDialog #cd header="Confirmation" icon="pi pi-exclamation-triangle" [key]="'timeline'">
  <p-footer>
    <div class="flex justify-content-around">
      <button
        type="button"
        pButton
        icon="pi pi-times"
        class="p-button-danger p-button-outlined"

        [label]="'common.button.dismiss' | translate"
        (click)="cd.reject()">
      </button>
      <button
        type="button"
        class="p-button-outlined"
        pButton
        icon="pi pi-check"
        [label]="'common.button.proceed' | translate"
        (click)="cd.accept()">
      </button>
    </div>
  </p-footer>
</p-confirmDialog>

