import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'truncateOnWord'
})
export class TruncateOnWordPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(v: string, limit: number, append: string): string {
    return TruncateOnWordPipe.truncateOnWord(v, limit, append);
  }

  public static truncateOnWord(v: string, limit: number, append: string): string {
    const reg = new RegExp(
      '(?=[\u0009\u000A\u000B\u000C\u000D\u0020\u00A0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u2028\u2029\u3000\uFEFF])'
    );
    const words = v.split(reg);
    let count = 0;
    return words.filter((word) => {
      count += word.length;
      return count <= limit;
    }).join('') + append;
  }
}
