import {AbstractViewModel} from "../../../core/interfaces/abstract-view-model";
import {VehicleApiModel} from "../../../api/models/vehicle.api-model";
import {VehicleTypeViewModel} from "../../../core/models/vehicle-type.view-model";
import {ParameterInterface} from "../project/parameters/parameter-interface";
import {EngineInterface} from "../project/parameters/engine-interface";
import {BodyworkInterface} from "../project/parameters/bodywork-interface";
import {DictInterface} from "../project/parameters/dict-interface";
import {TransmissionInterface} from "../project/parameters/transmission-interface";
import {BodyGenInterface} from "../project/parameters/body-gen-interface";

export class VehicleViewModel extends AbstractViewModel<VehicleApiModel> {
  public user: string;
  public vehicleType: VehicleTypeViewModel;
  public fullVehicleIdentity: string;
  public make: DictInterface;
  public bodyGen: BodyGenInterface;
  public bodyType: DictInterface;
  public edition: DictInterface;
  public engine: EngineInterface;
  public transmission: TransmissionInterface;
  public wheelDrive: DictInterface;
  public model: DictInterface;
  public bodyWork: BodyworkInterface;
  public parameter: ParameterInterface;
  public frontSuspension: DictInterface;
  public rearSuspension: DictInterface;
  public frontBrake: DictInterface;
  public rearBrake: DictInterface;


  public constructor(protected apiModel: VehicleApiModel = new VehicleApiModel()) {
    super(apiModel);
    this.fromApiModel(apiModel);
  }

  fromApiModel(apiModel: VehicleApiModel): AbstractViewModel<VehicleApiModel> {
    this.id = apiModel.id;
    this.fullVehicleIdentity = apiModel.fullVehicleIdentity;
    this.make = apiModel.make as DictInterface;
    this.bodyGen = apiModel.bodyGen as BodyGenInterface;
    this.bodyType = apiModel.bodyType as DictInterface;
    this.edition = apiModel.edition as DictInterface;
    this.model = apiModel.model as DictInterface;
    this.engine = apiModel.engine as EngineInterface;
    this.transmission = apiModel.transmission as TransmissionInterface;
    this.wheelDrive = apiModel.wheelDrive as DictInterface;
    this.bodyWork = apiModel.bodyWork
    this.parameter = apiModel.parameter
    this.frontSuspension = apiModel.frontSuspension as DictInterface;
    this.rearSuspension = apiModel.rearSuspension as DictInterface;
    this.frontBrake = apiModel.frontBrake as DictInterface;
    this.rearBrake = apiModel.rearBrake as DictInterface;
    return this;
  }

  toApiModel(): VehicleApiModel {
    return this.apiModel;
  }

}
