import {Component, OnInit, Input, AfterViewChecked} from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit, AfterViewChecked {

  @Input() name: string = '';
  @Input() overlay: boolean;

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewChecked() {
  }
}
