import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {ModalInterface} from '../../modal.interface';
import {concat, EMPTY, Observable, of, Subject} from 'rxjs';
import {ModalStateEvent} from '../../modal-state-event';
import {CurrentUserViewModel} from '../../../../core/models/current-user.view-model';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../state/app.state';
import {LoaderService} from '../../../../core/services/loader.service';
import {PostViewModel} from '../../../timeline/post.view-model';
import {SwiperOptions} from 'swiper/types';
import Swiper, {Controller, FreeMode, Keyboard, Mousewheel, Pagination} from 'swiper';
import {ModalState} from '../../modal-state.enum';

import {MediaViewComponent} from '../../../media-view/media-view.component';
// import * as Plyr from 'plyr';
import {VideoService} from '../../../timeline/video.service';
import {PostTypeEnum} from '../../../../core/enums/post-type.enum';
import {EventShowTabsEnum} from '../../../../features/event/event-show/event-show-tabs.enum';
import {SwiperComponent} from "swiper/angular";

Swiper.use([Pagination, FreeMode, Keyboard, Mousewheel, Controller]);


@Component({
  selector: 'app-overlay-gallery',
  templateUrl: './overlay-gallery.component.html',
  styleUrls: ['./overlay-gallery.component.scss'],
})
export class OverlayGalleryComponent implements OnInit, OnDestroy, ModalInterface {
  constructor(
    private store: Store<AppState>,
    private ls: LoaderService,
    private vs: VideoService
  ) {
  }

  public currentUser: CurrentUserViewModel;
  public observeState: string;

  @Input() data: {post: PostViewModel, component: MediaViewComponent, activeIndex: number};
  public state: Observable<ModalStateEvent>;
  public subject: Subject<ModalStateEvent>;
  public currentTime: number;
  @ViewChildren(MediaViewComponent, {emitDistinctChangesOnly: false}) mediaVIewComponents: QueryList<MediaViewComponent>;
  @ViewChild('swiperComponent') swiperComponent: SwiperComponent;
  public post: PostViewModel;
  public config: SwiperOptions;
  public activeIndex: number;

  public readonly PostTypeEnum = PostTypeEnum;
  public readonly EventShowTabsEnum = EventShowTabsEnum;

  ngOnInit() {
    this.config = {
      slidesPerView: 1,
      autoHeight: true,
      virtual: false,
      preloadImages: false,
      lazy: true,
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      pagination: true
    };
    // this.disableBodyScroll();
    const player/*: Plyr*/ = null //this.data.component.player;
    if (player) {
      player.pause();
      this.currentTime = player.currentTime;
    }
    this.activeIndex = this.data.activeIndex;
    this.post = this.data.post;
  }

  ngOnDestroy() {
  }

  close(): void {
    this.mediaVIewComponents.get(this.activeIndex).togglePlay({value: false});
    this.subject.next(new ModalStateEvent(ModalState.SUCCESS, {component: this.data.component, currentTime: this.currentTime}));
    // this.enableBodyScroll();
  }
  onSwiper(swiper) {
    console.log(swiper);
    const currentPlayer = this.mediaVIewComponents.get(this.activeIndex).player;
    if (currentPlayer) {
      currentPlayer.play();
    }
  }
  onSlideChange(event: any) {
    console.log(event, 'slide change');
    const previousPlayer = this.mediaVIewComponents.get(event[0].previousIndex)?.player;
    if (previousPlayer) {
      const previousPlaySettings = previousPlayer.playing;
      previousPlayer.pause();
      console.log(event, 'test123');
      const currentPlayer = this.mediaVIewComponents.get(this.activeIndex).player;
      currentPlayer.muted = previousPlayer.muted;
      previousPlaySettings ? currentPlayer.play() : currentPlayer.pause();
    }

  }


  onNavigationNext(event: any) {
    console.log(event, 'navigation change');
  }

  onActiveIndexChange($event: [Swiper]) {
    console.log($event, $event[0].activeIndex);
    this.activeIndex = $event[0].activeIndex;
  }

  toggleVideoPlay(): void {
    const player = this.mediaVIewComponents.get(this.activeIndex).player;
    if (player) {
      player.togglePlay();
    }
  }
}
