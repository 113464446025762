<!--<p>{{'shared.modal.component.add_vehicle.choose_vehicle' | translate}}</p>-->
<form *ngIf="data.selectedTab.type === SearchTabsEnum.EVENTS" [formGroup]="form" novalidate>
  <div class="grid">
    <div class="col-12 lg:col-6 p-fluid m-0 p-0 mb-2">
      <p-fieldset legend="Lokalizacja" styleClass="pb-3">
        <p-autoComplete
          formControlName="address"
          [placeholder]="'shared.modal.modal_service.add_event.search'| translate"
          [minLength]="2"
          [delay]="500"
          [field]="'display_name'"
          [required]="true"
          [styleClass]="'mb-2'"
        >
          <ng-template let-result pTemplate="item">
            <div class="text-white">{{ result.label }}</div>
          </ng-template>
        </p-autoComplete>
        <app-map></app-map>
      </p-fieldset>
    </div>
    <div class="col-12 lg:col-6 p-fluid m-0 p-0 mb-2">
      <p-fieldset legend="Odległość">
        <label><span> +{{form.get('distance').value}} km</span></label>
        <br/>
        <br/>
        <p-slider formControlName="distance" [step]="10" [max]="5000" styleClass="w-full mb-2"></p-slider>
      </p-fieldset>
    </div>
  </div>
  <div class="grid ">
    <div class="col-12 lg:col-6 p-fluid m-0 p-0 mb-2">
      <p-fieldset legend="Hashtagi">
        <app-hashtags
          formControlName="hashtags"
          [placeholder]="'shared.modal.modal_service.add_event.search'| translate"
          [completeOnFocus]="true"
          [forceSelection]="true"
        >
          <div *ngIf="form.get('hashtags').invalid && form.get('hashtags').touched" class="app-form-error">
            <p>{{form.get('hashtags')['errorMessage']}}</p>
            <p *ngIf="form.get('hashtags').errors?.custom">{{form.get('hashtags').errors.custom}}</p>
          </div>
        </app-hashtags>
      </p-fieldset>
    </div>
<!--    <div class="col-12 lg:col-6 p-fluid">-->
<!--      <div class="p-field">-->
<!--&lt;!&ndash;        <p-dropdown&ndash;&gt;-->
<!--&lt;!&ndash;            formControlName="model"&ndash;&gt;-->
<!--&lt;!&ndash;            filter="true"&ndash;&gt;-->
<!--&lt;!&ndash;            dataKey="@id"&ndash;&gt;-->
<!--&lt;!&ndash;            optionLabel="name"&ndash;&gt;-->
<!--&lt;!&ndash;            [placeholder]="'shared.modal.component.add_vehicle.form.placeholder.model' | translate"&ndash;&gt;-->
<!--&lt;!&ndash;            [options]="models"&ndash;&gt;-->
<!--&lt;!&ndash;            (onChange)="seekBodyGens(vType.value, make.value, bType.value, model.value)"&ndash;&gt;-->
<!--&lt;!&ndash;            #model&ndash;&gt;-->
<!--&lt;!&ndash;        >&ndash;&gt;-->
<!--&lt;!&ndash;        </p-dropdown>&ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="grid">-->
<!--    <div class="col-12 lg:col-6 p-fluid">-->
<!--      <div class="p-field">-->
<!--&lt;!&ndash;        <p-dropdown&ndash;&gt;-->
<!--&lt;!&ndash;            formControlName="bodyGen"&ndash;&gt;-->
<!--&lt;!&ndash;            filter="true"&ndash;&gt;-->
<!--&lt;!&ndash;            dataKey="@id"&ndash;&gt;-->
<!--&lt;!&ndash;            optionLabel="name"&ndash;&gt;-->
<!--&lt;!&ndash;            [placeholder]="'shared.modal.component.add_vehicle.form.placeholder.body_gen' | translate"&ndash;&gt;-->
<!--&lt;!&ndash;            [options]="bodyGens"&ndash;&gt;-->
<!--&lt;!&ndash;            (onChange)="seekEditions(vType.value, make.value, bType.value, model.value, bGen.value)"&ndash;&gt;-->
<!--&lt;!&ndash;            #bGen&ndash;&gt;-->
<!--&lt;!&ndash;        >&ndash;&gt;-->
<!--&lt;!&ndash;        </p-dropdown>&ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-12 lg:col-6 p-fluid">-->
<!--      <div class="p-field">-->
<!--&lt;!&ndash;        <p-dropdown&ndash;&gt;-->
<!--&lt;!&ndash;            formControlName="edition"&ndash;&gt;-->
<!--&lt;!&ndash;            filter="true"&ndash;&gt;-->
<!--&lt;!&ndash;            dataKey="@id"&ndash;&gt;-->
<!--&lt;!&ndash;            optionLabel="name"&ndash;&gt;-->
<!--&lt;!&ndash;            [placeholder]="'shared.modal.component.add_vehicle.form.placeholder.edition' | translate"&ndash;&gt;-->
<!--&lt;!&ndash;            [options]="editions"&ndash;&gt;-->
<!--&lt;!&ndash;            (onChange)="seekVehicle()"&ndash;&gt;-->
<!--&lt;!&ndash;            #edition&ndash;&gt;-->
<!--&lt;!&ndash;        >&ndash;&gt;-->
<!--&lt;!&ndash;        </p-dropdown>&ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="grid">-->
<!--    <div class="col-12 p-fluid">-->
<!--&lt;!&ndash;      <p *ngIf="vType.selectedOption">{{'shared.modal.component.add_vehicle.choice' | translate}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;      <p class="font-weight-bold">&ndash;&gt;-->
<!--&lt;!&ndash;        {{vType?.label}} {{make?.label}}&ndash;&gt;-->
<!--&lt;!&ndash;        {{bType?.label}} {{model?.label}}&ndash;&gt;-->
<!--&lt;!&ndash;        {{bGen?.label}} {{edition?.label}}&ndash;&gt;-->
<!--&lt;!&ndash;      </p>&ndash;&gt;-->
<!--    </div>-->
  </div>
</form>
