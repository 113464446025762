import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import {plainToClass, plainToClassFromExist} from "class-transformer";
import {MediaObjectApiModel} from "../models/media-object.api-model";
import {ResultListApiModel} from "../models/result-list.api.model";
import {QueryParamsApiModel} from "../models/query-params-api.model";
import {QueryParamsService} from "../../core/services/query-params.service";
import {MediaTypeEnum} from "../../core/enums/media-type.enum";

@Injectable({
  providedIn: 'root'
})
export class MediaObjectRestService {

  private readonly resource: string = '/media_objects';
  totalItems: number = 0;

  constructor(private http: HttpClient, private params: QueryParamsService) { }

  getMediaObject(id: string): Observable<MediaObjectApiModel> {
    return this.http.get<MediaObjectApiModel>(environment.apiUrl + id)
      .pipe(map((response: MediaObjectApiModel) => {
        return plainToClass(MediaObjectApiModel, response);
    }));
  }

  getMediaObjectCollection(queryParamsApiModel?: QueryParamsApiModel) {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<MediaObjectApiModel>>(environment.apiUrl + this.resource, {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<MediaObjectApiModel>(MediaObjectApiModel), result as object)));
  }

  deleteMediaObject(id: string) {
    return this.http.delete(environment.apiUrl + id)
  }

  upload(file: FormData): Observable<MediaObjectApiModel> {
    return this.http.post<MediaObjectApiModel>(environment.apiUrl + this.resource, file);
  }

  uploadImage(image: File, caption: string = null): Observable<MediaObjectApiModel> {
    const fd = MediaObjectRestService.prepareFormData(image, MediaTypeEnum.IMAGE, caption);
    return this.upload(fd).pipe(map((response: MediaObjectApiModel) => plainToClass(MediaObjectApiModel, response)));
  }

  uploadVideo(image: File, caption: string = null): Observable<MediaObjectApiModel> {
    const fd = MediaObjectRestService.prepareFormData(image, MediaTypeEnum.VIDEO, caption);
    return this.upload(fd).pipe(map((response: MediaObjectApiModel) => plainToClass(MediaObjectApiModel, response)));
  }

  private static prepareFormData(file: File, type: any, caption: string): FormData {
    const fd = new FormData();
    fd.append('file', file, file.name);
    fd.append('type', type);
    fd.append('caption', caption);
    return fd;
  }
}
