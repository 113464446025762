<p-confirmDialog
  #cd header="Confirmation"
  icon="pi pi-exclamation-triangle"
  appendTo="body"
  [key]="'project'"
  [autoZIndex]="true"
>
  <p-footer>
    <div class="flex justify-content-around">
      <button
        type="button"
        pButton
        icon="pi pi-times"
        class="p-button-danger p-button-outlined"

        [label]="'common.button.dismiss' | translate"
        (click)="cd.reject()">
      </button>
      <button
        type="button"
        class="p-button-outlined"
        pButton
        icon="pi pi-check"
        [label]="'common.button.proceed' | translate"
        (click)="cd.accept()">
      </button>
    </div>
  </p-footer>
</p-confirmDialog>
