import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {ReactionApiModel} from '../../api/models/reaction.api.model';
import * as ReactionActions from './reaction.actions';
import {ReactionActionTypes} from './reaction.actions';

const reactionAdapter = createEntityAdapter<ReactionApiModel>({
  selectId: selectReactionId,
  sortComparer: sortById,
});

export function selectReactionId(a: ReactionApiModel): string {
  return a['@id'];
}

export function sortById(a: ReactionApiModel, b: ReactionApiModel): number {
  return a.id - b.id;
}

export interface ReactionState extends EntityState<ReactionApiModel> { }

export const initialState: ReactionState = reactionAdapter.getInitialState();

export function reactionReducer(state: ReactionState = initialState, action: ReactionActions.Actions): ReactionState {
  switch (action.type) {
    case ReactionActionTypes.GET_ALL:
      return reactionAdapter.setAll(action.reactions, state);
    case ReactionActionTypes.GET_ONE:
      return reactionAdapter.setOne(action.reaction, state);
    case ReactionActionTypes.ADD_ONE:
      return reactionAdapter.addOne(action.reaction, state);
    case ReactionActionTypes.ADD_MANY:
      return reactionAdapter.addMany(action.reactions, state);
    case ReactionActionTypes.UPDATE_ONE:
      return reactionAdapter.updateOne({
        id: action.id,
        changes: action.changes
      }, state);
    case ReactionActionTypes.DELETE_ONE:
      return reactionAdapter.removeOne(action.id, state);
    default:
      return state;
  }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = reactionAdapter.getSelectors();
