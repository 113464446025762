import {Inject, Injectable, Injector, PLATFORM_ID} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import {ResponseNotificationService} from "./response-notification.service";
import {isPlatformBrowser} from "@angular/common";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {LoaderService} from "./loader.service";

@Injectable()
export class ResponseErrorsHandlerService {

  private translate: TranslateService;

  constructor(
    private rns: ResponseNotificationService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private injector: Injector,
    private ls: LoaderService
  ) {
  }

  handleFormErrors(form: UntypedFormGroup, response: HttpErrorResponse) {
    if (form && response.error.hasOwnProperty('violations')) {
      const formFields = Object.keys(form.controls);
      for (const violation of response.error.violations) {
        const paths = violation.propertyPath.split('.');
        if (formFields.indexOf(paths[0]) !== -1) {
          let control = form;
          for (let path of paths) {
            control = this.findControl(control, path);
          }
          control.setErrors({
            custom: violation.message
          });
        }
      }
    }
  }

  private findControl(object: any, path: string) {
    if (object.hasOwnProperty('controls')) {
      return object.controls[path];
    }
    return object;
  }

  //TODO: Add Translations
  public handleServerErrors(httpErrorResponse: HttpErrorResponse) {
    this.translate = this.injector.get(TranslateService);
    this.ls.hide();
    switch (httpErrorResponse.status) {
      case 0:
        this.rns.emitError(this.translate.instant('app.error.api_connection'));
        break;
      case 401:
        if (isPlatformBrowser(this.platformId)) {
          localStorage.removeItem('token');
          this.router.navigate(['/login']);
        }
        this.rns.emitError(this.translate.instant('app.error.api_connection'));
        break;
      case 403:
        this.rns.emitError(httpErrorResponse.error.hasOwnProperty('detail') ? httpErrorResponse.error.detail : this.translate.instant('app.error.api_forbidden'));
        break;
      case 404:
        this.router.navigate(['/404']);
        break;
      case 423:
        let msg = this.translate.instant('app.error.locked');
        if (httpErrorResponse.error.hasOwnProperty('hydra:description')
          && httpErrorResponse.error['hydra:description'] === 'user.registration.closed') {
          msg = this.translate.instant('app.error.registration_closed');
        }
        this.rns.emitError(msg);
        break;
      case 500:
        this.rns.emitError(this.translate.instant('app.error.api_internal'));
        break;
    }
  }
}
