import {Injectable} from '@angular/core';
import {Subject, Observable} from 'rxjs';
import {MenuItem} from 'primeng/api';

@Injectable()
export class AppBreadcrumbService {
  public searchInputVisible: boolean = false;
  public filtersVisible: boolean = false;
  public query: string = '';
  private itemsSource: Subject<MenuItem[]> = new Subject<MenuItem[]>();
  private searchQuery: Subject<string> = new Subject<string>();
  public searchSubscriber =  this.searchQuery.asObservable();
  private filtersClicked: Subject<void> = new Subject<void>();
  public filtersClickedSubscriber: Observable<void> = this.filtersClicked.asObservable();


  itemsHandler = this.itemsSource.asObservable();

  setItems(items: MenuItem[]) {
    this.itemsSource.next(items);
  }

  emitQuery(query: string) {
    this.searchQuery.next(query);
  }

  emitFiltersClicked(): void {
    this.filtersClicked.next();
  }
}
