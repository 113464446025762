import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {RatingApiModel} from "../models/rating.api-model";
import {ResultListApiModel} from "../models/result-list.api.model";
import { HttpClient } from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {plainToClass, plainToClassFromExist} from "class-transformer";

@Injectable()
export class RatingRestService {
  private readonly resource: string = '/ratings';

  constructor(
    private http: HttpClient
  ) {
  }

  public create(ratingAM: RatingApiModel): Observable<RatingApiModel> {
    return this.http.post<RatingApiModel>(environment.apiUrl + this.resource, ratingAM)
      .pipe(map((result: RatingApiModel) => plainToClass(RatingApiModel, result)))
  }

  public put(ratingAM: RatingApiModel): Observable<RatingApiModel> {
    return this.http.put<RatingApiModel>(environment.apiUrl + ratingAM['@id'], ratingAM)
      .pipe(map((result: RatingApiModel) => plainToClass(RatingApiModel, result)))
  }

  public get(id: string): Observable<RatingApiModel> {
    return this.http.get<RatingApiModel>(environment.apiUrl + this.resource + '/' + id)
      .pipe(map((result: RatingApiModel) => plainToClass(RatingApiModel, result)))
  }

  public getCollection(): Observable<ResultListApiModel<RatingApiModel>> {
    return this.http.get<ResultListApiModel<RatingApiModel>>(environment.apiUrl + this.resource)
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<RatingApiModel>(RatingApiModel), result as object)));
  }

  public getUserSubResourceCollection(userId: string): Observable<ResultListApiModel<RatingApiModel>> {
    return this.http.get<ResultListApiModel<RatingApiModel>>(environment.apiUrl+ userId + this.resource)
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<RatingApiModel>(RatingApiModel), result as object)));
  }
}
