import {createEntityAdapter, EntityState} from '@ngrx/entity';
import * as ParticipantActions from './participant.actions';
import {ParticipantActionTypes} from './participant.actions';
import {ParticipantApiModel} from '../../api/models/participant.api.model';

const participantAdapter = createEntityAdapter<ParticipantApiModel>({
  selectId: selectParticipantId,
  sortComparer: sortById,
});

export function selectParticipantId(a: ParticipantApiModel): string {
  return a['@id'];
}

export function sortById(a: ParticipantApiModel, b: ParticipantApiModel): number {
  return a.id - b.id;
}

export interface ParticipantState extends EntityState<ParticipantApiModel> { }

export const initialState: ParticipantState = participantAdapter.getInitialState();

export function participantReducer(state: ParticipantState = initialState, action: ParticipantActions.Actions): ParticipantState {
  switch (action.type) {
    case ParticipantActionTypes.GET_ALL:
      console.log(action.participants, state);
      return participantAdapter.setAll(action.participants, state);
    case ParticipantActionTypes.GET_ONE:
      return participantAdapter.setOne(action.participant, state);
    case ParticipantActionTypes.ADD_ONE:
      return participantAdapter.addOne(action.participant, state);
    case ParticipantActionTypes.ADD_MANY:
      return participantAdapter.addMany(action.participants, state);
    case ParticipantActionTypes.UPDATE_ONE:
      return participantAdapter.updateOne({
        id: action.id,
        changes: action.changes
      }, state);
    case ParticipantActionTypes.DELETE_ONE:
      console.log(action.id, state);
      return participantAdapter.removeOne(action.id, state);
    default:
      return state;
  }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = participantAdapter.getSelectors();
