import {UserViewModel} from '../../features/user/settings/user.view-model';
import {CurrentUserViewModel} from '../../core/models/current-user.view-model';
import {AbstractViewModel} from '../../core/interfaces/abstract-view-model';
import {PostApiModel} from '../../api/models/post-api.model';
import {UserApiModel} from '../../api/models/user.api-model';
import {instanceToInstance} from 'class-transformer';
import {ProjectViewModel} from '../../features/user/garage/project.view-model';
import {MediaObjectViewModel} from '../../core/models/media-object.view-model';
import {MediaObjectApiModel} from '../../api/models/media-object.api-model';
import {EventApiModel} from '../../api/models/event.api.model';
import {ReactionApiModel} from "../../api/models/reaction.api.model";

export class PostViewModel extends AbstractViewModel<PostApiModel> {


  public constructor(protected apiModel: PostApiModel = new PostApiModel()) {
    super(apiModel);
    this.isPlaceHolder = !apiModel.id;
    this.fromApiModel(apiModel);
  }
  postedBy: UserViewModel | CurrentUserViewModel;
  content: string;
  project: ProjectViewModel;
  event: EventApiModel;
  comments: string[];
  reactions: ReactionApiModel[];
  media: MediaObjectApiModel[];
  type: string;
  updatedAt?: Date;
  createdAt?: Date;
  isPlaceHolder = true;
  currentUserReaction: ReactionApiModel;

  public static create(content: string, type: string, media: MediaObjectApiModel[], projectVM: ProjectViewModel, eventAM: EventApiModel): PostViewModel {
    const postVM = new PostViewModel();
    postVM.content = content;
    postVM.media = media;
    postVM.type = type;
    if (projectVM) {
      postVM.project = projectVM;
    }
    if (eventAM) {
      postVM.event = eventAM;
    }
    return postVM;
  }

  public static update(content: string, media: MediaObjectApiModel[], postVM: PostViewModel): PostViewModel {
    postVM.content = content;
    postVM.media = media;
    console.log(postVM.media);
    return postVM;
  }

  fromApiModel(apiModel: PostApiModel): this {
    this['@id'] = apiModel['@id'];
    this.id = apiModel.id;
    this.postedBy = new UserViewModel(apiModel.postedBy as UserApiModel);
    this.content = apiModel.content;
    // this.project = apiModel.project;
    this.event = apiModel.event as EventApiModel;
    this.comments = apiModel.comments;
    this.reactions = apiModel.reactions as ReactionApiModel[];
    this.media = apiModel.media as MediaObjectApiModel[];
    this.type = apiModel.type;
    this.createdAt = apiModel.createdAt;
    this.updatedAt = apiModel.updatedAt;
    return this;
  }

  getApiModel(): PostApiModel {
    return this.apiModel;
  }

  toApiModel(): PostApiModel {
    this.apiModel.content = this.content;
    this.apiModel.type = this.type;
    if (this.project) {
      this.apiModel.project = this.project['@id'];
    }
    if (this.event) {
      this.apiModel.event = this.event['@id'];
    }
    this.apiModel.media = this.media.map(e => e['@id']);
    return this.apiModel;
  }
}
