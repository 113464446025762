import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DropdownComponent} from "./dropdown.component";
import {RouterModule} from "@angular/router";
import {DisableLinkDirective} from "../../core/directives/disable-link.directive";

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [DropdownComponent, DisableLinkDirective],
  exports: [DropdownComponent, DisableLinkDirective]
})
export class DropdownModule { }
