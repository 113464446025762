import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {LanguageService} from "../services/language.service";

@Injectable()
export class LanguageHttpInterceptor implements HttpInterceptor {

  constructor(
    private languageService: LanguageService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try {
      return next.handle(this.addLanguage(request));
    } catch (error) {
      return next.handle(request);
    }
  }

  private addLanguage(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        'Accept-Language': this.languageService.getLanguage()
      }
    });
  }
}
