type paramType = 'single'|'array'|'array_with_key';
export class FilterParameterApiModel {
  public name: string;
  public value: any;
  public type: paramType;
  public key: paramType;

  constructor(name: string, value: any, type: paramType = 'array', key: any = null) {
    this.name = name;
    this.value = value;
    this.type = type;
    this.key = key;
  }

}
