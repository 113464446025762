  <app-speed-dial
    [model]="socialMenuItems"
    buttonClassName="p-button p-button-outlined p-button-icon-only p-button-rounded p-button-sm"
    direction="left"
    showIcon="pi pi-chevron-left"
    hideIcon="pi pi-chevron-right"
    [(visible)]="visibleList"
    [disableOnClickHide]="true"
  >
  </app-speed-dial>

  <!--          <button-->
  <!--            pButton-->
  <!--            pRipple-->
  <!--            class="p-button p-button-outlined p-button-icon-only p-button-rounded p-button-sm"-->
  <!--            icon="fas fa-warehouse"-->
  <!--            (click)="navigateTo(following, 'garage')"-->
  <!--          >-->

  <!--          </button>-->
  <!--                    <app-end-cap-->
  <!--            *ngxPermissionsExcept="[following.follower | ownerRole]"-->
  <!--            [icon]="socialIcon"-->
  <!--            [pointerClass]="{-->
  <!--                      'p-button-sm p-button-rounded p-button-outlined p-button-primary m-0': true,-->
  <!--                      'p-highlight': isFollowedByCurrentUser-->
  <!--                      }"-->
  <!--            [secondButtonPointerClass]="{-->
  <!--                      'p-highlight': isFollowedByCurrentUser-->
  <!--                    }"-->
  <!--            [label]="socialLabel | translate"-->
  <!--            [menuItems]="socialMenuItems"-->
  <!--            [showLoader]="false"-->
  <!--          >-->
  <!--          </app-end-cap>-->
  <!--          <button-->
  <!--            class="p-button p-button-outlined p-button-icon-only p-button-rounded p-button-sm"-->
  <!--            *ngIf="following.follower['@id'] !== currentUser['@id']"-->
  <!--            style="margin-left: auto"-->
  <!--          >-->
  <!--            <span *ngSwitchDefault (click)="postFollowing(following)" class="material-icons">favorite_border</span>-->
  <!--            <span *ngSwitchCase="'pending'" class="pi pi-spin pi-spinner"></span>-->
  <!--            <span *ngSwitchCase="'filled'" (click)="removeFollowing(following)" class="material-icons">favorite</span>-->
  <!--          </button>-->
