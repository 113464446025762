import {NgModule} from '@angular/core';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {JoyrideModule} from 'ngx-joyride';
import {NgxLongPress2Module} from 'ngx-long-press2';
import {CustomCameraPage} from './custom-camera.page';
import {CustomCameraService} from './custom-camera.service';
import {PrePublishModeComponent} from './pre-publish-mode/pre-publish-mode.component';
import {CommonModule} from '@angular/common';
import {EndCapModule} from '../../shared/end-cap/end-cap.module';
import {PushPipe} from "@ngrx/component";
import {provideClientHydration} from "@angular/platform-browser";

@NgModule({
    imports: [
        NgxLongPress2Module,
        NgCircleProgressModule.forRoot({}),
        JoyrideModule.forChild(),
        CommonModule,
        EndCapModule,
        PushPipe,
    ],
    providers: [
      provideClientHydration(),
      CustomCameraService
    ],
    declarations: [CustomCameraPage, PrePublishModeComponent],
    exports: [
        CustomCameraPage
    ]
})
export class CustomCameraPageModule {
}
