import {Component, OnInit, Input, ViewChild, forwardRef, HostListener} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NG_VALIDATORS, Validator, AbstractControl, ValidationErrors, ControlContainer, FormGroupDirective } from '@angular/forms';
import { ObservableInput, Subject } from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import { Chips } from 'primeng/chips';
import { AutoComplete } from 'primeng/autocomplete';
import { HashtagApiModel } from '../../api/models/hashtag.api.model';
import {instanceToInstance, plainToClass} from 'class-transformer';
import { ResponseNotificationService } from '../../core/services/response-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ResultListApiModel } from '../../api/models/result-list.api.model';
import { HashtagsModeEnum } from './hashtags-mode.enum';
import {HashtagsService} from './hashtags.service';
import {FilterParameterApiModel} from '../../api/models/filter-parameter.api.model';
import {QueryParamsApiModel} from '../../api/models/query-params-api.model';

@Component({
  selector: 'app-hashtags',
  templateUrl: './hashtags.component.html',
  styleUrls: ['./hashtags.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HashtagsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => HashtagsComponent),
      multi: true
    },
    HashtagsService
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class HashtagsComponent implements OnInit, ControlValueAccessor, Validator {

  constructor(
    private readonly rns: ResponseNotificationService,
    private readonly translate: TranslateService,
    private readonly hashtagDPS: HashtagsService
  ) { }

  get hashtags(): HashtagApiModel[] {
    return this._hashtags;
  }

  set hashtags(value: HashtagApiModel[]) {
    this._hashtags = value || [];
  }

  @Input() public mode: HashtagsModeEnum = HashtagsModeEnum.NORMAL;
  @Input() public disabled: boolean = false;
  @Input() public forceSelection: boolean = false;
  @Input() public completeOnFocus: boolean = false;
  @Input() public placeholder: string = '';
  @ViewChild(Chips) public tagInput: Chips;
  @ViewChild(AutoComplete, { static: false }) public autocomplete: AutoComplete;

  public searchValue = '#';
  public keydownDetectEnabled: boolean;
  public readonly HashtagsModeEnum = HashtagsModeEnum;
  private tagsSubject$: Subject<string> = new Subject();
  private _hashtags: HashtagApiModel[] = [];
  public selectedHashtags: string[] = [];
  private queryParams: QueryParamsApiModel = new QueryParamsApiModel([], 1, 'ASC', 50);
  private searchSubject$: Subject<QueryParamsApiModel> = new Subject();
  private control: AbstractControl;
  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  ngOnInit(): void {
    this.subscribeHashTagFieldChange();
    this.subscribeToAddHashTagEvent();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (this.keydownDetectEnabled) {
      let currentKey = event.key;
      let val = this.searchValue;
      const element = document.getElementById('hashtagInput') as HTMLInputElement;
      if (element) {
        val = element.value.replace(' ', '').toLowerCase();
      }

      if (!currentKey || currentKey === 'Unidentified') {
        const currentCode = this.detectCharCode(element.value.toLowerCase()) || Number(event.code);
        currentKey = String.fromCharCode(currentCode);
      }
      switch (true) {
        case currentKey === 'Backspace' && this.searchValue.length > 1:
          this.autocomplete.loading = true;
          this.autocomplete.completeMethod.emit({originalEvent: event, query: val});
          break;
        case currentKey === ' ':
          this.onAddHashtagByInput({value: val});
          this.searchValue = '#';
          break;
        case currentKey === 'Enter':
          if (this.searchValue.length < 3) {
            this.rns.emitError(this.translate.instant('feature.user.project.hashtags.too_short'));
            return;
          }
          this.onAddHashtagByInput({value: val});
          this.searchValue = '#';
          break;
        case currentKey.length > 1:
          break;
        default:
          this.searchValue = val;
          this.autocomplete.loading = true;
          if (this.mode === HashtagsModeEnum.COMPACT) {
            this.autocomplete.completeMethod.emit({originalEvent: event, query: val});
          }
      }
    }
  }

  subscribeToAddHashTagEvent(): void {
    this.tagsSubject$.pipe(
      debounceTime(0),
      distinctUntilChanged(),
      tap((val: string) => {
        if (this.mode === HashtagsModeEnum.COMPACT) {
          this.tagInput.inputViewChild.nativeElement.value = '';
          this.tagInput.value.push(val);
          // this.tagInput.onAdd.emit({ value: val });
          this.tagInput.updateFilledState();
        } else {
          this.selectedHashtags.push(val);
          this.resetAutocompleteValue();
        }

        this.updateValue();
      })).subscribe();
  }

  private detectCharCode(value: string): number {
    return value.charCodeAt(value.length - 1);
  }

  public onAddHashtagByInput(e: { value: string }): void {
    let val = e.value.replace(' ', '').toLowerCase();
    val = val.includes('#') ? val : '#' + val;
    this.searchValue = '#';
    this.autocomplete.overlayVisible = false;
    if (val) {
      if (this.isWithoutDuplicates(val)) {
        this.hashtags.push(plainToClass(HashtagApiModel, { '@id': null, name: val }));
        this.tagsSubject$.next(val);
        if (this.tagInput) {
          this.tagInput.value.pop();
          this.tagInput.updateFilledState();
        }
        this.updateValue();
      } else {
        this.rns.emitError(this.translate.instant('feature.user.project.hashtags.already_assigned'));
      }
      this.informAboutMaximumAmount();
      this.resetAutocompleteValue();
    }
  }

  public onRemoveHashtagByInput(e: { value: string }): void {
    this.searchValue = '#';
    this.hashtags = this.hashtags.filter((x: HashtagApiModel) => x.name !== e.value);
    this.selectedHashtags = this.selectedHashtags.filter(tag => tag !== e.value);
    this.updateValue();
  }

  public onHashtagSelect(e: HashtagApiModel): void {
    if (this.tagInput) {
      this.tagInput.inputViewChild.nativeElement.value = '';
    }
    const val = e.name.includes('#') ? e.name : '#' + e.name;

    if (this.isWithoutDuplicates(val)) {
      this.hashtags.push(e);
      this.tagsSubject$.next(val);
      this.updateValue();
    }
    this.informAboutMaximumAmount();
  }

  private resetAutocompleteValue(): void {
    this.autocomplete.value = null;
    this.autocomplete.overlayVisible = false;
    // this.autocomplete.updateInputField();
  }

  public searchHashtags({query}: {query: string}): void {
    this.searchValue = query.includes('#') ? query : '#' + query;
    this.appendQueryFilters(query);
  }

  public appendQueryFilters(query: string): void {
    this.queryParams.filters = [];
    if (query) {
      const filter = new FilterParameterApiModel('name', query, 'single');
      this.queryParams.filters.push(filter);
    }
    this.searchSubject$.next(instanceToInstance(this.queryParams));
  }

  private informAboutMaximumAmount(): void {
    if (this.selectedHashtags.length === 10) {
      this.rns.emitWarning(this.translate.instant('feature.user.project.hashtags.length_above_max'));
    }
  }

  private isWithoutDuplicates(hashTagVal: string): boolean {
    return !this.selectedHashtags.includes(hashTagVal);
  }

  private subscribeHashTagFieldChange(): void {
    this.hashtagDPS.getHashTagCollectionBySubjectValue(this.searchSubject$)
      .subscribe((hashTagRLAM: ResultListApiModel<HashtagApiModel>) => {
        this.hashtags = hashTagRLAM.records;
        if (this.autocomplete) {
          this.autocomplete.handleSuggestionsChange();
          this.autocomplete.overlayVisible = true;
        }
      });
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.selectedHashtags = value || [];
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.control = control;
    return null;
  }

  private updateValue(): void {
    this.onChange(this.selectedHashtags);
    this.onTouched();
  }

  public isPristine(): boolean {
    return this.control ? this.control.pristine : false;
  }

  public isDirty(): boolean {
    return this.control ? this.control.dirty : false;
  }

  public isValid(): boolean {
    return this.control ? this.control.valid : false;
  }

  public isInvalid(): boolean {
    return this.control ? this.control.invalid : false;
  }
}
