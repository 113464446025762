import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ResultListApiModel} from '../models/result-list.api.model';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {plainToClassFromExist} from 'class-transformer';
import {QueryParamsApiModel} from '../models/query-params-api.model';
import {HashtagApiModel} from '../models/hashtag.api.model';
import {QueryParamsService} from '../../core/services/query-params.service';

@Injectable()
export class HashtagRestService {
  private readonly resource: string = '/hashtags';

  constructor(
    private http: HttpClient,
    private params: QueryParamsService
  ) {
  }

  public getCollection(queryParamsAM: QueryParamsApiModel = new QueryParamsApiModel()): Observable<ResultListApiModel<HashtagApiModel>> {
    const params = this.params.applyParameters(queryParamsAM);
    return this.http.get<ResultListApiModel<HashtagApiModel>>(environment.apiUrl + this.resource, {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<HashtagApiModel>(HashtagApiModel), result as object)));
  }
}
