<form [formGroup]="form" novalidate>
  <div class="p=grid">
    <div class="col-12 p-fluid">
      <div class="p-field p-float-label p-input-icon-left">
        <i class="pi pi-key"></i>
        <input pInputText type="password" id="current-password" class="p-input-filled" formControlName="currentPassword" autocomplete="off">
        <label for="current-password">
          {{'shared.modal.component.current_password.form.field.current_password' | translate}}
        </label>
      </div>
      <div class="app-form-error" *ngIf="form.get('currentPassword').invalid && form.get('currentPassword').touched">
        <div>{{form.get('currentPassword')['errorMessage']}}</div>
        <div *ngIf="form.get('currentPassword').errors?.custom">
          {{form.get('currentPassword').errors?.custom}}
        </div>
      </div>
    </div>
  </div>
</form>
<app-spinner [name]="'new-password'" *ngIf="spinnerState"></app-spinner>
