import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {EventApiModel} from '../models/event.api.model';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {plainToClass, plainToClassFromExist} from 'class-transformer';
import {ResultListApiModel} from '../models/result-list.api.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {QueryParamsApiModel} from '../models/query-params-api.model';
import {QueryParamsService} from '../../core/services/query-params.service';
import {FollowApiModel} from "../models/follow.api-model";
import {ReactionApiModel} from "../models/reaction.api.model";

@Injectable({
  providedIn: 'root'
})
export class EventRestService {
  private readonly resource: string = '/events';

  constructor(
    private http: HttpClient,
    public params: QueryParamsService
  ) {
  }

  public create(eventAM: EventApiModel): Observable<EventApiModel> {
    return this.http.post<EventApiModel>(environment.apiUrl + this.resource, eventAM)
      .pipe(map((result: EventApiModel) => plainToClass(EventApiModel, result)));
  }

  public put(eventAM: EventApiModel): Observable<EventApiModel> {
    return this.http.put<EventApiModel>(environment.apiUrl + eventAM['@id'], eventAM)
      .pipe(map((result: EventApiModel) => plainToClass(EventApiModel, result)));
  }

  public get(slug: string): Observable<EventApiModel> {
    return this.http.get<EventApiModel>(environment.apiUrl + this.resource + '/' + slug)
      .pipe(map((result: EventApiModel) => plainToClass(EventApiModel, result)));
  }

  public delete(eventAM: EventApiModel): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(environment.apiUrl + eventAM['@id']);
  }

  public getCollection(queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<ResultListApiModel<EventApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<EventApiModel>>(environment.apiUrl + this.resource, {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<EventApiModel>(EventApiModel), result as object)));
  }

  public getUserSubResourceCollection(userId: string): Observable<ResultListApiModel<EventApiModel>> {
    return this.http.get<ResultListApiModel<EventApiModel>>(environment.apiUrl + userId + this.resource)
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<EventApiModel>(EventApiModel), result as object)));
  }

  public getFollowsSubResourceCollection(eventAM: EventApiModel, queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<ResultListApiModel<FollowApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<FollowApiModel>>(environment.apiUrl + eventAM['@id'] + '/follows', {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<FollowApiModel>(FollowApiModel), result as object)));
  }

  public getReactionsSubResourceCollection(eventAM: EventApiModel, queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<ResultListApiModel<ReactionApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<ReactionApiModel>>(environment.apiUrl + eventAM['@id'] + '/reactions', {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<ReactionApiModel>(ReactionApiModel), result as object)));
  }
}
