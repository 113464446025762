import {Pipe, PipeTransform} from '@angular/core';
import {TokenDataProviderService} from '../services/token-data-provider.service';
import {ReactedWithToEmojiMapper} from '../helpers/reacted-with-to-emoji.mapper';


@Pipe({
  name: 'reactionClassMap',
})
export class ReactionStateClassMapPipe implements PipeTransform {

  constructor() {
  }

  transform(value: string): {id: string, skin: number} {
    return ReactedWithToEmojiMapper.map(value);
  }
}
