import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {ReactiveFormsModule} from '@angular/forms';
import {AuthRoutingModule} from './auth-routing.module';
import {provideHttpClient, withFetch, withInterceptorsFromDi} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegistrationRestService} from "../../api/services/registration.rest.service";
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {ResetConfirmationComponent} from './reset-confirmation/reset-confirmation.component';
import {RegistrationConfirmationComponent} from './registration-confirmation/registration-confirmation.component';
import {
  CompleteOauth2RegistrationComponent
} from './complete-oauth2-registration/complete-oauth2-registration.component';
import {InputTextModule} from "primeng/inputtext";
import {CheckboxModule} from "primeng/checkbox";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {AuthComponent} from './auth.component';
import {BackgroundResolverService} from "../../core/services/background.resolver.service";
import {SharedModule} from "../../shared/shared.module";
import {SpinnerModule} from "../../shared/spinner/spinner.module";
import {provideClientHydration} from "@angular/platform-browser";

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    ResetPasswordComponent,
    ResetConfirmationComponent,
    RegistrationConfirmationComponent,
    CompleteOauth2RegistrationComponent,
    AuthComponent,
  ], imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    InputTextModule,
    CheckboxModule,
    ButtonModule,
    RippleModule,
    SharedModule,
    SpinnerModule
  ],
  providers: [
    provideClientHydration(),
    provideHttpClient(
      withInterceptorsFromDi(),
      withFetch()
    ),
    RegistrationRestService,
    BackgroundResolverService
  ]
})
export class AuthModule {
}
