export class ArrayHelper {
  /** https://stackoverflow.com/questions/14446511/most-efficient-method-to-groupby-on-an-array-of-objects */
  public static groupBy<T>(array: T[], predicate: (value: T, index: number, array: T[]) => string) {
    return array.reduce((acc, value, index, array) => {
      (acc[predicate(value, index, array)] ||= []).push(value);
      return acc;
    }, {} as { [key: string]: T[] });
  }

  public static flattenArray(arr: string[][]): string[] {
    return arr.reduce((acc, val) => acc.concat(val), []);
  }
}
