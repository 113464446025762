import {AbstractViewModel} from '../../../core/interfaces/abstract-view-model';
import {UserApiModel} from '../../../api/models/user.api-model';
import {MediaObjectViewModel} from '../../../core/models/media-object.view-model';
import {PersonalInformationViewModel} from './personal-information/personal-information-view-model';
import {MediaObjectApiModel} from '../../../api/models/media-object.api-model';
import {FollowApiModel} from '../../../api/models/follow.api-model';
import {ReactionApiModel} from '../../../api/models/reaction.api.model';

export class UserViewModel extends AbstractViewModel<UserApiModel> {
  public username: string;
  public email: string;
  public currentPassword: string;
  public slug: string;
  public info: string | PersonalInformationViewModel;
  public avatar: MediaObjectViewModel;
  public background: MediaObjectViewModel;
  public projects: string[];
  public follows: FollowApiModel[];
  public receivedFollows: FollowApiModel[];
  public reactions: ReactionApiModel[];
  public receivedReactions: ReactionApiModel[];
  public individualProjects: number;
  public collaborationProjects: number;
  public saleProjects: number;
  public soldProjects: number;
  public createdAt: Date;
  public lastSeenAt: Date;

  public constructor(protected apiModel: UserApiModel = new UserApiModel()) {
    super(apiModel);
    this.fromApiModel(apiModel);
  }

  public fromApiModel(apiModel: UserApiModel): this {
    this['@id'] = apiModel['@id'];
    this.username = apiModel.username;
    this.email = apiModel.email;
    this.avatar = apiModel.avatar && apiModel instanceof MediaObjectApiModel ? new MediaObjectViewModel(apiModel.avatar as MediaObjectApiModel) : null;
    this.info = apiModel.info as string;
    this.slug = apiModel.slug;
    this.projects = apiModel.projects;
    this.individualProjects = apiModel.individualProjects;
    this.collaborationProjects = apiModel.collaborationProjects;
    this.saleProjects = apiModel.saleProjects;
    this.soldProjects = apiModel.soldProjects;
    this.createdAt = apiModel.createdAt;
    this.lastSeenAt = apiModel.updatedAt;
    return this;
  }

  public toApiModel(): UserApiModel {
    this.apiModel.username = this.username;
    this.apiModel.email = this.email;
    this.apiModel.currentPassword = this.currentPassword;
    return this.apiModel;
  }

  public getApiModel(): UserApiModel {
    return this.apiModel;
  }

}
