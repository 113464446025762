import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {StorageService} from "../services/storage.service";
import {TokenDataProviderService} from "../services/token-data-provider.service";
import {catchError, filter, switchMap, take} from "rxjs/operators";
import {ResponseErrorsHandlerService} from "../services/response-errors-handler.service";
import {JwtHelperService} from "@auth0/angular-jwt";

@Injectable()
export class JwtHttpInterceptor implements HttpInterceptor {

  private jwt: JwtHelperService;
  private storage: StorageService;
  private ts: TokenDataProviderService;
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private injector: Injector,
    private reh: ResponseErrorsHandlerService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.jwt = this.injector.get(JwtHelperService);
    this.storage = this.injector.get(StorageService);
    this.ts = this.injector.get(TokenDataProviderService);
    let token = this.storage.getItem('token');
    if (this.jwt.isTokenExpired(token) && !this.ts.hasRemember()) {
      return next.handle(request);
    }

    return next.handle(this.addToken(request, token)).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401 && this.ts.hasRemember()) {
        return this.handle401Error(request, next);
      } else {
        this.reh.handleServerErrors(error);
        return throwError(error);
      }
    }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.ts.refreshJwtToken().pipe(
        switchMap((successResponse: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(successResponse.token);
          return next.handle(this.addToken(request, successResponse.token));
        }));
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }
}
