import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

export class OwnerGuard {

  public static redirectToFunc(rejectedPermissionName: string, activateRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): object {
    return {
      navigationCommands: ['403'],
      navigationExtras: {
        skipLocationChange: false
      }
    };
  }

  public static ownerPermissions(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string[] {
    const id = route.params['id'] || route.parent.params['id'];
    return [`ROLE_OWNER_${OwnerGuard.swapCase(id)}`];
  }

  public static swapCase(str: string) {
    return str.replace(/([a-z]+)|([A-Z]+)/g, function (match, chr) {
      return chr ? match.toUpperCase() : match.toLowerCase();
    });
  }
}
