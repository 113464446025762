import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {isPlatformBrowser} from "@angular/common";

@Injectable()
export class StorageService {

  private storageSub = new Subject<any>();
  public constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  watchStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }

  setItem(key: string, data: any) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(key, data);
      const obj = {};
      obj[key] = data;
      this.storageSub.next(obj);
    }
  }

  removeItem(key) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(key);
      this.storageSub.next('changed');
    }
  }

  getItem(key: string): string {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(key);
    }
  }
}
