<div class="app-grid-fluid garage">
  <div class="grid grid-nogutter justify-content-center">
    <div class="col-12 sm:col-10 xl:col-5" [style]="{maxWidth: '900px'}">
      <app-timeline
        [writingPostEnabled]="false"
        [postTypes]="postTypes"
        [noResultsMsg]="'common.no_posts'|translate"
      ></app-timeline>
    </div>
  </div>
</div>
