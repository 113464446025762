import {ModalState} from './modal-state.enum';

export class ModalStateEvent {
  status: ModalState;
  data?: any;

  constructor(status: ModalState, data?: any) {
    this.status = status;
    this.data = data;
  }

}
