import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, ObservableInput, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private geocodeUrl = 'https://nominatim.openstreetmap.org/search';
  private reverseGeocodeUrl = 'https://nominatim.openstreetmap.org/reverse';
  public querySubject$: Subject<string> = new Subject();
  public queryObservable: Observable<any>;


  constructor(private http: HttpClient) {
    this.queryObservable = this.subscribeToQuerySubject();
  }

  searchPlaces(query: string): void{
    this.querySubject$.next(query);
  }

  reverseGeocode(lat: number, lng: number): Observable<any> {
    return this.http.get(`${this.reverseGeocodeUrl}?lat=${lat}&lon=${lng}&format=json`);
  }
  public subscribeToQuerySubject(): Observable<any> {
    return this.querySubject$.pipe(
      distinctUntilChanged(),
      switchMap((query: string): Observable<any> => {
        return this.http.get(`${this.geocodeUrl}?q=${encodeURIComponent(query)}&addressdetails=1&format=json`);
      }));
  }

  getCoordinates(location: string, language: string): Observable<any> {
    const params = {
      q: location,
      format: 'json',
      limit: '1',
      'accept-language': language
    };
    return this.http.get<any[]>(this.geocodeUrl, { params })
      .pipe(map(results => results[0]));
  }
}
