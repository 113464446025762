import {AbstractControl} from '@angular/forms';
export class PasswordValidator {

  constructor() {}

  static matchPassword(AC: AbstractControl) {
    const password = AC.get('first').value; // to get value in input tag
    const confirmPassword = AC.get('second').value; // to get value in input tag
    if ( password !== confirmPassword) {
        const second = AC.get('second');
        const errors = second.errors || {};

        errors.matchPassword = true;
        second.setErrors(errors);
    } else {
        return null;
    }
  }
}
