import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PostApiModel} from '../../api/models/post-api.model';
import {PostTypeEnum} from '../../core/enums/post-type.enum';
import {TimelineComponent} from '../../shared/timeline/timeline.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {

  public timeLineItems: PostApiModel[] = [];
  public postTypes: PostTypeEnum[] = [PostTypeEnum.SIMPLE_POST, PostTypeEnum.PROJECT_POST, PostTypeEnum.EVENT_DISCUSSION_POST];
  @ViewChild(TimelineComponent) timeline: TimelineComponent;
  constructor(
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.elementRef.nativeElement.remove();
  }
}
