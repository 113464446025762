import {Exclude, Expose, Type} from 'class-transformer';

@Exclude()
export class ResultListApiModel<T> {
  @Expose({ name: 'hydra:member' })
  @Type(options => (options.newObject as ResultListApiModel<T>).type)
  public records: T[] = [];

  @Expose({ name: 'hydra:totalItems' })
  public total: number;

  private type: Function;

  constructor(type: Function) {
    this.type = type;
  }

}
