import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import {TokenDataProviderService} from "./token-data-provider.service";
import {Oauth2UserApiModel} from "../../api/models/oauth2-user.api.model";

@Injectable({
  providedIn: "root"
})
export class Oauth2UserResolverService  {
  constructor(private ts: TokenDataProviderService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Oauth2UserApiModel {
    return this.ts.oAuth2User;
  }
}
