import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {TimelineDataProviderService} from './timeline.data-provider.service';
import {PostViewModel} from './post.view-model';
import {ConfirmationService, MenuItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {UntypedFormGroup} from '@angular/forms';
import {RxFormBuilder, RxwebValidators} from '@rxweb/reactive-form-validators';
import {LoaderService} from '../../core/services/loader.service';
import {ProjectViewModel} from '../../features/user/garage/project.view-model';
import {CommentApiModel} from '../../api/models/comment.api.model';
import {ContextMenuObjectInterface} from './context-menu-object.interface';
import {MediaObjectInterface} from '../../core/interfaces/media-object.interface';
import {TimelineTileComponent} from './timeline-tile/timeline-tile.component';
import {PostApiModel} from '../../api/models/post-api.model';
import {EventSourceService} from '../../core/services/event-source.service';
import {debounceTime, delay, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {TokenDataProviderService} from '../../core/services/token-data-provider.service';
import {CurrentUserViewModel} from '../../core/models/current-user.view-model';
import {EventSourceInterface} from '../../core/interfaces/event-source.interface';
import {SiteLayoutComponent} from '../../core/layout/site/site-layout.component';
import {InputModeTypes} from './timeline-action-panel/timeline-action-panel.component';
import {PostTypeEnum} from '../../core/enums/post-type.enum';
import {SocialInputComponent} from '../social/social-input/social-input.component';
import {SocialInputSubmitInterface} from '../social/social-input/social-input-submit.interface';
import {ITEM_TYPE, OnRequestAppend} from '@egjs/infinitegrid';
import {EventApiModel} from '../../api/models/event.api.model';
import {VideoService} from './video.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MediaTypeEnum} from '../../core/enums/media-type.enum';
import {NgxInfiniteGridComponent} from '@egjs/ngx-infinitegrid';
import {ActionsService} from '../footer/actions.service';
import {ViewTypeEnum} from '../../core/enums/view-type.enum';
import scrollIntoView from 'scroll-into-view-if-needed';
import {ObservableInput, Subject} from "rxjs";


@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],

})
export class TimelineComponent implements OnInit, OnDestroy, AfterViewInit {
  get permissions(): string[] {
    return this._permissions;
  }

  @Input() set permissions(value: string[]) {
    this._permissions = value;
  }

  get postTypes(): string[] {
    return this._postTypes;
  }

  @Input() set postTypes(value: string[]) {
    delete this.timelineDPS.totalPostItems;
    this._postTypes = value;
  }

  get viewType(): ViewTypeEnum {
    return this._viewType;
  }

  @Input() set viewType(value: ViewTypeEnum) {
    this._viewType = value;
    this.isRendered = false;
  }

  get items(): MenuItem[] {
    return this._items;
  }

  set items(value: MenuItem[]) {
    this._items = value;
  }

  constructor(
    private readonly timelineDPS: TimelineDataProviderService,
    private readonly translate: TranslateService,
    private readonly confirmationService: ConfirmationService,
    private readonly fb: RxFormBuilder,
    private readonly ls: LoaderService,
    private readonly eventSource: EventSourceService,
    private readonly cdr: ChangeDetectorRef,
    private readonly tokenDPS: TokenDataProviderService,
    private readonly layoutComp: SiteLayoutComponent,
    private readonly videoService: VideoService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly actionsService: ActionsService
  ) {
  }

  @Input() writingPostEnabled = true;
  private _permissions: string[] = [];
  private _postTypes: string[] = [];
  private _viewType: ViewTypeEnum = ViewTypeEnum.LIST;
  @Input() projectVM: ProjectViewModel;
  @Input() postAsPostType: PostTypeEnum;
  @Input() eventAM: EventApiModel;
  @Input() startIndex: number;
  @Input() noResultsMsg: string;
  @Output() viewTypeChange: EventEmitter<ViewTypeEnum> = new EventEmitter<ViewTypeEnum>();
  @ViewChild('addPostSocialInput') addPostSocialInput: SocialInputComponent;
  @ViewChild('editPostSocialInput') editPostSocialInput: SocialInputComponent;
  @ViewChild('ig', {static: false}) ig: NgxInfiniteGridComponent;
  @ViewChildren(TimelineTileComponent) private timelineTileComponents: QueryList<TimelineTileComponent>;

  public page = 2;
  private endOfResults = false;
  private totalItems = 9;
  public isSidebarOpened = false;
  public sidebarAction: InputModeTypes;
  public form: UntypedFormGroup;
  public placeholders: PostViewModel[] = Array.from({length: 9}, (value, key) => new PostViewModel());
  public posts: PostViewModel[] = this.placeholders;
  public contextMenuObject: ContextMenuObjectInterface<PostViewModel | CommentApiModel> = null;
  private _items: MenuItem[];
  public currentUser: CurrentUserViewModel;
  public spinnerState = false;
  public submitDisabled = false;
  private activeVideoIndex: number = null;
  public isPlaying = false;
  public isRendered = false;
  public status: any;

  public renderItems: { groupKey: number, key: number, post: PostViewModel }[] = [];

  public readonly ITEM_TYPE = ITEM_TYPE;

  public readonly MediaTypeEnum = MediaTypeEnum;

  public readonly ViewTypeEnum = ViewTypeEnum;
  private scrollSubject$: Subject<string> = new Subject();


  @HostListener('window:load', ['$event'])
  @HostListener('window:scroll', ['$event'])
  videoScroll() {
    this.videoService.videoScroll();
  }


  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.isRendered = false;
  }

  ngOnInit(): void {
    this.createForm();
    this.endOfResults = false;
    const {view} = this.route.snapshot.queryParams;
    if (view) {
      this.viewType = view;
      this.viewTypeChange.emit(this.viewType);
    }

    this.tokenDPS.currentUser.subscribe((u: CurrentUserViewModel) => this.currentUser = u);
    this.timelineDPS.getPostCollection(this.projectVM, this.eventAM, 1, this._postTypes)
      .pipe(tap(() => this.totalItems = this.timelineDPS.totalPostItems))
      .subscribe((postVMs: PostViewModel[]) => {
        if (!postVMs.length) {
          this.endOfResults = true;
        }

        this.posts = [...postVMs];
        this.ig.renderItems();
        this.ig.updateItems();
      });

    // window.addEventListener('beforeunload', (event) => {
    //   console.log('unload');
    //   this.getStatus();
    // });
    // if (this.timelineDPS.items) {
    //   this.posts = this.timelineDPS.items;
    //   this.page = this.timelineDPS.page;
    //   this.endOfResults = this.timelineDPS.endOfResults;
    // }
    // this.dataSource = new Datasource({
    //   get: (index, count) => {
    //     if (this.timelineDPS.totalPostItems && index >= this.timelineDPS.totalPostItems) {
    //       console.log('end!');
    //       return of([]); // empty result
    //     }
    //     console.log(index);
    //     const startPage = Math.floor(index / this.pageSize);
    //     console.log(index);
    //     return this.timelineDPS.getPostCollection(this.projectVM, startPage + 1, this.postTypes).pipe(map((postVMs: PostViewModel[]) => {
    //       this.ls.hide();
    //       console.log('length: ' + postVMs.length);
    //       if (postVMs.length === 0) {
    //         console.log('end!');
    //
    //         return [];
    //       }
    //       this.posts = [...this.posts, ...postVMs];
    //
    //       return postVMs;
    //     }));
    //   },
    //   settings: {
    //     minIndex: 0,
    //     startIndex: 0,
    //     bufferSize: 1,
    //     itemSize: 100,
    //     infinite: true,
    //     padding: 0.01,
    //     windowViewport: true,
    //     sizeStrategy: SizeStrategy.Average
    //   },
    //   devSettings: {
    //     debug: false,
    //     immediateLog: false
    //   }
    // });
    this.registerPostEventSource();
    this.registerCommentEventSource();
    this.subscribeToRenderComplete();
  }

  private initPostSlideMenu(): void {
    this.items = [
      {
        label: this.translate.instant('common.update') + ' ' + this.translate.instant('common.post'),
        icon: 'pi pi-fw pi-pencil',
        command: (e) => {
          this.sidebarAction = 'editPost';
        }
      },
      {
        label: this.translate.instant('common.remove') + ' ' + this.translate.instant('common.post'),
        icon: 'pi pi-fw pi-trash text-danger',
        command: () => {
          this.showRemovePostConfirmModal();
        }
      },
    ];
  }

  private initCommentSlideMenu(index: number): void {
    this.items = [
      {
        label: this.translate.instant('common.update') + ' ' + this.translate.instant('common.comment'),
        icon: 'pi pi-fw pi-pencil',
        command: (e) => {
          this.sidebarAction = 'editComment';
        }
      },
      {
        label: this.translate.instant('common.remove') + ' ' + this.translate.instant('common.comment'),
        icon: 'pi pi-fw pi-trash text-danger',
        command: () => {
          this.showRemoveCommentConfirmModal(index);
        }
      },
    ];
  }

  public subscribeToRenderComplete(): void {
    this.scrollSubject$.pipe(
      delay(200),
      debounceTime(500),
      distinctUntilChanged(),
      tap((fragment: string) => {
          this.preview(fragment);
      })).subscribe();
  }

  private createForm(): void {
    this.form = this.fb.group(
      {
        content: [null, [
          RxwebValidators.minLength({value: 1}),
          RxwebValidators.maxLength({value: 650000})
        ]],
      }
    );
  }

  public prependToPostCollection(post: PostViewModel): void {
    // this.dataSource.adapter.prepend({items: [post], bof: false});
    this.posts = [...[post], ...this.posts];
    setTimeout(() => {
      this.ig.updateItems();
    }, 50);
  }

  public removePostFromPostCollection(post: PostViewModel): void {
    // this.dataSource.adapter.remove({indexes: [this.posts.findIndex(e => e.id === post.id)]});
    this.posts = this.posts.filter(e => e.id !== post.id);
    setTimeout(() => {
      this.ig.updateItems();
    }, 50);
  }

  public async openPostContextMenu(post: PostViewModel) {
    this.initPostSlideMenu();
    this.isSidebarOpened = true;
    this.layoutComp.disableBodyScroll();
    this.contextMenuObject = {
      author: post.postedBy,
      avatar: post.postedBy.avatar,
      content: post.content,
      mediaObjectAMs: post.media,
      createdAt: post.createdAt,
      model: post
    };
    this.form.patchValue({content: post.content});
  }

  public openCommentContextMenu(commentAM: CommentApiModel, index: number): void {
    this.initCommentSlideMenu(index);
    this.isSidebarOpened = true;
    this.layoutComp.disableBodyScroll();
    this.contextMenuObject = {
      author: commentAM.author,
      avatar: commentAM.author.avatar as MediaObjectInterface,
      content: commentAM.content,
      mediaObjectAMs: [],
      createdAt: commentAM.createdAt,
      model: commentAM
    };
    this.form.patchValue({content: commentAM.content});
  }

  public updateComment(event: SocialInputSubmitInterface): void {
    const model = this.contextMenuObject.model;
    this.timelineDPS.updateComment({
      content: event.content,
      '@id': this.contextMenuObject.model['@id']
    }).subscribe((commentAM: CommentApiModel) => {
      model.content = commentAM.content;
      event.input.reset();
      this.clearMenuData();
    });
  }

  private showRemovePostConfirmModal(): void {
    this.isSidebarOpened = false;
    this.confirmationService.confirm({
      key: 'timeline',
      header: this.translate.instant('common.remove') + ' ' + this.translate.instant('common.post'),
      message: this.translate.instant('shared.timeline.post.remove_confirm'),
      accept: () => {
        this.ls.show();
        this.timelineDPS.removePost(this.contextMenuObject.model as PostViewModel).subscribe(() => {
          this.removePostFromPostCollection(this.contextMenuObject.model as PostViewModel);
          this.clearMenuData();
          this.ls.hide();
        });
      },
      reject: () => {
        this.clearMenuData();
      }
    });
  }

  private showRemoveCommentConfirmModal(index: number): void {
    this.isSidebarOpened = false;
    this.confirmationService.confirm({
      key: 'timeline',
      header: this.translate.instant('common.remove') + ' ' + this.translate.instant('common.comment'),
      message: this.translate.instant('shared.timeline.comment.remove_confirm'),
      accept: () => {
        this.ls.show();
        const commentAM = this.contextMenuObject.model as CommentApiModel;
        this.timelineDPS.removeComment(commentAM).subscribe(() => {
          this.timelineTileComponents.get(index).removeComment(commentAM);
          this.clearMenuData();
          this.ls.hide();
        }, () => {
          this.ls.hide();
        });
      },
      reject: () => {
        this.clearMenuData();
      }
    });
  }

  private clearMenuData(): void {
    this.isSidebarOpened = false;
    this.contextMenuObject = null;
    this.sidebarAction = null;
    this.layoutComp.enableBodyScroll();
  }

  public onSidebarHide(): void {
    this.clearMenuData();
  }

  private registerPostEventSource(): void {
    this.eventSource.listenSingleTopic<PostApiModel>(`/posts`).pipe(map((es: EventSourceInterface<PostApiModel>) => {
      const postAM = es.payload;
      let postVM = this.posts.find(e => e['@id'] === postAM['@id']);

      if (postVM) {
        postVM.state = es.state;
        postVM.content = postAM.content;
        return postVM;
      }

      postVM = new PostViewModel(postAM);
      postVM.state = es.state;
      return postVM;

    })).subscribe((postVM: PostViewModel) => {
      if (postVM.postedBy.slug === this.currentUser.slug) {
        return;
      }

      switch (postVM.state) {
        case 'persisted':
          this.prependToPostCollection(postVM);
          break;
        case 'removed':
          this.removePostFromPostCollection(postVM);
          break;
      }

      this.cdr.detectChanges();
    });
  }

  private registerCommentEventSource(): void {
    this.eventSource.listenSingleTopic<CommentApiModel>(`/comments`).subscribe((es: EventSourceInterface<CommentApiModel>) => {
      const commentAM = es.payload;
      const timelineTileComponent = this.timelineTileComponents.find((i: TimelineTileComponent) => i.post['@id'] === commentAM.post);

      switch (es.state) {
        case 'persisted':
        case 'updated':
          timelineTileComponent.upsertComment(commentAM);
          break;
        case 'removed':
          timelineTileComponent.removeComment(commentAM);
      }

      this.cdr.detectChanges();
    });
  }

  addPost(event: SocialInputSubmitInterface) {
    if (event.content.length < 1) {
      return;
    }
    this.timelineDPS.createPost(PostViewModel.create(
      event.content,
      this.postAsPostType,
      event.mediaObjectAMs,
      this.projectVM,
      this.eventAM
    ))
      .subscribe((postVM: PostViewModel) => {
        postVM.postedBy = this.currentUser;
        this.prependToPostCollection(postVM);
        this.addPostSocialInput.reset();
      });
  }

  public updatePost(event: SocialInputSubmitInterface): void {
    this.editPostSocialInput.loading = true;
    this.timelineDPS.updatePost(PostViewModel.update(event.content, event.mediaObjectAMs, this.contextMenuObject.model as PostViewModel))
      .subscribe((post: PostViewModel) => {
        const index = this.posts.findIndex(x => x['@id'] === post['@id']);
        this.posts[index] = post;
        this.clearMenuData();
        this.editPostSocialInput.reset();
        this.cdr.detectChanges();
      });
  }

  public update(event: SocialInputSubmitInterface): void {
    switch (this.sidebarAction) {
      case 'editPost':
        return this.updatePost(event);
      case 'editComment':
        return this.updateComment(event);
      default:
        console.error('sidebar action not implemented');
    }
  }

  groupBy(_: any, item: any) {
    return item.groupKey;
  }

  trackBy(_: any, item: any) {
    return item.key;
  }

  onRequestAppend(event: OnRequestAppend) {
    if (this.endOfResults || (this.totalItems && this.posts.length >= this.totalItems)) {
      return;
    }
    // tslint:disable-next-line:no-non-null-assertion
    // const nextGroupKey = ((+event.groupKey! || 0) + 1);
    event.wait();
    // event.currentTarget.appendPlaceholders(18, nextGroupKey);

    this.timelineDPS.getPostCollection(this.projectVM, this.eventAM, this.page, this._postTypes)
      .pipe(tap(() => this.totalItems = this.timelineDPS.totalPostItems))
      .subscribe((postVMs: PostViewModel[]) => {
        // this.ls.hide();
        if (!postVMs.length) {
          this.endOfResults = true;
          // this.timelineDPS.endOfResults = true;
        }

        // this.ig.renderer.update();
        this.posts = [...this.posts, ...postVMs];

        // const newRenderItems = postVMs.map((postVM: PostViewModel, i: number) => {
        //   return {groupKey: nextGroupKey, key: i, post: postVM};
        // });
        // this.renderItems = [...this.renderItems, ...newRenderItems];
        this.page++;
        // this.timelineDPS.items = this.posts;
        // this.timelineDPS.page = this.page;
        // this.ig.items = this.posts;
        event.ready();
      });
  }

  navigateToFragmentIfRequired(): void {
    this.isRendered = true;
    const fragment = this.route.snapshot.fragment;
    this.scrollSubject$.next(fragment);
  }

  navigateToList(item: any) {
    this.router.navigate([], {queryParams: {view: ViewTypeEnum.LIST}, fragment: 'item' + item.data.id}).then(() => {
      this.viewType = ViewTypeEnum.LIST;
      this.viewTypeChange.emit(this.viewType);
      this.actionsService.viewType = this.viewType;
    });
  }

  preview(id: any) {
    // if (window['onscrollend'] !== undefined) {
    //   addEventListener(
    //     'scrollend',
    //     (evt) => {console.log('test')},//this.router.navigate([], {queryParams: {view: this.viewType}}).then(() => this.actionsService.viewType = this.viewType),
    //     {once: true}
    //   );
    // }

    const element = document.querySelector(`#${id}`);
    if (element) {
      scrollIntoView(element, {block: 'nearest', scrollMode: 'if-needed'});

      element.scrollIntoView({behavior: 'auto', block: 'center'});
    }
  }
}

