import {PersonalInformationApiModel} from "../../../../api/models/personal-information.api.model";
import {AbstractViewModel} from "../../../../core/interfaces/abstract-view-model";

export class PersonalInformationViewModel extends AbstractViewModel<PersonalInformationApiModel> {
  public firstName: string;
  public lastName: string;
  public phone: string;
  public address: string;
  public streetNr: string;
  public houseNr: string;
  public town: string;
  public postcode: string;
  public province: string;
  public country: string;

  constructor(protected apiModel: PersonalInformationApiModel = new PersonalInformationApiModel()) {
    super(apiModel);
    this.fromApiModel(apiModel);
  }

  public fromApiModel(apiModel: PersonalInformationApiModel): this {
    this["@id"] = apiModel["@id"];
    this.firstName = apiModel.firstName;
    this.lastName = apiModel.lastName;
    this.phone = apiModel.phone;
    this.address = apiModel.address;
    this.streetNr = apiModel.streetNr;
    this.houseNr = apiModel.houseNr;
    this.town = apiModel.town;
    this.postcode = apiModel.postcode;
    this.province = apiModel.province;
    this.country = apiModel.country;
    return this;
  }

  public toApiModel(): PersonalInformationApiModel {
    const apiModel = this.apiModel;
    apiModel.firstName = this.firstName;
    apiModel.lastName = this.lastName;
    apiModel.phone = this.phone;
    apiModel.address = this.address;
    apiModel.streetNr = this.streetNr;
    apiModel.houseNr = this.houseNr;
    apiModel.town = this.town;
    apiModel.postcode = this.postcode;
    apiModel.province = this.province;
    apiModel.country = this.country;
    return apiModel;
  }
}
