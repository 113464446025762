import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ThumbnailComponent} from "./thumbnail.component";
import {SkeletonModule} from "primeng/skeleton";
import {Ripple} from "primeng/ripple";



@NgModule({
  declarations: [ThumbnailComponent],
  exports: [ThumbnailComponent],
    imports: [
        CommonModule,
        SkeletonModule,
        Ripple
    ]
})
export class ThumbnailModule { }
