import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DropdownModule} from './dropdown/dropdown.module';
import {LoaderComponent} from './loader/loader.component';
import {MediaModule} from './media/media.module';
import {ModalModule} from './modal/modal.module';
import {NotificationModule} from './notification/notification.module';
import {SocialModule} from './social/social.module';
import {SpinnerModule} from './spinner/spinner.module';
import {TimelineModule} from './timeline/timeline.module';
import {FooterComponent} from './footer/app.footer.component';
import {BackgroundPanelComponent} from './background-panel/background-panel.component';
import {RouterModule} from '@angular/router';
import {ButtonModule} from 'primeng/button';
import {EndCapComponent} from './end-cap/end-cap.component';
import {ImageCropperComponent} from './image-cropper/image-cropper.component';
import {ListingTileComponent} from './listing-tile/listing-tile.component';
import {CardModule} from 'primeng/card';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RxReactiveFormsModule} from '@rxweb/reactive-form-validators';
import {SliderModule} from 'primeng/slider';
import {NgxPermissionsModule} from 'ngx-permissions';
import {RippleModule} from 'primeng/ripple';
import {KeyFilterModule} from 'primeng/keyfilter';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {PipesModule} from '../core/pipes/pipes.module';
import {InputTextModule} from 'primeng/inputtext';
import {BadgeModule} from 'primeng/badge';
import {RatingModule} from 'primeng/rating';
import {PanelModule} from 'primeng/panel';
import {SidebarModule} from 'primeng/sidebar';
import {SlideMenuModule} from 'primeng/slidemenu';
import {TagModule} from 'primeng/tag';
import {ChipsModule} from 'primeng/chips';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {FocusTrapModule} from 'primeng/focustrap';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import {EndCapModule} from './end-cap/end-cap.module';
import {ThumbnailModule} from './thumbnail/thumbnail.module';
import {EditorModule} from 'primeng/editor';
import {MenuModule} from 'primeng/menu';
import {DataViewModule} from 'primeng/dataview';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {LanguageDropdownButtonComponent} from './language-dropdown-button/language-dropdown-button.component';
import {TranslateModule} from '@ngx-translate/core';
import {SpeedDialModule} from 'primeng/speeddial';
import {HtmlEditorModule} from './html-editor/html-editor.module';
import {CapacitorPluginsModule} from './capacitor-plugins/capacitor-plugins.module';
import {SkeletonModule} from 'primeng/skeleton';
import {EventTileComponent} from './event/event-tile/event-tile.component';
import {DirectivesModule} from './directives/directives.module';
import {BottomActionSheetModule} from './bottom-action-sheet/bottom-action-sheet.module';
import {provideClientHydration} from "@angular/platform-browser";
import {ImageCropperComponent  as NgxImageCropperComponent} from "ngx-image-cropper";


@NgModule({
    imports: [
        CommonModule,
        DropdownModule,
        MediaModule,
        NotificationModule,
        SocialModule,
        SpinnerModule,
        TimelineModule,
        ButtonModule,
        RouterModule,
        CardModule,
        MenuModule,
        InputTextareaModule,
        FormsModule,
        ReactiveFormsModule,
        RxReactiveFormsModule,
        SliderModule,
        NgxPermissionsModule,
        RippleModule,
        KeyFilterModule,
        InputMaskModule,
        InputSwitchModule,
        PipesModule.forRoot(),
        InputTextModule,
        BadgeModule,
        RatingModule,
        PanelModule,
        SidebarModule,
        SlideMenuModule,
        TagModule,
        ChipsModule,
        AutoCompleteModule,
        FocusTrapModule,
        ConfirmDialogModule,
        EndCapModule,
        ThumbnailModule,
        EditorModule,
        DataViewModule,
        TieredMenuModule,
        TranslateModule,
        SpeedDialModule,
        SidebarModule,
        HtmlEditorModule,
        CapacitorPluginsModule,
        SkeletonModule,
        MediaModule,
        DirectivesModule,
        BottomActionSheetModule,
        NgxImageCropperComponent
    ],
  declarations: [
    LoaderComponent,
    FooterComponent,
    BackgroundPanelComponent,
    ImageCropperComponent,
    ListingTileComponent,
    LanguageDropdownButtonComponent,
    EventTileComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    LoaderComponent,
    ModalModule,
    NotificationModule,
    ButtonModule,
    FooterComponent,
    BackgroundPanelComponent,
    EndCapComponent,
    ImageCropperComponent,
    ListingTileComponent,
    NgxPermissionsModule,
    RippleModule,
    KeyFilterModule,
    InputMaskModule,
    InputSwitchModule,
    PipesModule,
    InputTextModule,
    ReactiveFormsModule,
    BadgeModule,
    RatingModule,
    PanelModule,
    SidebarModule,
    SlideMenuModule,
    TagModule,
    ChipsModule,
    CardModule,
    MenuModule,
    AutoCompleteModule,
    FocusTrapModule,
    ConfirmDialogModule,
    ThumbnailModule,
    TimelineModule,
    DataViewModule,
    TieredMenuModule,
    LanguageDropdownButtonComponent,
    TranslateModule,
    HtmlEditorModule,
    EventTileComponent,
    MediaModule,
    BottomActionSheetModule
  ],
  providers: [
    provideClientHydration(),
    ConfirmationService
  ]
})
export class SharedModule {
}
