import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {QueryParamsService} from '../../core/services/query-params.service';
import {plainToClass, plainToClassFromExist} from 'class-transformer';
import {QueryParamsApiModel} from '../models/query-params-api.model';
import {ResultListApiModel} from '../models/result-list.api.model';
import {FollowApiModel} from '../models/follow.api-model';

@Injectable({
  providedIn: 'root'
})
export class FollowerRestService {
  private readonly resource: string = '/follows';

  constructor(
    private http: HttpClient,
    public params: QueryParamsService
  ) {
  }

  public create(projectAM: FollowApiModel): Observable<FollowApiModel> {
    return this.http.post<FollowApiModel>(environment.apiUrl + this.resource, projectAM)
      .pipe(map((result: FollowApiModel) => plainToClass(FollowApiModel, result)));
  }

  public put(followAM: FollowApiModel): Observable<FollowApiModel> {
    return this.http.put<FollowApiModel>(environment.apiUrl + followAM['@id'], followAM)
      .pipe(map((result: FollowApiModel) => plainToClass(FollowApiModel, result)));
  }

  public get(followAM: FollowApiModel): Observable<FollowApiModel> {
    return this.http.get<FollowApiModel>(environment.apiUrl + followAM['@id'])
      .pipe(map((result: FollowApiModel) => plainToClass(FollowApiModel, result)));
  }

  public delete(followAM: FollowApiModel): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(environment.apiUrl + followAM['@id']);
  }

  public getCollection(queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<ResultListApiModel<FollowApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<FollowApiModel>>(environment.apiUrl + this.resource, {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<FollowApiModel>(FollowApiModel), result as object)));
  }
}
