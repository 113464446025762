import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {PostViewModel} from '../post.view-model';
import {TimelineDataProviderService} from '../timeline.data-provider.service';
import {ResultListApiModel} from '../../../api/models/result-list.api.model';
import {CommentApiModel} from '../../../api/models/comment.api.model';
import {KeyValuePairInterface} from '../../interfaces/key-value-pair.interface';
import {EventSourceService} from '../../../core/services/event-source.service';
import {CurrentUserViewModel} from '../../../core/models/current-user.view-model';
import {MediaObjectApiModel} from '../../../api/models/media-object.api-model';
import {SocialInputSubmitInterface} from '../../social/social-input/social-input-submit.interface';
import {PostTypeEnum} from '../../../core/enums/post-type.enum';
import {EventShowTabsEnum} from '../../../features/event/event-show/event-show-tabs.enum';
import {ModalStateEvent} from '../../modal/modal-state-event';
import {ModalService} from '../../modal/modal.service';
import {MediaViewComponent} from '../../media-view/media-view.component';
import {VideoService} from '../video.service';
import {MediaTypeEnum} from '../../../core/enums/media-type.enum';
import * as _ from 'lodash';
import {MenuItem} from 'primeng/api';
import {ReactedWithToEmojiMapper} from '../../../core/helpers/reacted-with-to-emoji.mapper';
import {ReactedWithEnum} from '../../../core/enums/reacted-with.enum';
import {ReactionApiModel} from '../../../api/models/reaction.api.model';
import {UserApiModel} from '../../../api/models/user.api-model';
import {SpeedDialComponent} from '../../speed-dial/speed-dial.component';
import {ReactionActionTypes} from '../../../state/reaction/reaction.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../../../state/app.state';
import {ReactionTypeEnum} from '../../../core/enums/reaction-type.enum';
import {ReactionSubjectInterface} from './reaction-subject.interface';
import {TimelineComponent} from '../timeline.component';


@Component({
  selector: 'app-timeline-tile',
  templateUrl: './timeline-tile.component.html',
  styleUrls: ['./timeline-tile.component.scss']
})
export class TimelineTileComponent implements OnInit, AfterViewInit, OnDestroy {
  get post(): PostViewModel {
    return this._post;
  }

  @Input() set post(value: PostViewModel) {
    this._post = value;
    if (this._post.reactions?.length && this.currentUser) {
      this.reactionSubject = this._post;
      this.syncIsReacted();
    }
  }

  constructor(
    private readonly timelineDPS: TimelineDataProviderService,
    private readonly eventSource: EventSourceService,
    private readonly cdr: ChangeDetectorRef,
    private readonly renderer: Renderer2,
    private readonly ms: ModalService,
    private readonly vs: VideoService,
    private readonly store: Store<AppState>,
    private readonly timeline: TimelineComponent
  ) {

  }

  public activeIndex = 0;
  private _post: PostViewModel;
  @Input() currentUser: CurrentUserViewModel;
  @Input() permissions: string[] = [];
  @Output() onPostContextMenuOpen: EventEmitter<PostViewModel> = new EventEmitter<PostViewModel>();
  @Output() onCommentContextMenuOpen: EventEmitter<CommentApiModel> = new EventEmitter<CommentApiModel>();
  public embed: any;
  public isTextExpanded = false;
  public isCommentBoxOpened = false;
  public isThreadListOpened = false;
  public isThreadCommentListOpened: KeyValuePairInterface<number, boolean>[] = [];
  public isThreadCommentInputOpened: KeyValuePairInterface<number, boolean>[] = [];
  public comments: CommentApiModel[] = [];
  public commentBoxLoaded = false;
  public displayImgFullscreen = false;

  private swipeCoordinates?: [number, number];
  private swipeTime?: number;
  public skeletonRandomNumber: number;

  public readonly PostTypeEnum = PostTypeEnum;

  public readonly EventShowTabsEnum = EventShowTabsEnum;
  @ViewChild(MediaViewComponent) mediaView: MediaViewComponent;
  @ViewChild(SpeedDialComponent) speedDial: SpeedDialComponent;

  public readonly Array = Array;
  public items: MenuItem[];

  public readonly ReactedWithEnum = ReactedWithEnum;
  public readonly ReactionTypeEnum = ReactionTypeEnum;
  public reactionType: ReactionTypeEnum;
  public reactionSubject: ReactionSubjectInterface;

  ngOnInit() {
    this.skeletonRandomNumber = _.random(1, 5, false);

    this.items = [
      {
        state: {
          emoji: ReactedWithToEmojiMapper.map(ReactedWithEnum.FIRE)
        },
        command: () => {
          this.timelineDPS.createReaction(ReactedWithEnum.FIRE, this.reactionSubject, this.reactionType).subscribe((reactionAM: ReactionApiModel) => {
            this.syncReaction(reactionAM);
          });
        }
      },
      {
        state: {
          emoji: ReactedWithToEmojiMapper.map(ReactedWithEnum.HEART)
        },
        command: () => {
          this.timelineDPS.createReaction(ReactedWithEnum.HEART, this.reactionSubject, this.reactionType).subscribe((reactionAM: ReactionApiModel) => {
            this.syncReaction(reactionAM);
          });
        }
      },
      {
        state: {
          emoji: ReactedWithToEmojiMapper.map(ReactedWithEnum.THUMBS_UP)
        },
        command: () => {
          this.timelineDPS.createReaction(ReactedWithEnum.THUMBS_UP, this.reactionSubject, this.reactionType).subscribe((reactionAM: ReactionApiModel) => {
            this.syncReaction(reactionAM);
          });
        }
      },
      {
        state: {
          emoji: ReactedWithToEmojiMapper.map(ReactedWithEnum.FACE_SURPRISE)
        },
        command: () => {
          this.timelineDPS.createReaction(ReactedWithEnum.FACE_SURPRISE, this.reactionSubject, this.reactionType).subscribe((reactionAM: ReactionApiModel) => {
            this.syncReaction(reactionAM);
          });
        }
      },
    ];
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy() {
  }


  onCommentSubmit(event: SocialInputSubmitInterface, postVM: PostViewModel): void {
    this.timelineDPS.createComment(event.content, postVM).subscribe((commentAM: CommentApiModel) => {
      event.input.reset();
      this.comments.unshift(commentAM);
      this.post.comments.unshift(commentAM['@id']);
      this.assignReplyBooleanMap(commentAM);
      this.assignThreadCommentsMap(commentAM);
    });
  }

  onReplySubmit(event: SocialInputSubmitInterface, postVM: PostViewModel, commentAM: CommentApiModel): void {
    this.timelineDPS.createComment(event.content, postVM, commentAM).subscribe((replyAM: CommentApiModel) => {
      event.input.reset();
      commentAM.responses.push(replyAM);
    });
  }

  onCommentBoxOpen(postVM: PostViewModel): void {
    if (!this.isCommentBoxOpened) {
      this.commentBoxLoaded = false;
      this.timelineDPS.getCommentCollection(postVM)
        .subscribe((resultListAM: ResultListApiModel<CommentApiModel>) => {
          this.comments = resultListAM.records;
          this.comments.forEach((thread: CommentApiModel) => {
            thread.currentUserReaction = thread.reactions.find((reaction: ReactionApiModel) => reaction.author['@id'] === this.currentUser['@id']);
            thread.responses.forEach((response: CommentApiModel) => response.currentUserReaction = response.reactions.find((reaction: ReactionApiModel) => reaction.author['@id'] === this.currentUser['@id']));
          });
          this.assignReplyBooleanMap();
          this.assignThreadCommentsMap();
          this.commentBoxLoaded = true;
          this.cdr.detectChanges();
          // this.timeline.ig.updateItems();
        });
    }
    this.isCommentBoxOpened = !this.isCommentBoxOpened;
    this.cdr.detectChanges();
  }

  toggleReplyBooleanMap(commentAM: CommentApiModel): void {
    this.toggleThreadCommentsMap(commentAM);
    this.comments.forEach((c: CommentApiModel) => {
      const index = this.isThreadCommentInputOpened.findIndex(e => e.key === c.id);
      this.isThreadCommentInputOpened[index].value = this.isThreadCommentInputOpened[index].value ? false : commentAM.id === c.id;
    });
    // this.timeline.ig.updateItems();

  }

  assignReplyBooleanMap(commentAM?: CommentApiModel): void {
    if (commentAM) {
      this.isThreadCommentInputOpened.push(Object.assign({key: commentAM.id, value: false}));
      return;
    }

    this.comments.forEach((commentAM: CommentApiModel) => {
      this.isThreadCommentInputOpened.push(Object.assign({key: commentAM.id, value: false}));
    });
  }

  toggleThreadCommentsMap(commentAM: CommentApiModel): void {
    commentAM.responses = commentAM.responses.reverse();
    const index = this.isThreadCommentListOpened.findIndex(e => e.key === commentAM.id);
    this.isThreadCommentListOpened[index].value = !this.isThreadCommentListOpened[index].value;
    // this.timeline.ig.updateItems();
  }

  assignThreadCommentsMap(commentAM?: CommentApiModel): void {
    if (commentAM) {
      this.isThreadCommentListOpened.push(Object.assign({key: commentAM.id, value: false}));
      return;
    }

    this.comments.forEach((commentAM: CommentApiModel) => {
      this.isThreadCommentListOpened.push(Object.assign({key: commentAM.id, value: false}));
    });
  }

  isItemOpened(map: KeyValuePairInterface<number, boolean>[], comment: CommentApiModel): boolean {
    return map.find(e => e.key === comment.id).value;
  }

  public emitPostContextMenuOpen(post: PostViewModel): void {
    this.onPostContextMenuOpen.emit(post);
  }

  public emitCommentContextMenuOpen(commentAM: CommentApiModel): void {
    this.onCommentContextMenuOpen.emit(commentAM);
  }

  public removeComment(commentAM: CommentApiModel): void {
    if (commentAM.thread) {
      const thread = this.comments.find( e => e['@id'] === commentAM.thread);
      thread.responses = thread.responses.filter(e => e['@id'] !== commentAM['@id']);
      return;
    }
    this.comments = this.comments.filter(e => e['@id'] !== commentAM['@id']);
    this.post.comments = this.post.comments.filter(e => e !== commentAM['@id']);
  }

  public upsertComment(commentAM: CommentApiModel): void {
    if (commentAM.author.slug === this.currentUser.slug) {
      return;
    }

    if (commentAM.thread) {
      const thread = this.comments.find( e => e['@id'] === commentAM.thread);
      const reply = thread.responses.find( e => e['@id'] === commentAM['@id']);
      if (reply) {
        reply.content = commentAM.content;
        return;
      }
      thread.responses.push(commentAM);
      return;
    }

    const comment = this.comments.find(e => e['@id'] === commentAM['@id']);
    if (comment) {
      comment.content = commentAM.content;
      return;
    }

    this.assignReplyBooleanMap(commentAM);
    this.assignThreadCommentsMap(commentAM);
    this.comments.unshift(commentAM);
    this.post.comments.unshift(commentAM['@id']);
    this.cdr.detectChanges();
  }

  public imageContainerStyles(mediaObjectAM: MediaObjectApiModel, count: number): object {
    return {backgroundColor: 'black', backgroundSize: count > 1 ? 'cover' : 'contain',  backgroundPosition: 'center', backgroundRepeat: 'no-repeat', maxHeight: count > 1 && mediaObjectAM.type !== MediaTypeEnum.VIDEO  ? '480px' : '960px'};
  }

  log(val) {
    console.log(val);
  }

  openGalleria(event: Event, activeIndex: number, object?: MediaViewComponent): void {
    event.preventDefault();
    event.stopPropagation();
    this.activeIndex = activeIndex;
    if (object && object.player && object.player.paused) {
      this.vs.pauseAllExcept(object.id);
      object.togglePlay({event});
      return;
    }
    // this.displayImgFullscreen = true;
    this.vs.canScroll = false;

    this.ms.showOverlayGalleryModal({post: this.post, component: object , activeIndex}).successState.subscribe((modalStateEvent: ModalStateEvent) => {
      const player = modalStateEvent.data.component.player;
      if (player) {
        player.currentTime = modalStateEvent.data.currentTime;
        player.muted = this.vs.muted;
        modalStateEvent.data.component.player.play();
      }
      setTimeout(() => this.vs.canScroll = true, 200);
      // this.historyPostVM = modalStateEvent.data;
      // this.historyPostVMs.unshift(this.historyPostVM);
    });
  }

  toggleThreadList(): void {
    this.isThreadListOpened = !this.isThreadListOpened;
    // this.timeline.ig.updateItems();
  }

  public toggleReactionBtn(e, subject: ReactionSubjectInterface, type: ReactionTypeEnum, speedDial: SpeedDialComponent) {
    e.preventDefault();
    this.reactionType = type;
    this.reactionSubject = subject;
    speedDial.onButtonClick(e);
  }

  public toggleThumbsUp(e, subject: ReactionSubjectInterface, type: ReactionTypeEnum, speedDial: SpeedDialComponent) {
    e.preventDefault();
    this.reactionType = type;
    this.reactionSubject = subject;

    if (speedDial.visible) {
      return;
    }

    if (subject.currentUserReaction) {
      this.timelineDPS.removeReaction(subject.currentUserReaction).subscribe(() => {
        subject.reactions = subject.reactions.filter((x: ReactionApiModel) => (x.author as UserApiModel)['@id'] !== (subject.currentUserReaction.author as UserApiModel)['@id']);
        subject.currentUserReaction = null;
      });
      return;
    }

    this.timelineDPS.createReaction(ReactedWithEnum.THUMBS_UP, subject, this.reactionType).subscribe((reactionAM: ReactionApiModel) => {
      this.syncReaction(reactionAM);
    });
  }


  public syncReaction(reactionAM: ReactionApiModel): void {
    this.reactionSubject.reactions = this.reactionSubject.reactions.filter((x: ReactionApiModel) => (x.author as UserApiModel)['@id'] !== (reactionAM.author as UserApiModel)['@id']);
    this.reactionSubject.reactions.unshift(reactionAM);
    this.syncIsReacted();
  }

  public syncIsReacted(): void {
    this.reactionSubject.currentUserReaction = this.reactionSubject.reactions?.find((x: ReactionApiModel) => (x.author as UserApiModel)['@id'] === this.currentUser['@id']);
  }

  public openSocialModal(e: Event, subject: ReactionSubjectInterface) {
    console.log(e);
    e.preventDefault();
    e.stopImmediatePropagation();
    this.reactionSubject = subject;
    this.store.dispatch({type: ReactionActionTypes.GET_ALL, reactions: this.reactionSubject.reactions});
    this.ms.showReactionsModal().successState.subscribe();
  }
}
