import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ModalInterface} from "../../modal.interface";
import {Observable, Subject} from "rxjs";
import {ModalStateEvent} from "../../modal-state-event";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {ModalState} from "../../modal-state.enum";
import {RxwebValidators} from "@rxweb/reactive-form-validators";
import {GoalDataProviderService} from "../../../../features/user/project/goals/goal.data-provider.service";
import {ProjectViewModel} from "../../../../features/user/garage/project.view-model";
import {TaskViewModel} from "../../../../features/user/project/goals/task.view-model";
import {GoalViewModel} from "../../../../features/user/project/goals/goal.view-model";

@Component({
  selector: 'app-add-goal',
  templateUrl: './add-goal.component.html',
  styleUrls: ['./add-goal.component.scss'],
  providers: [GoalDataProviderService]
})
export class AddGoalComponent implements ModalInterface, OnInit, AfterViewInit {

  public data: { projectVM: ProjectViewModel, goalVM?: GoalViewModel };
  public state: Observable<ModalStateEvent>;
  public subject: Subject<ModalStateEvent>;
  public form: UntypedFormGroup;
  public availableMarkerIcons = [
    {name: 'Detailing', icon: 'car-detailing.png'},
    {name: 'Engine power', icon: 'engine-power.png'},
    {name: 'Event', icon: 'car-meeting.png'},
    {name: 'Service', icon: 'car-repair.png'},
    {name: 'Cooling', icon: 'car-radiator.png'},
    {name: 'Fuel', icon: 'gasoline.png'},
    {name: 'Brake', icon: 'brake_alt.png'},
    {name: 'Battery', icon: 'car-battery.png'},
    {name: 'Car wrap', icon: 'car-door.png'},
    {name: 'Lights', icon: 'car-lights.png'},
    {name: 'Interior', icon: 'car-seat.png'},
    {name: 'Suspension', icon: 'car-suspension.png'},
    {name: 'Clutch', icon: 'clutch-disc.png'},
    {name: 'Hood', icon: 'hood.png'},
    {name: 'Map', icon: 'diagnosis.png'},
    {name: 'Exhaust', icon: 'exhaust-pipe.png'},
    {name: 'Car protection', icon: 'insurance.png'},
    {name: 'Differential', icon: 'machine.png'},
    {name: 'Painting', icon: 'painting.png'},
    {name: 'Pistons', icon: 'piston.png'},
    {name: 'Track day', icon: 'racing-car.png'},
    {name: 'Maintenance', icon: 'spare-parts.png'},
    {name: 'Spark plug', icon: 'spark-plug.png'},
    {name: 'Speedometer', icon: 'speedometer.png'},
    {name: 'Aerodynamic', icon: 'spoiler.png'},
    {name: 'Steering wheel', icon: 'steering-wheel.png'},
    {name: 'Transmission', icon: 'transmission.png'},
    {name: 'Turbo', icon: 'turbo-engine.png'},
    {name: 'Water pump', icon: 'water-pump.png'},
  ];

  public tasks: TaskViewModel[] = [];
  public initialTask: TaskViewModel = {id: null, name: null, isFinished: false, inEdit: false, summaryCost: null};
  public inputTask: TaskViewModel = {...this.initialTask};
  public goalVM: GoalViewModel;
  public cols = [
    {field: 'name', header: 'shared.modal.component.add_goal.stage', type: 'readonly'},
    {field: 'isFinished', header: '', type: 'checkbox'},
    {field: 'summaryCost', header: 'shared.modal.component.add_goal.cost', type: 'currency'}
  ];

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly elementRef: ElementRef,
    private readonly goalDPS: GoalDataProviderService,
  ) {
  }


  ngOnInit(): void {
    this.goalVM = this.data.goalVM;
    this.createForm();
    this.watchFormStatusChange();
  }

  ngAfterViewInit(): void {
    // this.overrideButtonStyles('.p-picklist-buttons button[icon^="pi pi-angle"]', ['p-button-rounded', 'p-button-outlined'])
    // this.overrideButtonStyles('.p-fileupload .p-button', ['p-button-icon-only', 'p-button-rounded', 'p-button-outlined'])
  }

  // public overrideButtonStyles(querySelector: string, classList: string[]): void {
  //   const buttons = this.elementRef.nativeElement.querySelectorAll(querySelector);
  //   if (buttons.length) {
  //     for (let elem of buttons) {
  //       elem.classList.add(...classList);
  //     }
  //   }
  // }

  private createForm(): void {
    this.form = this.fb.group({
      name: new UntypedFormControl(null, [RxwebValidators.required(), RxwebValidators.minLength({value: 3}), RxwebValidators.maxLength({value: 255})]),
      startAt: new UntypedFormControl(new Date(), [RxwebValidators.date()]),
      finishedAt: new UntypedFormControl(null, [RxwebValidators.date()]),
      estimatedFinishAt: new UntypedFormControl(null, [RxwebValidators.date()]),
      description: new UntypedFormControl(null, [RxwebValidators.minLength({value: 3})]),
      isFinished: new UntypedFormControl(null, []),
      hasTasks: new UntypedFormControl(null, []),
      // markerIcon: new FormControl(null, [RxwebValidators.oneOf({matchValues: this.availableMarkerIcons.map(e => e.icon)})]),
    });

    if (this.goalVM) {
      this.form.setValue({
        name: this.goalVM.name,
        startAt: this.goalVM.startAt || new Date(),
        finishedAt: this.goalVM.finishedAt,
        estimatedFinishAt: this.goalVM.estimatedFinishAt,
        description: this.goalVM.description,
        isFinished: !!this.goalVM.finishedAt,
        hasTasks: !!this.goalVM.tasks.length
      });
      this.tasks = this.goalVM.tasks;
      this.tasks.forEach(e => e.isFinished = !!e.finishedAt);
      this.subject.next(new ModalStateEvent(ModalState.VALID));
    }

    this.form.get('isFinished').valueChanges.subscribe((value: boolean) => {
      if (value) {
        this.tasks.forEach(e => e.isFinished = value);
      }
      this.inputTask.isFinished = value;
      this.initialTask.isFinished = value;
    });
    this.form.valueChanges.subscribe(() => {
      if (this.form.valid) {
        this.subject.next(new ModalStateEvent(ModalState.VALID));
      } else {
        this.subject.next(new ModalStateEvent(ModalState.INVALID));
      }
    })
  }

  private watchFormStatusChange(): void {
    this.state.subscribe((mse: ModalStateEvent) => {
      switch (mse.status) {
        case ModalState.PENDING: this.submit();
      }
    })
    // this.form.statusChanges.subscribe((e) => {
    //   if (e === ModalState.VALID) {
    //     this.subject.next(new ModalStateEvent(ModalState.VALID, this.form));
    //   }
    // });
  }

  // public handleButtonOverride(event): void {
  //   setTimeout(() => {
  //     this.overrideButtonStyles('.p-fileupload .p-button', ['p-button-icon-only', 'p-button-rounded', 'p-button-outlined']);
  //   }, 1)
  // }

  public addTask(): void {
    this.tasks = this.appendOrReplaceElement(this.inputTask, this.tasks);
    this.clearInputTask();
  }

  private appendOrReplaceElement(element: TaskViewModel, list: TaskViewModel[]): TaskViewModel[] {
    if (element.inEdit && list.length) {
      list = list.map(t => t.inEdit ? element : t);
    } else {
      list.push(element);
    }
    element.inEdit = false;
    return list;
  }

  public clearInputTask() {
    this.inputTask = {...this.initialTask};
  }

  public removeTask() {
    this.clearInputTask();
    this.tasks = this.tasks.filter(e => !e.inEdit);
  }

  submit(): void {
    this.tasks.forEach((e) => e.isFinished && !e.finishedAt ? e.finishedAt = new Date() : null);
    if (!this.data.goalVM) {
      this.goalDPS.postGoal({
        ...this.form.value,
        state: 'NOT_PUBLISHED',
        project: this.data.projectVM['@id'],
        tasks: this.tasks
      })
        .subscribe((goalVM: GoalViewModel) => {
          this.data.goalVM = goalVM;
          this.form.reset();
          this.subject.next(new ModalStateEvent(ModalState.SUCCESS, this.data));
        });
    } else {
      this.goalDPS.putGoal({
        ...this.goalVM,
        ...this.form.value,
        state: 'NOT_PUBLISHED',
        project: this.data.projectVM['@id'],
        tasks: this.tasks
      }).subscribe((goalVM: GoalViewModel) => {
        this.data.goalVM = goalVM;
        this.form.reset();
        this.subject.next(new ModalStateEvent(ModalState.SUCCESS, this.data));
      })
    }
  }

  updateTemplate(): void {
    // this.goalDPS.putGoal();
  }

  selectTask(event: any): void {
    this.inputTask = event.data;
    this.inputTask.inEdit = true;
  }

  unselectTask(): void {
    this.inputTask.inEdit = false;
    this.clearInputTask();
  }
}
