import {AfterViewInit, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {ProjectViewModel} from '../user/garage/project.view-model';
import {RankingDataProviderService} from "./ranking.data-provider.service";
import {PostViewModel} from "../../shared/timeline/post.view-model";

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss']
})
export class RankingComponent implements OnInit {

  public projects: ProjectViewModel[] = Array.from({length: 9}, (value, key) => {
    const projectVM = new ProjectViewModel()
    projectVM.isPlaceholder = true;
    return projectVM;
  });

  constructor(
    private readonly rankingDPS: RankingDataProviderService
  ) {}

  ngOnInit(): void {
    this.rankingDPS.fetchRankingResources().subscribe((projectViewModels: ProjectViewModel[]) => {
      this.projects = projectViewModels;
    });
  }

  getIconForStar(star: number, value: number): string {
    const fullStarIcon = 'fa-solid fa-star';
    const halfStarIcon = 'fa-solid fa-star-half-stroke';
    let icon = 'fa-regular fa-star';

    if (value >= star) {
      icon = fullStarIcon;
    } else if (value > (star - 1) && value < star) {
      icon = halfStarIcon;
    }

    return icon;
  }
}
