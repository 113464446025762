import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CopyClipboardDirective} from './copy-clipboard.directive';
import {SwiperDirective} from './swiper.directive';



@NgModule({
  declarations: [
    CopyClipboardDirective,
    // SwiperDirective
  ],
  exports: [
    // SwiperDirective,
    CopyClipboardDirective
  ],
  imports: [
    CommonModule
  ]
})
export class DirectivesModule { }
