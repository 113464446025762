import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-auth',
  template: `
    <div class="layout-content outer" [ngStyle]="currentStyles">
      <app-language-dropdown-button class="lang-button-absolute"></app-language-dropdown-button>
      <div class="middle grid">
        <div class="inner col-12 md:col-8 lg:col-5 xl:col-4 grid-nogutter" style="max-width: 600px">
          <div class="auth-box border-round-md">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>`
})
export class AuthComponent implements OnInit {
  get currentStyles() {
    return this.route.snapshot.data['background'];
  }
  constructor(
    private readonly route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }

}
